import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { QueryBuilder } from "@/utils/query-builder";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { FetchClient } from "@/services/ApiClient";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { useLanguage } from "@/i18n/config";

type ChangeOverSummaryResponse = ThingworxError | ChangeOverSummaryResult;

const ChangeOverSummaryResult = z.object({
  response: z.literal(true),
  Duration: z.number(),
  Count: z.number(),
});

type ChangeOverSummaryResult = z.infer<typeof ChangeOverSummaryResult>;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  dateEnd?: DateTime;
  dateStart?: DateTime;
  language_id: string;
};

/**
 * returns the data for 2 little widget that display the duration and count
 */
export const useGetCountDurationSelector = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();

  const { timeSelection, dates } = useTimeSelection();
  const language = useLanguage();

  const query = QueryBuilder.buildWithCondition({
    baseQuery: [
      "change-over-counters",
      machine?.machine,
      timeSelection,
      language,
    ],
    condition: timeSelection === "Custom",
    query: [dates.dateStart, dates.dateEnd],
  });

  const payload: Payload = {
    machineName: machine?.machine || "",
    timeSelection,
    language_id: language,
  };

  return useQuery({
    queryKey: query,
    queryFn: async () => {
      const response = await FetchClient<Payload, ChangeOverSummaryResponse>({
        appKey,
        payload:
          timeSelection === "Custom"
            ? { ...payload, dateStart: dates.dateStart, dateEnd: dates.dateEnd }
            : payload,
        url: "dig.c.changeOver_thing/Services/getCountDurationSelector",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = ChangeOverSummaryResult.parse(response);

      return validatedResponse;
    },
  });
};
