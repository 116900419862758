import { colors } from "@/styles/colors";

export function convertKpiToArray(kpi: {
  [key: string]: number;
}): [string, number][] {
  const kpiArray = Object.entries(kpi);
  return kpiArray.sort((a, b) => {
    const order = [
      "oee",
      "availability",
      "performance",
      "idleTime",
      "scheduledTime",
      "notScheduledTime",
    ];
    return order.indexOf(a[0]) - order.indexOf(b[0]);
  });
}

export const customBarChartColors = [
  colors.kpi.oee,
  colors.kpi.availability,
  colors.kpi.performance,
  colors.kpi.idleTime,
  colors.kpi.scheduledTime,
  colors.kpi.notScheduledTime,
];

export const formatValue = (value: number): number => {
  if (Number.isInteger(value)) return value;
  if (Math.abs(value) < 0.01) return parseFloat(value.toFixed(4));
  return parseFloat(value.toFixed(2));
};
