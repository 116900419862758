import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { useIsolatorContext } from "../../context/useIsolatorContext";
import { BatchDiagnosticPhaseAnalytic } from "../../types";
import { useGetBatchDiagnosticAnalyticsList } from "../../api/Diagnostic/useGetBatchDiagnosticAnalyticsList";
import { BatchDiagnosticAssetSelection } from "../../components/Diagnostic/BatchDiagnosticAssetSelection";
import { BatchDiagnosticChart } from "../../components/Diagnostic/BatchDiagnosticChart";
import { Check, PriorityHigh } from "@mui/icons-material";
import { EVALUATION_COLORS } from "../../utils/evaluationColors";

export const BatchDiagnostic = () => {
  const translate = useTranslate();
  const [selectedAnalytic, setSelectedAnalytic] =
    useState<BatchDiagnosticPhaseAnalytic | null>(null);
  const { batches } = useIsolatorContext();
  const { data, isLoading, error } = useGetBatchDiagnosticAnalyticsList();

  useEffect(() => {
    setSelectedAnalytic(null);
  }, [batches]);

  if (isLoading) return <SkeletonCard width={320} height={400} />;
  if (error) return <Card>{translate("user_feedback.an_error_occurred")}</Card>;
  if (!data) return <Card>{translate("user_feedback.no_data")}</Card>;

  if (data.analytics.length === 0)
    return (
      <Card sx={{ width: 320, height: 400 }}>
        {translate("user_feedback.no_data_to_display")}
      </Card>
    );

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Card sx={{ width: 320, height: 400, overflowY: "auto" }}>
          <Typography variant="h6">{translate("analytics_other")}</Typography>
          {batches.length === 0 ? (
            <div>{translate("isolator.no_batch_selected")}</div>
          ) : (
            <FormControl>
              <RadioGroup
                onChange={(
                  _: React.ChangeEvent<HTMLInputElement>,
                  value: string,
                ) => {
                  const foundAnalytic = data.analytics.find(
                    (el) => el.id === parseInt(value),
                  );
                  if (foundAnalytic) setSelectedAnalytic(foundAnalytic);
                }}
                value={selectedAnalytic ? selectedAnalytic.id : null}
              >
                {data.analytics.map((analytic) => (
                  <Box
                    key={`radio-${analytic.id}`}
                    sx={{
                      display: "flex",
                      width: 280,
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value={analytic.id}
                      label={analytic.analyticName}
                    />
                    {analytic.hasWarn ? (
                      <PriorityHigh
                        sx={{ color: EVALUATION_COLORS.warning.selected }}
                      />
                    ) : (
                      <Check sx={{ color: EVALUATION_COLORS.good.selected }} />
                    )}
                  </Box>
                ))}
              </RadioGroup>
            </FormControl>
          )}
        </Card>
      </Box>
      {batches.length > 0 &&
        selectedAnalytic &&
        (selectedAnalytic.assets.length > 0 ? (
          <BatchDiagnosticAssetSelection
            assets={selectedAnalytic.assets}
            analyticId={selectedAnalytic.id}
          />
        ) : (
          <BatchDiagnosticChart
            analyticId={selectedAnalytic.id}
            assetIds={selectedAnalytic.assets.map((asset) => asset.id)}
          />
        ))}
    </Box>
  );
};
