import { useState } from "react";
import { toast } from "react-hot-toast";
import {
  Stack,
  Typography,
  Menu,
  MenuItem,
  Box,
  CircularProgress,
} from "@mui/material";
import { CheckRounded } from "@mui/icons-material";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslate } from "@/i18n/config";
import { useFirebaseContext } from "@/context/firebase-context";
import { userConfigurationStore } from "../../store/user-configuration-store";
import { IntellectaVersion } from "../../types";
import { chatStore } from "../../store/chat-store";
import { useSetActiveProfile } from "../../api/useSetActiveProfile";
import { useGetUserConfiguration } from "../../api/useGetUserConfiguration";

export const VersionProfileMenu = () => {
  const translate = useTranslate();
  const {
    version,
    profile,
    role,
    availableVersionsAndProfiles,
    setLastMachineId,
  } = userConfigurationStore();
  const { clearChat } = chatStore();
  const { user } = useFirebaseContext();
  const email = user?.email || "";
  const queryClient = useQueryClient();
  const { mutate: setActiveProfile, isPending: isSettingActiveProfile } =
    useSetActiveProfile();
  const { isFetching: isGettingUserConfiguration } = useGetUserConfiguration();

  // Stato per il controllo del menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // Handler per aprire e chiudere il menu
  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectVersion = ({
    newVersion,
  }: {
    newVersion: IntellectaVersion;
  }) => {
    if (newVersion === version) return handleClose();
    const newVersionObj = availableVersionsAndProfiles.find(
      (v) => v.name === newVersion,
    );
    const newProfile = newVersionObj
      ? newVersionObj.profiles.length > 0
        ? newVersionObj.profiles[0].name
        : null
      : null;
    if (!newProfile)
      return toast.error(translate("user_feedback.an_error_occurred"));
    setLastMachineId(null);
    setActiveProfile(
      { version: newVersion, profile: newProfile },
      {
        onSuccess: () => {
          clearChat();
          queryClient.invalidateQueries({
            queryKey: ["intellecta-historic-chat-list", email],
          });
          handleClose();
        },
        onSettled() {
          toast.error(translate("user_feedback.an_error_occurred"));
        },
      },
    );
  };

  const handleSelectProfile = ({
    newVersion,
    newProfile,
  }: {
    newVersion: IntellectaVersion;
    newProfile: string;
  }) => {
    if (newVersion === version && newProfile === profile) return handleClose();
    setLastMachineId(null);
    setActiveProfile(
      { version: newVersion, profile: newProfile },
      {
        onSuccess: () => {
          clearChat();
          queryClient.invalidateQueries({
            queryKey: ["intellecta-historic-chat-list", email],
          });
          handleClose();
        },
      },
    );
  };

  if (!version) return null;

  return (
    <>
      {/* Contenitore cliccabile */}
      <Stack
        sx={{
          border: "1px solid #adadadad",
          padding: 1,
          width: "100%",
          borderRadius: 2,
          overflow: "hidden",
          whiteSpace: "nowrap",
          cursor: "pointer",
          ":hover": { backgroundColor: "#ffffff10" },
        }}
        onClick={handleOpen}
      >
        {isSettingActiveProfile || isGettingUserConfiguration ? (
          <CircularProgress size={24} sx={{ alignSelf: "center" }} />
        ) : (
          <Typography
            variant="body1"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {translate(`intellecta.version_${version as IntellectaVersion}`)}
          </Typography>
        )}
      </Stack>

      {/* Menu */}
      {!isSettingActiveProfile && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              sx: { maxHeight: 300, width: 300, borderRadius: 2 },
            },
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {role === "base" &&
            availableVersionsAndProfiles.map((v) => (
              <MenuItem
                key={v.name}
                onClick={() => handleSelectVersion({ newVersion: v.name })}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    maxWidth: v.name === version ? "100%" : "90%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {translate(
                    `intellecta.version_${v.name as IntellectaVersion}`,
                  )}
                </Typography>
                {v.name === version && <CheckRounded sx={{ fontSize: 16 }} />}
              </MenuItem>
            ))}
          {role === "premium" &&
            availableVersionsAndProfiles.map((v) => (
              <Box key={v.name}>
                <Typography
                  variant="caption"
                  px="1rem"
                  py=".5rem"
                  sx={{ color: "#aaa" }}
                >
                  {translate(
                    `intellecta.version_${v.name as IntellectaVersion}`,
                  ).toUpperCase()}
                </Typography>
                {v.profiles.map((p, index) => (
                  <MenuItem
                    key={p.name}
                    onClick={() =>
                      handleSelectProfile({
                        newVersion: v.name,
                        newProfile: p.name,
                      })
                    }
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: index === v.profiles.length - 1 ? 2 : 0,
                    }}
                  >
                    <Typography
                      sx={{
                        maxWidth: p.name === profile ? "100%" : "90%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {p.name.replace(/_/g, " ")}
                    </Typography>
                    {p.name === profile && (
                      <CheckRounded sx={{ fontSize: 16 }} />
                    )}
                  </MenuItem>
                ))}
              </Box>
            ))}
        </Menu>
      )}
    </>
  );
};
