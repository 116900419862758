import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import { intellectaClient } from "../intellectaClient";
import {
  IntellectaErrorObj,
  IntellectaResultObj,
  intellectaResultObj,
} from "../../types";

type Payload = {
  user: string;
  prompt: {
    id: number;
    prompt: string;
    label: string;
    description: string;
  };
};

type Response = IntellectaErrorObj | IntellectaResultObj;

export const useUpdatePrompt = () => {
  const queryClient = useQueryClient();
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  return useMutation({
    mutationFn: async ({
      id,
      prompt,
      label,
      description,
    }: {
      id: number;
      prompt: string;
      label: string;
      description: string;
    }) => {
      const response = await intellectaClient<Payload, Response>({
        url: "/prompt/update",
        payload: {
          user: email,
          prompt: {
            id,
            prompt,
            label,
            description,
          },
        },
      });

      if (response.value === "ERROR") throw new Error();

      const validatedResponse = intellectaResultObj.parse(response);
      return validatedResponse;
    },
    onSuccess: (data) => {
      if (data.value === "OK") {
        //* per scaricare di nuovo i prompt
        queryClient.invalidateQueries({
          queryKey: ["intellecta-config", email],
        });
      }
    },
  });
};
