import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ChatInput } from "./ChatInput";
import { ChatMessage } from "../ChatMessage";
import { chatStore } from "../../store/chat-store";
import { useAskQuestion } from "../../api/useAskQuestion";
import { scrollChat } from "../../utilities/scrollChat";
import { DefaultChatMessage } from "./DefaultChatMessage";
import { InfoDialogButton } from "../InfoDialog";
import { useTranslate } from "@/i18n/config";
import {
  SENTRY_MODE_MESSAGES,
  SENTRY_MODE_TIMER_BETWEEN_MESSAGES_S,
  sentryModeStore,
} from "../../store/sentry-mode-store";
import { useCheckIfMessageBanner } from "@/hooks/useCheckIfInformationBanner";
import { LEFT_DRAWER_WIDTH, RIGHT_DRAWER_WIDTH } from "../..";
import { userConfigurationStore } from "../../store/user-configuration-store";
import { NewChatButton } from "../LeftDrawer/NewChatButton";

let index = 0;

export const ChatPanel = ({
  userInput,
  setUserInput,
  isLeftDrawerOpen,
  isRightDrawerOpen,
}: {
  userInput: string;
  setUserInput: (inputText: string) => void;
  isLeftDrawerOpen: boolean;
  isRightDrawerOpen: boolean;
}) => {
  const { showBanner } = useCheckIfMessageBanner();
  const translate = useTranslate();
  const theme = useTheme();
  const { mutateAsync: askQuestion, isPending } = useAskQuestion();
  const { messages, addMessage, setChatId, isAnswering, clearChat } =
    chatStore();
  const { machine } = userConfigurationStore();
  const { isActive, resetTimer } = sentryModeStore();
  const { version } = userConfigurationStore();

  const submitHandler = () => {
    if (version === "troubleshooting" && !machine) {
      return toast.error(translate("intellecta.no_machine_selected"));
    }
    resetTimer();

    addMessage({
      sender: "User",
      message: userInput,
    });

    scrollChat();

    askQuestion(
      {
        query: userInput,
      },
      {
        onError() {
          return toast.error(translate("user_feedback.an_error_occurred"));
        },
      },
    )
      .then((response) => {
        addMessage({
          sender: "Intellecta",
          message: response.value,
          image: response.image,
          id: response.message_id,
        });
        setChatId(response.chat_id);
      })
      .finally(() => {
        setUserInput("");
        resetTimer();
        scrollChat();
      });
  };

  useEffect(() => {
    let sentryModeInterval: NodeJS.Timeout | null;

    const printSentryModeMessage = () => {
      addMessage({
        message: SENTRY_MODE_MESSAGES[index],
        sender: "Sentry Mode",
      });

      if (index > 28) {
        index = 0;
      } else {
        index++;
      }

      scrollChat();
    };

    if (isActive) {
      printSentryModeMessage();
      sentryModeInterval = setInterval(
        printSentryModeMessage,
        SENTRY_MODE_TIMER_BETWEEN_MESSAGES_S * 1000,
      );
    }

    return () => {
      clearChat();
      if (sentryModeInterval) clearTimeout(sentryModeInterval);
    };
  }, [isActive, addMessage, clearChat]);

  return (
    <Stack
      sx={{
        bgcolor: theme.palette.background.default,
        width: "100%",
        height: "100%",
        pt: showBanner ? "32px" : 0,
      }}
    >
      <Stack
        id="chat-container"
        sx={{
          gap: 2,
          width: "100%",
          height: "100%",
          p: 2,
          overflowY: "auto",
        }}
      >
        {messages.length === 0 && <DefaultChatMessage />}
        {messages.map((message, i) => {
          return <ChatMessage key={i} {...message} />;
        })}
        {(isPending || isAnswering) && (
          <ChatMessage
            sender="Intellecta"
            message={""}
            isLoading={isPending}
            disableFeedback
          />
        )}
      </Stack>
      <Box
        sx={{
          position: "absolute",
          bottom: 30,
          left: 12,
          transform: isLeftDrawerOpen
            ? `translateX(${LEFT_DRAWER_WIDTH}px)`
            : "translateX(0)",
          transition: "transform 0.16s ease-in-out",
        }}
      >
        <NewChatButton />
      </Box>
      <Stack
        sx={{
          p: 2,
          pb: 0.5,
          alignItems: "center",
        }}
      >
        <ChatInput
          disabled={isPending}
          onChange={(e) => {
            setUserInput(e.target.value);
          }}
          onSubmit={submitHandler}
          value={userInput}
        />
        <Typography
          variant="caption"
          sx={{
            pt: 0.5,
            color: "#ddd",
          }}
        >
          {translate("intellecta.disclaimer_2")}
        </Typography>
      </Stack>
      <Box
        sx={{
          position: "absolute",
          right: 12,
          bottom: 30,
          transform: isRightDrawerOpen
            ? `translateX(-${RIGHT_DRAWER_WIDTH}px)`
            : "translateX(0)",
          transition: "transform 0.16s ease-in-out",
        }}
      >
        <InfoDialogButton />
      </Box>
    </Stack>
  );
};
