import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { Card } from "@/components/Layout/Card";
import { Stack } from "@mui/material";

export interface ChartProps {
  chartName: string;
  dataArray: {
    name: string;
    type: "line";
    data: number[][];
    color: string;
  }[];
}

export const Health = ({ chartsData }: { chartsData: ChartProps[] }) => {
  return (
    <Stack
      sx={{
        gap: 1,
      }}
    >
      {chartsData.map((chart, index) => (
        <Card sx={{ height: { md: "250px" } }} key={index}>
          <LineChart.Custom
            title={chart.chartName}
            height={240}
            xAxisOptions={{
              scrollbar: {
                enabled: false,
              },
            }}
            legend={{ enabled: true }}
            yAxis={[
              {
                uom: "%",
                options: {
                  type: "linear",
                  max: 100,
                },
                series: chart.dataArray,
              },
            ]}
          />
        </Card>
      ))}
    </Stack>
  );
};
