import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import {
  ImputationLogResponse,
  imputationLogResult,
  ImputationLogResult,
} from "../types";
import { useLanguage } from "@/i18n/config";

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  language_id: string;
};

export const useGetImputationLogData = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { timeSelection } = useTimeSelection();
  const language = useLanguage();

  return useQuery<ImputationLogResult>({
    queryKey: [
      "imputation-log-data",
      machine!.machine,
      timeSelection,
      language,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, ImputationLogResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
          timeSelection,
          language_id: language,
        },
        url: "dig.c.stopImputation_thing/Services/getImputationLogData",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = imputationLogResult.parse(response);

      return validatedResponse;
    },
  });
};
