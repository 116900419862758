import { Stack, Typography } from "@mui/material";
import { RangeCalendar } from "./RangeCalendar";
import { DateRange } from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import { useState } from "react";
import { useGetChartData } from "../api/useGetChartData";
import { AggregableCustomChart, CustomChart } from "./CustomChart";
import { SentinelLoader } from "@/components/Layout/SentinelLoader";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { timezoneStore } from "@/store/useTimezoneStore";

export const ChartLoader = ({ chartId }: { chartId: number }) => {
  const translate = useTranslate();
  const { timezoneType } = timezoneStore();
  const [date, setDate] = useState<DateRange<DateTime>>([
    DateTime.now().minus({ days: 7 }),
    DateTime.now(),
  ]);

  const [dateStart, dateEnd] = date as [DateTime, DateTime];

  const { data } = useGetChartData({
    chart_id: chartId,
    dateStart: dateStart.startOf("day").toMillis(),
    dateEnd: dateEnd.endOf("day").toMillis(),
  });

  return (
    <Card>
      <Stack
        sx={{
          gap: 4,
        }}
      >
        <Stack
          direction="row"
          sx={{
            gap: 2,
          }}
        >
          <RangeCalendar
            sx={{
              width: "fit-content",
            }}
            value={date}
            onChange={(newValue) => {
              if (newValue && newValue[0] && newValue[1]) {
                setDate(newValue as DateRange<DateTime>);
              }
            }}
          />
        </Stack>
        {data ? (
          data.chart_type === "aggregated" ? (
            <AggregableCustomChart
              variables={data.data}
              title={data.title}
              timezone={
                timezoneType === "machine"
                  ? data.machineTimezone
                  : Intl.DateTimeFormat().resolvedOptions().timeZone
              }
            />
          ) : (
            <CustomChart
              variables={data.data}
              title={data.title}
              min={dateStart.startOf("day").toMillis()}
              max={dateEnd.endOf("day").toMillis()}
              timezone={
                timezoneType === "machine"
                  ? data.machineTimezone
                  : Intl.DateTimeFormat().resolvedOptions().timeZone
              }
            />
          )
        ) : (
          <Stack
            sx={{
              height: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography variant="h6">
              {translate("user_feedback.loading")}
            </Typography>
            <SentinelLoader />
          </Stack>
        )}
      </Stack>
    </Card>
  );
};
