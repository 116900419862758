import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type AdvancedAnalyticEvaluationResponse =
  | ThingworxError
  | AdvancedAnalyticEvaluationResult;

const AdvancedAnalyticEvaluationInfo = z.object({
  id: z.number(),
  from: z.number(),
  to: z.number(),
  asset_id: z.number(),
  adv_chart_type: z.enum(["ori-pressure", "anomaly", "envelope"]),
  name: z.string(),
  uom: z.string(),
  machine_name: z.string(),
  batch_advanced_analytic_id: z.number(),
  envelope_id: z.number().optional(),
});

export type AdvancedAnalyticEvaluationInfo = z.infer<
  typeof AdvancedAnalyticEvaluationInfo
>;

const AdvancedAnalyticEvaluationResult = z.object({
  response: z.literal(true),
  analytics: z.array(AdvancedAnalyticEvaluationInfo),
});

type AdvancedAnalyticEvaluationResult = z.infer<
  typeof AdvancedAnalyticEvaluationResult
>;

export const useGetAdvancedAnalysisList = ({
  batch_advanced_analytic_id,
}: {
  batch_advanced_analytic_id: number;
}) => {
  const { appKey } = useFirebaseContext();

  return useQuery<AdvancedAnalyticEvaluationInfo[]>({
    queryKey: ["batch-advanced-analytics-infos", batch_advanced_analytic_id],
    queryFn: async () => {
      const response = await FetchClient<
        {
          analytic_id: number;
        },
        AdvancedAnalyticEvaluationResponse
      >({
        appKey,
        payload: {
          analytic_id: batch_advanced_analytic_id,
        },
        url: "dig.c.lyophilizer_thing/Services/getAdvancedAnalysisList",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse =
        AdvancedAnalyticEvaluationResult.parse(response);

      return validatedResponse.analytics;
    },
    refetchInterval: 1000 * 30,
    gcTime: 0,
  });
};
