import {
  RadialBarCustomLayer,
  RadialBarLayerId,
  ResponsiveRadialBar,
} from "@nivo/radial-bar";

export const NivoSolidGauge = ({
  title,
  subTitle,
  max,
  value,
  CustomLayer,
  enableTrack = false,
  color = "#ff00aa",
  trackColor = "rgba(255, 0, 0, .25)",
}: {
  title: string;
  subTitle?: string;
  min: number;
  max: number;
  value: number;
  enableTrack?: boolean;
  CustomLayer?: RadialBarCustomLayer;
  color: string;
  trackColor?: string;
}) => {
  const defaultLayers: (RadialBarCustomLayer | RadialBarLayerId)[] | undefined =
    ["tracks", "bars"];
  if (CustomLayer) {
    defaultLayers?.push(CustomLayer);
  }

  return (
    <ResponsiveRadialBar
      data={[
        {
          id: "Current",
          data: [
            {
              x: "blue",
              y: Number.isInteger(value) ? value : parseFloat(value.toFixed(2)),
              color,
              tip: title,
              subTip: subTitle,
            },
          ],
        },
      ]}
      startAngle={0}
      isInteractive={false}
      endAngle={360}
      circularAxisOuter={null}
      enableTracks={enableTrack}
      tracksColor={trackColor}
      enableLabels={false}
      cornerRadius={90}
      innerRadius={0.8}
      colors={(dat) => dat.data.color}
      maxValue={max}
      enableRadialGrid={false}
      enableCircularGrid={false}
      radialAxisStart={null}
      layers={[...defaultLayers]}
    />
  );
};
