import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { TranslationKey, useTranslate } from "@/i18n/config";
import { useFirebaseContext } from "@/context/firebase-context";
import { useTimeSelection } from "@/store/useTimeSelection";
import { translateReportField } from "@/utils/itemCardTranslate";
import { REPORT_AUTH_TOKEN, REPORT_ENDPOINT } from "./report-endpoint";
import { ThingworxError } from "src/types/error";

type ReportsListResponse = ThingworxError | ReportsListResult;
const ReportProps = z.object({
  filePath: z.string(),
  type: z.union([z.literal("weekly"), z.literal("yearly"), z.literal("daily")]),
  name: z.string(),
  date: z.string(),
  year: z.string(),
  timestamp: z.union([z.number(), z.string()]),
  time: z.object({
    timestamp: z.number(),
    machineTimezone: z.string(),
  }),
  entity: z.string(),
  entityType: z.string(),
});
export type ReportProps = z.infer<typeof ReportProps>;

const ReportsListResult = z.object({
  filesList: z.array(ReportProps),
  response: z.literal(true),
});
export type ReportsListResult = z.infer<typeof ReportsListResult>;

export const useGetReportsList = ({ isEnabled }: { isEnabled: boolean }) => {
  const { dates } = useTimeSelection();
  const { user } = useFirebaseContext();
  const translate = useTranslate();

  const payload = {
    user: user?.email,
    fileSystemName: "ima-reports",
    dateStart: dates.dateStart.toMillis(),
    dateEnd: dates.dateEnd.toMillis(),
  };

  return useQuery({
    queryKey: ["reports-list", dates],
    queryFn: async () => {
      const res = await fetch(`${REPORT_ENDPOINT}/report-list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${REPORT_AUTH_TOKEN}`,
        },
        body: JSON.stringify(payload),
      });
      const response = (await res.json()) as ReportsListResponse;
      if (!response.response) {
        throw new Error(response.errorString);
      }
      const validatedResponse = ReportsListResult.parse(response);

      return {
        ...validatedResponse,
        filesList: validatedResponse.filesList
          .sort((a, b) => {
            return (b.time.timestamp as number) - (a.time.timestamp as number);
          })
          .map((file) => ({
            ...file,
            date: translateReportField(
              transformDate(file.date.toString()),
              translate,
            ),
            entityType: translate(
              `${file.entityType.toLocaleLowerCase() as TranslationKey}`,
            ),
            type: translateReportField(file.type.toString(), translate),
          })),
      };
    },
    enabled: isEnabled,
  });
};

export function transformDate(value: string) {
  const dateString = value.startsWith("d")
    ? `day-${value.slice(1)}`
    : value.startsWith("w")
    ? `week-${value.slice(1)}`
    : value.startsWith("y")
    ? `year-${value.slice(1)}`
    : value;
  return dateString;
}
