import { useDisclosure } from "@/hooks/useDisclosure";
import { Edit } from "@mui/icons-material";
import {
  IconButton,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Stack,
  Button,
} from "@mui/material";
import { Line } from "../../api/useGetUserProductionLines";
import styles from "./EditManualLineConfigurationButton.module.css";

import { Modal } from "@/components/Modal";
import { useState } from "react";
import { TimebasedConfigForm } from "./TimebasedConfigForm";
import { MachinebasedConfigForm } from "./MachinebasedConfigForm";
import { useTranslate } from "@/i18n/config";

export const EditManualLineConfigurationButton = ({
  line,
  configureFrom = "linePage",
}: {
  line: Line;
  configureFrom?: "linePage" | "plantView";
}) => {
  const translate = useTranslate();
  const { open, close, isOpen } = useDisclosure();
  const [alignment, setAlignment] = useState("machine_based");

  const form =
    alignment === "time_based" ? (
      <TimebasedConfigForm onSubmit={() => close()} line={line} />
    ) : (
      <MachinebasedConfigForm onSubmit={() => close()} line={line} />
    );

  return (
    <>
      {configureFrom === "linePage" ? (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="close"
          className={styles.pulse}
          onClick={(e) => {
            e.stopPropagation();
            open();
          }}
        >
          <Edit />
        </IconButton>
      ) : (
        <Button variant="outlined" onClick={open}>
          {translate("line.configure_calculations")}
        </Button>
      )}
      <Modal
        open={isOpen}
        onClick={(e) => e.stopPropagation()}
        titleContent={
          <Stack
            sx={{
              gap: 2,
            }}
          >
            <Typography>
              {translate("overall_line_efficiency.configure_line")}
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              fullWidth
              exclusive
              size="small"
              onChange={(_, value) => setAlignment(value)}
              aria-label="Platform"
            >
              <ToggleButton size="small" value="machine_based">
                {translate("overall_line_efficiency.machine_based")}
              </ToggleButton>
              <ToggleButton size="small" value="time_based">
                {translate("overall_line_efficiency.time_based")}
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        }
        onClose={close}
        actions={<></>}
        bodyContent={<>{form}</>}
        fullWidth
      />
    </>
  );
};
