import { Button, Tooltip } from "@mui/material";
import { intellectaColors } from "../../utilities/colors";
import { PowerWord } from "../../types";

interface PowerwordButtonProps {
  powerWord: PowerWord;
  onClick: () => void;
}

export const PowerwordButton = ({
  powerWord,
  onClick,
}: PowerwordButtonProps) => {
  return (
    <Tooltip title={powerWord.description} enterDelay={1000} leaveDelay={200}>
      <Button
        fullWidth
        variant="contained"
        onClick={onClick}
        sx={{
          background: `linear-gradient(135deg, ${intellectaColors.powerWordButtonBackgroundHover} 0%, ${intellectaColors.powerWordButtonBackground} 100%)`,
          borderRadius: 2,
          color: "white",
          justifyContent: "flex-start",
          boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
          transition: "all 0.2s",
          "&:hover": {
            background: `linear-gradient(135deg, ${intellectaColors.powerWordButtonBackgroundHover}80 0%, ${intellectaColors.powerWordButtonBackground}80 100%)`,
            boxShadow: "0px 2px 8px rgba(0,0,0,0.3)",
          },
          "&:active": {
            boxShadow: "inset 0px 2px 4px rgba(0,0,0,0.4)",
          },
        }}
      >
        {powerWord.label}
      </Button>
    </Tooltip>
  );
};
