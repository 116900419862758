import { Grid2 } from "@mui/material";
import { BatchEl } from "../../types";
import { Card } from "@/components/Layout/Card";
import { useState } from "react";
import { Asset } from "./Asset";
import { PhaseListSelect } from "./PhaseListSelect";

export const BatchListSelect = ({ batchList }: { batchList: BatchEl[] }) => {
  const [selectedBatchId, setSelectedBatchId] = useState<number | null>(null);
  const [selectedBatch, setSelectedBatch] = useState<BatchEl | null>(null);

  return (
    <Grid2 container spacing={2} columns={{ xs: 2, md: 3, lg: 4 }}>
      <Grid2
        size={{
          xs: 1,
          md: 1,
        }}
      >
        <Card
          key="condition-batch-list"
          initial={{ opacity: 0, scale: 0.75, x: 20 }}
          animate={{ opacity: 1, scale: 1, x: 0 }}
          exit={{ opacity: 0 }}
          sx={{
            maxHeight: 300,
            overflow: "auto",
          }}
        >
          {batchList.map((batch) => (
            <Asset
              key={batch.id}
              type="Other"
              active={selectedBatchId === batch.id}
              name={batch.name}
              hasWarn={batch.hasWarn}
              id={batch.id}
              onClick={(id) => {
                if (id === selectedBatchId) {
                  setSelectedBatchId(null);
                  setSelectedBatch(null);
                } else {
                  setSelectedBatchId(id);
                  const foundBatch = batchList.find((batch) => batch.id === id);
                  if (foundBatch) setSelectedBatch(foundBatch);
                }
              }}
            />
          ))}
        </Card>
      </Grid2>
      {selectedBatch && selectedBatchId && (
        <PhaseListSelect
          key={selectedBatchId}
          phaseList={selectedBatch.phaseList}
          batchId={selectedBatchId}
        />
      )}
    </Grid2>
  );
};
