import { useState } from "react";
import { useTranslate } from "@/i18n/config";
import { useCheckIfCanRenderAnalytic } from "../utils/useCheckIfCanRenderAnalytic";
import { Box, Stack, Typography } from "@mui/material";
import { SelectRuns } from "../components/Selections/SelectRuns";
import { SelectSegment } from "../components/Selections/SelectSegment";
import { SelectShuttlesStandard } from "../components/Selections/SelectShuttles";
import { Card } from "@/components/Layout/Card";
import { DecompositionAnalyticsSelection } from "../components/Diagnostic/DecompositionAnalyticsSelection";
import { DecompositionAnalytics } from "../components/Diagnostic/DecompositionAnalytics";

export const Decomposition = () => {
  const translate = useTranslate();
  const [selectedAnalytics, setSelectedAnalytics] = useState<number[]>([]);
  const {
    canRenderAnalytic,
    noRunsSelected,
    noSegmentSelected,
    noShuttlesSelected,
  } = useCheckIfCanRenderAnalytic({
    hasRunsSelection: true,
    hasLapsSelection: false,
  });

  return (
    <Stack
      sx={{
        gap: 1,
      }}
    >
      <SelectRuns enableFilter={false} />
      <Box
        sx={{
          display: "flex",
          gap: ".5rem",
        }}
      >
        <SelectSegment />
        <SelectShuttlesStandard />
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <DecompositionAnalyticsSelection
          setSelectedAnalytics={setSelectedAnalytics}
        />
        {canRenderAnalytic ? (
          <DecompositionAnalytics analytics={selectedAnalytics} />
        ) : (
          <Card sx={{ width: "calc(100% - 320px)" }}>
            <Typography sx={{ color: "red" }} variant="h4">
              {translate("motors.no_selection")}
            </Typography>
            {noRunsSelected && (
              <Typography variant="h6">
                {translate("acopos.select_at_least_one_run")}
              </Typography>
            )}
            {noSegmentSelected && (
              <Typography variant="h6">
                {translate("acopos.select_at_least_one_segment")}
              </Typography>
            )}
            {noShuttlesSelected && (
              <Typography variant="h6">
                {translate("acopos.select_at_least_one_shuttle")}
              </Typography>
            )}
          </Card>
        )}
      </Box>
    </Stack>
  );
};
