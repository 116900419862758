import { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";

const LoginFormValues = z.object({
  email: z.string().email("Enter a valid email"),
  password: z.string().min(8, "Password should be at least 8 characters"),
});
type LoginFormType = z.infer<typeof LoginFormValues>;

export const LoginForm = ({
  onSubmit,
}: {
  onSubmit: ({ email, password }: { email: string; password: string }) => void;
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginFormType>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const switchShowPasswordHandler = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        alignItems: "center",
      }}
    >
      <h2>Data Room</h2>
      <Controller
        name="email"
        control={control}
        rules={{
          required: "Please enter an email",
          validate: {
            isEmail: (value) => {
              return (
                z.string().email().safeParse(value).success ||
                "Badly formatted email"
              );
            },
          },
        }}
        render={({ field: { value, onChange } }) => {
          return (
            <TextField
              type="email"
              role="textbox"
              placeholder="Username"
              variant="outlined"
              id="email"
              sx={{ width: "100%", marginBottom: "1rem" }}
              value={value}
              onChange={onChange}
              error={!!errors.email}
              helperText={errors.email?.message}
              slotProps={{
                htmlInput: {
                  "data-testid": "email-input",
                },
              }}
            />
          );
        }}
      />
      <Controller
        name="password"
        control={control}
        rules={{
          required: "Password is required",
          minLength: {
            value: 8,
            message: "Password must be at least 8 characters",
          },
        }}
        render={({ field: { value, onChange } }) => {
          return (
            <TextField
              type={showPassword ? "text" : "password"}
              variant="outlined"
              placeholder="Password"
              style={{
                maxWidth: "fit-content",
                marginBottom: ".6rem",
              }}
              value={value}
              onChange={onChange}
              error={!!errors.password}
              helperText={errors.password && errors.password.message}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={switchShowPasswordHandler}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                },

                htmlInput: {
                  "data-testid": "password-input",
                },

                inputLabel: {
                  shrink: true,
                },
              }}
            />
          );
        }}
      />
      <Button
        type="submit"
        variant="contained"
        sx={{ minWidth: "0", width: "100%" }}
      >
        Login
      </Button>
    </Box>
  );
};
