import { Box } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { ShowEmptyData } from "@/components/ShowEmptyData";
import { BasicTable } from "@/components/BasicTable";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { TableParser } from "@/utils/TableParser";
import { Card } from "@/components/Layout/Card";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { useGetMaterialsList, MaterialInfo } from "../api/useGetMaterialsList";
import { MaterialsCounters } from "./MaterialsCounters";

export const MaterialsDetailedList = () => {
  const { data: materials, isLoading } = useGetMaterialsList();
  const { formatTimestamp } = useFormatTimestamp();
  const translate = useTranslate();

  if (isLoading) return <AnalyticContainerSkeleton />;

  if (!materials) return null;

  const { count, data, duration } = materials;

  if (data.length === 0) {
    return <ShowEmptyData title={translate("materials")} />;
  }

  const { columns, rows } = TableParser.parseData<MaterialInfo>({
    data: data,
    columnsMap: {
      lackDuration: translate("duration"),
      state: translate("state"),
      timestamp: "Timestamp",
    },
    columnsOrder: ["state", "lackDuration", "timestamp"],
    dateFormatter: formatTimestamp,
  });

  const stateColumnDefinition = columns.findIndex(
    (item) => item.field === "state",
  );

  if (stateColumnDefinition !== -1) {
    columns[stateColumnDefinition].renderCell = (params) => {
      const { value } = params;
      return (
        <Box
          sx={{
            backgroundColor: value === "Start" ? "green" : "red",
            color: "white",
            padding: "8px 8px",
            borderRadius: "9999px",
            aspectRatio: "1/1",
          }}
        />
      );
    };
  }

  return (
    <>
      <Card>
        <BasicTable
          columns={columns}
          rows={rows}
          fileName="materials_detail_data"
        />
      </Card>
      <MaterialsCounters count={count} duration={duration} />
    </>
  );
};
