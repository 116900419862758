import Stack from "@mui/material/Stack";
import { Box, Grid2 } from "@mui/material";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";

export const ConfigurableLoaderLayout = () => {
  return (
    <Stack spacing={2}>
      <Box sx={{ width: "100%" }}>
        <Grid2 container columnSpacing={2} rowSpacing={2}>
          <Grid2
            size={{
              xs: 12,
              md: 6,
              lg: 4,
            }}
            sx={{
              order: { md: 1, lg: 1 },
            }}
          >
            <SkeletonCard width={"100%"} height={250} />
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              md: 12,
              lg: 4,
            }}
            sx={{
              order: { md: 3, lg: 1 },
            }}
          >
            <SkeletonCard width={"100%"} height={250} />
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              md: 6,
              lg: 4,
            }}
            sx={{
              order: { md: 1, lg: 1 },
            }}
          >
            <SkeletonCard width={"100%"} height={250} />
          </Grid2>
        </Grid2>
      </Box>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          width: "100%",
          height: 350,
          justifyContent: "center",
        }}
      >
        {Array.from(Array(2)).map((_, index) => (
          <SkeletonCard key={index} width={"100%"} height={"100%"} />
        ))}
      </Stack>
      <SkeletonCard width={"100%"} height={250} />
    </Stack>
  );
};
