import { useMutation, useQueryClient } from "@tanstack/react-query";
import { gCloudClient } from "@/services/gCloudClient";
import { encode } from "@/utils/b64_encoder";

export const useUpdateRole = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async ({ email, role }: { email: string; role: number }) => {
      await gCloudClient<{ email: string }, null>({
        service: "UPDATE_ROLE",
        queryStrings: {
          email: encode(email),
          role: role.toString(),
        },
      });
    },
    onSuccess() {
      client.invalidateQueries({ queryKey: ["get-colleagues"] });
    },
  });
};
