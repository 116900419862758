import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { useMachineContext } from "@/context/machine-context";
import { efficiencyNavigatorColors } from "@/features/EfficiencyNavigator/utils/efficiencyNavigatorColors";
import { useGetEfficiencyLosses } from "../../api/efficiency/useGetEfficiencyLosses";

export const EfficiencyLosses = () => {
  const { data, error, isLoading } = useGetEfficiencyLosses();
  const { machine } = useMachineContext();
  const machineName = machine?.machine;

  const translate = useTranslate();

  if (isLoading) {
    return <SkeletonCard height={300} />;
  }

  if (!data) {
    return null;
  }

  if (error) {
    return <Card>{translate("user_feedback.an_error_occurred")}</Card>;
  }

  return (
    <>
      <Card>
        <LineChart.Custom
          title={translate("losses.efficiency_losses")}
          filename={fileNameFactory({
            name: "efficiency_losses",
            machine: machineName,
          })}
          legend={{ enabled: true }}
          xAxisType="datetime"
          xAxisOptions={{
            scrollbar: { enabled: false },
          }}
          yAxis={data?.data.map((result, i) => ({
            min: 0,
            max: 100,
            uom: "%",
            options: {
              opposite: i % 2 === 0 ? false : true,
              type: "linear",
              min: 0,
              max: 100,
              scrollbar: { enabled: false },
            },
            series: [
              {
                name: translate("losses.machine_losses_percentage"),
                type: "line",
                data: result.machineLossesPercentage,
                color: efficiencyNavigatorColors.category.machine,
              },
              {
                name: translate("losses.process_losses_percentage"),
                type: "line",
                data: result.processLossesPercentage,
                color: efficiencyNavigatorColors.category.process,
              },
              {
                name: translate("losses.extra_capacity_percentage"),
                type: "line",
                data: result.extraCapacityPercentage,
                color: efficiencyNavigatorColors.category.default,
              },
              {
                name: translate("losses.line_losses_percentage"),
                type: "line",
                data: result.lineLossesPercentage,
                color: efficiencyNavigatorColors.category.line,
              },
            ],
          }))}
          tooltip={{ enabled: true, options: { valueSuffix: "%" } }}
        />
      </Card>
    </>
  );
};
