import { toast } from "react-hot-toast";
import { Delete } from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { useTranslate } from "@/i18n/config";
import { useAskConfirmAction } from "@/hooks/ask-confirm-action";
import { useDeleteUser } from "../../../../lambda/useDeleteUser";

export const DeleteUserCell = ({ user }: { user: string }) => {
  const { mutate: deleteUser } = useDeleteUser();
  const translate = useTranslate();
  const { askConfirm, renderConfirmModal } = useAskConfirmAction(
    translate("delete_user", { user }),
    () =>
      deleteUser(
        { email: user },
        {
          onSuccess() {
            toast.success(translate("user_feedback.user_deleted_successfully"));
          },
          onError() {
            toast.error(translate("user_feedback.an_error_occurred"));
          },
        },
      ),
  );

  return (
    <>
      <GridActionsCellItem
        icon={<Delete />}
        label={translate("actions.delete")}
        onClick={askConfirm}
      />
      {renderConfirmModal()}
    </>
  );
};
