import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import {
  IntellectaErrorObj,
  IntellectaResultObj,
  IntellectaVersion,
  intellectaResultObj,
} from "../../types";
import { intellectaClient } from "../intellectaClient";
import { userConfigurationStore } from "../../store/user-configuration-store";

type Payload = {
  user: string;
  prompt: {
    prompt: string;
    label: string;
    description: string;
    version: IntellectaVersion;
    profile: string;
  };
};

type Response = IntellectaErrorObj | IntellectaResultObj;

export const useAddPrompt = () => {
  const queryClient = useQueryClient();
  const { user } = useFirebaseContext();
  const email = user?.email || "";
  const { version, profile } = userConfigurationStore();

  return useMutation({
    mutationFn: async ({
      prompt,
      label,
      description,
    }: {
      prompt: string;
      label: string;
      description: string;
    }) => {
      if (!version || !profile) {
        throw new Error();
      }

      const response = await intellectaClient<Payload, Response>({
        url: "/prompt/create",
        payload: {
          user: email,
          prompt: {
            prompt,
            label,
            description,
            version,
            profile,
          },
        },
      });

      if (response.value === "ERROR") throw new Error();

      const validatedResponse = intellectaResultObj.parse(response);
      return validatedResponse;
    },
    onSuccess: (data) => {
      if (data.value === "OK") {
        //* per scaricare di nuovo i prompt
        queryClient.invalidateQueries({
          queryKey: ["intellecta-config", email],
        });
      }
    },
  });
};
