import { useState } from "react";
import { toast } from "react-hot-toast";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  TextField,
  Rating,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { useLeaveFeedback } from "../../api/useLeaveFeedback";

export const FeedbackModal = ({
  isOpen,
  close,
  messageId,
}: {
  isOpen: boolean;
  close: () => void;
  messageId?: number;
}) => {
  const translate = useTranslate();
  const [rating, setRating] = useState<number | null>(null);
  const [userInput, setUserInput] = useState("");
  const { mutateAsync: leaveFeedback } = useLeaveFeedback();

  const handleClose = () => {
    close();
    setRating(null);
    setUserInput("");
  };

  const handleSubmitFeedback = () => {
    if (!rating)
      return toast.error(translate("user_feedback.feedback_select_rating"));
    leaveFeedback({ rating, user_notes: userInput, message_id: messageId || 0 })
      .then(() => {
        close();
        toast.success(translate("user_feedback.feedback_thanks"));
      })
      .catch(() => {
        close();
        toast.error(translate("user_feedback.an_error_occurred"));
      });
  };
  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose}>
      <DialogTitle> {translate("intellecta.leave_feedback")}</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            gap: 1,
          }}
        >
          <Typography variant="body2">
            {translate("intellecta.leave_feedback_subtitle")}
          </Typography>
          <TextField
            size="small"
            placeholder={translate("intellecta.write_feedback")}
            multiline
            onChange={(e) => setUserInput(e.target.value)}
          />
          <Box
            sx={{
              mt: 2,
            }}
          >
            <Rating
              value={rating}
              onChange={(_, newValue) => setRating(newValue)}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {translate("actions.cancel")}
        </Button>
        <Button variant="contained" onClick={handleSubmitFeedback}>
          {translate("actions.submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
