import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { socialStore } from "@/features/Intellecta/store/social-store";
import { SocialContent } from "./SocialContent";

export const Social = ({
  onUsePrompt,
  isLoading,
  error,
}: {
  onUsePrompt: (prompt: string) => void;
  isLoading: boolean;
  error: Error | null;
}) => {
  const translate = useTranslate();
  const { setHasNewSocialNotification } = socialStore();

  useEffect(() => {
    // Resettiamo il flag della notifica
    setHasNewSocialNotification(false);
  }, [setHasNewSocialNotification]);

  if (isLoading) {
    return (
      <Stack p={2} gap={1}>
        <SkeletonCard height="42px" sx={{ borderRadius: 4 }} />
        <SkeletonCard height="42px" sx={{ borderRadius: 4 }} />
        <SkeletonCard height="42px" sx={{ borderRadius: 4 }} />
      </Stack>
    );
  }

  if (error) {
    return toast.error(translate("user_feedback.an_error_occurred"));
  }

  return <SocialContent onUsePrompt={onUsePrompt} />;
};
