import { motion } from "framer-motion";
import { Button, styled } from "@mui/material";

export const AssetLayout = styled(motion(Button))(() => ({
  color: "#fff",
  width: "100%",
  padding: ".5rem",
  display: "flex",
  textAlign: "left",
  justifyContent: "space-between",
  flexDirection: "row",
  gap: "1rem",
  alignItems: "center",
  borderRadius: ".25rem",
  transition: "all .3s ease-in",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "rgba(255,255,255, .3)",
  },
}));
