import {
  ContentPaste,
  ThumbsUpDownOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { useDisclosure } from "@/hooks/useDisclosure";
import { ChatMessageAction } from "./ChatMessageAction";
import { FeedbackModal } from "./FeedbackModal";
import { ShareModal } from "./ShareModal";
import { userConfigurationStore } from "../../store/user-configuration-store";

export const ChatMessageActionListIntellecta = ({
  onCopy,
  onOpenModal,
  onCloseModal,
  messageId,
}: {
  onCopy: () => void;
  onOpenModal: () => void;
  onCloseModal: () => void;
  messageId?: number;
}) => {
  const translate = useTranslate();
  const { services } = userConfigurationStore();
  const {
    open: openFeedbackModal,
    isOpen: isOpenFeedbackModal,
    close: closeFeedbackModal,
  } = useDisclosure();
  const {
    open: openShareModal,
    isOpen: isOpenShareModal,
    close: closeShareModal,
  } = useDisclosure();
  // Flag che indica se il servizio "social" è abilitato
  const socialEnabled = services.includes("social");

  const handleOpenFeedback = () => {
    openFeedbackModal();
    onOpenModal(); // 🔹 Comunica al parent che la modale è aperta
  };

  const handleCloseFeedback = () => {
    closeFeedbackModal();
    onCloseModal(); // 🔹 Comunica al parent che la modale è chiusa
  };

  const handleOpenShare = () => {
    openShareModal();
    onOpenModal(); // 🔹 Comunica al parent che la modale è aperta
  };

  const handleCloseShare = () => {
    closeShareModal();
    onCloseModal(); // 🔹 Comunica al parent che la modale è chiusa
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{
          gap: 1,
        }}
      >
        <ChatMessageAction
          onClick={onCopy}
          popoverText={translate("actions.copy")}
          icon={<ContentPaste fontSize="small" />}
        ></ChatMessageAction>
        <ChatMessageAction
          onClick={handleOpenFeedback}
          popoverText={translate("intellecta.leave_feedback")}
          icon={<ThumbsUpDownOutlined fontSize="small" />}
        ></ChatMessageAction>
        {socialEnabled && (
          <ChatMessageAction
            onClick={handleOpenShare}
            popoverText={translate("actions.share")}
            icon={<ShareOutlined fontSize="small" />}
          ></ChatMessageAction>
        )}
      </Stack>
      <FeedbackModal
        isOpen={isOpenFeedbackModal}
        close={handleCloseFeedback}
        messageId={messageId}
      />
      {socialEnabled && (
        <ShareModal
          isOpen={isOpenShareModal}
          close={handleCloseShare}
          messageId={messageId}
        />
      )}
    </>
  );
};
