import { Box, Divider, Stack } from "@mui/material";
import { userServicesStore } from "@/context/services-store";
import { Card } from "@/components/Layout/Card";
import { LossType } from "../../types";
import { Sparkline } from "./Sparkline";
import { ShortTermSuggestion } from "../ShortTermSuggestion";
import { UnavailableSuggestion } from "../UnavailableSuggestion";

export const ShortTermChart = ({
  title,
  lossType,
}: {
  title: string;
  lossType: LossType;
}) => {
  const { services } = userServicesStore();

  return (
    <Card sx={{ height: 300, display: "flex", flexDirection: "column" }}>
      <Stack
        sx={{
          gap: 1,
          flex: 1,
          minHeight: 0,
        }}
      >
        <Box sx={{ flexShrink: 0 }}>
          <Sparkline title={title} lossType={lossType} />
          <Divider />
        </Box>
        <Box sx={{ flex: 1, minHeight: 0 }}>
          {services.includes("efficiency navigator gen ai") ? (
            <ShortTermSuggestion lossType={lossType} />
          ) : (
            <UnavailableSuggestion />
          )}
        </Box>
      </Stack>
    </Card>
  );
};
