import { Typography, Box, Tooltip, Zoom } from "@mui/material";
import { ItemHealthStatus } from "../../../types";
import { Card } from "@/components/Layout/Card";
import { EVALUATION_COLORS } from "../../../context/acopos-context";
import { useTranslate } from "@/i18n/config";
import { HealthStatusBarChart } from "./HealthStatusBarChart";

interface BarSeriesData {
  name: string;
  color: string;
  data: number[];
}

const parseData = ({
  data,
}: {
  data: ItemHealthStatus[];
}): { barSeriesData: BarSeriesData[] } => {
  const barSeriesData: BarSeriesData[] = [];
  const tmp: { [key: string]: { color: string; count: number } } = {};

  data.forEach((item) => {
    if (Object.keys(tmp).includes(item.healthStatus)) {
      //* c'è, devo fare update count
      tmp[item.healthStatus].count += 1;
    } else {
      //* devo inizializzare la chiave, mettere colore e count a 1
      tmp[item.healthStatus] = {
        color: EVALUATION_COLORS[item.healthStatus].selected,
        count: 1,
      };
    }
  });

  for (const [key, val] of Object.entries(tmp)) {
    barSeriesData.push({ name: key, color: val.color, data: [val.count] });
  }

  return { barSeriesData };
};

export const ItemsHealthStatus = ({
  title,
  items,
  itemTranslation,
}: {
  title: string;
  items: ItemHealthStatus[];
  itemTranslation: string;
}) => {
  const sortedItemsByName = items.sort((a, b) => {
    const aSplit = a.name.split(" ");
    const aNum = parseInt(aSplit[aSplit.length - 1]);
    const bSplit = b.name.split(" ");
    const bNum = parseInt(bSplit[bSplit.length - 1]);
    return aNum > bNum ? 1 : -1;
  });
  const sortedItemsByStatus = sortedItemsByName
    .sort((a) => (a.healthStatus === "undefined" ? -1 : 1))
    .sort((a) => (a.healthStatus === "lack_of_data" ? -1 : 1))
    .sort((a) => (a.healthStatus === "no_data" ? -1 : 1))
    .sort((_, b) => (b.healthStatus === "good" ? 1 : -1))
    .sort((a) => (a.healthStatus === "warning" ? 1 : -1))
    .sort((_, b) => (b.healthStatus === "critical" ? 1 : -1));

  const { barSeriesData } = parseData({ data: sortedItemsByStatus });

  return (
    <Card
      sx={{
        overflow: "auto",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          p: 1,
        }}
      >
        {title}
      </Typography>
      <Box sx={{ height: "12px", width: "100%", marginBottom: ".5rem" }}>
        <HealthStatusBarChart data={barSeriesData} />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: ".8rem",
          padding: ".4rem",
          overflow: "auto",
        }}
      >
        {sortedItemsByStatus.map((item, i) => (
          <Item
            key={item.name}
            item={item}
            itemTranslation={itemTranslation}
            transitionTime={i * 100}
          />
        ))}
      </Box>
    </Card>
  );
};

const Item = ({
  item,
  transitionTime,
}: {
  item: ItemHealthStatus;
  itemTranslation: string;
  transitionTime: number;
}) => {
  const translate = useTranslate();
  const color = EVALUATION_COLORS[item.healthStatus].selected;
  // const text = `${itemTranslation} ${item.name}`;
  const text = item.name;

  return (
    <Tooltip
      title={translate(`evaluation.${item.healthStatus}`)}
      placement="top"
      slots={{
        transition: Zoom,
      }}
    >
      <Zoom in style={{ transitionDelay: `${transitionTime}ms` }}>
        <Box
          sx={{
            backgroundColor: "#1F293F",
            border: `2px solid ${color}`,
            borderRadius: "1rem",
            padding: ".4rem",
            transition: "all .2s ease-in",
          }}
        >
          <Typography
            variant="caption"
            sx={{ cursor: "default", whiteSpace: "nowrap" }}
          >
            {text}
          </Typography>
        </Box>
      </Zoom>
    </Tooltip>
  );
};
