import { useFirebaseContext } from "../../../context/firebase-context";
import { FetchClient } from "../../../services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "../../../types/error";
import { z } from "zod";

const OverviewPagesResult = z.object({
  response: z.literal(true),
  pages: z.array(z.string()),
});

type OverviewPagesResult = z.infer<typeof OverviewPagesResult>;
type OverviewPagesResponse = ThingworxError | OverviewPagesResult;

export const useGetUserOverviewPages = () => {
  const { user, appKey } = useFirebaseContext();

  const email = user?.email as string;

  return useQuery<string[]>({
    queryKey: ["user-overview-pages", email],
    queryFn: async () => {
      const response = await FetchClient<
        { account: string },
        OverviewPagesResponse
      >({
        url: "dig.c.pagesManagement_thing/Services/getUserPages",
        appKey,
        payload: {
          account: email,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return OverviewPagesResult.parse(response).pages;
    },
  });
};
