import { Chip, Typography } from "@mui/material";
import { Card } from "./Layout/Card";

export const ItemCard = <T,>({
  title,
  subtitle,
  secondarySubtitle,
  optionalLabel,
  item,
  onClick,
  isAcceptanceTest = false,
}: {
  title: string;
  subtitle?: string;
  secondarySubtitle?: string;
  optionalLabel?: string;
  item: T;
  onClick: (item: T) => void;
  isAcceptanceTest?: boolean;
}) => {
  return (
    <Card
      sx={{
        cursor: "pointer",
      }}
      variants={{
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
      }}
      whileHover={{
        scale: 1.02,
        transition: { duration: 0.2 },
      }}
      onClick={() => onClick(item)}
    >
      <Chip label={title} />
      <div style={{ marginTop: 15 }} />
      <Typography
        sx={{
          fontWeight: "",
        }}
      >
        {subtitle}
      </Typography>
      {secondarySubtitle && (
        <Typography
          sx={{
            fontWeight: "",
          }}
        >
          {secondarySubtitle}
        </Typography>
      )}
      {optionalLabel ? (
        <Typography
          sx={{
            textAlign: isAcceptanceTest ? "left" : "right",
            fontWeight: isAcceptanceTest ? "400" : "bold",
          }}
        >
          {optionalLabel}
        </Typography>
      ) : null}
    </Card>
  );
};
