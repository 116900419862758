import { Grid2, Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { formatNumber } from "@/utils/formatNumber";
import { efficiencyNavigatorColors } from "../../utils/efficiencyNavigatorColors";
import { EfficiencyPie } from "../EfficiencyPie";
import { LegendItem } from "../LegendItem";
import { LongTermDataResult } from "../../api/useGetLongTermData";

export const HistoricPieSection = ({
  data,
}: {
  data: Omit<LongTermDataResult, "response">;
}) => {
  const translate = useTranslate();

  return (
    <Grid2
      size={{
        lg: 4,
        xs: 12,
      }}
      sx={{
        height: "30vh",
      }}
    >
      <Card
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          boxSizing: "border-box",
        }}
      >
        <EfficiencyPie
          title={translate("kpi.oee")}
          data={data.oeeMachineStatus}
        />
        <Stack spacing={1}>
          <LegendItem
            description={`${translate("kpi.oee")} - ${formatNumber(
              data.oeeMachineStatus.oee,
            )}%`}
            color={efficiencyNavigatorColors.pie.oee}
          />
          <LegendItem
            description={`${translate(
              "losses.machine_losses",
            )} - ${formatNumber(data.oeeMachineStatus.machine)}%`}
            color={efficiencyNavigatorColors.category.machine}
          />
          <LegendItem
            description={`${translate(
              "losses.process_losses",
            )} - ${formatNumber(data.oeeMachineStatus.process)}%`}
            color={efficiencyNavigatorColors.category.process}
          />
          <LegendItem
            description={`${translate("losses.line_losses")} - ${formatNumber(
              data.oeeMachineStatus.line,
            )}%`}
            color={efficiencyNavigatorColors.category.line}
          />
        </Stack>
      </Card>
    </Grid2>
  );
};
