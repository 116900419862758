import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import {
  AlarmData,
  AlarmsColumnData,
} from "@/features/Alarms/api/useGetAlarmsColumnData";
import { useLanguage } from "@/i18n/config";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type HistoricMachineResponse = ThingworxError | HistoricMachineKpiResult;

const Production = z.union([
  z.tuple([
    z.array(
      z.object({
        drilldown: z.union([z.string(), z.number()]),
        name: z.union([z.string(), z.number()]),
        valueCounter: z.number(),
        valueWaste: z.number(),
      }),
    ),
  ]),
  z.tuple([
    z.array(
      z.object({
        drilldown: z.union([z.string(), z.number()]),
        name: z.union([z.string(), z.number()]),
        valueCounter: z.number(),
        valueWaste: z.number(),
      }),
    ),
    z.array(
      z.object({
        name: z.string(),
        id: z.string(),
        data: z.array(
          z.object({
            drilldown: z.union([z.string(), z.number()]),
            name: z.union([z.string(), z.number()]),
            valueCounter: z.number(),
            valueWaste: z.number(),
          }),
        ),
      }),
    ),
  ]),
  z.tuple([]),
]);

type Production = z.infer<typeof Production>;

const HistoricAlarmsData = AlarmsColumnData.extend({
  result_v2: z.array(AlarmData),
});
type HistoricAlarmsData = z.infer<typeof HistoricAlarmsData>;

const HistoricMachineKpi = z.object({
  wasteCounter: z.number(),
  kpi: z.object({
    performance: z.number(),
    allTurnTime: z.number(),
    uptimeSs: z.number(),
    availability: z.number(),
    inactiveTime: z.number(),
    oee: z.number(),
    quality: z.number(),
  }),
  recipe: z.object({
    Recipe: z.array(
      z.object({
        Recipe: z.string(),
        Duration: z.number(),
      }),
    ),
  }),
  alarmStream: z.object({
    array: z.array(
      z.object({
        ErrorDescription: z.string(),
        FamilyDescription: z.string(),
        timestampEnd: z.number(),
        EfficiencyFamilyDescription: z.string(),
        Zone: z.number(),
        Error: z.number(),
        Family: z.number(),
        Timestamp: z.number(),
        durationms: z.number(),
        ZoneDescription: z.string(),
      }),
    ),
  }),
  counter: z.number(),
  batchID: z.string(),
  states: z.array(
    z.object({
      color: z.string(),
      name: z.string(),
      y: z.number(),
      z: z.number().optional(),
    }),
  ),
  recipeName: z.string(),
  dataStartTimeLapse: z.number(),
  alarmCountDuration: HistoricAlarmsData,
  timezoneDebug: z.number().optional(),
  timestamp: z.number(),
  production: Production,
});

export type HistoricMachineKpi = z.infer<typeof HistoricMachineKpi>;

const HistoricMachineKpiResult = z.object({
  result: HistoricMachineKpi,
  response: z.literal(true),
});

export type HistoricMachineKpiResult = z.infer<typeof HistoricMachineKpiResult>;

type Payload = {
  timeSelection: TimeSelection;
  id: string | null;
  timezoneOffset: number;
  platformTiimezone: "machine" | "user";
  machineName: string;
  language_id: string;
};

export const useGetHistoricData = ({ id }: { id: string }) => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const { timeSelection } = useTimeSelection();
  const language = useLanguage();

  return useQuery<HistoricMachineKpi>({
    queryKey: [
      "machinedetail-historic",
      machine!.machine,
      timeSelection,
      id,
      language,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, HistoricMachineResponse>({
        url: "dig.c.historicMachineDetail_thing/Services/getData",
        appKey,
        payload: {
          id: id,
          timeSelection,
          timezoneOffset: new Date().getTimezoneOffset(),
          platformTiimezone: "user",
          machineName: machine!.machine ?? "",
          language_id: language,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return HistoricMachineKpiResult.parse(response).result;
    },
    enabled: !!machine && id !== null,
  });
};
