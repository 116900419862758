import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "../../../../../types/error";
import { z } from "zod";
import { QueryBuilder } from "@/utils/query-builder";
import { alternativeViewStore } from "../store/alternative-view-store";
import { UNAVAILABLE_TIME_SELECTIONS } from "@/features/OverallLineEfficiency/constants";

type Payload =
  | {
      timeSelection: TimeSelection;
      line_id: number;
      config: "automatic";
      viewFromFirstProduct: boolean;
    }
  | {
      line_id: number;
      config: "manual";
      config_id: number;
    };

const LineMachineResult = z.object({
  response: z.literal(true),
  data: z.array(
    z.object({
      machine: z.string(),
      values: z.array(z.array(z.number())),
    }),
  ),
});

type LineMachineResult = z.infer<typeof LineMachineResult>;

type LineMachineResponse = LineMachineResult | ThingworxError;

export const useGetLineMachinesEfficiencies = ({
  line_id,
  config,
  config_id,
  has_view_from_first_product = false,
}: {
  line_id: number;
  config: "automatic" | "manual";
  config_id: number;
  has_view_from_first_product?: boolean;
}) => {
  const { appKey } = useFirebaseContext();
  const { viewFromFirstProduct } = alternativeViewStore();

  const useView = has_view_from_first_product ? viewFromFirstProduct : false;

  const { timeSelection } = useTimeSelection();
  const allowedTimeSelection = UNAVAILABLE_TIME_SELECTIONS.includes(
    timeSelection,
  )
    ? "Shift"
    : timeSelection;

  const payload: Payload =
    config === "automatic"
      ? {
          timeSelection: allowedTimeSelection,
          line_id,
          config,
          viewFromFirstProduct: useView,
        }
      : {
          line_id,
          config,
          config_id,
        };

  const query = QueryBuilder.buildWithCondition({
    baseQuery: [
      "getLineMachineEfficiencies",
      line_id,
      config,
      config_id,
      useView ? "custom" : "standard",
    ],
    condition: config === "automatic",
    query: [allowedTimeSelection],
  });

  return useQuery<LineMachineResult>({
    queryKey: query,
    queryFn: async () => {
      const response = await FetchClient<Payload, LineMachineResponse>({
        url: "dig.c.plantOverview_thing/Services/getLineMachinesEfficiencies",
        appKey,
        payload,
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }
      return LineMachineResult.parse(response);
    },
    staleTime: 0,
    gcTime: 0,
  });
};
