/* eslint-disable @typescript-eslint/ban-ts-comment */
import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { Card } from "@/components/Layout/Card";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useTranslate } from "@/i18n/config";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import {
  Alert,
  Snackbar,
  styled,
  Typography,
  Grid2,
  Stack,
} from "@mui/material";
import { useGetRecipe } from "../MachineDetail/api/useGetRecipe";
import { CurrentMachineState } from "../MachineDetail/components/CurrentMachineState";
import { ProductCounter } from "../MachineDetail/components/ProductCounter";
import { Recipe } from "../MachineDetail/components/Recipe";
import {
  ProductionHour,
  StateInfo,
  useGetBatchNavigatorData,
} from "./api/useGetBatchNavigatorData";
import {
  ProductsTargetForm,
  formatDuration,
} from "./components/ProductsTargetForm";
import { RadialDoubleBarGaugeLayout } from "./components/RadialGaugeLayout";
import { SkeletonLoaderLayout } from "./layout/SkeletonLoaderLayout";
import { useMachineContext } from "@/context/machine-context";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { useFormatTimestamp } from "@/utils/TimeHelpers";

export const BatchNavigator = () => {
  const { data, isLoading } = useGetBatchNavigatorData();
  const { close, isOpen, open } = useDisclosure();
  const translate = useTranslate();
  return (
    <>
      <MachineViewNavBar></MachineViewNavBar>
      {isLoading ? <SkeletonLoaderLayout /> : null}
      {data ? (
        <Stack
          sx={{
            gap: 2,
          }}
        >
          <ProductsTargetForm
            showNotification={() => open()}
            stopForced={data.forceStopBatch}
            key={1}
          />
          <TargetInfos {...data} />
          <MiddleRow {...data} />
          <Card>
            <ChartSection productionHour={data.productionHour} />
          </Card>
        </Stack>
      ) : null}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={isOpen}
        autoHideDuration={3000}
        onClose={close}
      >
        <Alert severity="success">
          {translate("batch_navigator.batch_will_start")}
        </Alert>
      </Snackbar>
    </>
  );
};

export const CustomCard = styled(
  Card,
  {},
)<{ color?: string }>(({ color }) => ({
  width: "100%",
  backgroundImage: "none",
  padding: ".5rem",
  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: ".25rem",
  color: color,
}));

const TargetInfos = ({
  batchCountToComplete,
  batchTargetCount,
  batchTargetTime,
  batchTime,
  batchTimeToComplete,
  currentBatchName,
  timeEndBatch,
  timeEndBatchTarget,
  targetBatchProductHour,
  speed,
}: {
  batchCount: number;
  batchCountGood: number;
  batchCountToComplete: number;
  batchCurrentDelay: number;
  batchEnd: boolean;
  batchExpectedCount: number;
  batchTargetCount: number;
  batchTargetTime: number;
  batchTime: number;
  batchTimeToComplete: number;
  currentBatchName: string;
  timeEndBatchTarget: number;
  timeEndBatch: number;
  targetBatchProductHour: number;
  speed: number;
}) => {
  const translate = useTranslate();
  const { formatTimestamp } = useFormatTimestamp();
  return (
    <Grid2
      container
      spacing={2}
      sx={{
        textAlign: "center",
      }}
    >
      <Grid2
        size={{
          md: 2.4,
          xs: 6,
        }}
        sx={{
          alignItems: "center",
        }}
      >
        <Typography>{translate("batch_navigator.target_products")}</Typography>
        <CustomCard>{batchTargetCount}</CustomCard>
      </Grid2>
      <Grid2
        size={{
          md: 2.4,
          xs: 6,
        }}
        sx={{
          alignItems: "center",
        }}
      >
        <Typography>
          {translate("batch_navigator.products_per_minute")}
        </Typography>
        <CustomCard>{targetBatchProductHour}</CustomCard>
      </Grid2>
      <Grid2
        size={{
          md: 2.4,
          xs: 6,
        }}
        sx={{
          alignItems: "center",
        }}
      >
        <Typography>{translate("batch_navigator.target_hours")}</Typography>
        <CustomCard>{formatDuration(batchTargetTime * 60 * 1000)}</CustomCard>
      </Grid2>
      <Grid2
        size={{
          md: 2.4,
          xs: 6,
        }}
        sx={{
          alignItems: "center",
        }}
      >
        <Typography>{translate("batch_navigator.target_date")}</Typography>
        <CustomCard>
          {formatTimestamp({
            timestamp: timeEndBatchTarget,
            fmt: "dd/MM/yyyy HH:mm",
          })}
        </CustomCard>
      </Grid2>
      <Grid2
        size={{
          md: 2.4,
          xs: 6,
        }}
        sx={{
          alignItems: "center",
        }}
      >
        <Typography>{translate("batch_navigator.batch_name")}</Typography>
        <CustomCard>{currentBatchName}</CustomCard>
      </Grid2>
      <Grid2
        size={{
          md: 2.4,
          xs: 6,
        }}
        sx={{
          alignItems: "center",
        }}
      >
        <Typography>{translate("batch_navigator.missing_products")}</Typography>
        <CustomCard>{batchCountToComplete}</CustomCard>
      </Grid2>
      <Grid2
        size={{
          md: 2.4,
          xs: 6,
        }}
        sx={{
          alignItems: "center",
        }}
      >
        <Typography>{translate("batch_navigator.missing_time")}</Typography>
        <CustomCard>
          {formatDuration(batchTimeToComplete * 60 * 1000)
            ? formatDuration(batchTimeToComplete * 60 * 1000)
            : "0m"}
        </CustomCard>
      </Grid2>
      <Grid2
        size={{
          md: 2.4,
          xs: 6,
        }}
        sx={{
          alignItems: "center",
        }}
      >
        <Typography>{translate("batch_navigator.expected_arrival")}</Typography>
        <CustomCard color={timeEndBatchTarget > timeEndBatch ? "green" : "red"}>
          {formatTimestamp({
            timestamp: timeEndBatch,
            fmt: "dd/MM/yyyy HH:mm",
          })}
        </CustomCard>
      </Grid2>
      <Grid2
        size={{
          md: 2.4,
          xs: 6,
        }}
        sx={{
          alignItems: "center",
        }}
      >
        <Typography>{translate("speed")}</Typography>
        <CustomCard>{speed}</CustomCard>
      </Grid2>
      <Grid2
        size={{
          md: 2.4,
          xs: 6,
        }}
        sx={{
          alignItems: "center",
        }}
      >
        <Typography>{translate("batch_navigator.time_passed")}</Typography>
        <CustomCard>
          {formatDuration(batchTime * 60 * 1000)
            ? formatDuration(batchTime * 60 * 1000)
            : "0m"}
        </CustomCard>
      </Grid2>
    </Grid2>
  );
};

const MiddleRow = ({
  stateInfo,
  currentRecipe,
  currentRecipeClient,
  batchCount,
  batchExpectedCount,
  batchCurrentDelay,
  wasteBatchCount,
  batchCountGood,
  batchTargetCount,
}: {
  stateInfo: StateInfo;
  currentRecipeClient: string;
  currentRecipe: string;
  batchCount: number;
  batchExpectedCount: number;
  batchCurrentDelay: number;
  wasteBatchCount: number;
  batchTargetCount: number;
  batchCountGood: number;
}) => {
  const { data: recipeContent } = useGetRecipe();
  const translate = useTranslate();
  return (
    <Grid2
      container
      spacing={2}
      sx={{
        justifyContent: "space-between",
      }}
    >
      <Grid2
        spacing={2}
        direction={"column"}
        size={{
          md: 4,
          xs: 12,
        }}
      >
        <Stack
          sx={{
            gap: 2,
            justifyContent: "space-around",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CurrentMachineState
            categoryCode={stateInfo.categoryCode}
            color={stateInfo.color}
            state={stateInfo.state}
            key={1}
          />
          <Recipe
            recipe={currentRecipe}
            recipeChanged={false}
            recipeClient={currentRecipeClient}
            recipeContent={recipeContent}
          />
        </Stack>
      </Grid2>
      <Grid2
        direction="row"
        spacing={1}
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <RadialDoubleBarGaugeLayout
          color=""
          realValue={batchCount}
          targetValue={batchExpectedCount}
          value={batchCurrentDelay}
          max={batchTargetCount > 0 ? batchTargetCount : 1}
          title={
            batchCurrentDelay < 0
              ? translate("batch_navigator.delay")
              : translate("batch_navigator.advanced")
          }
          dimension="large"
        />
        <ProductCounter
          counter={batchCount}
          text={translate("total_production")}
          size={"small"}
          color={"#015291"}
        />
        <ProductCounter
          counter={batchCountGood}
          text={translate("good")}
          size={"extrasmall"}
          color={"#56BC72"}
        />
        <ProductCounter
          counter={wasteBatchCount}
          text={translate("waste")}
          size={"extrasmall"}
          color={"#AE2525"}
        />
      </Grid2>
    </Grid2>
  );
};

const ChartSection = ({
  productionHour,
}: {
  productionHour: ProductionHour[];
}) => {
  const translate = useTranslate();
  const { formatTimestamp } = useFormatTimestamp();
  const categories: string[] | undefined = [];
  const dataTarget: number[] = [];
  const dataReject: number[] = [];
  const dataReal: number[] = [];
  let isRejectZero: boolean = true;

  const { machine } = useMachineContext();
  const machineName = machine?.machine;

  if (productionHour.length) {
    productionHour.forEach((values) => {
      categories.push(
        formatTimestamp({ timestamp: values.lossName, fmt: "HH:mm" }),
      );
      dataTarget.push(values.valueBM);
      dataReject.push(values.valueWaste);
      dataReal.push(values.value);
    });
    isRejectZero = !productionHour.some((values) => values.valueWaste !== 0);
  }

  return (
    <BarChart.Custom
      title=""
      uom=""
      categories={categories}
      filename={fileNameFactory({
        machine: machineName,
        name: "batch_navigator",
      })}
      series={[
        {
          type: "column",
          name: translate("reject"),
          data: dataReject,
          color: "#AE2525",
          stacking: "normal",
          zIndex: 1,
          // @ts-ignore
          borderRadiusTopRight: 10,
          borderRadiusTopLeft: 10,
        },
        {
          type: "column",
          name: translate("real"),
          data: dataReal,
          color: "#56BC72",
          stacking: "normal",
          zIndex: 1,
          // @ts-ignore
          borderRadiusTopRight: isRejectZero ? 10 : 0,
          borderRadiusTopLeft: isRejectZero ? 10 : 0,
        },
        {
          type: "column",
          name: translate("target"),
          data: dataTarget,
          color: "#3B4351",
          pointPadding: 0.1,
          pointPlacement: -0.2,
          zIndex: 0,
          // @ts-ignore
          borderRadiusTopRight: 10,
          borderRadiusTopLeft: 10,
        },
      ]}
      tooltip={{ enabled: true }}
      legend={{ enabled: true }}
    />
  );
};
