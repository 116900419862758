import { Stack } from "@mui/material";
import { RejectCauseDetail } from "./RejectCauseDetail";
import { TopTenRejects } from "./TopTenRejects";

export const RejectCausesBaseView = () => {
  return (
    <Stack
      sx={{
        gap: 2,
        marginTop: 2,
      }}
    >
      <TopTenRejects />
      <RejectCauseDetail />
    </Stack>
  );
};
