import { Card } from "@/components/Layout/Card";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { Box, Divider, Stack, styled, Typography } from "@mui/material";
import { DataLive, PcB } from "../api/useGetEdgePcData";
import { formatDuration } from "../utils/convert-duration";
import { useTranslate } from "@/i18n/config";

const CustomTypography = styled(
  Typography,
  {},
)(() => ({
  fontWeight: "lighter",
  color: "#bbbbbb",
}));

/** @description CustomCard creato per la gestione del colore della Card Bidirectional in base allo stato */
const CustomCard = styled(
  Card,
  {},
)<{ isEnable: boolean | null }>(({ isEnable }) => ({
  backgroundColor: isEnable ? "#680101" : "",
}));

const RowItem = ({
  property,
  value,
}: {
  property: string;
  value: string | boolean;
}) => {
  return (
    <Stack
      direction={"row"}
      spacing={1}
      sx={{
        justifyContent: "space-between",
      }}
    >
      <CustomTypography>{property}</CustomTypography>
      {property === "Color" && typeof value === "string" ? (
        <Box
          sx={{
            bgcolor: value,
            width: 20,
            height: 20,
          }}
        ></Box>
      ) : (
        <CustomTypography textAlign="end">{value}</CustomTypography>
      )}
    </Stack>
  );
};

export const LeftSideSection = ({
  dataLive,
  poweroffPcB,
}: {
  dataLive: DataLive;
  poweroffPcB: PcB["poweroff"];
}) => {
  const translate = useTranslate();
  const poweroffLastValue = poweroffPcB.length
    ? poweroffPcB[poweroffPcB.length - 1]
    : { value: "", timestamp: 0 };
  return (
    <Stack
      sx={{
        gap: 1,
      }}
    >
      <CustomCard
        isEnable={
          typeof dataLive.bidirectionalActive === "boolean"
            ? dataLive.bidirectionalActive
            : null
        }
      >
        <Stack
          direction={"column"}
          sx={{
            gap: 0.4,
          }}
        >
          <Typography>{translate("bidirectional")}</Typography>
          <Divider />
          <RowItem
            property={"Status"}
            value={
              dataLive.bidirectionalActive === ""
                ? "-"
                : dataLive.bidirectionalActive
                ? "true"
                : "false"
            }
          />
          <RowItem
            property={"Remaining Time"}
            value={formatDuration(
              dataLive.bidirectionalRemainingTime * 60 * 1000,
            )}
          />
        </Stack>
      </CustomCard>
      <Card>
        <Stack
          direction={"column"}
          sx={{
            gap: 0.4,
          }}
        >
          <Typography>PC B</Typography>
          <Divider property="" />
          <RowItem
            property={"Uptime"}
            value={formatDuration(dataLive.pcBUptime * 1000)}
          />
          <Typography>Site Manager</Typography>
          <Divider />
          <RowItem
            property={"Status"}
            value={
              dataLive.siteManagerStatus === ""
                ? "-"
                : dataLive.siteManagerStatus
            }
          />
          <RowItem property={"Color"} value={dataLive.siteManagerColor} />
          <RowItem
            property={"AP mode"}
            value={dataLive.apMode ? "true" : "false"}
          />
          <Typography>{translate("power_off")}</Typography>
          <Divider />
          <RowItem
            property={"Timestamp"}
            value={TimeHelpers.parseTimestampToString({
              timestamp: poweroffLastValue.timestamp,
              dateFormat: "",
              language: "en",
            })}
          />
          <RowItem
            property={"Status"}
            value={
              poweroffLastValue.value === ""
                ? "-"
                : dataLive.bidirectionalActive
                ? "true"
                : "false"
            }
          />
        </Stack>
      </Card>
      <Card>
        <Stack
          direction={"column"}
          sx={{
            gap: 0.4,
          }}
        >
          <Typography>PC A</Typography>
          <Divider />
          <RowItem
            property={"Uptime"}
            value={formatDuration(dataLive.pcAUptime * 1000)}
          />
        </Stack>
      </Card>
      <Card>
        <Stack
          direction={"column"}
          sx={{
            gap: 0.4,
          }}
        >
          <Typography>Vitality</Typography>
          <Divider />
          <RowItem property={"Vitality"} value={dataLive.vitality.toString()} />
          <RowItem
            property={"Vitality status"}
            value={dataLive.vitalityStatus ? "true" : "false"}
          />
        </Stack>
      </Card>
    </Stack>
  );
};
