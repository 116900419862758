import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import {
  TestCircleLayoutLoader,
  SmallCircleLayoutLoader,
  GaugeSkeleton,
} from "@/features/MachineDetail/components/CircleLayout";
import { Box, Stack, Grid2 } from "@mui/material";

export const HistoricLoadingView = () => {
  return (
    <>
      <Grid2
        container
        columns={12}
        spacing={2}
        sx={{
          alignItems: "center",
        }}
      >
        <Grid2
          size={{
            xs: 6,
            md: 2,
          }}
        >
          <TestCircleLayoutLoader height={"35%"} />
        </Grid2>
        <Grid2
          size={{
            xs: 6,
            md: 2,
          }}
        >
          <TestCircleLayoutLoader height={"35%"} />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            md: 4,
          }}
        >
          <Stack
            sx={{
              gap: 2,
            }}
          >
            <SkeletonCard height={100} />
            <SkeletonCard height={100} />
          </Stack>
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            md: 4,
          }}
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              paddingX: "15%",
              height: {
                sx: "200px",
                md: "auto",
              },
            }}
          >
            <SmallCircleLayoutLoader />
          </Box>
        </Grid2>
      </Grid2>
      <Grid2
        id="machine-detail-gauge"
        container
        columns={8}
        sx={{
          alignItems: "center",
        }}
      >
        <Grid2
          size={{
            md: 1,
          }}
          sx={{
            alignItems: "center",
            flex: 1,
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <GaugeSkeleton
            sx={{
              padding: 1,
              margin: "auto",
              width: "9vmax",
              height: "9vmax",
            }}
          />
        </Grid2>
        <Grid2
          size={{
            md: 1,
          }}
          sx={{
            alignItems: "center",
            flex: 1,
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <GaugeSkeleton
            sx={{
              padding: 1,
              margin: "auto",
              width: "9vmax",
              height: "9vmax",
            }}
          />
        </Grid2>
        <Grid2
          size={{
            md: 1,
          }}
          sx={{
            alignItems: "center",
            flex: 1,
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <GaugeSkeleton
            sx={{
              padding: 1,
              margin: "auto",
              width: "9vmax",
              height: "9vmax",
            }}
          />
        </Grid2>
        <Grid2
          size={{
            md: 2,
          }}
          sx={{
            alignItems: "center",
            flex: 1,
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <GaugeSkeleton
            sx={{
              padding: 1,
              margin: "auto",
              width: "12vmax",
              height: "12vmax",
            }}
          />
        </Grid2>
        <Grid2
          size={{
            md: 1,
          }}
          sx={{
            alignItems: "center",
            flex: 1,
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <GaugeSkeleton
            sx={{
              padding: 1,
              margin: "auto",
              width: "9vmax",
              height: "9vmax",
            }}
          />
        </Grid2>
        <Grid2
          size={{
            md: 1,
          }}
          sx={{
            alignItems: "center",
            flex: 1,
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <GaugeSkeleton
            sx={{
              padding: 1,
              margin: "auto",
              width: "9vmax",
              height: "9vmax",
            }}
          />
        </Grid2>
        <Grid2
          size={{
            md: 1,
          }}
          sx={{
            alignItems: "center",
            flex: 1,
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <GaugeSkeleton
            sx={{
              padding: 1,
              margin: "auto",
              width: "9vmax",
              height: "9vmax",
            }}
          />
        </Grid2>
      </Grid2>
      <SkeletonCard height={300} />
      <SkeletonCard height={300} />
    </>
  );
};
