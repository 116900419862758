import { SeriesLineOptions } from "highcharts/highstock";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { ScatterAndLine } from "@/components/highcharts/scatter/ScatterAndLine";
import { ContextInformation } from "../Layouts/ContextInformation";
import {
  ResidualsDataSchema,
  ResidualsResSchema,
} from "../../api/useGetResiduals";

const processData = ({
  data,
  seriesNames,
}: {
  data: ResidualsDataSchema;
  seriesNames: {
    warningLower: string;
    warningUpper: string;
    criticalLower: string;
    criticalUpper: string;
  };
}): { lineSeries: SeriesLineOptions[]; decompositionData: number[][] } => {
  const decompositionData: number[][] = [];
  const warningLowerLineData: number[][] = [];
  const warningUpperLineData: number[][] = [];
  const criticalLowerLineData: number[][] = [];
  const criticalUpperLineData: number[][] = [];

  data.forEach((entry) => {
    const {
      metric,
      value,
      warningLower,
      warningUpper,
      criticalLower,
      criticalUpper,
    } = entry;
    decompositionData.push([metric, value]);
    warningLower && warningLowerLineData.push([metric, warningLower]);
    warningUpper && warningUpperLineData.push([metric, warningUpper]);
    criticalLower && criticalLowerLineData.push([metric, criticalLower]);
    criticalUpper && criticalUpperLineData.push([metric, criticalUpper]);
  });

  return {
    lineSeries: [
      {
        type: "line",
        name: seriesNames.warningLower,
        data: warningLowerLineData,
        color: "#e8c52a",
      },
      {
        type: "line",
        name: seriesNames.warningUpper,
        data: warningUpperLineData,
        color: "#e8c52a",
      },
      {
        type: "line",
        name: seriesNames.criticalLower,
        data: criticalLowerLineData,
        color: "#D62422",
      },
      {
        type: "line",
        name: seriesNames.criticalUpper,
        data: criticalUpperLineData,
        color: "#D62422",
      },
    ],
    decompositionData,
  };
};

export const ResidualsChart = ({ props }: { props: ResidualsResSchema }) => {
  const translate = useTranslate();
  const { residualData, currentData, contextInfo } = props;
  const { lineSeries, decompositionData } = processData({
    data: residualData,
    seriesNames: {
      criticalLower: translate("threshold.critical_lower"),
      criticalUpper: translate("threshold.critical_upper"),
      warningLower: translate("threshold.warning_lower"),
      warningUpper: translate("threshold.warning_upper"),
    },
  });
  lineSeries.push({
    type: "line",
    name: translate("diagnostic.decomposition"),
    color: "#ff00c3",
    data: decompositionData,
  });

  return (
    <Card>
      <ContextInformation props={contextInfo} />
      <ScatterAndLine
        title={translate("diagnostic.decomposition")}
        scrollbarEnabled={false}
        lineSeries={lineSeries}
        zoomType="xy"
        tooltip={{
          enabled: true,
          options: {
            pointFormatter: function () {
              if (this.series.type === "scatter") {
                return `
              ${translate("date")}: <b>${new Date(
                this.x,
              ).toLocaleString()}</b><br>
              ${translate("value")}: <b>${this.y}</b>
            `;
              }
              return `<span style='color: ${this.color}'>● </span>${this.series.name}: <b>${this.y}</b><br>`;
            },
          },
        }}
        yAxis={[
          {
            uom: "",
            series: [
              {
                type: "scatter",
                name: translate("raw_data"),
                color: "#7cb5ec",
                data: currentData,
                enableMouseTracking: false,
              },
            ],
          },
        ]}
        legend={{ enabled: true }}
        radius={1}
      />
    </Card>
  );
};
