import { toast } from "react-hot-toast";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  Select,
  MenuItem,
  CircularProgress,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { useDisclosure } from "@/hooks/useDisclosure";
import { Modal } from "@/components/Modal";
import { PowerWord } from "../../types";
import { chatStore } from "../../store/chat-store";
import { userConfigurationStore } from "../../store/user-configuration-store";
import { usePowerWord } from "../../api/usePowerWord";
import { sentryModeStore } from "../../store/sentry-mode-store";
import { scrollChat } from "../../utilities/scrollChat";
import { PowerwordButton } from "./PowerwordButton";
import { useGetStrainList } from "../../api/useGetStrainList";

export const PowerwordButtonStrain = ({
  powerWord,
}: {
  powerWord: PowerWord;
}) => {
  const translate = useTranslate();
  const { addMessage, setChatId, setIsAnswering } = chatStore();
  const { version, profile, machine } = userConfigurationStore();
  const { mutateAsync: callPowerWord } = usePowerWord();
  const { open, close, isOpen } = useDisclosure();
  const { resetTimer } = sentryModeStore();

  const { data, isFetching, refetch } = useGetStrainList();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  } = useForm<{ strain: string }>();

  const fetchStrainList = async () => {
    if (!machine) {
      toast.error(translate("intellecta.no_machine_selected"));
      return;
    }

    try {
      await refetch();
      open();
    } catch {
      toast.error(translate("user_feedback.an_error_occurred"));
    }
  };

  const onConfirm = handleSubmit(async (data) => {
    if (!version || !profile)
      return toast.error(translate("user_feedback.an_error_occurred"));

    close();
    setIsAnswering(true);
    resetTimer();

    addMessage({
      sender: "User",
      message: `Quali sono i parametri per la strain **${data.strain}**?`,
    });

    scrollChat();

    try {
      const response = await callPowerWord({
        endpoint: powerWord.endpoint,
        params: { strain: data.strain },
      });

      addMessage({
        sender: "Intellecta",
        message: response.value,
        image: response.image,
        id: response.message_id,
      });
      setChatId(response.chat_id);
    } catch {
      toast.error(translate("user_feedback.an_error_occurred"));
    } finally {
      scrollChat();
      setIsAnswering(false);
      resetTimer();
      reset();
    }
  });

  return (
    <>
      <Modal
        titleContent={translate("intellecta.strain_select")}
        maxWidth="xs"
        fullWidth
        open={isOpen}
        bodyContent={
          <FormControl fullWidth margin="normal" error={!!errors.strain}>
            <InputLabel>{translate("intellecta.strain")}</InputLabel>
            <Controller
              name="strain"
              control={control}
              rules={{ required: translate("intellecta.strain_not_selected") }}
              render={({ field }) => (
                <Select
                  {...field}
                  onChange={(e) => {
                    setValue("strain", e.target.value);
                    clearErrors("strain");
                  }}
                  value={field.value || ""}
                  defaultValue=""
                  label={translate("intellecta.strain")}
                  MenuProps={{ style: { maxHeight: 400 } }}
                >
                  {isFetching ? (
                    <MenuItem disabled>
                      <CircularProgress size={20} />
                    </MenuItem>
                  ) : (
                    data &&
                    data.value.map((strain) => (
                      <MenuItem key={strain} value={strain}>
                        {strain}
                      </MenuItem>
                    ))
                  )}
                </Select>
              )}
            />
            {errors.strain && (
              <FormHelperText>{errors.strain.message}</FormHelperText>
            )}
          </FormControl>
        }
        actions={
          <>
            <Button variant="outlined" onClick={close}>
              {translate("actions.cancel")}
            </Button>
            <Button variant="contained" onClick={onConfirm}>
              {translate("actions.confirm")}
            </Button>
          </>
        }
      />
      <PowerwordButton powerWord={powerWord} onClick={fetchStrainList} />
    </>
  );
};
