import {
  HistoricMachineNodeDataLoader,
  MachineNodeDataLoader,
} from "@/features/OverallLineEfficiency/components/CustomNodes";
import { Node, NodeProps, NodeTypes, useNodes, useNodeId } from "reactflow";
import { CustomHandle } from "../custom-handles/CustomHandle";
import { NodeBlockData } from "../types";
import { PlaceholderMachineNode } from "./view/PlaceholderNode";
import { Box } from "@mui/material";
import { memo } from "react";

/**
 * @description used to render nodes in the line efficiency editor
 */

export type CustomMachineLineEditNode = Node<NodeBlockData, "machine">;

const MachineNode = memo((props: NodeProps<NodeBlockData>) => {
  const handles = Object.entries(props.data.handles)
    .filter(([, edge]) => edge.enabled)
    .map(([id, edge]) => {
      return edge.enabled && <CustomHandle key={id} type={edge.type} id={id} />;
    });

  const nodes = useNodes();

  const nodeId = useNodeId();

  const node = nodes.find((node) => node.id === nodeId);

  return (
    <Box
      sx={{
        width: node?.width ?? 450,
        height: node?.height ?? 150,
      }}
    >
      {handles}
      {props.data.isPlaceholder ? (
        <PlaceholderMachineNode model={props.data.name} />
      ) : (
        <MachineNodeDataLoader
          name={props.data.name}
          lineId={props.data.lineId}
        />
      )}
    </Box>
  );
});

const HistoricMachineNode = memo((props: NodeProps<NodeBlockData>) => {
  const handles = Object.entries(props.data.handles)
    .filter(([, edge]) => edge.enabled)
    .map(([id, edge]) => {
      return edge.enabled && <CustomHandle key={id} type={edge.type} id={id} />;
    });

  const nodes = useNodes();

  const nodeId = useNodeId();

  const node = nodes.find((node) => node.id === nodeId);

  return (
    <Box
      sx={{
        width: node?.width ?? 450,
        height: node?.height ?? 150,
      }}
    >
      {handles}
      {props.data.isPlaceholder ? (
        <PlaceholderMachineNode model={props.data.name} />
      ) : (
        <HistoricMachineNodeDataLoader
          name={props.data.name}
          lineId={props.data.lineId}
        />
      )}
    </Box>
  );
});

export const LineNodeTypes: NodeTypes = {
  machine: MachineNode,
};

export const HistoricLineNodeTypes: NodeTypes = {
  machine: HistoricMachineNode,
};
