import { useGetUserOverviewPages } from "@/pages/Overview/api/useGetUserOverviewPages";
import { useEffect, useState } from "react";
import { OverviewPages } from "src/constants/sentinelNavigations";

export const useCheckUserPage = (page: OverviewPages) => {
  const { data: pages } = useGetUserOverviewPages();
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    if (!pages) return;
    const hasAccess = pages.length && pages.find((p) => p === page);

    setHasAccess(!!hasAccess);
  }, [pages, page]);

  return {
    hasAccess,
  };
};
