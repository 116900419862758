import { ReactNode } from "react";
import {
  Box,
  Grid2,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { BenchmarkRow } from "./BenchmarkRow";
import { AiSuggest } from "../types";

const getCategoryColor = ({ category }: { category: string }): string => {
  const categoriesColors: { [key: string]: string } = {
    Machine: "#D62422",
    Process: "#ED8526",
    Line: "#16A99C",
  };
  return categoriesColors[category] || "#4d4d4d";
};

export const AiSuggestItem = ({ data }: { data: AiSuggest }) => {
  const { palette } = useTheme();
  const translate = useTranslate();
  const efficiencyLostPercentage = Number.isInteger(data.efficiencyLost)
    ? data.efficiencyLost
    : data.efficiencyLost.toFixed(2);
  const mttrDurationString = TimeHelpers.parseDurationToString({
    duration: data.mttr * 60 * 1000,
  });
  const mttrBmDurationString = TimeHelpers.parseDurationToString({
    duration: data.mttrBm * 60 * 1000,
  });

  return (
    <Box
      sx={{
        backgroundColor: palette.background.paper,
        borderRadius: ".25rem",
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            backgroundColor: getCategoryColor({ category: data.category }),
            height: ".5rem",
            mt: 2,
            mb: 0,
            mx: 6,
            borderRadius: 4,
          }}
        />
        <Card
          sx={{
            backgroundColor: "transparent",
            width: "100%",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            px: 8,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: 1, alignItems: "baseline" }}>
              <Typography>
                {efficiencyLostPercentage}% - {data.error}
              </Typography>
              <Typography sx={{ color: "#bbb", fontSize: ".6rem" }}>
                {translate("long_term")}
              </Typography>
            </Box>
            <Tooltip
              title={
                <span style={{ whiteSpace: "pre-line" }}>
                  {translate(
                    "efficiency_navigator.loss_compared_to_benchmark_descriptive",
                    {
                      efficiencyLostPercentage,
                      nEvents: data.nEvents,
                      nEventsBm: data.nEventsBm,
                      mttr: mttrDurationString,
                      mttrBm: mttrBmDurationString,
                    },
                  )}
                </span>
              }
            >
              <InfoOutlined />
            </Tooltip>
          </Box>
        </Card>
        <Card
          sx={{
            width: "100%",
            height: "100%",
            px: 10,
          }}
        >
          <Stack
            sx={{
              height: "100%",
              justifyContent: "space-evenly",
            }}
          >
            <Grid2 container spacing={1}>
              <Grid2 size={6}></Grid2>
              <Grid2
                size={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: ".7rem",
                  }}
                >
                  {translate("current")}
                </Typography>
              </Grid2>
              <Grid2
                size={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: ".7rem",
                  }}
                >
                  {translate("benchmark")}
                </Typography>
              </Grid2>
              <Grid2
                size={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: ".7rem",
                  }}
                >
                  {translate("efficiency_navigator.gap")}
                </Typography>
              </Grid2>
            </Grid2>
            <BenchmarkRow
              label={translate("losses.efficiency_losses")}
              valueCurrent={
                data.efficiency ? parseFloat(data.efficiency.toFixed(2)) : 0
              }
              gap={
                data.efficiency &&
                (data.efficiencyBm || data.efficiencyBm === 0)
                  ? parseFloat((data.efficiency - data.efficiencyBm).toFixed(2))
                  : 0
              }
              valueBenchmark={
                data.efficiencyBm ? parseFloat(data.efficiencyBm.toFixed(2)) : 0
              }
              chartType="percentage"
            />
            <BenchmarkRow
              label={translate("count")}
              valueCurrent={data.nEvents}
              gap={data.nEvents - data.nEventsBm}
              valueBenchmark={data.nEventsBm}
              chartType="count"
            />
            <BenchmarkRow
              label={`${translate("duration")} (hh:mm:ss)`}
              valueCurrent={parseFloat(data.mttr.toFixed(2))}
              gap={parseFloat((data.mttr - data.mttrBm).toFixed(2))}
              valueBenchmark={parseFloat(data.mttrBm.toFixed(2))}
              chartType="duration"
            />
          </Stack>
        </Card>
      </Stack>
    </Box>
  );
};

// FOR RECIPE PRODUCTION ELAPSED TIME
export const ItemLayout = ({
  icon,
  label,
  value,
}: {
  icon: ReactNode;
  label: string;
  value: string;
}) => {
  return (
    <Card>
      <Grid2
        container
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid2
          size={{
            md: 1,
          }}
        >
          {icon}
        </Grid2>
        <Grid2
          container
          size={{
            md: 10,
          }}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography>{label}</Typography>
          <Typography>{value}</Typography>
        </Grid2>
      </Grid2>
    </Card>
  );
};
