import { z } from "zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import { IntellectaVersion } from "../types";
import { chatStore } from "../store/chat-store";
import { intellectaClient } from "./intellectaClient";
import { userConfigurationStore } from "../store/user-configuration-store";

type Payload = {
  user: string;
  machine?: string;
  version: IntellectaVersion;
  //* dovrebbe anche accettare altre cose (e.g.: alarm_code)
  params?: { [key: string]: unknown };
  chat_id: number | null;
  profile: string;
};

const Response = z.object({
  value: z.string(),
  chat_id: z.number(),
  message_id: z.number(),
  image: z.optional(z.string()),
});

type Response = z.infer<typeof Response>;

export const usePowerWord = () => {
  const queryClient = useQueryClient();
  const { user } = useFirebaseContext();
  const email = user?.email || "";
  const { chatId } = chatStore();
  const { version, profile, machine } = userConfigurationStore();

  return useMutation({
    mutationFn: async ({
      endpoint,
      params,
    }: {
      endpoint: string;
      params?: { [key: string]: unknown };
    }) => {
      if (!version || !profile) {
        throw new Error();
      }

      const response = await intellectaClient<Payload, Response>({
        url: endpoint,
        payload: {
          user: email,
          chat_id: chatId,
          version,
          machine: machine ? machine : undefined,
          params,
          profile,
        },
      });

      return Response.parse(response);
    },
    onSuccess() {
      if (chatId === null) {
        //* Inizio nuova chat
        //* Scarico di nuovo lo storico delle chat
        queryClient.invalidateQueries({
          queryKey: ["intellecta-historic-chat-list", email],
        });
      }
    },
  });
};
