import { Card } from "@/components/Layout/Card";
import {
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import { useState } from "react";
import {
  AcceptanceTestDataResult,
  DatiTabella,
} from "../api/useGetAcceptanceTestData";
import { useSetWho } from "../api/useSetWho";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderEditCellParams,
  GridRenderCellParams,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { TimeHelpers, useFormatTimestamp } from "@/utils/TimeHelpers";
import { useSetComment } from "../api/useSetComment";
import { useAcceptanceTestContext } from "../context/acceptanceTest-context";
import { DateTime } from "luxon";
import { useTranslate } from "@/i18n/config";
import { BasicTable } from "@/components/BasicTable";

export interface TableRows {
  id: number;
  timestampStop: number;
  timestampRestart: number;
  stateString: string;
  errorDescription: string;
  comment: string;
  duration: string;
  who: string;
  deadTime?: boolean;
  dataId?: number;
  isDisabled?: boolean;
}

function transformedRows({
  tableData,
  satConfiguration,
}: {
  tableData: DatiTabella[];
  satConfiguration: "Blocked" | "Free";
}): TableRows[] {
  return tableData.length > 0
    ? tableData.map<TableRows>((row, index) => ({
        id: tableData.length - index,
        timestampStop: row.Stop - 7200000,
        timestampRestart: row.Restart - 7200000,
        stateString: row.StateString,
        errorDescription: row.ErrorDescription,
        comment: row.Comment,
        duration: TimeHelpers.parseDurationToString({
          duration: row.Duration * 1000,
        }),
        who: row.Who === 0 ? "related" : "unrelated",
        deadTime: row.deadTime,
        dataId: row.Id,
        isDisabled: disabledHandler({
          stateString: row.StateString,
          stopTime: row.Stop,
          satConfiguration,
        }),
      }))
    : [];
}

function CustomSaveCommentComponent(props: GridRenderEditCellParams) {
  const { id, field, value, row } = props;
  const apiRef = useGridApiContext();
  return (
    <Stack
      direction="row"
      key={id}
      sx={{
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Typography>{value}</Typography>
      <GridActionsCellItem
        disabled={row.isDisabled ? true : false}
        key={id}
        label="edit"
        icon={<EditIcon />}
        onClick={() => {
          apiRef.current.startCellEditMode({ id, field });
        }}
      />
    </Stack>
  );
}

const CustomEditComponent = ({
  params,
}: {
  params: GridRenderEditCellParams;
}) => {
  const { mutate: setComment } = useSetComment();
  const { id, value: valueProp, field, row } = params;
  const [value, setValue] = useState(valueProp);
  const apiRef = useGridApiContext();

  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <TextField
        type="text"
        fullWidth
        value={value}
        onChange={(e) => {
          const newValue = e.target.value;
          apiRef.current.setEditCellValue({
            id,
            field,
            value: newValue,
            debounceMs: 200,
          });
          setValue(newValue);
        }}
        slotProps={{
          input: { inputProps: { min: 0, sx: { padding: 0.5 } } },
        }}
      />
      <Stack
        direction="row"
        sx={{
          gap: 1,
        }}
      >
        <GridActionsCellItem
          label="save"
          icon={<SaveIcon />}
          onClick={() => {
            setComment({ Comment: value, Id: row.dataId });
            apiRef.current.stopCellEditMode({ id, field });
          }}
        />
        <GridActionsCellItem
          label="cancel"
          icon={<CancelIcon />}
          onClick={() => apiRef.current.stopCellEditMode({ id, field })}
        />
      </Stack>
    </Stack>
  );
};

function CustomWhoComponent(props: GridRenderCellParams) {
  const { mutate: setWho } = useSetWho();
  const { value, row } = props;
  const [whoValue, setWhoValue] = useState<string>(value);
  const { isDisabled } = row;

  const handleChange = (event: SelectChangeEvent) => {
    const changedValue = event.target.value;
    setWhoValue(changedValue);
    setWho({
      id: row.dataId.toString(),
      who: changedValue === "related" ? "0" : "1",
    });
  };

  return (
    <FormControl
      sx={{ m: 0, minWidth: 130 }}
      size="small"
      disabled={isDisabled ? true : false}
    >
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={whoValue}
        renderValue={(value: string) => value}
        onChange={handleChange}
      >
        <MenuItem value={"related"}>related</MenuItem>
        <MenuItem value={"unrelated"}>unrelated</MenuItem>
      </Select>
    </FormControl>
  );
}

export const LiveTable = ({
  tableData,
}: {
  tableData: AcceptanceTestDataResult["DatiTabella"];
}) => {
  const { formatTimestamp } = useFormatTimestamp();
  const { satConfiguration } = useAcceptanceTestContext();
  const translate = useTranslate();
  const rows = transformedRows({ tableData, satConfiguration });

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      flex: 0.5,
    },
    {
      field: "timestampStop",
      headerName: translate("acceptance_test.pause"),
      type: "dateTime",
      flex: 1.3,
      valueFormatter: (value) =>
        value &&
        formatTimestamp({
          timestamp: +new Date(value),
          skipTimezoneTransform: true,
        }),
      valueGetter: (value) => {
        const date =
          value &&
          formatTimestamp({
            timestamp: +new Date(value),
            skipLocale: true,
          });
        return value ? new Date(date) : null;
      },
    },
    {
      field: "timestampRestart",
      headerName: translate("acceptance_test.restart"),
      type: "dateTime",
      flex: 1.3,
      valueFormatter: (value) =>
        value &&
        formatTimestamp({
          timestamp: +new Date(value),
          skipTimezoneTransform: true,
        }),
      valueGetter: (value) => {
        const date =
          value &&
          formatTimestamp({
            timestamp: +new Date(value),
            skipLocale: true,
          });
        return value ? new Date(date) : null;
      },
    },
    {
      field: "stateString",
      headerName: translate("machine.status"),
      type: "string",
      flex: 1.2,
    },
    {
      field: "errorDescription",
      headerName: translate("alarms"),
      type: "string",
      flex: 1.2,
    },
    {
      field: "comment",
      headerName: translate("acceptance_test.comment"),
      flex: 3,
      type: "string",
      editable: true,
      renderCell: (params: GridRenderEditCellParams) => (
        <CustomSaveCommentComponent {...params} />
      ),
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomEditComponent params={{ ...params }} />
      ),
    },
    {
      field: "duration",
      headerName: translate("machine.downtime"),
      type: "number",
      flex: 1.2,
    },
    {
      field: "who",
      headerName: translate("acceptance_test.who"),
      type: "number",
      flex: 1.2,
      renderCell: (params: GridRenderCellParams) => (
        <CustomWhoComponent {...params} />
      ),
    },
    {
      field: "deadTime",
      headerName: translate("acceptance_test.dead_time"),
      type: "boolean",
      flex: 1.2,
    },
  ];

  return (
    <Card>
      <BasicTable
        columns={columns}
        rows={rows}
        fileName="acceptance_test_detail"
        sx={{ height: 400, width: "100%" }}
      />
    </Card>
  );
};

const disabledHandler = ({
  stateString,
  stopTime,
  satConfiguration,
}: {
  stateString: string | null;
  stopTime: number;
  satConfiguration: "Blocked" | "Free";
}) => {
  if (
    stateString === "Missing Downstream" ||
    stateString === "Missing Upstream"
  )
    return true;
  return satConfiguration === "Blocked" &&
    // TODO: manca la condizione di utc = machine o user
    DateTime.now().toMillis() - (stopTime - 7200000) > 1000 * 60 * 15
    ? true
    : false;
};
