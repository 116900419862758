import { Stack, Typography } from "@mui/material";
import { Prompt } from "../../types";
import { PromptButton } from "./PromptButton";
import { useTranslate } from "@/i18n/config";
import { userConfigurationStore } from "../../store/user-configuration-store";

export const PromptButtonList = ({
  onPromptClick,
}: {
  onPromptClick: (prompt: Prompt) => void;
}) => {
  const translate = useTranslate();
  const { version, profile, prompts } = userConfigurationStore();

  const filteredPrompts =
    version && profile
      ? prompts.filter(
          (prompt) => prompt.version === version && prompt.profile === profile,
        )
      : [];

  return (
    <Stack
      sx={{
        gap: 2,
      }}
    >
      {filteredPrompts.length > 0 ? (
        filteredPrompts.map((prompt) => (
          <PromptButton
            key={prompt.id}
            prompt={prompt}
            onPromptClick={onPromptClick}
          />
        ))
      ) : (
        <Typography>{translate("intellecta.no_prompts")}</Typography>
      )}
    </Stack>
  );
};
