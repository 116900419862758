interface FaultAnalysisColors {
  [key: string]: string;
}

export const efficiencyNavigatorColors = {
  category: {
    machine: "#D62422",
    process: "#ED8526",
    line: "#16A99C",
    default: "#4d4d4d",
  },
  pie: {
    oee: "#76A12B",
    offColor: "#1f293f",
    extraCapacity: "#596679",
  },
  faultAnalysis: {
    MACHINE: "#9A0000",
    INTERACTION: "#ED8526",
    LINE: "#16A99C",
    " ": "#4d4d4d",
  } as FaultAnalysisColors,
};
