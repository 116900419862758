import { useState, MouseEvent } from "react";
import { toast } from "react-hot-toast";
import { z } from "zod";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Collapse,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  // Typography,
  styled,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  ExpandMoreOutlined,
} from "@mui/icons-material";
import { XrangePointOptionsObject } from "highcharts/highstock";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { ONE_MINUTE, ONE_HOUR } from "@/utils/durationsInMilliseconds";
import { useGetBatchesAndRecipes } from "../../api/useGetBatchesAndRecipes";
import { XRangeChart } from "@/components/highcharts/xrange/XRange";
import { colors } from "@/styles/colors";
import {
  FILTER_COLOR,
  Item,
  MAX_ITEMS_NUMBER,
  Timelapse,
} from "../../context/motors-context";
import { useMotorsContext } from "../../context/useMotorsContext";
import { useMotorsDispatchContext } from "../../context/useMotorsDispatchContext";
import { TimeHelpers, useFormatTimestamp } from "@/utils/TimeHelpers";
import { RunsSelection } from "./RunsSelection";
import { SelectedFrame } from "./SelectedFrame";

const THIRTY_MINUTES = ONE_MINUTE * 30;

const parseData = ({
  data,
  selectedItems,
  filterByDuration,
}: {
  data: Item[];
  selectedItems: Item[];
  filterByDuration: "1h" | "30m" | "all";
}): XrangePointOptionsObject[] => {
  const filteredData =
    filterByDuration === "all"
      ? data
      : filterByDuration === "30m"
      ? data.filter((entry) => entry.dateEnd - entry.dateStart > THIRTY_MINUTES)
      : data.filter((entry) => entry.dateEnd - entry.dateStart > ONE_HOUR);

  return filteredData.map((occurrence) => {
    const { timelapse, dateStart, dateEnd, id } = occurrence;
    const isSelected = selectedItems.find((batch) => batch.id === id);
    const color = isSelected ? "#ccc" : "#777";
    // const color =
    //   EVALUATION_COLORS[status][isSelected ? "selected" : "notSelected"];

    return {
      x: dateStart,
      x2: dateEnd,
      y: 0,
      color,
      name: timelapse,
      //   custom: {
      //     recipeName,
      //     status,
      //     phases,
      //   },
    };
  });
};

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  minHeight: "36.5px",
  maxHeight: "36.5px",
  backgroundColor: colors.palette.darkBlue,
  border: "1px solid #a5a5a5",
  "&.Mui-expanded": {
    border: `1px solid ${FILTER_COLOR}`,
    borderBottom: "1px solid transparent",
  },
  ":hover": {
    border: `1px solid ${FILTER_COLOR}`,
  },
}));

export const BatchRecipeSelection = () => {
  const translate = useTranslate();
  const { formatTimestamp } = useFormatTimestamp();
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [expand, setExpand] = useState(true);
  const [filterByDuration, setFilterByDuration] = useState<
    "30m" | "1h" | "all"
  >("all");
  const { timelapse, selectedItems, frames } = useMotorsContext();
  const dispatch = useMotorsDispatchContext();
  const { data: apiData, error, isLoading } = useGetBatchesAndRecipes();

  if (isLoading) return <SkeletonCard height={180} />;
  if (error) return <Card>{translate("user_feedback.an_error_occurred")}</Card>;
  if (!apiData) return <Card>{translate("user_feedback.no_data")}</Card>;

  const data = parseData({
    data:
      timelapse === "recipes" ? apiData.items.recipes : apiData.items.batches,
    selectedItems,
    filterByDuration,
  });
  // const batchEvaluations: BatchEvaluation[] = [];
  // apiData.batches.forEach(
  //   (batch) =>
  //     !batchEvaluations.includes(batch.status) &&
  //     batchEvaluations.push(batch.status),
  // );

  const onClickItem = ({ start, end }: { start: number; end: number }) => {
    const clonedApiData =
      timelapse === "recipes"
        ? [...apiData.items.recipes]
        : [...apiData.items.batches];
    const selectedItem = clonedApiData.find(
      (item) => item.dateStart === start && item.dateEnd === end,
    );
    if (selectedItem) {
      const isAlreadySelected =
        selectedItems.findIndex((item) => item.id === selectedItem.id) > -1;
      if (isAlreadySelected) {
        dispatch({ type: "REMOVE_ITEM", item: selectedItem });
        dispatch({ type: "CLEAR_RUNS" });
      } else {
        if (selectedItems.length >= MAX_ITEMS_NUMBER) {
          toast.error(
            translate("user_feedback.max_n_selected", {
              max: MAX_ITEMS_NUMBER,
            }),
          );
        } else {
          dispatch({ type: "ADD_ITEM", item: selectedItem });
        }
      }
    }
  };

  const handleExpandPanel =
    (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleTimelapse = (
    _: MouseEvent<HTMLElement>,
    newTimelapse: Timelapse,
  ) => {
    if (newTimelapse !== null) {
      dispatch({ type: "SET_TIMELAPSE", timelapse: newTimelapse });
    }
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        zIndex: 1,
      }}
      initial={{ opacity: 0.5, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ p: ".5rem", display: "flex", gap: 1, alignItems: "center" }}>
          <ToggleButtonGroup
            value={timelapse}
            exclusive
            onChange={handleTimelapse}
            aria-label="timelapse selection"
            size="small"
          >
            <ToggleButton value="recipes" aria-label="recipes list">
              {translate("machine.recipe_other")}
            </ToggleButton>
            <ToggleButton value="batches" aria-label="batches list">
              {translate("analytics.batch_other")}
            </ToggleButton>
          </ToggleButtonGroup>
          <Button
            variant="outlined"
            onClick={() => dispatch({ type: "CLEAR_ITEMS" })}
          >
            {translate("actions.clear_batches_recipes")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => dispatch({ type: "CLEAR_RUNS" })}
          >
            {translate("actions.clear_runs")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => dispatch({ type: "CLEAR_FRAMES" })}
          >
            {translate("actions.clear_frames")}
          </Button>
          <Accordion
            disableGutters
            expanded={expanded === "duration-panel"}
            onChange={handleExpandPanel("duration-panel")}
          >
            <StyledAccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="duration-panel-content"
              id="duration-panel-header"
            >
              {translate("duration")}
              {expanded === "duration-panel" && (
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: 2,
                    bottom: "-5px",
                    left: "-1px",
                    boxSizing: "content-box",
                    width: "100%",
                    height: "5px",
                    borderRight: `1px solid ${FILTER_COLOR}`,
                    borderLeft: `1px solid ${FILTER_COLOR}`,
                    background: colors.palette.darkBlue,
                  }}
                ></Box>
              )}
            </StyledAccordionSummary>
            <AccordionDetails
              sx={{
                position: "absolute",
                zIndex: 1,
                backgroundColor: colors.palette.darkBlue,
                minWidth: 360,
                border: `1px solid ${FILTER_COLOR}`,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                p: "1rem",
                gap: 1,
                marginTop: "3px",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  width: 120,
                  color: filterByDuration === "30m" ? FILTER_COLOR : null,
                  borderColor: filterByDuration === "30m" ? FILTER_COLOR : null,
                }}
                onClick={() => {
                  setFilterByDuration("30m");
                }}
              >
                {`> 30 ${translate("durations.minute_other")}`}
              </Button>
              <Button
                variant="outlined"
                sx={{
                  width: 120,
                  color: filterByDuration === "1h" ? FILTER_COLOR : null,
                  borderColor: filterByDuration === "1h" ? FILTER_COLOR : null,
                }}
                onClick={() => {
                  setFilterByDuration("1h");
                }}
              >
                {`> 1 ${translate("durations.hour")}`}
              </Button>
            </AccordionDetails>
          </Accordion>
          {filterByDuration !== "all" && (
            <Chip
              label={filterByDuration}
              onDelete={() => {
                setFilterByDuration("all");
              }}
            />
          )}
        </Box>

        <IconButton onClick={() => setExpand(!expand)}>
          {expand ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      {frames.length > 0 && (
        <Collapse in={!expand} timeout="auto" unmountOnExit>
          <Box
            sx={{
              display: "flex",
              gap: 1,
            }}
          >
            {frames.map((frame) => (
              <SelectedFrame key={frame.id} frame={frame} />
            ))}
          </Box>
        </Collapse>
      )}
      <Collapse
        in={expand}
        timeout="auto"
        unmountOnExit
        // sx={{ maxHeight: 250 }}
      >
        <Box
          sx={{
            height: 100,
          }}
        >
          <XRangeChart
            customHeight={100}
            allowExporting={false}
            categories={
              timelapse === "recipes"
                ? [translate("machine.recipe")]
                : [translate("analytics.batch")]
            }
            title=""
            data={data}
            tooltipFormatter={function () {
              const parseResult = z
                .object({
                  x: z.number(),
                  x2: z.number(),
                  color: z.string(),
                  key: z.string(),
                  // point: z.object({
                  // custom: z.object({
                  //   recipeName: z.string(),
                  //   status: batchEvaluation,
                  //   phases: z.array(phase),
                  // }),
                  // }),
                })
                .safeParse(this);
              if (!parseResult.success) return;

              const {
                color,
                key,
                x,
                x2,
                // point: {
                //   custom: { status, recipeName, phases },
                // },
              } = parseResult.data;

              const dateStart = formatTimestamp({
                timestamp: x,
              });
              const dateEnd = formatTimestamp({
                timestamp: x2,
              });
              const durationString = TimeHelpers.parseDurationToString({
                duration: x2 - x,
              });

              const category = key;
              return `<span style="color:${color}">●</span> ${category}<br>
                    ${translate("start")}: <b>${dateStart}</b><br>
                    ${translate("end")}: <b>${dateEnd}</b><br>
                    ${translate("duration")}: <b>${durationString}</b>
                  `;
            }}
            seriesOptions={{
              cursor: "pointer",
              events: {
                click: function (event) {
                  const point: XrangePointOptionsObject = event.point;
                  onClickItem({ start: point.x!, end: point.x2! });
                },
              },
            }}
          />
        </Box>
        {selectedItems.length > 0 && <RunsSelection />}
      </Collapse>
    </Card>
  );
};
