import { Card } from "@/components/Layout/Card";
import styled from "@emotion/styled";
import { Stack, Box } from "@mui/material";

const ComposableWidgetLayout = styled(Card)({
  borderRadius: ".25rem",
  minHeight: "110px",
  color: "#fff",
  backgroundImage: "none",
  position: "relative",
  padding: "1rem",
  alignItems: "center",
});

export const ComposableWidget = ({
  titleSection,
  description,
  chart,
}: {
  titleSection: () => JSX.Element;
  description?: () => JSX.Element;
  chart?: () => JSX.Element;
}) => {
  return (
    <ComposableWidgetLayout>
      <Stack
        sx={{
          gap: 1,
        }}
      >
        <Box>{titleSection()}</Box>
        <Stack
          direction="row"
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              flex: 4,
            }}
          >
            {description ? description() : null}
          </Box>
          {chart ? (
            <Box
              sx={{
                height: 100,
                width: 100,
                flex: 1,
              }}
            >
              {chart()}
            </Box>
          ) : null}
        </Stack>
      </Stack>
    </ComposableWidgetLayout>
  );
};
