import { Card } from "@/components/Layout/Card";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { Chip, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  AdvancedAnalytic,
  useGetBatchAdvancedAnalytics,
} from "../../api/useGetBatchAdvancedAnalytics";
import { AdvancedAnalyticsList } from "../../components/AdvancedAnalyticsList";
import { Condenser } from "../../views/Condenser";
import { PressureExcursionAnalytic } from "../../views/PressureExcursionAnalytic";
import { useLyoContext } from "../../context/lyo-context";
import { Batch } from "../../types";
import { Refrigeration } from "../../views/Refrigeration";
import { VacuumPump } from "../../views/VacuumPump";

const AdvancedAnalyticOverview = ({ batch }: { batch: Batch }) => {
  const { formatTimestamp } = useFormatTimestamp();
  const startedAt = `Started at: ${formatTimestamp({
    timestamp: batch.from,
  })}`;

  return (
    <Card>
      <Chip label={batch.batchName} />
      <div style={{ marginTop: 20 }} />
      <Typography
        sx={{
          fontWeight: "",
        }}
      >
        {startedAt}
      </Typography>
      <Typography
        sx={{
          textAlign: "right",
          fontWeight: "bold",
        }}
      >
        {batch.recipe}
      </Typography>
    </Card>
  );
};

export const AdvancedView = () => {
  const { data = [] } = useGetBatchAdvancedAnalytics();
  const [selectedAdvancedAnalytic, setSelectedAdvancedAnalytic] =
    useState<AdvancedAnalytic>();

  const [{ selectedBatch }] = useLyoContext();

  useEffect(() => {
    setSelectedAdvancedAnalytic(undefined);
  }, [selectedBatch]);

  const items = data.map((item) => ({ ...item, id_value: item.id }));

  return (
    <Stack
      sx={{
        gap: 2,
      }}
    >
      {selectedBatch ? (
        <AdvancedAnalyticOverview batch={selectedBatch} />
      ) : null}
      <AdvancedAnalyticsList
        onItemClicked={(item) => {
          if (item.id === selectedAdvancedAnalytic?.id) {
            setSelectedAdvancedAnalytic(undefined);
            return;
          }
          setSelectedAdvancedAnalytic(item);
        }}
        activeId={selectedAdvancedAnalytic?.id}
        items={items}
        renderTextItem={(item) => item.display_name}
      />
      {selectedAdvancedAnalytic && (
        <RenderAdvancedAnalytic
          key={selectedAdvancedAnalytic.id}
          advancedAnalytic={selectedAdvancedAnalytic}
        />
      )}
    </Stack>
  );
};

function RenderAdvancedAnalytic({
  advancedAnalytic,
}: {
  advancedAnalytic: AdvancedAnalytic;
}) {
  switch (advancedAnalytic.name) {
    case "Condenser":
      return <Condenser analytic={advancedAnalytic} />;
    case "Pressure_anomaly":
      return <PressureExcursionAnalytic analytic={advancedAnalytic} />;
    case "Refrigeration":
      return <Refrigeration analytic={advancedAnalytic} />;
    case "vacuum_pump":
      return <VacuumPump analytic={advancedAnalytic} />;
    default:
      return <div>Not implemented</div>;
  }
}
