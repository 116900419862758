import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import {
  IntellectaError,
  IntellectaResult,
  intellectaResult,
} from "../../types";
import { intellectaClient } from "../intellectaClient";

type Response = IntellectaError | IntellectaResult;

type Payload = {
  user: string;
  social_id: number;
};

export const usePinNotificationById = () => {
  const queryClient = useQueryClient();
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  return useMutation({
    mutationFn: async ({ social_id }: { social_id: number }) => {
      const response = await intellectaClient<Payload, Response>({
        url: "/social/pin_notification",
        payload: { user: email, social_id },
      });

      const validatedResponse = intellectaResult.parse(response);
      return validatedResponse;
    },
    onSuccess: (data) => {
      if (data === "OK") {
        //* per scaricare di nuovo lo storico delle notifiche
        queryClient.invalidateQueries({
          queryKey: ["intellecta-historic-notification-list", email],
        });
      }
    },
  });
};
