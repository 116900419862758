import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { LinearProgress, Stack } from "@mui/material";
import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { SentinelLoader } from "@/components/Layout/SentinelLoader";
import { useGetAssetChart } from "../../api/useGetAssetChart";
import { useTranslate } from "@/i18n/config";
import { renderToString } from "react-dom/server";

export const AssetChart = ({
  asset_id,
  name,
}: {
  asset_id: number;
  name: string;
}) => {
  const translate = useTranslate();
  const { isLoading, data, isFetching } = useGetAssetChart({ asset_id });

  if (isLoading)
    return (
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
          gap: 4,
          flex: 1,
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <SentinelLoader />
        <span style={{ display: "block" }}>
          {translate("user_feedback.loading")}
        </span>
      </Stack>
    );

  return (
    <>
      {data &&
        (data.chartType === "linechart" ? (
          <LineChart.DurationLine data={data.data} title={name} />
        ) : data.chartType === "barchart" ? (
          <>
            <BarChart.Custom
              uom={data.data.uom}
              categories={data.data.categories}
              title={name}
              tooltip={{
                enabled: true,
                options: {
                  formatter() {
                    return renderToString(
                      <div
                        style={{
                          width: "200px",
                          display: "flex",
                          flexDirection: "column",
                          gap: ".5rem",
                        }}
                      >
                        {this.points
                          ? this.points.map((point) => (
                              <div>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: "&bull;",
                                  }}
                                  style={{
                                    color: point.color?.toString(),
                                  }}
                                />
                                <b> {point.series.name}</b>:{" "}
                                <b>
                                  {point.y?.toFixed(2)} {data.data.uom}
                                </b>
                                <br />
                              </div>
                            ))
                          : null}
                      </div>,
                    );
                  },
                },
              }}
              series={[
                {
                  type: "column",
                  name: "Amount",
                  data: [
                    {
                      color: "#fa6400",
                      ...data.data.data[0],
                    },
                    {
                      color: "#ffe455",
                      ...data.data.data[1],
                    },
                  ],
                },
              ]}
            />
          </>
        ) : null)}
      {isFetching && (
        <LinearProgress
          sx={{
            position: "absolute",
            width: "90%",
            margin: "auto",
            bottom: 10,
            left: 0,
            right: 0,
            borderRadius: "1000px",
          }}
        />
      )}
    </>
  );
};
