import { Box, Stack, Typography } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import {
  ResSchema,
  useGetConditionData,
} from "../../api/Monitoring/useGetConditionData";
import { Card } from "@/components/Layout/Card";
import { ContextInformation } from "@/features/Motors/components/Layouts/ContextInformation";
import { BoxPlot } from "@/components/highcharts/boxplot/BoxPlot";
import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { ScatterPlot } from "@/components/highcharts/scatter/Scatter";
import { EVALUATION_COLORS } from "../../context/acopos-context";

const calculateYAxisMax = ({
  data,
  maxThreshold,
}: {
  data: number[][];
  maxThreshold: number;
}): number | undefined => {
  let highestValue = 0;
  data.forEach((entry) => {
    if (entry[1] > highestValue) {
      highestValue = entry[1];
    }
  });
  return maxThreshold > highestValue ? maxThreshold : undefined;
};

export const ConditionAnalytics = ({ analytics }: { analytics: number[] }) => {
  const { data, isLoading, error } = useGetConditionData({ analytics });
  const translate = useTranslate();

  if (isLoading)
    return <AnalyticContainerSkeleton width="calc(100% - 320px)" />;

  if (error)
    return (
      <Card sx={{ width: "calc(100% - 320px)" }}>
        {translate("user_feedback.an_error_occurred")}
      </Card>
    );

  if (!data)
    return (
      <Card sx={{ width: "calc(100% - 320px)" }}>
        {translate("user_feedback.no_data")}
      </Card>
    );

  return (
    <Stack
      sx={{
        gap: 1,
        width: "calc(100% - 320px)",
      }}
    >
      {data.data.length > 0 ? (
        data.data.map((analytic, index) => (
          <Analytic
            key={`${analytic.analyticName}-${index}`}
            props={analytic}
          />
        ))
      ) : (
        <Card sx={{ height: 400 }}>
          <Typography sx={{ color: "red" }} variant="h4">
            {translate("motors.no_selection")}
          </Typography>
          <Typography variant="h6">
            {translate("acopos.select_at_least_one_analytic")}
          </Typography>
        </Card>
      )}
    </Stack>
  );
};

const Analytic = ({ props }: { props: ResSchema }) => {
  const translate = useTranslate();
  const { analyticName, data, contextInfo, chartType, thresholds } = props;

  return (
    <Card>
      <Box>
        <ContextInformation props={contextInfo} key={analyticName} />
        {chartType === "box" && (
          <BoxPlot
            key={JSON.stringify(contextInfo)}
            title={analyticName}
            seriesName={translate("acopos.reference_current")}
            boxplotData={data.boxPlotData}
            categories={data.categories}
            scatterData={data.scatterData}
            trendData={data.trendData}
            yAxisTitle=""
          />
        )}
        {chartType === "lines" && (
          <LineChart.Custom
            filename={analyticName}
            key={JSON.stringify(contextInfo)}
            title={analyticName}
            yAxis={[
              {
                uom: "",
                series: [{ type: "line", name: analyticName, data }],
                thresholds:
                  analyticName === "RMS Current by Lap"
                    ? [
                        {
                          text: translate("threshold.warning_upper"),
                          color: EVALUATION_COLORS.warning.selected,
                          value: thresholds.warning_upper,
                        },
                        {
                          text: translate("threshold.critical_upper"),
                          color: EVALUATION_COLORS.critical.selected,
                          value: thresholds.critical_upper,
                        },
                      ]
                    : [],
                options: {
                  max: calculateYAxisMax({
                    maxThreshold: thresholds.critical_upper,
                    data,
                  }),
                },
              },
            ]}
          />
        )}
        {chartType === "scatter" && (
          <ScatterPlot.Custom
            key={JSON.stringify(contextInfo)}
            title={analyticName}
            yAxis={[
              {
                uom: "",
                series: [{ type: "scatter", name: analyticName, data }],
              },
            ]}
            xAxisType="linear"
            scrollbarEnabled={false}
            tooltip={{
              enabled: true,
              options: {
                formatter: function () {
                  return `<span style="color:${
                    this.color
                  }">●</span> ${analyticName}<br>
                  ${translate("position")}: <b>${this.x}</b><br>
                  ${translate("value")}: <b>${this.y}</b>`;
                },
              },
            }}
          />
        )}
      </Box>
    </Card>
  );
};
