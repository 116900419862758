import { useFirebaseContext } from "@/context/firebase-context";
import { UNAVAILABLE_TIME_SELECTIONS } from "@/features/OverallLineEfficiency/constants";
import { useLanguage } from "@/i18n/config";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { QueryBuilder } from "@/utils/query-builder";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

export const AlarmSchema = z.object({
  alarmInfo: z.string(),
  occurences: z.number(),
  durationInSeconds: z.number(),
  alarmDescription: z.string(),
});
export type AlarmSchema = z.infer<typeof AlarmSchema>;
export const TopAlarmsResult = z.object({
  response: z.literal(true),
  topAlarms: z.array(AlarmSchema),
});
export type TopAlarmsResult = z.infer<typeof TopAlarmsResult>;

type TopAlarmsResponse = ThingworxError | TopAlarmsResult;

type Payload =
  | {
      line_id: number;
      config: "manual";
      language_id: string;
    }
  | {
      line_id: number;
      config: "automatic";
      timeSelection: TimeSelection;
      language_id: string;
    };

export const useGetTopAlarms = ({
  line_id,
  config,
}: {
  line_id: number;
  config: "automatic" | "manual";
}) => {
  const { appKey } = useFirebaseContext();
  const { timeSelection } = useTimeSelection();
  const language = useLanguage();
  const allowedTimeSelection = UNAVAILABLE_TIME_SELECTIONS.includes(
    timeSelection,
  )
    ? "Shift"
    : timeSelection;
  const queryKey = QueryBuilder.buildWithCondition({
    baseQuery: ["line-top-alarms", line_id, config, language],
    condition: config === "automatic",
    query: [allowedTimeSelection],
  });

  const payload: Payload =
    config === "automatic"
      ? {
          config,
          timeSelection: allowedTimeSelection,
          line_id,
          language_id: language,
        }
      : {
          config,
          line_id,
          language_id: language,
        };

  return useQuery<TopAlarmsResult>({
    queryKey: queryKey,
    queryFn: async () => {
      const response = await FetchClient<Payload, TopAlarmsResponse>({
        appKey,
        url: "dig.c.plantOverview_thing/Services/getTopTenAlarms",
        payload,
      });

      if (!response.response) throw new Error(response.errorString);

      return TopAlarmsResult.parse(response);
    },
    refetchInterval: 1000 * 20,
  });
};
