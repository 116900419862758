import { Box } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { TypewriterText } from "./TypewriterText";

export const UnavailableSuggestion = () => {
  const translate = useTranslate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "auto",
        p: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.5rem",
          alignItems: "flex-start",
          mt: 1,
        }}
      >
        <TypewriterText
          text={translate("user_feedback.feature_unavailable_on_demand")}
          maxFontSize={14}
          minFontSize={14}
          iconSrc="/assets/img/intellecta-logo.png"
          iconAlt="Intellecta logo"
        />
      </Box>
    </Box>
  );
};
