import { z } from "zod";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export const service = z.union([
  z.literal("intellecta"),
  z.literal("efficiency navigator gen ai"),
  z.literal("stop imputation"),
]);

export type Service = z.infer<typeof service>;

export const userServicesStore = create(
  persist<{
    services: Service[];
    setServices: (services: Service[]) => void;
  }>(
    (set) => ({
      services: [] as Service[],
      setServices: (services: Service[]) => set({ services }),
    }),
    { name: "user-machine-services", getStorage: () => localStorage },
  ),
);
