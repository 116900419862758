import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { RangePicker } from "@/components/Calendars/RangePicker";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { ShowEmptyData } from "@/components/ShowEmptyData";
import { useGetMinMaxOeeRanges } from "@/features/Download/api/useGetMinMaxOeeRanges";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { CustomSelectTimeSelection } from "@/components/SelectTimeSelection/CustomSelectTimeSelection";
import { useGetDownloadData } from "./api/useGetDownloadData";
import { RangeOeeInput } from "./components/RangeOeeInput";
import { DownloadTable } from "./components/DownloadTable";

export const Download = () => {
  const translate = useTranslate();
  const [isEnabled, setIsEnabled] = useState(false);
  const { data, isLoading } = useGetDownloadData({ isEnabled });
  const { data: ranges } = useGetMinMaxOeeRanges();

  useEffect(() => {
    setIsEnabled(true);
  }, []);

  return (
    <>
      <MachineViewNavBar>
        <CustomSelectTimeSelection
          exclude={[
            "Batch",
            "TwoWeeks",
            "Month",
            "ThreeMonths",
            "SixMonths",
            "Year",
            "Custom",
          ]}
        />
        <RangePicker
          onPickDate={() => {}}
          initialDates={[DateTime.now().minus({ months: 1 }), DateTime.now()]}
        />
      </MachineViewNavBar>
      <Stack
        sx={{
          gap: 2,
          height: "100%",
        }}
      >
        {ranges ? (
          <RangeOeeInput min={ranges.lowerBound} max={ranges.upperBound} />
        ) : (
          <SkeletonCard />
        )}
        {isLoading ? (
          <SkeletonCard height={400} />
        ) : data?.processedDataTable.length &&
          data?.columnsDefinition.length ? (
          <DownloadTable
            dataTable={data.processedDataTable}
            columnsDef={data.columnsDefinition}
            maxBad={ranges?.lowerBound}
            maxMedium={ranges?.upperBound}
          />
        ) : (
          <ShowEmptyData title={translate("actions.download")} />
        )}
      </Stack>
    </>
  );
};
