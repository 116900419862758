import { ItemCard } from "@/components/ItemCard";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { Grid2, Stack, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { DateTime } from "luxon";
import { Period } from "..";
import { useCustomTimeRange } from "../store/useCustomTimeRange";
import {
  TimeSelectionsListData,
  useGetTimeSelectionList,
} from "../api/useGetTimeSelectionsList";
import { ColumnComparison } from "./ColumnComparison";
import { OccurrencesPareto } from "./OccurrencesPareto";
import { RejectCausesSunburst } from "./RejectCausesSunburst";
import { RejectsStatesComparison } from "./RejectStatesComparison";
import { useEffect, useState } from "react";
import { useTranslate } from "@/i18n/config";
import { useTimeSelection } from "@/store/useTimeSelection";
import { translateItemTitle } from "@/utils/itemCardTranslate";

interface RejectCausesAdvancedViewProps {
  selectedPeriod: Period | null;
  setSelectedPeriod: React.Dispatch<Period | null>;
}

export const RejectCausesAdvancedView = ({
  selectedPeriod,
  setSelectedPeriod,
}: RejectCausesAdvancedViewProps) => {
  const { formatTimestamp } = useFormatTimestamp();
  const translate = useTranslate();
  const [isEnabled, setIsEnabled] = useState(false);
  const { data: list } = useGetTimeSelectionList({ isEnabled });

  useEffect(() => {
    setSelectedPeriod(null);

    //? forzatura per filler
    useTimeSelection.setState((prevState) => ({
      ...prevState,
      timeSelection: "Month",
      dates: {
        dateStart: DateTime.fromMillis(1641025029000), // 1 gennaio 2022
        dateEnd: DateTime.fromMillis(1653985029000), // 31 maggio 2022
      },
    }));
    setIsEnabled(true);
  }, [setSelectedPeriod]);

  const updateDates = (timeSelectionInfo: TimeSelectionsListData) => {
    const dateStart = DateTime.fromSeconds(timeSelectionInfo.dateStart / 1000);
    const dateEnd = DateTime.fromSeconds(timeSelectionInfo.dateEnd / 1000);
    const timeSelectionName = timeSelectionInfo.timelapse;
    useCustomTimeRange.setState(() => ({
      dateStart,
      dateEnd,
      timeSelectionName,
    }));
    setSelectedPeriod({ start: dateStart, end: dateEnd });
  };

  return (
    <>
      {selectedPeriod ? (
        <Stack
          sx={{
            gap: 2,
            marginTop: 2,
          }}
        >
          <OccurrencesPareto />
          {/* <RejectsAlarmsComparison /> */}
          <RejectCausesSunburst />
          <ColumnComparison />
          <RejectsStatesComparison />
        </Stack>
      ) : null}
      {!selectedPeriod ? (
        <Stack spacing={2}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
            }}
          >
            {translate("please_select_a_period")}
          </Typography>
          <AnimatePresence presenceAffectsLayout mode="wait">
            <motion.div
              key={JSON.stringify(list)}
              variants={{
                hidden: { opacity: 0 },
                show: {
                  opacity: 1,
                },
              }}
              initial="hidden"
              animate="show"
            >
              <Grid2 container spacing={2}>
                {list?.map((item) => (
                  <Grid2
                    key={item.id}
                    size={{
                      xs: 6,
                      md: 3,
                    }}
                  >
                    <ItemCard
                      item={item}
                      onClick={(i) => updateDates(i)}
                      // onClick={(i) => setSelectedPeriod(i.id.toString())}
                      subtitle={`${translate(
                        "durations.from",
                      )}: ${formatTimestamp({
                        timestamp: item.dateStart,
                        fmt: "DD HH:mm:ss ",
                      })}`}
                      secondarySubtitle={`${translate(
                        "durations.to",
                      )}: ${formatTimestamp({
                        timestamp: item.dateEnd,
                        fmt: "DD HH:mm:ss ",
                      })}`}
                      title={translateItemTitle(item.timelapse, translate)}
                    />
                  </Grid2>
                ))}
              </Grid2>
            </motion.div>
          </AnimatePresence>
          {(list?.length == 0 || !list) && (
            <Typography>
              {translate(
                "user_feedback.no_options_available_user_different_time_selection",
              )}
            </Typography>
          )}
        </Stack>
      ) : null}
    </>
  );
};
