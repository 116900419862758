import { Grid2, Stack } from "@mui/material";
import { Card } from "@/components/Layout/Card";
import { formatNumber } from "@/utils/formatNumber";
import { efficiencyNavigatorColors } from "../../utils/efficiencyNavigatorColors";
import { EfficiencyPie } from "../EfficiencyPie";
import { LegendItem } from "../LegendItem";
import { useTranslate } from "@/i18n/config";
import { useGetLongTermData } from "../../api/useGetLongTermData";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";

export const PieSection = () => {
  const translate = useTranslate();
  const { data, error, isLoading } = useGetLongTermData();

  return (
    <Grid2
      size={{
        lg: 4,
        xs: 12,
      }}
      sx={{
        height: "30vh",
      }}
    >
      {isLoading && <SkeletonCard height="30vh" />}
      {error && (
        <Card
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            boxSizing: "border-box",
          }}
        >
          {translate("user_feedback.an_error_occurred")}
        </Card>
      )}
      {data && data.response && (
        <Card
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            boxSizing: "border-box",
          }}
        >
          <EfficiencyPie
            title={translate("kpi.oee")}
            data={data.oeeMachineStatus}
          />
          <Stack spacing={1}>
            <LegendItem
              description={`${translate("kpi.oee")} - ${formatNumber(
                data.oeeMachineStatus.oee,
              )}%`}
              color={efficiencyNavigatorColors.pie.oee}
            />
            <LegendItem
              description={`${translate(
                "losses.machine_losses",
              )} - ${formatNumber(data.oeeMachineStatus.machine)}%`}
              color={efficiencyNavigatorColors.category.machine}
            />
            <LegendItem
              description={`${translate(
                "losses.process_losses",
              )} - ${formatNumber(data.oeeMachineStatus.process)}%`}
              color={efficiencyNavigatorColors.category.process}
            />
            <LegendItem
              description={`${translate("losses.line_losses")} - ${formatNumber(
                data.oeeMachineStatus.line,
              )}%`}
              color={efficiencyNavigatorColors.category.line}
            />
          </Stack>
        </Card>
      )}
    </Grid2>
  );
};
