import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import { IntellectaError } from "../../types";
import { intellectaClient } from "../intellectaClient";

type Response = IntellectaError | Result;

type Payload = {
  user: string;
};

const result = z.object({
  users_list: z.array(z.string()),
});

type Result = z.infer<typeof result>;

export const useGetAvailableUsers = () => {
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  return useQuery<Result>({
    queryKey: ["intellecta-social-available-users", email],
    queryFn: async () => {
      const response = await intellectaClient<Payload, Response>({
        url: "/social/list_available_users",
        payload: { user: email },
      });

      if (response === "ERROR") throw new Error();

      const validatedResponse = result.parse(response);
      return validatedResponse;
    },
  });
};
