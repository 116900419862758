import { FullScreenDialog } from "@/components/FullScreenDialog";
import { Card } from "@/components/Layout/Card";
import { useDisclosure } from "@/hooks/useDisclosure";
import { QueryStats } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { DataGridPremium, GridActionsCellItem } from "@mui/x-data-grid-premium";
import { useState } from "react";
import { useGetRuleBreakages } from "../../api/useGetRuleBreakages";
import { BreakagesParser } from "../../utils/breakages-parser";
import { DistanceCoefficient } from "../DistanceCoefficient";
import { useTranslate } from "@/i18n/config";
import { colors } from "@/styles/colors";

export const BreakagesTable = () => {
  const translate = useTranslate();
  const { isOpen, open, close } = useDisclosure();

  const [phaseSelected, setPhaseSelected] = useState<{
    phaseId: number;
    phaseName: string;
  } | null>(null);

  const { data, isLoading } = useGetRuleBreakages();

  const { columns, rows } = data
    ? BreakagesParser.parse({
        columnsDefinitions: data.columnsDefinition,
        rowValues: data.data,
        extendColumns: [
          {
            field: "actions",
            type: "actions",
            headerName: "Detail",
            getActions: ({ row }) => {
              if (row.path?.length !== 1) return [];

              return [
                <GridActionsCellItem
                  icon={<QueryStats />}
                  label="Detail"
                  onClick={() => {
                    open();
                    if (row.phaseId)
                      setPhaseSelected({
                        phaseId: row.phaseId,
                        phaseName: row.phaseName,
                      });
                  }}
                />,
              ];
            },
          },
        ],
      })
    : { columns: [], rows: [] };

  return (
    <>
      <Card sx={{ height: "400px", display: "flex", flexDirection: "column" }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            marginBottom: 1,
          }}
        >
          {translate("diagnostics.lyo.rule_breakages")}
        </Typography>
        <DataGridPremium
          treeData
          sx={{
            flex: 1,
            "--DataGrid-containerBackground": colors.palette.darkBlue,
          }}
          loading={isLoading}
          getTreeDataPath={(row) => row.path}
          columns={columns}
          rows={rows}
          groupingColDef={{
            headerName: "Name",
          }}
        />
      </Card>
      <FullScreenDialog
        open={isOpen}
        handleClose={() => {
          setPhaseSelected(null);
          close();
        }}
        title={phaseSelected?.phaseName || ""}
      >
        {phaseSelected ? (
          <DistanceCoefficient
            phase_id={phaseSelected.phaseId}
            phase_name={phaseSelected.phaseName}
          />
        ) : null}
      </FullScreenDialog>
    </>
  );
};
