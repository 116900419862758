export const colors = {
  lyo: {
    status: {
      good: "#14FF00",
      bad: "#f28518",
      not_analyzable: "#ff0",
      not_analyzed: "#333",
    },
  },
  physicalMeasures: {
    setpoint: "#5181ef",
    actual: "#5bae0c",
  },
  kpi: {
    oee: "#56BC72",
    availability: "#005D6E",
    uptime: "#008190",
    performance: "#007E77",
    quality: "#2F974B",
    elapsedTime: "#73BB6E",
    inactiveTime: "#666961",
    processOee: "#4df79a",
    processQuality: "#02a39b",
    downtime: "#8D2525",
    idleTime: "#666961",
    scheduledTime: "#A52A5A",
    notScheduledTime: "#A52A2A",
  },
  palette: {
    darkBlue: "#0D1626",
    lightBlue: "#1C2539",
  },
  categories: {
    producing: "#0AB126",
    notProducing: "#FF7A0D",
    line: "#1A98C9",
    setupPreProd: "#B277E2",
    maintenance: "#8A8A8A",
    disconnected: "#EEEEEE",
  },
  states: {
    lackOfMaterial: "#EE8527",
    missingDownstream: "#1FA79F",
    missingUpstream: "#1B7284",
    production: "#56BC72",
    warmup: "#C63938",
    changeOver: "#BE5107",
    disconnectedMachine: "#666961",
    error: "#960113",
    intentionalNoProduction: "#909A7C",
    jog: "#FFD083",
    stop: "#FFA800",
  },
} as const;
