import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { AcceptanceTestDataResult } from "../useGetAcceptanceTestData";
import { OeeStream } from "../useGetOeeStream";
import { useLanguage } from "@/i18n/config";

const HistoricAcceptanceTestResult = z.object({
  data: AcceptanceTestDataResult,
  oeeStream: z.array(OeeStream),
  response: z.literal(true),
});

type HistoricAcceptanceTestResult = z.infer<
  typeof HistoricAcceptanceTestResult
>;

type HistoricAcceptanceTestResponse =
  | ThingworxError
  | HistoricAcceptanceTestResult;

export const useGetHistoricAcceptanceTestData = ({ id }: { id: string }) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const language = useLanguage();

  return useQuery<HistoricAcceptanceTestResult>({
    queryKey: ["historic-acceptance-test-data", machine?.machine, id, language],
    queryFn: async () => {
      const response = await FetchClient<
        { id: string; machineName: string; language_id: string },
        HistoricAcceptanceTestResponse
      >({
        appKey,
        payload: {
          id: id,
          machineName: machine!.machine,
          language_id: language,
        },
        url: "dig.c.acceptanceTest_thing/Services/getHistoric",
      });
      if (!response.response) throw new Error(response.errorString);
      return HistoricAcceptanceTestResult.parse(response);
    },
  });
};
