import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CardContent,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { Card } from "@/components/Layout/Card";
import { useChangePassword } from "../../../lambda/useRenewAppKey";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { toast } from "react-hot-toast";
import { Modal } from "@/components/Modal";
import { useDisclosure } from "@/hooks/useDisclosure";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
} from "firebase/auth";
import { useTranslate } from "@/i18n/config";

const ChangePasswordSchema = z.object({
  password: z.string().min(6, "La password deve contenere almeno 6 caratteri"),
  confirmPassword: z
    .string()
    .min(6, "La password deve contenere almeno 6 caratteri")
    .regex(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/,
      {
        message:
          "The password must contain at least one uppercase letter, one lowercase letter, one number and one special character",
      },
    ),
});

type ChangePasswordSchema = z.infer<typeof ChangePasswordSchema>;

export const SettingsPassword = () => {
  const translate = useTranslate();
  const { isOpen, open, close } = useDisclosure();
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<ChangePasswordSchema>({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const firstPassword = watch("password");

  const {
    mutate: changePassword,
    isPending,
    sessionExpired,
  } = useChangePassword();
  const [showPassword, setShowPassword] = useState(false);

  const switchShowPasswordHandler = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (sessionExpired) {
      open();
    } else {
      close();
    }
  }, [sessionExpired]);

  return (<>
    <form
      onSubmit={handleSubmit((data) => {
        changePassword({
          newPassword: data.password,
        });
      })}
    >
      <Card>
        <CardContent>
          <Controller
            control={control}
            name="password"
            rules={{
              required: translate("settings.enter_password"),
              minLength: {
                value: 6,
                message: translate("settings.password_min_length_error", {
                  amount: 6,
                }),
              },
              pattern: {
                value:
                  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/,
                message: translate(
                  "settings.password_must_contain_special_characters",
                ),
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size="small"
                error={errors.password ? true : false}
                helperText={
                  errors.password &&
                  errors.password.message &&
                  errors.password.message
                }
                label={translate("new_password")}
                margin="normal"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={switchShowPasswordHandler}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="confirmPassword"
            rules={{
              required: translate("settings.confirm_password"),
              validate: {
                matchPreviousPassword: (value) => {
                  return (
                    value === firstPassword ||
                    translate("settings.passwords_do_not_match")
                  );
                },
              },
              minLength: {
                value: 6,
                message: translate("settings.password_min_length_error", {
                  amount: 6,
                }),
              },
              pattern: {
                value:
                  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/,
                message: translate(
                  "settings.password_must_contain_special_characters",
                ),
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size="small"
                error={errors.confirmPassword ? true : false}
                helperText={
                  errors.confirmPassword &&
                  errors.confirmPassword.message &&
                  errors.confirmPassword.message
                }
                label={translate("settings.confirm_password")}
                margin="normal"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={switchShowPasswordHandler}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />
            )}
          />
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
            <Button
              disabled={isPending}
              type="submit"
              color="primary"
              variant="contained"
            >
              {translate("actions.update")}
            </Button>
          </Box>
        </Card>
      </form>
      <Modal
        titleContent={translate("user_feedback.session_expired")}
        maxWidth="xl"
        onClose={() => close()}
        open={isOpen}
        bodyContent={<ReauthUser dismissModal={() => close()} />}
      ></Modal>
    </>
  );
};

const ReauthUser = ({ dismissModal }: { dismissModal: () => void }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [loggingUser, setLoggingUser] = useState(false);
  const translate = useTranslate();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<{ email: string; password: string }>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const switchShowPasswordHandler = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <form
      onSubmit={handleSubmit((data) => {
        const user = getAuth().currentUser;
        if (!user) return;

        const credentials = EmailAuthProvider.credential(
          data.email,
          data.password,
        );

        setLoggingUser(true);

        reauthenticateWithCredential(user, credentials)
          .then(() => {
            dismissModal();
            toast.success(translate("settings.successfully_reauthenticated"));
          })
          .catch((error) => {
            toast.error(
              translate(
                error.code === "auth/wrong-password"
                  ? "user_feedback.invalid_password"
                  : "user_feedback.failed_to_login",
              ),
            );
          })
          .finally(() => {
            setLoggingUser(false);
          });
      })}
    >
      <Controller
        control={control}
        name="email"
        rules={{
          required: translate("settings.enter_email"),
          validate: {
            isEmail: (value) => {
              return (
                z.string().email().safeParse(value).success || "Invalid email"
              );
            },
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            size="small"
            error={errors.email ? true : false}
            helperText={
              errors.email && errors.email.message && errors.email.message
            }
            label={translate("email")}
            margin="normal"
            variant="outlined"
          />
        )}
      />
      <Controller
        control={control}
        name="password"
        rules={{
          required: translate("settings.enter_password"),
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            size="small"
            error={errors.password ? true : false}
            helperText={
              errors.password &&
              errors.password.message &&
              errors.password.message
            }
            label={translate("password")}
            margin="normal"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={switchShowPasswordHandler}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        )}
      />
      <Stack
        direction="row"
        sx={{
          gap: 2,
          justifyContent: "flex-end",
        }}
      >
        <Button color="error" variant="contained">
          {translate("actions.cancel")}
        </Button>

        <Button
          disabled={loggingUser}
          type="submit"
          color="success"
          variant="contained"
        >
          {translate("authenticate")}
        </Button>
      </Stack>
    </form>
  );
};
