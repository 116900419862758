import { Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { NavButton } from "@/components/NavButton";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { CustomSelectTimeSelection } from "@/components/SelectTimeSelection/CustomSelectTimeSelection";
import { PageContent } from "./components/PageContent";

export const StopJustificationLiveView = () => {
  const translate = useTranslate();

  return (
    <>
      <MachineViewNavBar>
        <Stack
          direction={{ sm: "row", xs: "column" }}
          sx={{
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <CustomSelectTimeSelection
            exclude={[
              "TwoWeeks",
              "Month",
              "ThreeMonths",
              "SixMonths",
              "Year",
              "Custom",
            ]}
          />
          <NavButton
            to="/stop-justification/historic"
            text={translate("view_historic")}
          />
        </Stack>
      </MachineViewNavBar>
      <PageContent />
    </>
  );
};
