import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { TimeSelection, useTimeSelection } from "@/store/useTimeSelection";
import { ThingworxError } from "src/types/error";
import { aiSuggest, loss, lossesData, oeeMachineStatus } from "../../types";

export type EfficiencyHistoricResponse =
  | ThingworxError
  | EfficiencyHistoricResult;

export const efficiencyHistoricResult = z.object({
  response: z.literal(true),
  shortTermData: z.object({
    machineLosses: lossesData,
    lineLosses: lossesData,
    processLosses: lossesData,
  }),
  longTermData: z.object({
    aiSuggest: z.array(aiSuggest),
    oeeMachineStatus: oeeMachineStatus,
    lineLosses: z.array(loss),
    machineLosses: z.array(loss),
    processLosses: z.array(loss),
  }),
});

type EfficiencyHistoricResult = z.infer<typeof efficiencyHistoricResult>;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  id: string;
};

export const useGetHistoricData = ({ id }: { id: string }) => {
  const { machine } = useMachineContext();
  const { timeSelection } = useTimeSelection();
  const { appKey } = useFirebaseContext();

  return useQuery<EfficiencyHistoricResult>({
    queryKey: [
      "efficiency-navigator-historic",
      machine!.machine,
      timeSelection,
      id,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, EfficiencyHistoricResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
          id,
          timeSelection,
        },
        url: "dig.c.historicEfficiencyNavigator_thing/Services/getData",
      });
      if (!response.response) {
        throw new Error(response.errorString);
      }
      const validatedResponse = efficiencyHistoricResult.parse(response);
      return validatedResponse;
    },
    refetchInterval: 1000 * 30,
  });
};
