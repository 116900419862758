/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box } from "@mui/material";
import { AssetLayout } from "./AssetLayout";
import { Status } from "../../api/useGetHistoricData";
import { colors } from "@/styles/colors";

const assetVariants = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: {
    y: 10,
    opacity: 0,
  },
};

export const Asset = ({
  status,
  active,
  name,
  id,
  onClick,
}: {
  name: string;
  id: number;
  onClick: (asset_id: number) => void;
  active: boolean;
  status: Status;
}) => {
  return (
    <AssetLayout
      key={id}
      variants={assetVariants}
      sx={{ backgroundColor: active ? "#3E4657" : "#1C2539" }}
      onClick={() => {
        onClick(id);
      }}
    >
      <Box>{name}</Box>
      <Box
        sx={{
          backgroundColor:
            status === "Good"
              ? colors.lyo.status.good
              : status === "Bad"
              ? colors.lyo.status.bad
              : colors.lyo.status.not_analyzed,
          aspectRatio: "1/1",
          width: ".75rem",
          height: ".75rem",
          borderRadius: "10000px",
        }}
      />
    </AssetLayout>
  );
};
