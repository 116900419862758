import { Box, Typography } from "@mui/material";
import { TypewriterText } from "./TypewriterText";
import { LossType } from "../types";
import { SuggestionsData, useGetSuggestions } from "../api/useGetSuggestions";
import { useTranslate } from "@/i18n/config";
import { ShortTermSuggestionLoader } from "./ShortTermSuggestionLoader";
import { useFormatTimestamp } from "@/utils/TimeHelpers";

const getSuggestion = ({
  data,
  lossType,
}: {
  data: SuggestionsData;
  lossType: LossType;
}): string => {
  const suggestion =
    lossType === "machine"
      ? data.machine_losses
      : lossType === "process"
      ? data.process_losses
      : data.line_losses;
  const isCritical = data.critical_category === lossType;
  return isCritical
    ? suggestion.concat(" " + data.critical_message)
    : suggestion;
};

export const ShortTermSuggestion = ({ lossType }: { lossType: LossType }) => {
  const translate = useTranslate();
  const { formatTimestamp } = useFormatTimestamp();
  const { error, isLoading, data } = useGetSuggestions();

  if (isLoading) return <ShortTermSuggestionLoader />;

  let timestampText = " "; // un semplice spazio, oppure potresti mettere "..." con visibility: "hidden"
  let message = "";

  if (error) {
    message = translate("user_feedback.an_error_occurred");
  } else if (!data) {
    message = "No suggestion available";
  } else {
    // Abbiamo dati
    timestampText = formatTimestamp({
      timestamp: data.suggestions.generation_timestamp,
    });
    message = getSuggestion({ data: data.suggestions, lossType });
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "auto",
        p: 1,
      }}
    >
      {/* Timestamp di generazione del messaggio */}
      {data && (
        <Typography
          variant="caption"
          sx={{
            display: "inline-flex",
            borderRadius: 2,
            px: ".5rem",
            py: "0.25rem",
            gap: 1,
            alignItems: "center",
            color: "#ccc",
          }}
        >
          {timestampText}
        </Typography>
      )}
      {/* Testo con effetto macchina da scrivere */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.5rem",
          alignItems: "flex-start",
          mt: 1,
        }}
      >
        <TypewriterText
          text={message}
          maxFontSize={14}
          minFontSize={14}
          iconSrc="/assets/img/intellecta-logo.png"
          iconAlt="Intellecta logo"
        />
      </Box>
    </Box>
  );
};
