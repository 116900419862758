import { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { Edit, Save, Cancel } from "@mui/icons-material";
import { useUpdateSubCategory } from "../../api/useUpdateSubCategory";

export const ImputationSubCategoryCell = ({
  id,
  value,
  isEditable,
}: {
  id: number;
  value: string;
  isEditable: boolean;
}) => {
  const { mutate: updateSubCategory } = useUpdateSubCategory();
  const [view, setView] = useState<"read" | "edit">("read");
  const [updatedValue, setUpdatedValue] = useState(value);
  const handleSave = () => {
    updateSubCategory(
      { subCategoryId: id, subCategoryValue: updatedValue },
      {
        onSuccess(data) {
          if (data.response) setView("read");
        },
      },
    );
  };

  const handleCancel = () => {
    setView("read");
    setUpdatedValue(value);
  };

  return (
    <>
      {isEditable && view === "read" && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography>{updatedValue}</Typography>
          <Edit sx={{ cursor: "pointer" }} onClick={() => setView("edit")} />
        </Box>
      )}
      {isEditable && view === "edit" && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <TextField
            size="small"
            variant="standard"
            value={updatedValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUpdatedValue(event.target.value);
            }}
          />
          <Save sx={{ cursor: "pointer" }} onClick={handleSave} />
          <Cancel sx={{ cursor: "pointer" }} onClick={handleCancel} />
        </Box>
      )}
      {!isEditable && <Typography>{value}</Typography>}
    </>
  );
};
