/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Card } from "@/components/Layout/Card";
import {
  ScaleDeviationData,
  useGetScaleDeviationData,
} from "../api/useGetScaleDeviationData";
import { AnalyticContainerSkeleton } from "../../LYO/components/AnalyticContainer/AnalyticContainerLayout";
import {
  Box,
  Tab,
  Tabs,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { colors } from "@/styles/colors";
import { LineAndAreaRange } from "@/components/highcharts/linechart/LineAndAreaRange";
import { useTranslate } from "@/i18n/config";

const VIEWS = {
  net: 0,
  tare: 1,
};

const defaultDataset = {
  tareData: {
    meanData: [],
    sigma1Data: [],
    sigma2Data: [],
    sigma3Data: [],
    scatterData: [],
  },
  netData: {
    meanData: [],
    sigma1Data: [],
    sigma2Data: [],
    sigma3Data: [],
    scatterData: [],
  },
  name: "",
};

export const ScaleStandardDeviation = () => {
  const [view, setView] = useState(VIEWS.net);
  const { data, isLoading, error } = useGetScaleDeviationData();
  const [scale, setScale] = useState("");
  const [scaleDataset, setScaleDataset] =
    useState<ScaleDeviationData>(defaultDataset);
  const translate = useTranslate();

  useEffect(() => {
    data && data.sort((a, b) => a.name.localeCompare(b.name));
    setScale(data ? (data.length > 0 ? data[0].name || "" : "") : "");
    setScaleDataset(
      data
        ? data.length > 0
          ? data[0] || defaultDataset
          : defaultDataset
        : defaultDataset,
    );
  }, [data]);

  const handleScaleClick = (_: unknown, selectedScale: string) => {
    setScale(selectedScale);
    const selectedScaleDataset = data?.find(
      (dataset) => dataset.name === selectedScale,
    );
    setScaleDataset(selectedScaleDataset || defaultDataset);
  };

  if (isLoading) {
    return <AnalyticContainerSkeleton />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <List
        subheader={
          <ListSubheader>{translate("filler.scale_other")}:</ListSubheader>
        }
        sx={{
          mr: 1,
          width: "21vw",
          overflowY: "auto",
          backgroundColor: colors.palette.darkBlue,
          color: "#fff",
          backgroundImage: "none",
          padding: "1rem",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: ".25rem",
        }}
      >
        {data.map((balance) => (
          <ListItemButton
            key={balance.name}
            selected={scale === balance.name}
            onClick={() => handleScaleClick(null, balance.name)}
          >
            <ListItemText primary={balance.name} />
          </ListItemButton>
        ))}
      </List>
      <Box
        sx={{
          width: "79vw",
        }}
      >
        <Card>
          <Tabs
            value={view}
            aria-label="net and tare weight tabs for scale"
            onChange={(_: React.SyntheticEvent, newValue: number) => {
              setView(newValue);
            }}
          >
            <Tab value={VIEWS.net} label={translate("filler.net")} />
            <Tab value={VIEWS.tare} label={translate("filler.tare")} />
          </Tabs>
        </Card>
        <Card>
          <LineAndAreaRange
            key={`${scale}_${view}`}
            title={translate("filler.scale_mean_and_sigma")}
            customCredits={translate("filler.data_calculated")}
            yAxis={[
              {
                uom: "g",
                options: {
                  plotLines:
                    view === VIEWS.net
                      ? [
                          {
                            value: scaleDataset.netData.benchmark?.minErr,
                            color: "red",
                            dashStyle: "LongDash",
                            label: { text: "T2" },
                          },
                          {
                            value: scaleDataset.netData.benchmark?.maxErr,
                            color: "red",
                            dashStyle: "LongDash",
                            label: { text: "T2" },
                          },
                          {
                            value: scaleDataset.netData.benchmark?.minWarn,
                            color: "orange",
                            dashStyle: "LongDash",
                            label: { text: "T1" },
                          },
                          {
                            value: scaleDataset.netData.benchmark?.maxWarn,
                            color: "orange",
                            dashStyle: "LongDash",
                            label: { text: "T1" },
                          },
                        ]
                      : [],
                },
                series:
                  view === VIEWS.net
                    ? [
                        {
                          type: "line",
                          data: scaleDataset.netData.meanData,
                          color: "#18e4f2",
                          name: translate("math.mean"),
                        },
                        {
                          type: "arearange",
                          data: scaleDataset.netData.sigma1Data,
                          color: "#18e4f2",
                          opacity: 0.4,
                          name: "Sigma 1",
                        },
                        {
                          type: "arearange",
                          data: scaleDataset.netData.sigma2Data,
                          color: "#18e4f2",
                          opacity: 0.3,
                          name: "Sigma 2",
                        },
                        {
                          type: "arearange",
                          data: scaleDataset.netData.sigma3Data,
                          color: "#18e4f2",
                          opacity: 0.2,
                          name: "Sigma 3",
                        },
                        {
                          type: "scatter",
                          data: scaleDataset.netData.scatterData,
                          color: "orange",
                          name: translate("reject_other"),
                          marker: {
                            enabled: true,
                            symbol: "cirle",
                            radius: 2,
                          },
                        },
                      ]
                    : [
                        {
                          type: "line",
                          data: scaleDataset.tareData.meanData,
                          color: "#18e4f2",
                          name: translate("math.mean"),
                        },
                        {
                          type: "arearange",
                          data: scaleDataset.tareData.sigma1Data,
                          color: "#18e4f2",
                          opacity: 0.4,
                          name: "Sigma 1",
                        },
                        {
                          type: "arearange",
                          data: scaleDataset.tareData.sigma2Data,
                          color: "#18e4f2",
                          opacity: 0.3,
                          name: "Sigma 2",
                        },
                        {
                          type: "arearange",
                          data: scaleDataset.tareData.sigma3Data,
                          color: "#18e4f2",
                          opacity: 0.2,
                          name: "Sigma 3",
                        },
                        {
                          type: "scatter",
                          data: scaleDataset.tareData.scatterData,
                          color: "orange",
                          name: translate("reject_other"),
                          marker: {
                            enabled: true,
                            symbol: "cirle",
                            radius: 2,
                          },
                        },
                      ],
                // options: { crosshair: true },
              },
            ]}
            // xAxisOptions={{ crosshair: true }}
            legend={{
              enabled: true,
              options: { itemHoverStyle: { color: "lightblue" } },
            }}
            xAxisType="datetime"
            tooltip={{
              enabled: true,
              options: {
                formatter() {
                  const info = this as unknown as any;
                  if (info.points) {
                    const date = new Date(info.points[0].x).toUTCString();
                    const mean = info.points[0].y.toFixed(2);
                    const low1 = info.points[1]
                      ? info.points[1].point.low.toFixed(2)
                      : "";
                    const high1 = info.points[1]
                      ? info.points[1].point.high.toFixed(2)
                      : "";
                    const low2 = info.points[2]
                      ? info.points[2].point.low.toFixed(2)
                      : "";
                    const high2 = info.points[2]
                      ? info.points[2].point.high.toFixed(2)
                      : "";
                    const low3 = info.points[3]
                      ? info.points[3].point.low.toFixed(2)
                      : "";
                    const high3 = info.points[3]
                      ? info.points[3].point.high.toFixed(2)
                      : "";
                    const color = info.points[0].color;
                    return `
                      ${date}<br>
                      <span style='color: ${color}'>● </span>${translate(
                        "math.mean",
                      )}: <b>${mean}</b><br>
                      <span style='color: ${color}'>● </span>Sigma 1: <b>${low1}</b> - <b>${high1}</b><br>
                      <span style='color: ${color}'>● </span>Sigma 2: <b>${low2}</b> - <b>${high2}</b><br>
                      <span style='color: ${color}'>● </span>Sigma 3: <b>${low3}</b> - <b>${high3}</b>
                    `;
                  } else {
                    const date = new Date(info.x).toUTCString();
                    const modality = info.point.modality;
                    return `
                      ${date}<br>
                      <span style='color: ${info.color}'>● </span>${translate(
                        "reject_other",
                      )}<br>
                      ${translate("weight")}: <b>${info.y} g</b><br>
                      ${translate("filler.modality")}: <b>${modality}</b>
                    `;
                  }
                },
              },
            }}
          />
        </Card>
      </Box>
    </Box>
  );
};
