/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Stack } from "@mui/material";
import { LineStateCategory } from "./LineStateCategory";
import { useTranslate } from "@/i18n/config";

export const LineStateCategories = ({
  total,
  idleAmount,
  producingAmount,
  notProducingAmount,
  downtimeAmount,
  activeState,
  notConfiguredAmount,
  onStateSelected,
}: {
  total: number;
  idleAmount: number;
  producingAmount: number;
  notProducingAmount: number;
  downtimeAmount: number;
  notConfiguredAmount: number;
  activeState: string | null;
  onStateSelected: (state: string) => void;
}) => {
  const translate = useTranslate();
  return (
    <>
      <Stack
        direction="row"
        sx={{
          gap: 2,
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        <LineStateCategory
          activeState={activeState}
          total={total}
          stateAmount={producingAmount}
          stateString={translate("category.1")}
          stateColor="#00FF00"
          onStateSelected={() => onStateSelected("Producing")}
        />
        <LineStateCategory
          activeState={activeState}
          total={total}
          stateAmount={notProducingAmount}
          stateString={translate("category.2")}
          stateColor="#ff7a0d"
          onStateSelected={() => onStateSelected("Not Producing")}
        />
        <LineStateCategory
          activeState={activeState}
          total={total}
          stateAmount={downtimeAmount}
          stateString={translate("line.category_scheduled_downtime")}
          stateColor="#8a8a8a"
          onStateSelected={() => onStateSelected("Scheduled Downtime")}
        />
        <LineStateCategory
          activeState={activeState}
          total={total}
          stateAmount={idleAmount}
          stateString={translate("line.category_idle")}
          stateColor="#eeeeee"
          onStateSelected={() => onStateSelected("Idle")}
        />
        <LineStateCategory
          activeState={activeState}
          total={total}
          stateAmount={notConfiguredAmount}
          stateString={translate("line.category_not_configured")}
          stateColor="#800080"
          onStateSelected={() => onStateSelected("Not Configured")}
        />
      </Stack>
    </>
  );
};
