import { Card } from "@/components/Layout/Card";
import { Solidgauge } from "@/components/highcharts/gauge/SolidGauge";
import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { Box, Grid2, Slider, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { create } from "zustand";

const accuracyData = [84, 91, 92, 89, 92, 93, 87, 94];

const accuracyDataStore = create<{
  baseData: Array<number[]>;
  runtimeData: Array<number[]>;
  addNewPoint: () => void;
}>((set) => ({
  baseData: generateData(accuracyData),
  runtimeData: [],
  addNewPoint: () => {
    set((state) => {
      const time = new Date().getTime();
      const data = [time, +(Math.random() * (98 - 96) + 96).toFixed(2)];

      return { runtimeData: [...state.runtimeData, data] };
    });
  },
}));

function generateData(_data: number[]) {
  const data = [];
  const time = new Date().getTime();

  for (let i = -_data.length; i < 0; i++) {
    data.push([time + i * 1000, _data[_data.length + i]]);
  }
  return data;
}

export const ForecastAccuracyChart = () => {
  const { baseData, runtimeData, addNewPoint } = accuracyDataStore();

  const [attentionThreshold, setAttentionThreshold] = useState<number>(90);
  const [criticalThreshold, setCriticalThreshold] = useState<number>(85);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      addNewPoint();
    }, 1000);

    return () => clearInterval(refreshInterval);
  }, []);

  const latestValue = runtimeData.at(-1) ? runtimeData.at(-1)![1] : 0;

  return (
    <Card>
      <Grid2 container>
        <Grid2 size={10}>
          <Box
            sx={{
              flex: 1,
            }}
          >
            <LineChart.Custom
              title="Quantization Error"
              xAxisOptions={{
                scrollbar: {
                  enabled: false,
                },
              }}
              exportingEnabled={false}
              yAxis={[
                {
                  options: {
                    plotLines: [
                      {
                        value: attentionThreshold,
                        color: "green",
                        dashStyle: "ShortDash",
                      },
                      {
                        value: criticalThreshold,
                        color: "red",
                        dashStyle: "ShortDash",
                      },
                    ],
                    min: 0,
                    max: 100,
                  },
                  uom: "",
                  series: [
                    {
                      type: "line",
                      name: "Train Data",
                      data: baseData,
                      color: "#0066ff",
                    },
                    {
                      type: "line",
                      name: "Runtime Data",
                      data: runtimeData,
                      color: "#ff6600",
                    },
                  ],
                },
              ]}
            />

            <Box
              sx={{
                width: 800,
              }}
            >
              <Stack direction="row" spacing={2}>
                <Typography sx={{ minWidth: "20ch" }}>
                  Attention threshold
                </Typography>
                <Slider
                  size="small"
                  sx={{ width: 300 }}
                  min={1}
                  max={100}
                  step={1}
                  value={attentionThreshold}
                  onChange={(event) => {
                    const { target } = event;

                    const val = (target as HTMLInputElement).value;

                    if (val === "") return;

                    const value = Number(val);
                    setAttentionThreshold(value);
                  }}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                />
                <Typography>{attentionThreshold}</Typography>
              </Stack>

              <Stack direction="row" spacing={2}>
                <Typography sx={{ minWidth: "20ch" }}>
                  Critical threshold
                </Typography>
                <Slider
                  size="small"
                  min={1}
                  max={100}
                  step={1}
                  value={criticalThreshold}
                  sx={{ width: 300 }}
                  onChange={(event) => {
                    const { target } = event;

                    const val = (target as HTMLInputElement).value;

                    if (val === "") return;

                    const value = Number(val);
                    setCriticalThreshold(value);
                  }}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                />
                <Typography>{criticalThreshold}</Typography>
              </Stack>
            </Box>
          </Box>
        </Grid2>
        <Grid2 size={2}>
          <Stack
            sx={{
              justifyItems: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography>Health state</Typography>
            <Gauge
              latestValue={latestValue}
              criticalThreshold={criticalThreshold}
            />
          </Stack>
        </Grid2>
      </Grid2>
    </Card>
  );
};

function Gauge({
  latestValue,
}: {
  latestValue: number;
  criticalThreshold: number;
}) {
  return (
    <Box
      sx={{
        width: 150,
        position: "relative",
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {latestValue.toFixed(2)}%
      </Typography>
      <Solidgauge.Custom
        title=""
        plotOptions={{
          solidgauge: {
            innerRadius: "80%",
            dataLabels: {
              y: 5,
              borderWidth: 0,
              useHTML: true,
              enabled: false,
            },
          },
        }}
        legend={{
          enabled: false,
        }}
        tooltip={{
          enabled: false,
        }}
        yAxis={{
          options: {
            min: 0,
            max: 100,
            stops: [
              [0.1, "#DF5353"], // red
              [0.5, "#DDDF0D"], // yellow
              [0.9, "#55BF3B"], // green
            ],
            lineWidth: 0,
            minorTickInterval: 0,
            tickAmount: 0,
            labels: {
              enabled: false,
            },
          },
          series: [
            {
              type: "solidgauge",
              data: [latestValue],
              rounded: true,
            },
          ],
        }}
      />
    </Box>
  );
}
