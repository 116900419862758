import { Card } from "@/components/Layout/Card";
import { Solidgauge } from "@/components/highcharts/gauge/SolidGauge";
import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { Box, Grid2, Slider, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { create } from "zustand";

const baseQuantizationData = [
  24.99992612, 19.35582824, 19.67546311, 18.50168446, 18.12232537, 18.55046955,
  18.56793946, 19.52416716, 20.24273493, 18.2378728, 21.08986582, 18.06941627,
  21.5077846, 17.94953876, 19.83345908, 18.17327388, 17.97576026, 18.73427749,
  18.40535498, 18.07022924, 18.61585574, 18.8117366, 19.06694411, 18.70156012,
  19.60175595, 20.06803111, 18.98302406, 18.87154901, 18.74625671, 19.36666442,
  18.41500393, 18.4694593, 20.96175866, 18.5668386, 19.13176057, 19.19916615,
  18.3482011, 20.54642701, 17.92736226, 19.85887306, 18.66934611, 18.39650757,
  18.73067595, 17.60817642, 18.88117981, 21.85169237, 18.52408605, 17.75064576,
  18.92618085, 18.73596894, 18.47427308, 20.11608252, 17.51024379, 17.76391011,
  18.5053766, 17.99697791, 17.61158573, 18.211655, 18.31692705, 17.39485944,
  18.31264868, 17.30159163, 17.4529384, 17.4221916, 16.89454122, 17.78670622,
  18.15105525, 17.26380853, 16.80874676, 16.82871335, 16.68165214, 16.60128317,
  16.68949567, 17.44021902, 16.01003272, 16.6153645, 16.08277005, 15.86744048,
  16.19012021, 15.89926772, 15.65728874, 15.51983502, 15.45915039, 15.34440018,
  14.97295845, 14.82508474, 15.03517046, 14.70432381, 14.78863622, 14.43447427,
  14.47745976, 14.18760359, 14.14687391, 13.74961912, 13.62700792, 13.26619761,
  13.0417662, 12.95678535, 12.42683859, 12.3530569, 12.05291743, 11.62174228,
  11.50251833, 11.23928291, 10.73398116, 10.40787453, 10.02477814, 9.831622817,
  9.623363466, 9.498998042, 9.128426244, 8.83671606, 8.459713097, 8.270522116,
  8.016878583, 7.734014965, 7.594069281, 7.238023635, 7.014711005, 6.792942281,
  6.514177834, 6.366040148, 6.135551055, 5.915395056, 5.756543567, 5.599386985,
  5.422783049, 5.259419359, 5.094569253, 4.965288178, 4.816095667, 4.733084611,
  4.657076942, 4.563874269, 4.478924817, 4.403392878, 4.315997067, 4.252207684,
  4.160208811, 4.100268121, 4.036603594, 3.971298597, 3.901204302, 3.84615909,
  3.783200732, 3.733675084, 3.695712313, 3.64910579, 3.604874208, 3.564974402,
  3.53185319, 3.49070476, 3.453887603, 3.420877943, 3.384736318, 3.358924106,
  3.325011413, 3.302761442, 3.27490226, 3.253283905, 3.238690962, 3.220198936,
  3.203436553, 3.181949536, 3.16589845, 3.152775655, 3.141314372,
];

const quantizationStore = create<{
  baseData: Array<number[]>;
  runtimeData: Array<number[]>;
  addNewQuantizationPoint: () => void;
}>((set) => ({
  baseData: generateData(baseQuantizationData),
  runtimeData: [],
  addNewQuantizationPoint: () => {
    set((state) => {
      const time = new Date().getTime();
      const data = [time, Math.random() * (3.1 - 2.8) + 2.8];

      return { runtimeData: [...state.runtimeData, data] };
    });
  },
}));

function generateData(_data: number[]) {
  const data = [];
  const time = new Date().getTime();

  for (let i = -_data.length; i < 0; i++) {
    data.push([time + i * 1000, _data[_data.length + i]]);
  }
  return data;
}

export const QuantizazionErrorChart = () => {
  const { baseData, runtimeData, addNewQuantizationPoint } =
    quantizationStore();

  const [attentionThreshold, setAttentionThreshold] = useState<number>(3.2);
  const [criticalThreshold, setCriticalThreshold] = useState<number>(4);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      addNewQuantizationPoint();
    }, 1000);

    return () => clearInterval(refreshInterval);
  }, []);

  const latestValue = runtimeData.at(-1) ? runtimeData.at(-1)![1] : 0;

  return (
    <Card>
      <Grid2 container>
        <Grid2 size={10}>
          <Box
            sx={{
              flex: 1,
            }}
          >
            <LineChart.Custom
              title="Quantization Error"
              xAxisOptions={{
                scrollbar: {
                  enabled: false,
                },
              }}
              exportingEnabled={false}
              yAxis={[
                {
                  options: {
                    plotLines: [
                      {
                        value: attentionThreshold,
                        color: "green",
                        dashStyle: "ShortDash",
                      },
                      {
                        value: criticalThreshold,
                        color: "red",
                        dashStyle: "ShortDash",
                      },
                    ],
                  },
                  uom: "",
                  series: [
                    {
                      type: "line",
                      name: "Train Data",
                      data: baseData,
                      color: "#0066ff",
                    },
                    {
                      type: "line",
                      name: "Runtime Data",
                      data: runtimeData,
                      color: "#ff6600",
                    },
                  ],
                },
              ]}
            />

            <Box
              sx={{
                width: 800,
              }}
            >
              <Stack direction="row" spacing={2}>
                <Typography sx={{ minWidth: "20ch" }}>
                  Attention threshold
                </Typography>
                <Slider
                  size="small"
                  sx={{ width: 300 }}
                  min={0}
                  max={5}
                  step={0.1}
                  value={attentionThreshold}
                  onChange={(event) => {
                    const { target } = event;

                    const val = (target as HTMLInputElement).value;

                    if (val === "") return;

                    const value = Number(val);
                    setAttentionThreshold(value);
                  }}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                />
                <Typography>{attentionThreshold}</Typography>
              </Stack>

              <Stack direction="row" spacing={2}>
                <Typography sx={{ minWidth: "20ch" }}>
                  Critical threshold
                </Typography>
                <Slider
                  size="small"
                  min={0}
                  max={5}
                  step={0.1}
                  value={criticalThreshold}
                  sx={{ width: 300 }}
                  onChange={(event) => {
                    const { target } = event;

                    const val = (target as HTMLInputElement).value;

                    if (val === "") return;

                    const value = Number(val);
                    setCriticalThreshold(value);
                  }}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                />
                <Typography>{criticalThreshold}</Typography>
              </Stack>
            </Box>
          </Box>
        </Grid2>
        <Grid2 size={2}>
          <Stack
            sx={{
              justifyItems: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography>Health state</Typography>
            <Gauge
              latestValue={latestValue}
              criticalThreshold={criticalThreshold}
            />
          </Stack>
        </Grid2>
      </Grid2>
    </Card>
  );
};

function Gauge({
  latestValue,
  criticalThreshold,
}: {
  latestValue: number;
  criticalThreshold: number;
}) {
  return (
    <Box
      sx={{
        width: 150,
        position: "relative",
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {latestValue.toFixed(2)}
      </Typography>
      <Solidgauge.Custom
        title=""
        plotOptions={{
          solidgauge: {
            innerRadius: "80%",
            dataLabels: {
              y: 5,
              borderWidth: 0,
              useHTML: true,
              enabled: false,
            },
          },
        }}
        legend={{
          enabled: false,
        }}
        tooltip={{
          enabled: false,
        }}
        yAxis={{
          options: {
            min: 0,
            max: criticalThreshold,
            stops: [
              [0.1, "#55BF3B"],
              [0.5, "#DDDF0D"],
              [0.9, "#DF5353"],
            ],
            lineWidth: 0,
            minorTickInterval: 0,
            tickAmount: 0,
            labels: {
              enabled: false,
            },
          },
          series: [
            {
              type: "solidgauge",
              data: [latestValue],
              rounded: true,
            },
          ],
        }}
      />
    </Box>
  );
}
