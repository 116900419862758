import { Box, Grid2, Stack, Typography } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { formatNumber } from "@/utils/formatNumber";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { LossType } from "../../types";
import { useGetShortTermData } from "../../api/useGetShortTermData";

export const Sparkline = ({
  title,
  lossType,
}: {
  title: string;
  lossType: LossType;
}) => {
  const translate = useTranslate();
  const { data, error, isLoading } = useGetShortTermData();

  if (error)
    return (
      <Box sx={{ minHeight: 132 }}>
        {translate("user_feedback.an_error_occurred")}
      </Box>
    );

  if (isLoading) return <SkeletonCard height={132} />;

  if (!data)
    return (
      <Box sx={{ minHeight: 132 }}>
        {translate("user_feedback.no_data_to_display")}
      </Box>
    );

  const { benchmarkValue, chartData, lastValue } =
    lossType === "machine"
      ? data.data.machineLosses
      : lossType === "process"
      ? data.data.processLosses
      : data.data.lineLosses;

  const highestValue = Math.max(...chartData.map((record) => record[1]));
  const difference = benchmarkValue - lastValue;
  const color = difference >= 0 ? "green" : "red";

  return (
    <Box sx={{ minHeight: 132 }}>
      <Box sx={{ display: "flex", gap: 1, alignItems: "baseline" }}>
        <Typography>{title}</Typography>
        <Typography sx={{ color: "#bbb", fontSize: ".6rem" }}>
          {translate("short_term")}
        </Typography>
      </Box>
      <Grid2 container>
        <Grid2 size={9}>
          <LineChart.Custom
            title=""
            height={100}
            exportingEnabled={false}
            xAxisOptions={{
              lineColor: "transparent",
              gridLineColor: "transparent",
              tickColor: "transparent",
              labels: { enabled: false },
            }}
            yAxis={[
              {
                uom: "%",
                options: {
                  plotLines: [
                    { value: benchmarkValue, color, dashStyle: "LongDash" },
                  ],
                  lineColor: "transparent",
                  gridLineColor: "transparent",
                  tickColor: "transparent",
                  max: highestValue > 90 ? 100 : null,
                },
                series: [
                  {
                    type: "line",
                    data: chartData,
                    color,
                    name: title,
                  },
                ],
              },
            ]}
            tooltip={{ enabled: true, options: { valueSuffix: "%" } }}
          />
        </Grid2>
        <Grid2 size={3}>
          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>{lastValue.toFixed(2)}%</Typography>
            <Box
              sx={{
                backgroundColor: color,
                borderRadius: 2,
                paddingX: "1rem",
                paddingY: "0.25rem",
              }}
            >
              <Typography
                variant="caption"
                sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
              >
                {`${difference > 0 ? "-" : "+"} ${formatNumber(
                  Math.abs(difference),
                )}%`}
              </Typography>
            </Box>
          </Stack>
        </Grid2>
      </Grid2>
    </Box>
  );
};
