import { ReactNode } from "react";
import { Swiper, SwiperProps } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { ONE_SECOND } from "@/utils/durationsInMilliseconds";

export const Carousel = ({
  children,
  ...rest
}: SwiperProps & { children: ReactNode }) => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      navigation={true}
      autoplay={{ delay: ONE_SECOND * 10, pauseOnMouseEnter: true }}
      loop={true}
      style={{ height: "100%" }}
      {...rest}
    >
      {children}
    </Swiper>
  );
};
