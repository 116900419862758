import { Phase, PieData } from "../../types";
import { PieChart } from "@/components/highcharts/piechart/Piechart";
import { parsePieData } from "../../utils/parsePieData";
import { useTranslate } from "@/i18n/config";

export const SummaryPieChart = ({
  phase,
  data,
}: {
  phase: Phase;
  data: PieData[];
}) => {
  const translate = useTranslate();

  return (
    <PieChart.Custom
      title={
        phase === "production"
          ? translate("completed_vs_not_completed")
          : translate("passed_vs_failed")
      }
      tooltipOptions={{
        formatter: function () {
          const clonedData = [...data];
          const elementsByEvaluation = clonedData.filter((el) =>
            this.key === "Completed" || this.key === "Passed"
              ? el.evaluation === "good"
              : el.evaluation === "bad",
          );
          if (elementsByEvaluation.length > 0) {
            let htmlList = "";
            elementsByEvaluation.forEach(
              (el) => (htmlList += `${el.name}<br>`),
            );
            return `
              ${this.key}: <b>${this.y}%</b><br>
              ${htmlList}
            `;
          } else {
            return `${this.key}: <b>${this.y}%</b>`;
          }
        },
      }}
      seriesOptions={{
        type: "pie",
        data: parsePieData({ data, phase, translate }),
      }}
    />
  );
};
