import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { ONE_HOUR } from "@/utils/durationsInMilliseconds";
import { IntellectaError } from "../types";
import { intellectaClient } from "./intellectaClient";
import { userConfigurationStore } from "../store/user-configuration-store";

type Response = IntellectaError | Result;

type Payload = {
  version: string;
  profile: string;
  machine: string;
};

const result = z.object({
  value: z.array(z.string()),
});

type Result = z.infer<typeof result>;

export const useGetStrainList = () => {
  const { version, profile, machine } = userConfigurationStore();

  return useQuery<Result, Error>({
    queryKey: ["strain-list", version, profile, machine],
    queryFn: async () => {
      if (!version || !profile || !machine) {
        throw new Error();
      }

      const response = await intellectaClient<Payload, Response>({
        url: "/get_strain_list",
        payload: { version, profile, machine },
      });

      if (response === "ERROR") {
        throw new Error();
      }

      try {
        return result.parse(response);
      } catch (error) {
        throw new Error();
      }
    },
    staleTime: ONE_HOUR,
    enabled: !!machine,
    retry: false,
  });
};
