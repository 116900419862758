import { BasicTable } from "@/components/BasicTable";
import { Card } from "@/components/Layout/Card";
import { TableParser } from "@/utils/TableParser";
import { Box, Stack } from "@mui/material";
import { PcA, PcB } from "../api/useGetEdgePcData";
import { TableTitle } from "../layout/StyledText";

export const SoftwareVersion = ({
  data,
}: {
  data: { pcAVersion: PcA["version"]; pcBVersion: PcB["version"] };
}) => {
  const { pcAVersion, pcBVersion } = data;
  const tableDataPcA = Object.entries(pcAVersion).map((el) => {
    return {
      software: el[0],
      sample: typeof el[1] !== "object" ? el[1] : JSON.stringify(el[1]),
    };
  });
  const tableDataPcB = Object.entries(pcBVersion).map((el) => {
    return {
      software: el[0],
      sample: typeof el[1] !== "object" ? el[1] : JSON.stringify(el[1]),
    };
  });
  const { columns: pcACol, rows: pcARows } = getTableColumnsRows({
    data: tableDataPcA,
  });
  const { columns: pcBCol, rows: pcBRows } = getTableColumnsRows({
    data: tableDataPcB,
  });
  return (
    <Stack
      direction="row"
      sx={{
        gap: 2,
      }}
    >
      <Card>
        <TableTitle>Software PC A</TableTitle>
        <BasicTable
          columns={pcACol}
          rows={pcARows}
          fileName="pc_a_software_data"
        />
      </Card>
      <Card>
        <TableTitle>Software PC B</TableTitle>
        <BasicTable
          columns={pcBCol}
          rows={pcBRows}
          fileName="pc_b_software_data"
        />
      </Card>
    </Stack>
  );
};

function getTableColumnsRows({
  data,
}: {
  data: {
    software: string;
    sample: unknown;
  }[];
}) {
  const { columns, rows } =
    data.length > 0
      ? TableParser.parseData({
          data: data,
          columnsMap: {
            software: "Software",
            sample: "Sample",
          },
          columnsOrder: ["software", "sample"],
        })
      : { columns: [], rows: [] };

  const indexFound = columns.findIndex((item) => item.field === "sample");

  if (indexFound > -1) {
    columns[indexFound].renderCell = (params) => {
      const { value } = params as { value?: string };

      if (!value) return null;

      return <Box sx={{ overflow: "auto" }}>{value}</Box>;
    };
  }

  return { columns: columns, rows: rows };
}
