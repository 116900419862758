import { Stack } from "@mui/material";
import { ScaleStandardDeviation } from "./ScaleStandardDeviation";
import { DoserStandardDeviation } from "./DoserStandardDeviation";

export const TimeBasedView = () => {
  return (
    <Stack
      spacing={2}
      sx={{
        marginTop: 2,
      }}
    >
      <ScaleStandardDeviation />
      <DoserStandardDeviation />
      {/* <RejectsAndBlockings /> */}
    </Stack>
  );
};
