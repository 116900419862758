import { Typography, Grid2 } from "@mui/material";
import { ProgressBar } from "./ProgressBar";
import { ChartType, formatValue } from "../utils/formatValue";

export const BenchmarkRow = ({
  label,
  valueCurrent,
  valueBenchmark,
  gap,
  chartType,
}: {
  label: string;
  valueCurrent: number;
  valueBenchmark: number;
  gap: number;
  chartType: ChartType;
}) => {
  return (
    <Grid2 container spacing={1}>
      <Grid2 size={6}>
        {chartType === "percentage" && (
          <ProgressBar
            valueCurrent={valueCurrent}
            valueBenchmark={valueBenchmark}
            label={label}
            chartType="percentage"
          />
        )}
        {chartType === "duration" && (
          <ProgressBar
            valueCurrent={valueCurrent}
            valueBenchmark={valueBenchmark}
            label={label}
            chartType="duration"
          />
        )}
        {chartType === "count" && (
          <ProgressBar
            valueCurrent={valueCurrent}
            valueBenchmark={valueBenchmark}
            label={label}
            chartType="count"
          />
        )}
      </Grid2>
      <Grid2
        size={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography>
          {formatValue({ value: valueCurrent, chartType })}
        </Typography>
      </Grid2>
      <Grid2
        size={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography>
          {formatValue({ value: valueBenchmark, chartType })}
        </Typography>
      </Grid2>
      <Grid2
        size={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography>{formatValue({ value: gap, chartType })}</Typography>
      </Grid2>
    </Grid2>
  );
};
