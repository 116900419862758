import { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import {
  ResSchema,
  useGetNoveltyAndAnomalyEvaluation,
} from "../../api/Diagnostic/useGetNoveltyAndAnomalyEvaluation";
import { Card } from "@/components/Layout/Card";
import { ContextInformation } from "@/features/Motors/components/Layouts/ContextInformation";
import { useDisclosure } from "@/hooks/useDisclosure";
import { CompareLapsModal } from "./CompareLapsModal/CompareLapsModal";
import { ScatterPlot } from "@/components/highcharts/scatter/Scatter";
import { Evaluation } from "../../types";
import { NoveltyAnomalyDetailModal } from "./NoveltyAnomalyDetailModal";
import { EVALUATION_COLORS } from "../../context/acopos-context";

export const NoveltyAnomalyAnalytics = ({
  noveltyAnalytics,
  anomalyAnalytics,
}: {
  noveltyAnalytics: number[];
  anomalyAnalytics: number[];
}) => {
  const { open, close, isOpen } = useDisclosure();

  // return (
  //   <>
  //     {isOpen && <CompareLapsModal isOpen={isOpen} close={close} />}
  //     <Stack gap={1} width="calc(100% - 320px)">
  //       <>
  //         <Button
  //           variant="outlined"
  //           sx={{ alignSelf: "center" }}
  //           onClick={open}
  //         >
  //           Compare Laps
  //         </Button>
  //       </>
  //     </Stack>
  //   </>
  // );

  const { data, isLoading, error } = useGetNoveltyAndAnomalyEvaluation({
    noveltyAnalytics,
    anomalyAnalytics,
  });
  const translate = useTranslate();

  if (isLoading)
    return <AnalyticContainerSkeleton width="calc(100% - 320px)" />;

  if (error)
    return (
      <Card sx={{ width: "calc(100% - 320px)" }}>
        {translate("user_feedback.an_error_occurred")}
      </Card>
    );

  if (!data)
    return (
      <Card sx={{ width: "calc(100% - 320px)" }}>
        {translate("user_feedback.no_data")}
      </Card>
    );

  return (
    <>
      {isOpen && <CompareLapsModal isOpen={isOpen} close={close} />}
      <Stack
        sx={{
          gap: 1,
          width: "calc(100% - 320px)",
        }}
      >
        {data.data.length > 0 ? (
          <>
            <Button
              variant="outlined"
              sx={{ alignSelf: "center" }}
              onClick={open}
            >
              {translate("acopos.compare_laps")}
            </Button>
            {data.data.map((analytic, index) => (
              <Analytic
                key={`${analytic.analyticName}-${index}`}
                props={analytic}
              />
            ))}
          </>
        ) : (
          <Card sx={{ height: 400 }}>
            <Typography sx={{ color: "red" }} variant="h4">
              {translate("motors.no_selection")}
            </Typography>
            <Typography variant="h6">
              {translate("acopos.select_at_least_one_analytic")}
            </Typography>
          </Card>
        )}
      </Stack>
    </>
  );
};

interface NoveltyPoint {
  x: number;
  y: number;
  custom: {
    runId: number;
    lapId: number;
    segmentId: number;
    shuttleId: number;
    hasNovelty: boolean;
  };
}

interface AnomalyPoint {
  x: number;
  y: number;
  custom: {
    runId: number;
    lapId: number;
    segmentId: number;
    shuttleId: number;
    evaluation: Evaluation;
  };
}

const Analytic = ({ props }: { props: ResSchema }) => {
  const translate = useTranslate();
  const { open, close, isOpen } = useDisclosure();
  const [info, setInfo] = useState<
    | {
        runId: number;
        lapId: number;
        segmentId: number;
        shuttleId: number;
      }
    | undefined
  >(undefined);
  const { analyticName, analyticId, analyticType, data, contextInfo } = props;
  const yCategoriesAnomaly: Evaluation[] = ["good", "warning", "critical"];
  const length = data.length;
  const dotSize = length < 10 ? 8 : length < 50 ? 6 : 4;

  return (
    <>
      {isOpen && info && (
        <NoveltyAnomalyDetailModal
          info={info}
          analyticId={analyticId}
          isOpen={isOpen}
          close={close}
        />
      )}
      <Card>
        <Box>
          <ContextInformation props={contextInfo} key={analyticName} />
          {analyticType === "novelty" && (
            <ScatterPlot.Custom
              title={analyticName}
              yAxis={[
                {
                  uom: "",
                  series: [
                    {
                      type: "scatter",
                      name: analyticName,
                      data: data.map((entry) => {
                        return {
                          x: entry.timestamp,
                          y: entry.hasNovelty ? 1 : 0,
                          color: entry.hasNovelty ? "orange" : "cyan",
                          custom: {
                            runId: entry.runId,
                            lapId: entry.lapId,
                            segmentId: entry.segmentId,
                            shuttleId: entry.shuttleId,
                            hasNovelty: entry.hasNovelty,
                          },
                          marker: {
                            radius: dotSize,
                          },
                        };
                      }),
                      cursor: "pointer",
                      point: {
                        events: {
                          click: function () {
                            const point = this as unknown as NoveltyPoint;
                            if (point) {
                              setInfo({
                                runId: point.custom.runId,
                                lapId: point.custom.lapId,
                                segmentId: point.custom.segmentId,
                                shuttleId: point.custom.shuttleId,
                              });
                              open();
                            }
                          },
                        },
                      },
                    },
                  ],
                  options: {
                    min: 0,
                    max: 1,
                    categories: ["Normal", "Novelty"],
                  },
                },
              ]}
              scrollbarEnabled={false}
              tooltip={{
                enabled: true,
                options: {
                  formatter: function () {
                    const point = this.point as unknown as NoveltyPoint;
                    if (point) {
                      return `
                        <span style="color:${
                          this.color
                        }">●</span> ${analyticName}<br>
                        ${new Date(point.x).toLocaleString()}<br>
                        ${translate("acopos.lap")} ${point.custom.lapId}<br>
                        ${translate("evaluation")}: <b>${
                          point.custom.hasNovelty ? "Novelty" : "Normal"
                        }</b>
                      `;
                    }
                    return `
                      ${analyticName}<br>
                      ${new Date(this.x!).toLocaleString()}<br>
                    `;
                  },
                },
              }}
            />
          )}
          {analyticType === "anomaly" && (
            <ScatterPlot.Custom
              title={analyticName}
              yAxis={[
                {
                  uom: "",
                  series: [
                    {
                      type: "scatter",
                      name: analyticName,
                      data: data.map((entry) => {
                        return {
                          x: entry.timestamp,
                          y: yCategoriesAnomaly.indexOf(entry.status),
                          color: EVALUATION_COLORS[entry.status].selected,
                          custom: {
                            runId: entry.runId,
                            lapId: entry.lapId,
                            segmentId: entry.segmentId,
                            shuttleId: entry.shuttleId,
                            evaluation: entry.status,
                          },
                          marker: {
                            radius: dotSize,
                          },
                        };
                      }),
                      cursor: "pointer",
                      point: {
                        events: {
                          click: function () {
                            const point = this as unknown as AnomalyPoint;
                            if (point) {
                              setInfo({
                                runId: point.custom.runId,
                                lapId: point.custom.lapId,
                                segmentId: point.custom.segmentId,
                                shuttleId: point.custom.shuttleId,
                              });
                              open();
                            }
                          },
                        },
                      },
                    },
                  ],
                  options: {
                    min: 0,
                    max: 2,
                    categories: yCategoriesAnomaly.map((status) =>
                      translate(`evaluation.${status}`),
                    ),
                  },
                },
              ]}
              scrollbarEnabled={false}
              tooltip={{
                enabled: true,
                options: {
                  formatter: function () {
                    const point = this.point as unknown as AnomalyPoint;
                    if (point) {
                      return `
                        <span style="color:${
                          this.color
                        }">●</span> ${analyticName}<br>
                        ${new Date(point.x).toLocaleString()}<br>
                        ${translate("acopos.lap")} ${point.custom.lapId}<br>
                        ${translate("evaluation")}: <b>${translate(
                          `evaluation.${point.custom.evaluation}`,
                        )}</b>
                      `;
                    }
                    return `
                      ${analyticName}<br>
                      ${new Date(this.x!).toLocaleString()}<br>
                    `;
                  },
                },
              }}
            />
          )}
        </Box>
      </Card>
    </>
  );
};
