import { useEffect, useState } from "react";
import { useTranslate } from "@/i18n/config";
import { Box, Stack, Typography } from "@mui/material";
import { Card } from "@/components/Layout/Card";
import {
  useAcoposContext,
  useAcoposDispatchContext,
} from "../context/acopos-context";
import { SelectLapsContainer } from "../components/Selections/SelectLapsContainer";
import { SelectRuns } from "../components/Selections/SelectRuns";
import { SelectSegment } from "../components/Selections/SelectSegment";
import { SelectShuttlesStandard } from "../components/Selections/SelectShuttles";
import { DescriptiveAnalyticsSelection } from "../components/Monitoring/DescriptiveAnalyticsSelection";
import { DescriptiveAnalytics } from "../components/Monitoring/DescriptiveAnalytics";
import { useCheckIfCanRenderAnalytic } from "../utils/useCheckIfCanRenderAnalytic";

export const Descriptive = () => {
  const translate = useTranslate();
  const [selectedAnalytics, setSelectedAnalytics] = useState<string[]>([]);
  const { runs } = useAcoposContext();
  const dispatch = useAcoposDispatchContext();
  const {
    canRenderAnalytic,
    noRunsSelected,
    noLapsSelected,
    noSegmentSelected,
    noShuttlesSelected,
  } = useCheckIfCanRenderAnalytic({
    hasRunsSelection: true,
    hasLapsSelection: true,
  });

  useEffect(() => {
    dispatch({ type: "SET_MAX_DAYS_CALENDAR", item: 7 });
  }, []);

  return (
    <Stack
      sx={{
        gap: 1,
      }}
    >
      <SelectRuns enableFilter={false} />
      <Box
        sx={{
          display: "flex",
          gap: ".5rem",
        }}
      >
        <SelectSegment />
        <SelectShuttlesStandard />
      </Box>
      {runs.length === 0 ? (
        <Card>
          <Typography variant="h4" color="red">
            {translate("acopos.select_at_least_one_run")}
          </Typography>
        </Card>
      ) : (
        <SelectLapsContainer />
      )}
      <Box sx={{ display: "flex", gap: 1 }}>
        <DescriptiveAnalyticsSelection
          setSelectedAnalytics={setSelectedAnalytics}
        />
        {canRenderAnalytic ? (
          <DescriptiveAnalytics analytics={selectedAnalytics} />
        ) : (
          <Card sx={{ width: "calc(100% - 320px)" }}>
            <Typography sx={{ color: "red" }} variant="h4">
              {translate("motors.no_selection")}
            </Typography>
            {noRunsSelected && (
              <Typography variant="h6">
                {translate("acopos.select_at_least_one_run")}
              </Typography>
            )}
            {noLapsSelected && (
              <Typography variant="h6">
                {translate("acopos.select_at_least_one_lap")}
              </Typography>
            )}
            {noSegmentSelected && (
              <Typography variant="h6">
                {translate("acopos.select_at_least_one_segment")}
              </Typography>
            )}
            {noShuttlesSelected && (
              <Typography variant="h6">
                {translate("acopos.select_at_least_one_shuttle")}
              </Typography>
            )}
          </Card>
        )}
      </Box>
    </Stack>
  );
};
