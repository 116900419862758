import { Box, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Typewriter } from "react-simple-typewriter";

type TypewriterTextProps = {
  text: string;
  maxFontSize?: number;
  minFontSize?: number;
  iconSrc?: string;
  iconAlt?: string;
};

export const TypewriterText: React.FC<TypewriterTextProps> = ({
  text,
  maxFontSize = 36,
  minFontSize = 14,
  iconSrc,
  iconAlt = "User Icon",
}) => {
  const [fontSize, setFontSize] = useState(maxFontSize);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const adjustFontSize = () => {
      if (!containerRef.current) return;

      const { width, height } = containerRef.current.getBoundingClientRect();
      const textLength = text.length;

      const widthBasedFont = width / (textLength * 0.6);
      const heightBasedFont = height * 0.5;
      const calculatedFontSize = Math.min(widthBasedFont, heightBasedFont);

      setFontSize(
        Math.min(maxFontSize, Math.max(minFontSize, calculatedFontSize)),
      );
    };

    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);
    return () => window.removeEventListener("resize", adjustFontSize);
  }, [text, maxFontSize, minFontSize]);

  return (
    <Box
      ref={containerRef}
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "0.5rem",
        overflow: "hidden",
      }}
    >
      {iconSrc && (
        <Box
          component="img"
          src={iconSrc}
          alt={iconAlt}
          sx={{
            width: "1.5rem",
            height: "1.5rem",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      )}

      <Typography
        sx={{
          fontSize: `${fontSize}px`,
          lineHeight: 1.2,
          whiteSpace: "normal",
        }}
      >
        <Typewriter
          words={[text]}
          loop={1}
          cursor={false}
          typeSpeed={30}
          deleteSpeed={50}
          delaySpeed={1000}
        />
      </Typography>
    </Box>
  );
};
