import { Stack } from "@mui/material";
import { useGetAdvancedAnalysisList } from "../api/useGetAdvancedAnalysisList";
import { AdvancedAnalytic } from "../api/useGetBatchAdvancedAnalytics";
import { AnomalyDetection } from "../components/AnomalyDetection";

export const PressureExcursionAnalytic = ({
  analytic,
}: {
  analytic: AdvancedAnalytic;
}) => {
  const { data = [] } = useGetAdvancedAnalysisList({
    batch_advanced_analytic_id: analytic.id,
  });

  return (
    <Stack
      sx={{
        gap: 2,
      }}
    >
      <AnomalyDetection
        analytics={data.filter((item) => item.adv_chart_type === "anomaly")}
      />
    </Stack>
  );
};
