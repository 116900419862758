import { useState } from "react";
import {
  Box,
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-premium";
import { XrangePointOptionsObject } from "highcharts/highstock";
import { Card } from "@/components/Layout/Card";
import { Modal } from "@/components/Modal";
import { BasicTable } from "@/components/BasicTable";
import { XRangeChart } from "@/components/highcharts/xrange/XRange";
import { useDisclosure } from "@/hooks/useDisclosure";
import { StatesLogData } from "../api/useGetStatesLog";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { z } from "zod";
import { StatesLogLegend } from "../../../components/StatesLogLegend";
import { useSetMachinesOrder } from "@/features/OverallLineEfficiency/api/useSetMachinesOrder";
import { useIsAllowed } from "@/hooks/useIsAllowed";
import { UserRole } from "@/context/firebase-context";
import { getCustomHeightFromN } from "@/features/StateLog/utils/getCustomHeightFromN";
import { useTranslate } from "@/i18n/config";

interface Row {
  id: number;
  "Machine Name": string;
}

const updateRowPosition = (
  initialIndex: number,
  newIndex: number,
  rows: Row[],
): Promise<Row[]> => {
  return new Promise((resolve) => {
    const rowsClone = [...rows];
    const row = rowsClone.splice(initialIndex, 1)[0];
    rowsClone.splice(newIndex, 0, row);
    resolve(rowsClone);
  });
};

const sortObjectKeys = (
  object: StatesLogData,
  orderArray: string[],
): StatesLogData => {
  const orderedKeys = Object.keys(object).sort((a, b) => {
    const indexA = orderArray.indexOf(a);
    const indexB = orderArray.indexOf(b);
    return indexA - indexB;
  });

  const sortedObject: StatesLogData = {};
  orderedKeys.forEach((key) => {
    sortedObject[key] = object[key];
  });

  return sortedObject;
};

const LegendData = z.record(z.string());

export type LegendData = z.infer<typeof LegendData>;

const parseData = (
  data: StatesLogData,
): {
  machines: string[];
  chartData: XrangePointOptionsObject[];
  legendData: LegendData;
} => {
  const machines = Object.keys(data);
  const chartData: XrangePointOptionsObject[] = [];
  const legendData: LegendData = {};

  machines.forEach((machine, index) => {
    const machineStatesLog = data[machine];
    machineStatesLog.forEach((occurrence) => {
      const { start, end, state, color } = occurrence;
      legendData[state] = color;
      chartData.push({
        name: state,
        x: start,
        x2: end,
        y: index,
        color,
      });
    });
  });

  return { machines, chartData, legendData };
};

export const LineStatesLog = ({
  dataBatches,
  order,
}: {
  dataBatches: { [key: string]: StatesLogData };
  order: string[];
}) => {
  const { formatTimestamp } = useFormatTimestamp();
  const translate = useTranslate();
  const [orderedMachines, setOrderedMachines] = useState<string[]>(order);
  const [selectedBatch, setSelectedBatch] = useState(
    Object.keys(dataBatches)[0],
  );
  const [batchData, setBatchData] = useState(dataBatches[selectedBatch]);
  const { open, close, isOpen } = useDisclosure();
  const { mutate: setMachinesOrder } = useSetMachinesOrder();
  const isAllowed = useIsAllowed([
    UserRole.USER_ADMIN,
    UserRole.SUPER_USER,
    UserRole.SUPER_USER_SENTINEL,
  ]);

  const { machines, chartData, legendData } =
    orderedMachines.length > 0
      ? parseData(sortObjectKeys(batchData, orderedMachines))
      : parseData(batchData);

  const columns = [{ field: "Machine Name" }];
  const rows = machines.map((machine, i) => {
    return { id: i, "Machine Name": machine };
  });

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows,
    );
    setOrderedMachines(newRows.map((row) => row["Machine Name"]));
  };

  const handleSelectBatch = (
    _: React.MouseEvent<HTMLElement>,
    newBatch: string,
  ) => {
    if (!newBatch) return;
    setSelectedBatch(newBatch);
    setBatchData(dataBatches[newBatch]);
  };

  const handleSave = () => {
    setMachinesOrder({
      machinesOrder: orderedMachines,
    });
    close();
  };

  return (
    <Card>
      <Stack>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {isAllowed && (
            <Button
              variant="outlined"
              sx={{
                marginRight: "auto",
              }}
              onClick={open}
            >
              {translate("line.sort_machines")}
            </Button>
          )}
          <ToggleButtonGroup
            value={selectedBatch}
            exclusive
            onChange={handleSelectBatch}
            aria-label="Variables"
            sx={{ backgroundColor: "#1F293F", marginRight: "auto" }}
          >
            {Object.keys(dataBatches).map((batch) => (
              <ToggleButton key={batch} value={batch}>
                {batch}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
        <Modal
          open={isOpen}
          onClose={close}
          bodyContent={
            <Box>
              <BasicTable
                columns={columns.map((col) => ({
                  ...col,
                  headerName: translate("machine"),
                }))}
                rows={rows}
                allowRowReordering={true}
                handleRowOrderChange={handleRowOrderChange}
              />
            </Box>
          }
          titleContent={translate("line.sort_line_machines")}
          actions={
            <>
              <Button variant="outlined" onClick={close}>
                {translate("acceptance_test.close")}
              </Button>
              <Button variant="contained" onClick={handleSave}>
                {translate("actions.save")}
              </Button>
            </>
          }
        />
        <Box sx={{ maxHeight: 450, overflowY: "auto", mt: 2 }}>
          <XRangeChart
            title={translate("line.machines_states_log")}
            categories={machines}
            data={chartData}
            customHeight={getCustomHeightFromN(machines.length)}
            borderRadius={0}
            tooltipFormatter={function () {
              const pointData = z
                .object({
                  name: z.string(),
                  yCategory: z.string(),
                })
                .safeParse(this.point);

              if (!pointData.success) return "";

              const { name: state, yCategory } = pointData.data;

              const dateStart = formatTimestamp({
                timestamp: this.point.x,
              });
              const dateEnd = formatTimestamp({
                timestamp: z.number().parse(this.point.x2),
              });
              const color = this.point.color;
              const machine = yCategory;

              return `<b>${machine}</b><br>
            <span style="color:${color}">●</span> <b>${state}</b><br>
            ${translate("start")}: <b>${dateStart}</b><br>
            ${translate("end")}: <b>${dateEnd}</b>`;
            }}
          />
        </Box>
        <StatesLogLegend legendData={legendData} />
      </Stack>
    </Card>
  );
};
