import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { useLanguage } from "@/i18n/config";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { FetchClient } from "@/services/ApiClient";
import { ONE_SECOND } from "@/utils/durationsInMilliseconds";
import { ThingworxError } from "src/types/error";
import { lossType } from "../types";

export const suggestionsData = z.object({
  generation_timestamp: z.number(),
  machine_losses: z.string(),
  line_losses: z.string(),
  process_losses: z.string(),
  critical_message: z.string(),
  critical_category: lossType,
});

export type SuggestionsData = z.infer<typeof suggestionsData>;

export const suggestionsResult = z.object({
  response: z.literal(true),
  suggestions: suggestionsData,
});

export type SuggestionsResult = z.infer<typeof suggestionsResult>;

export type SuggestionsResponse = ThingworxError | SuggestionsResult;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  language_id: string;
};

export const useGetSuggestions = () => {
  const { machine } = useMachineContext();
  const { timeSelection } = useTimeSelection();
  const { appKey } = useFirebaseContext();
  const language = useLanguage();

  return useQuery<SuggestionsResult>({
    queryKey: [
      "efficiency-navigator-suggestions",
      machine!.machine,
      timeSelection,
      language,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, SuggestionsResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
          timeSelection,
          language_id: language,
        },
        url: "dig.c.benchmark_thing/Services/getEfficiencySuggestions",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return suggestionsResult.parse(response);
    },
    refetchInterval: ONE_SECOND * 30,
  });
};
