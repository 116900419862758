import { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { useGetMotorList } from "../api/useGetMotorsList";
import { SmallAnalyticContainerLayout } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { Card } from "@/components/Layout/Card";
import { DailyMeanCurrentChartAllTime } from "../components/Diagnostic/DailyMeanCurrentChartAllTime";
import { DailyMeanCurrentChartByRange } from "../components/Diagnostic/DailyMeanCurrentChartByRange";
import { MeanCurrentPolarChart } from "../components/Diagnostic/MeanCurrentPolarChart";
import { LongTermDriftStatus } from "../components/Diagnostic/LongTermDriftStatus";

// export type View = "Mean" | "RMS";

export const LongTermDrift = ({
  onRender,
  onUnmount,
}: {
  onRender: () => void;
  onUnmount: () => void;
}) => {
  const [motor, setMotor] = useState("");
  // const [view, setView] = useState<View>("Mean");
  const translate = useTranslate();
  const { data, isLoading, error } = useGetMotorList();

  useEffect(() => {
    onRender();

    return () => onUnmount();
  }, [onRender, onUnmount]);

  if (isLoading) {
    return <SmallAnalyticContainerLayout sx={{ height: 80 }} />;
  }

  if (error) {
    return (
      <Card sx={{ height: 80 }}>
        {translate("user_feedback.an_error_occurred")}
      </Card>
    );
  }

  if (!data) {
    return (
      <Card sx={{ height: 80 }}>{translate("user_feedback.no_data")}</Card>
    );
  }

  const handleChangeMotor = (event: SelectChangeEvent) => {
    setMotor(event.target.value as string);
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
        }}
      >
        <FormControl size="small" sx={{ minWidth: 300 }}>
          <InputLabel id="select-motor-label">{translate("motor")}</InputLabel>
          <Select
            labelId="select-motor-label"
            id="select-motor"
            value={motor}
            label={translate("motor")}
            onChange={handleChangeMotor}
          >
            <MenuItem value="">---</MenuItem>
            {data.map((motor) => (
              <MenuItem key={motor.id} value={motor.id}>
                {motor.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {motor !== "" && <LongTermDriftStatus />}
      </Card>
      {motor !== "" && <ChartSection motor={parseInt(motor)} />}
    </>
  );
};

const ChartSection = ({ motor }: { motor: number }) => {
  return (
    <>
      <DailyMeanCurrentChartAllTime motor={motor} />
      <DailyMeanCurrentChartByRange motor={motor} />
      <MeanCurrentPolarChart motor={motor} />
    </>
  );
};
