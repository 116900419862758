import { useState } from "react";
import { toast } from "react-hot-toast";
import { ROLE_NUMBER_TO_STRING } from "../ManageUsersTable";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useAskConfirmAction } from "@/hooks/ask-confirm-action";
import { useUpdateRole } from "../../../../lambda/useUpdateRole";
import { UserRole } from "src/lambda/useGetColleagues";
import { useTranslate } from "@/i18n/config";

const SETTABLE_ROLES = [
  { name: "USER_ADMIN", clientName: "Admin", value: 3 },
  { name: "USER", clientName: "Base User", value: 4 },
] as const;

export const RoleSelectCell = ({
  role,
  user,
}: {
  role: UserRole;
  user: string;
}) => {
  const [message, setMessage] = useState("");
  const [selectedRole, setSelectedRole] = useState(role);
  const translate = useTranslate();
  const { mutate: updateRole } = useUpdateRole();
  const { askConfirm, renderConfirmModal } = useAskConfirmAction(message, () =>
    updateRole(
      { email: user, role: selectedRole },
      {
        onSuccess() {
          toast.success(translate("user_feedback.role_updated_successfully"));
        },
        onError() {
          toast.error(translate("user_feedback.an_error_occurred"));
        },
      },
    ),
  );

  const handleChange = (event: SelectChangeEvent) => {
    const newRole = parseInt(event.target.value) as UserRole;
    if (newRole !== role) {
      setSelectedRole(newRole);

      const roleString = ROLE_NUMBER_TO_STRING[role];

      const translatedRole = translate(
        roleString === "Super User Sentinel"
          ? "roles.super_user_sentinel"
          : roleString === "Super User"
          ? "roles.super_user"
          : roleString === "Admin"
          ? "roles.admin"
          : "roles.base_user",
      );
      setMessage(
        translate("assign_role_to_user", {
          role: translatedRole,
          user,
        }),
      );
      askConfirm();
    }
  };

  return (
    <>
      <Select
        value={role.toString()}
        labelId="role-select-label"
        id="role-select"
        label="Role"
        onChange={handleChange}
        variant="standard"
      >
        {SETTABLE_ROLES.map((role) => (
          <MenuItem key={role.value} value={role.value}>
            {translate(
              role.clientName === "Admin" ? "roles.admin" : "roles.base_user",
            )}
          </MenuItem>
        ))}
      </Select>
      {renderConfirmModal()}
    </>
  );
};
