import { Card } from "@/components/Layout/Card";
import { TimeHelpers, useFormatTimestamp } from "@/utils/TimeHelpers";
import { GridColDef } from "@mui/x-data-grid-premium";
import { DatiTabella as TableData } from "../api/useGetAcceptanceTestData";
import { BasicTable } from "@/components/BasicTable";
import { useTranslate } from "@/i18n/config";

interface TableRows extends TableData {
  id: number;
  timestampStop: number;
  timestampRestart: number;
  stateString: string;
  errorDescription: string;
  comment: string;
  duration: string;
  who: string;
}

export const HistoricTable = ({ tableData }: { tableData: TableData[] }) => {
  const { formatTimestamp } = useFormatTimestamp();
  const translate = useTranslate();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      flex: 0.6,
    },
    {
      field: "timestampStop",
      headerName: translate("acceptance_test.pause"),
      type: "dateTime",
      flex: 1.5,
      valueFormatter: (value) =>
        value &&
        formatTimestamp({
          timestamp: +new Date(value),
          skipTimezoneTransform: true,
        }),
      valueGetter: (value) => {
        const date =
          value &&
          formatTimestamp({
            timestamp: +new Date(value),
            skipLocale: true,
          });
        return value ? new Date(date) : null;
      },
    },
    {
      field: "timestampRestart",
      headerName: translate("acceptance_test.restart"),
      type: "dateTime",
      flex: 1.5,
      valueFormatter: (value) =>
        value &&
        formatTimestamp({
          timestamp: +new Date(value),
          skipTimezoneTransform: true,
        }),
      valueGetter: (value) => {
        const date =
          value &&
          formatTimestamp({
            timestamp: +new Date(value),
            skipLocale: true,
          });
        return value ? new Date(date) : null;
      },
    },
    {
      field: "stateString",
      headerName: translate("machine.status"),
      type: "string",
      flex: 1.2,
    },
    {
      field: "errorDescription",
      headerName: translate("alarms"),
      type: "string",
      flex: 1.5,
    },
    {
      field: "comment",
      headerName: translate("acceptance_test.comment"),
      type: "string",
      flex: 4,
    },
    {
      field: "duration",
      headerName: translate("machine.downtime"),
      type: "number",
      flex: 1.2,
    },
    {
      field: "who",
      headerName: translate("acceptance_test.who"),
      type: "number",
      flex: 1.2,
    },
  ];

  const rows: TableRows[] =
    tableData.length > 0
      ? tableData.map((row, index) => ({
          ...row,
          id: tableData.length - index,
          timestampStop: row.Stop - 7200000,
          timestampRestart: row.Restart - 7200000,
          stateString: row.StateString,
          errorDescription: row.ErrorDescription,
          comment: row.Comment,
          duration: TimeHelpers.parseDurationToString({
            duration: row.Duration * 1000,
          }),
          who:
            row.Who === 0
              ? translate("acceptance_test.related")
              : translate("acceptance_test.unrelated"),
        }))
      : [];

  return (
    <Card>
      <BasicTable
        columns={columns}
        rows={rows}
        fileName="acceptance_test_historic_detail"
      />
    </Card>
  );
};
