import { Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { useGetConditionMonitoringAnalyticsList } from "../../api/Monitoring/useGetConditionMonitoringAnalyticsList";
import { Card } from "@/components/Layout/Card";
import { BatchListSelect } from "../../components/Monitoring/BatchListSelect";
import { TagLineChart } from "../../components/Monitoring/TagLineChart";
import { useIsolatorContext } from "../../context/useIsolatorContext";

export const ConditionMonitoring = () => {
  const translate = useTranslate();
  const { selectedTag } = useIsolatorContext();
  const { batches } = useIsolatorContext();
  const { data, isLoading, error } = useGetConditionMonitoringAnalyticsList();

  if (isLoading) return <SkeletonCard height={180} width="25%" />;
  if (error) return <Card>{translate("user_feedback.an_error_occurred")}</Card>;
  if (!data) return <Card>{translate("user_feedback.no_data")}</Card>;
  if (batches.length === 0)
    return (
      <Card
        initial={{ opacity: 0, scale: 0.75, x: 20 }}
        animate={{ opacity: 1, scale: 1, x: 0 }}
        exit={{ opacity: 0 }}
        sx={{ width: "25%", height: 100 }}
      >
        {translate("isolator.no_batch_selected")}
      </Card>
    );
  if (data.analytics.length === 0)
    return (
      <Card
        initial={{ opacity: 0, scale: 0.75, x: 20 }}
        animate={{ opacity: 1, scale: 1, x: 0 }}
        exit={{ opacity: 0 }}
        sx={{ width: "25%", height: 100 }}
      >
        {translate("motors.no_analytics_to_display")}
      </Card>
    );

  return (
    <Stack
      sx={{
        gap: 2,
      }}
    >
      <BatchListSelect batchList={data.analytics} />
      {selectedTag && <TagLineChart tagId={selectedTag} />}
    </Stack>
  );
};
