import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

export const Modal = ({
  titleContent,
  bodyContent,
  actions,
  ...dialogProps
}: React.ComponentProps<typeof Dialog> & {
  titleContent: React.ReactNode;
  bodyContent: React.ReactNode;
  actions?: React.ReactNode;
}) => {
  return (
    <Dialog {...dialogProps}>
      <DialogTitle>{titleContent}</DialogTitle>
      <DialogContent>{bodyContent}</DialogContent>
      {actions ? (
        <DialogActions sx={{ paddingTop: 0, paddingX: 3, paddingBottom: 2 }}>
          {actions}
        </DialogActions>
      ) : null}
    </Dialog>
  );
};
