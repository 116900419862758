import {
  Box,
  CircularProgress,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { EVALUATION_COLORS } from "../../utils/evaluationColors";
import { useGetLongTermDriftStatus } from "../../api/useGetLongTermDriftStatus";

export const LongTermDriftStatus = () => {
  const translate = useTranslate();
  const { isLoading, data } = useGetLongTermDriftStatus({ motor: 1 });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: ".3rem",
        border: "1px solid #2f3a52",
        p: ".5rem",
        borderRadius: ".5rem",
      }}
    >
      <Typography>{translate("motors.long_term_drift_status")}:</Typography>
      {isLoading ? (
        <CircularProgress size={24} />
      ) : data ? (
        <Tooltip
          title={translate(`evaluation.${data.evaluation}`)}
          placement="top"
          slots={{
            transition: Zoom,
          }}
        >
          <Box
            sx={{
              width: "24px",
              height: "8px",
              backgroundColor: EVALUATION_COLORS[data.evaluation].selected,
              borderRadius: "5px",
            }}
          ></Box>
        </Tooltip>
      ) : (
        <Tooltip
          title={translate(`evaluation.no_data`)}
          placement="top"
          slots={{
            transition: Zoom,
          }}
        >
          <Box
            sx={{
              width: "24px",
              height: "8px",
              backgroundColor: EVALUATION_COLORS["no_data"].selected,
              borderRadius: "5px",
            }}
          ></Box>
        </Tooltip>
      )}
    </Box>
  );
};
