import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { NoveltyAnomalyResSchema } from "../../api/useGetNoveltyAndAnomalyData";
import { Card } from "@/components/Layout/Card";
import { ContextInformation } from "../Layouts/ContextInformation";
import { humanizeString } from "../../utils/humanizeString";
import { VariableSelection } from "./VariableSelection";
import { ScatterAndLine } from "@/components/highcharts/scatter/ScatterAndLine";

export const NoveltyAnomalyAnalytic = ({
  props,
}: {
  props: NoveltyAnomalyResSchema;
}) => {
  const translate = useTranslate();
  const {
    analysisName,
    analysisType,
    frameName,
    motorName,
    contextInfo,
    data,
  } = props;

  const [selectedVariable, setSelectedVariable] = useState(
    data[0]?.analyticName || "",
  );

  if (data.length === 0) {
    return (
      <Card sx={{ height: 400 }}>
        <Typography>
          {translate("motors.no_data_for_following_selection")}
        </Typography>
        <Typography>
          {translate("analysis")}: {analysisName}
        </Typography>
        <Typography>
          {translate("frame")}: {frameName}
        </Typography>
        <Typography>
          {translate("motor")}: {motorName}
        </Typography>
      </Card>
    );
  }

  const dataset = data.find((el) => el.analyticName === selectedVariable) || {
    analyticName: "",
    rawData: [],
    outliers: [],
  };
  const { analyticName, rawData, outliers } = dataset;
  const subAnalyses = data.map((analysis) => analysis.analyticName);

  return (
    <Card>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ContextInformation
          props={contextInfo}
          key={`${analysisName}-${motorName}-${frameName}`}
        />
        <ScatterAndLine
          key={analyticName}
          title={humanizeString(analysisName)}
          scrollbarEnabled={false}
          legend={{ enabled: true }}
          zoomType="xy"
          tooltip={{
            enabled: true,
            options: {
              pointFormatter: function () {
                if (this.series.type === "scatter") {
                  return `
                ${translate("date")}: <b>${new Date(
                  this.x,
                ).toLocaleString()}</b><br>
                ${translate("value")}: <b>${this.y}</b>
              `;
                }
                return `<span style='color: ${this.color}'>● </span>${this.series.name}: <b>${this.y}</b>`;
              },
            },
          }}
          yAxis={[
            {
              uom: "",
              series: [
                {
                  type: "scatter",
                  zIndex: 1,
                  name:
                    analysisType === "anomaly"
                      ? translate("anomalies.anomaly_other")
                      : translate("anomalies.novelty_other"),
                  color: analysisType === "anomaly" ? "#dd8844" : "#ff00c3",
                  data: outliers,
                },
              ],
            },
          ]}
          lineSeries={[
            {
              type: "line",
              name: translate("raw_data"),
              color: "#7cb5ec",
              data: rawData,
            },
          ]}
        />
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <VariableSelection
            variables={subAnalyses}
            selectedVariable={selectedVariable}
            setSelectedVariable={setSelectedVariable}
          />
        </Box>
      </Box>
    </Card>
  );
};
