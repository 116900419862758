import { useFirebaseContext } from "@/context/firebase-context";
import { useLanguage } from "@/i18n/config";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type LandingResponse = ThingworxError | LandingData;

const CategoryCode = z.number().min(1).max(6).int();
export type CategoryCode = z.infer<typeof CategoryCode>;

const MachineLogic = z.enum(["static", "parametric"]);
export type MachineLogic = z.infer<typeof MachineLogic>;

const MachineType = z.enum(["cyclic", "process"]);
export type MachineType = z.infer<typeof MachineType>;

const MachineArray = z.object({
  stateColor: z.string(),
  factory: z.string(),
  factory_id: z.number(),
  machineLogic: MachineLogic,
  oeeLine: z.number(),
  line: z.string(),
  line_id: z.number(),
  machine: z.string(),
  machine_id: z.number(),
  company: z.string(),
  company_id: z.number(),
  hasAnomalies: z.boolean(),
  hasBatch: z.boolean(),
  hasSpeed: z.boolean(),
  machineTimezone: z.string(),
  /**
   * attualmente gli stati sono dinamici, quindi non tipizzabili con un enum
   */
  state: z.string(),
  categoryCode: CategoryCode,
  machineType: MachineType,
  oee: z.number(),
  lastConnection: z.number(),
  isPml: z.boolean(),
});

export type MachineArray = z.infer<typeof MachineArray>;

const LandingPageData = z.object({
  machineArray: z.array(MachineArray),
});

export type LandingPageData = z.infer<typeof LandingPageData>;

const LandingData = z.object({
  response: z.literal(true),
  landingPageData: LandingPageData,
});

export type LandingData = z.infer<typeof LandingData>;

export const useGetLandingData = () => {
  const { timeSelection } = useTimeSelection();
  const { user, appKey } = useFirebaseContext();
  const language = useLanguage();

  return useQuery<LandingData>({
    queryKey: ["landing-data", timeSelection, language],
    queryFn: async () => {
      const response = await FetchClient<
        {
          timeSelection: TimeSelection;
          account: string;
          language_id: string;
        },
        LandingResponse
      >({
        appKey,
        payload: {
          timeSelection,
          account: user?.email ?? "",
          language_id: language,
        },
        url: "dig.c.landingPage_thing/Services/getDataReact",
      });

      if (!response.response) throw new Error(response.errorString);

      return LandingData.parse(response);
    },
    refetchInterval: 10000,
    gcTime: 0,
  });
};
