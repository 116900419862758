import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { useLanguage } from "@/i18n/config";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { FetchClient } from "@/services/ApiClient";
import { ONE_SECOND } from "@/utils/durationsInMilliseconds";
import { ThingworxError } from "src/types/error";
import { aiSuggest, loss, oeeMachineStatus } from "../types";

export const longTermDataResult = z.object({
  response: z.literal(true),
  oeeMachineStatus: oeeMachineStatus,
  lineLosses: z.array(loss),
  machineLosses: z.array(loss),
  processLosses: z.array(loss),
  aiSuggest: z.array(aiSuggest),
});

export type LongTermDataResult = z.infer<typeof longTermDataResult>;

export type LongTermDataResponse = ThingworxError | LongTermDataResult;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  language_id: string;
};

export const useGetLongTermData = () => {
  const { machine } = useMachineContext();
  const { timeSelection } = useTimeSelection();
  const { appKey } = useFirebaseContext();
  const language = useLanguage();

  return useQuery<LongTermDataResult>({
    queryKey: [
      "efficiency-navigator-long-term",
      machine!.machine,
      timeSelection,
      language,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, LongTermDataResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
          timeSelection,
          language_id: language,
        },
        url: "dig.c.benchmark_thing/Services/getLongTermData",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return longTermDataResult.parse(response);
    },
    refetchInterval: ONE_SECOND * 30,
  });
};
