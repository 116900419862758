import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { ThingworxError } from "src/types/error";
import { FetchClient } from "@/services/ApiClient";
import { useLanguage } from "@/i18n/config";

export type AlarmsByRangeResponse = ThingworxError | AlarmsByRangeResult;

export const stateLogAlarm = z.object({
  start: z.number(),
  end: z.number(),
  alarmCode: z.number(),
  alarmDescription: z.string(),
});

export type StateLogAlarm = z.infer<typeof stateLogAlarm>;

const AlarmsByRangeResult = z.object({
  response: z.literal(true),
  data: z.array(stateLogAlarm),
});

export type AlarmsByRangeResult = z.infer<typeof AlarmsByRangeResult>;

type Payload = {
  machineName: string;
  dateStart: number;
  dateEnd: number;
  language_id: string
};

export const useGetAlarmsByRange = ({
  dateStart,
  dateEnd,
}: {
  dateStart: number;
  dateEnd: number;
}) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const language = useLanguage();

  return useQuery<AlarmsByRangeResult>({
    queryKey: ["state-log-alarms-data", machine!.machine, dateStart, dateEnd, language],
    queryFn: async () => {
      const response = await FetchClient<Payload, AlarmsByRangeResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
          dateStart,
          dateEnd,
          language_id: language,
        },
        url: "dig.c.stateLog_thing/Services/getAlarmsByRange",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = AlarmsByRangeResult.parse(response);

      return validatedResponse;
    },
  });
};
