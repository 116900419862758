import { CreateOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import styles from "./NewChatButton.module.css";
import { useNewChat } from "../../hooks/useNewChat";

export const NewChatButton = () => {
  const translate = useTranslate();
  const { startNewChat } = useNewChat();

  return (
    <Tooltip
      title={translate("intellecta.new_chat")}
      enterDelay={2000}
      leaveDelay={200}
    >
      <IconButton
        color="inherit"
        aria-label="new chat"
        onClick={startNewChat}
        className={styles.pulse}
      >
        <CreateOutlined />
      </IconButton>
    </Tooltip>
  );
};
