import { ChangeEvent, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Modal } from "@/components/Modal";
import {
  useRuleContext,
  useRuleDispatchContext,
} from "../context/RuleContextProvider";
import { Variable, useGetVariableList } from "../api/useGetVariableList";
import { useDisclosure } from "@/hooks/useDisclosure";
import { VariablesModal } from "./VariablesModal";
import { Conditions } from "./Conditions";
import { useCreateCondition } from "../api/useCreateCondition";
import { useCreateRule } from "../api/useCreateRule";
import { getSliderValue } from "../utils/getSliderValue";
import { toast } from "react-hot-toast";
import { EmailInput } from "./EmailInput";
import { TimeRange } from "../types";
import { useTranslate } from "@/i18n/config";

export const NewRuleModal = ({
  isOpen,
  close,
  machines,
}: {
  isOpen: boolean;
  close: () => void;
  machines: string[];
}) => {
  const [machine, setMachine] = useState(machines ? machines[0] : "");
  const [ruleName, setRuleName] = useState("");
  const [ruleDescription, setRuleDescription] = useState("");
  const [selectedMachineVariables, setSelectedMachineVariables] = useState<
    Variable[]
  >([]);
  const [timeRange, setTimeRange] = useState<TimeRange>("minutes");
  const [duration, setDuration] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [eventLooping, setEventLooping] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState<string[]>([]);
  const [severity, setSeverity] = useState(1);
  const { conditions } = useRuleContext();
  const dispatch = useRuleDispatchContext();
  const { data: machineVariables = [], isLoading } =
    useGetVariableList(machine);
  const { createConditions } = useCreateCondition();
  const { mutate: createRule } = useCreateRule();
  const {
    isOpen: isOpenVariablesModal,
    open: openVariablesModal,
    close: closeVariablesModal,
  } = useDisclosure();
  const translate = useTranslate();

  const handleReset = () => {
    dispatch({ type: "RESET_CONDITIONS" });
    setSelectedMachineVariables([]);
    setRuleName("");
    setRuleDescription("");
    setMachine(machines ? machines[0] : "");
    setTimeRange("minutes");
    setDuration(0);
    setIsActive(false);
    setEventLooping(false);
    setEmail([]);
    setSeverity(1);
  };

  const handleConfirm = async () => {
    if (emailError)
      return toast.error(translate("error.badly_formatted_email"));
    if (conditions.length === 0)
      return toast.error(translate("error.no_conditions_specified"));
    if (email.length === 0)
      return toast.error(translate("error.no_email_specified"));

    try {
      const ruleConditions = await createConditions({
        conditions,
        machineName: machine,
      });

      createRule(
        {
          ruleName,
          ruleDescription,
          machine,
          data: ruleConditions,
          recapTime: 0,
          timerange: getSliderValue({ duration, timeRange }),
          isEnabled: isActive,
          eventLooping,
          mailList: email,
          priority: severity,
        },
        {
          onSuccess(data) {
            if (!data.response)
              return toast.error(translate("events.failed_to_update_rule"));
            toast.success(translate("events.rule_updated_successfully"));
            handleReset();
            close();
          },
        },
      );
    } catch (error) {
      toast.error(translate("events.failed_to_update_rule"));
    }
  };

  const handleChangeMachine = (event: SelectChangeEvent) => {
    const machine = event.target.value as string;
    setMachine(machine);
    setSelectedMachineVariables([]);
  };

  const handleChangeRuleName = (event: ChangeEvent<HTMLInputElement>) => {
    setRuleName(event.target.value);
  };

  const handleChangeRuleDescription = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setRuleDescription(event.target.value);
  };

  const handleChangeEmail = (mailList: string[]) => {
    setEmail(mailList);
  };

  const handleAddEmail = (newMail: string) => {
    setEmail((prevState) => [...prevState, newMail]);
  };

  const handleChangeIsActive = (event: ChangeEvent<HTMLInputElement>) => {
    setIsActive(event.target.checked);
  };

  const handleChangeEventLooping = (event: ChangeEvent<HTMLInputElement>) => {
    setEventLooping(event.target.checked);
  };

  const handleChangeHasError = (hasError: boolean) => {
    setEmailError(hasError);
  };

  return (
    <Modal
      open={isOpen}
      onClose={close}
      maxWidth="xl"
      bodyContent={
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            padding: 1,
          }}
        >
          <TextField
            label={translate("events.rule_name")}
            size="small"
            value={ruleName}
            onChange={handleChangeRuleName}
          />
          <TextField
            label={translate("events.rule_description")}
            size="small"
            value={ruleDescription}
            onChange={handleChangeRuleDescription}
          />
          <FormControl fullWidth>
            <InputLabel id="select-machine-label">
              {translate("machine")}
            </InputLabel>
            <Select
              labelId="select-machine-label"
              id="select-machine"
              size="small"
              value={machine}
              label={translate("machine")}
              onChange={handleChangeMachine}
            >
              {machines.map((machine) => (
                <MenuItem key={machine} value={machine}>
                  {machine}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            disabled={isLoading}
            variant="contained"
            onClick={openVariablesModal}
          >
            {translate("events.add_condition")}
          </Button>
          {selectedMachineVariables.length > 0 && (
            <Conditions
              variables={selectedMachineVariables}
              machine={machine}
              setSelectedVariables={setSelectedMachineVariables}
            />
          )}
          {isOpenVariablesModal && (
            <VariablesModal
              isOpen={isOpenVariablesModal}
              close={closeVariablesModal}
              variables={machineVariables}
              selectedVariables={selectedMachineVariables}
              setSelectedVariables={setSelectedMachineVariables}
            />
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <FormControl sx={{ width: 200 }}>
              <InputLabel id="select-time-range-label">
                {translate("time_range")}
              </InputLabel>
              <Select
                labelId="select-time-range-label"
                id="select-time-range"
                value={timeRange}
                label={translate("time_range")}
                size="small"
                onChange={(e) => {
                  setDuration(0);
                  setTimeRange(e.target.value as TimeRange);
                }}
              >
                {["minutes", "hours", "days", "weeks"].map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography
              sx={{
                width: 40,
              }}
            >
              {duration}
            </Typography>
            <Slider
              key={timeRange}
              aria-label={translate("duration")}
              value={duration}
              onChange={(_, value) => {
                setDuration(typeof value === "number" ? value : value[0]);
              }}
              valueLabelDisplay="auto"
              min={0}
              max={
                timeRange === "minutes"
                  ? 60
                  : timeRange === "hours"
                  ? 24
                  : timeRange === "days"
                  ? 60
                  : 10
              }
            />
          </Box>
          <FormControlLabel
            label={translate("events.active")}
            control={
              <Checkbox checked={isActive} onChange={handleChangeIsActive} />
            }
          />
          <FormControlLabel
            label={translate("events.event_loop")}
            control={
              <Checkbox
                checked={eventLooping}
                onChange={handleChangeEventLooping}
              />
            }
          />
          <EmailInput
            emailList={email}
            onChangeEmailList={handleChangeEmail}
            onAddEmail={handleAddEmail}
            hasError={handleChangeHasError}
          />
          <FormControl fullWidth>
            <InputLabel id="select-severity-label">
              {translate("events.severity")}
            </InputLabel>
            <Select
              labelId="select-severity-label"
              id="select-severity"
              value={severity}
              label={translate("events.severity")}
              size="small"
              onChange={(e) => setSeverity(e.target.value as number)}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      }
      titleContent={translate("events.rule_definition")}
      actions={
        <>
          <Button variant="outlined" onClick={handleReset}>
            {translate("actions.reset")}
          </Button>
          <Button variant="outlined" onClick={close}>
            {translate("actions.cancel")}
          </Button>
          <Button variant="contained" onClick={handleConfirm}>
            {translate("actions.confirm")}
          </Button>
        </>
      }
      fullWidth
    />
  );
};
