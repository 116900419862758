import { useTranslate } from "@/i18n/config";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid2,
} from "@mui/material";
import { useGetImputationCategories } from "../../api/useGetImputationCategories";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { Card } from "@/components/Layout/Card";
import { ImputationSubCategoryRow } from "./ImputationSubCategoryRow";

export const ImputationCategoriesEditModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const { data, isLoading, error } = useGetImputationCategories();
  const translate = useTranslate();

  if (isLoading)
    return (
      <Dialog onClose={close} open={isOpen} maxWidth="lg" fullWidth>
        <DialogTitle>
          {translate("stop_imputation.machine_states_log_by_imputation")}
        </DialogTitle>
        <DialogContent>
          <SkeletonCard height={400} />
        </DialogContent>
      </Dialog>
    );

  if (error) return <div>{translate("user_feedback.an_error_occurred")}</div>;

  if (!data) return <div>{translate("user_feedback.no_data")}</div>;

  return (
    <Dialog onClose={close} open={isOpen} maxWidth="lg" fullWidth>
      <DialogTitle>
        {translate("stop_imputation.edit_imputation_sub_category")}
      </DialogTitle>
      <DialogContent>
        <Card sx={{ padding: 4 }}>
          <Grid2
            container
            columns={12}
            sx={{
              alignItems: "center",
              paddingY: 2,
            }}
          >
            <Grid2 size={2}>
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                ID
              </Typography>
            </Grid2>
            <Grid2 size={5}>
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                {translate("stop_imputation.sub_category")}
              </Typography>
            </Grid2>
            <Grid2 size={5}>
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                {translate("stop_imputation.category")}
              </Typography>
            </Grid2>
          </Grid2>
          {data.data.map((subCategory) => (
            <ImputationSubCategoryRow
              data={subCategory}
              categories={data.categories}
              key={subCategory.subCategoryId}
            />
          ))}
        </Card>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close}>
          {translate("actions.back")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
