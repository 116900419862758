import { Card } from "@/components/Layout/Card";
import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { useGetKpiStreamSelector } from "../../api/efficiency/useGetKpiStreamSelector";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { useTranslate } from "@/i18n/config";
import { useMachineContext } from "@/context/machine-context";
import { fileNameFactory } from "@/utils/fileNameFactory";

export const KpiSelector = () => {
  const { data, error, isLoading } = useGetKpiStreamSelector();
  const { machine } = useMachineContext();
  const machineName = machine?.machine;
  const translate = useTranslate();

  if (isLoading) {
    return <SkeletonCard height={300} />;
  }

  if (!data) {
    return null;
  }

  if (error) {
    return <Card>{translate("user_feedback.an_error_occurred")}</Card>;
  }

  return (
    <>
      <Card>
        <LineChart.Custom
          title={translate("kpi_chart")}
          filename={fileNameFactory({
            name: "kpi_chart",
            machine: machineName,
          })}
          legend={{ enabled: true }}
          xAxisType="datetime"
          xAxisOptions={{
            scrollbar: { enabled: false },
          }}
          yAxis={data?.data.map((result, i) => ({
            uom: "%",
            options: {
              opposite: i % 2 === 0 ? false : true,
              type: "linear",
              min: 0,
              max: 100,
              scrollbar: { enabled: false },
            },
            series: [
              {
                name: translate("kpi.availability"),
                type: "line",
                data: result.Availability,
                dashStyle: "LongDash",
                color: "#0394ad",
              },
              {
                name: translate("kpi.quality"),
                type: "line",
                data: result.Quality,
                dashStyle: "LongDash",
                color: "#3cbd5f",
              },
              {
                name: translate("kpi.performance"),
                type: "line",
                data: result.Performance,
                dashStyle: "LongDash",
                color: "#02a39b",
              },
              {
                name: translate("kpi.oee"),
                type: "line",
                data: result.Oee,
                color: "#4df79a",
              },
            ],
          }))}
          tooltip={{ enabled: true, options: { valueSuffix: "%" } }}
        />
      </Card>
    </>
  );
};
