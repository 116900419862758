import { toast } from "react-hot-toast";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { AccountCircleOutlined, NoAccountsOutlined } from "@mui/icons-material";
import { useAskConfirmAction } from "@/hooks/ask-confirm-action";
import { useTranslate } from "@/i18n/config";
import { useToggleEnableUser } from "../../../../lambda/useToggleEnableUser";

export const ToggleEnabledCell = ({
  isEnabled,
  user,
}: {
  isEnabled: boolean;
  user: string;
}) => {
  const translate = useTranslate();
  const { mutate: toggleEnableUser } = useToggleEnableUser();
  const { askConfirm, renderConfirmModal } = useAskConfirmAction(
    isEnabled
      ? translate("user_feedback.disable_user", { user })
      : translate("user_feedback.enable_user", { user }),
    () =>
      toggleEnableUser(
        { email: user, isDisabled: !isEnabled },
        {
          onSuccess() {
            if (isEnabled) {
              toast.success(
                translate("user_feedback.user_disabled_successfully"),
              );
            } else {
              toast.success(
                translate("user_feedback.user_enabled_successfully"),
              );
            }
          },
          onError() {
            toast.error(translate("user_feedback.an_error_occurred"));
          },
        },
      ),
  );

  return (
    <>
      <GridActionsCellItem
        icon={
          isEnabled ? (
            <AccountCircleOutlined />
          ) : (
            <NoAccountsOutlined color="error" />
          )
        }
        label={translate("actions.toggle_switch")}
        onClick={askConfirm}
      />
      {renderConfirmModal()}
    </>
  );
};
