import { useFirebaseContext } from "@/context/firebase-context";
import { useTranslate } from "@/i18n/config";
import { FetchClient } from "@/services/ApiClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { ThingworxError } from "src/types/error";

type Payload = {
  account: string;
  name: string;
  chart_id: number;
};

type Response = ThingworxError | { response: true };

export const useEditChartName = () => {
  const translate = useTranslate();
  const { appKey, user } = useFirebaseContext();
  const email = user?.email as string;

  const client = useQueryClient();
  return useMutation({
    mutationFn: async ({
      name,
      chart_id,
    }: {
      name: string;
      chart_id: number;
    }) => {
      const response = await FetchClient<Payload, Response>({
        appKey,
        payload: {
          account: email,
          name,
          chart_id,
        },
        url: "dig.c.customChartsDev_thing/services/updateChartName",
      });
      if (!response.response) {
        throw new Error(response.errorString);
      }

      return response;
    },
    onSuccess(data) {
      if (!data.response) {
        toast.error(translate("custom_charts.failed_to_update_chart"));
      } else {
        toast.success(translate("custom_charts.chart_updated_successfully"));

        client.invalidateQueries({ queryKey: ["user-chart", email] });
      }
    },
  });
};
