import { create } from "zustand";
import { Notification, RightDrawerTab } from "../types";

export const socialStore = create<{
  notifications: Notification[];
  addNotification: (notification: Notification) => void;
  hasNewSocialNotification: boolean;
  setHasNewSocialNotification: (value: boolean) => void;
  isRightDrawerOpen: boolean;
  setIsRightDrawerOpen: (open: boolean) => void;
  activeRightDrawerTab: RightDrawerTab;
  setActiveRightDrawerTab: (tab: RightDrawerTab) => void;
}>((set) => ({
  notifications: [],
  addNotification: (notification) =>
    set((state) => ({ notifications: [...state.notifications, notification] })),
  hasNewSocialNotification: false,
  setHasNewSocialNotification: (value) =>
    set(() => ({ hasNewSocialNotification: value })),
  isRightDrawerOpen: true,
  setIsRightDrawerOpen: (open) => set(() => ({ isRightDrawerOpen: open })),
  activeRightDrawerTab: "historic",
  setActiveRightDrawerTab: (tab) => set(() => ({ activeRightDrawerTab: tab })),
}));
