import { Box } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { TypewriterText } from "../TypewriterText";

export const ShortTermSuggestionHistoric = () => {
  const translate = useTranslate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "auto",
        p: 1,
      }}
    >
      {/* Testo con effetto macchina da scrivere */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.5rem",
          alignItems: "flex-start",
          mt: 1,
        }}
      >
        <TypewriterText
          text={translate(
            "user_feedback.advanced_analysis_available_only_live_data",
          )}
          maxFontSize={14}
          minFontSize={14}
          iconSrc="/assets/img/intellecta-logo.png"
          iconAlt="Intellecta logo"
        />
      </Box>
    </Box>
  );
};
