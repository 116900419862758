import { Arearange } from "@/components/highcharts/arearange/Arearange";
import { Card } from "@/components/Layout/Card";
import { SentinelLoader } from "@/components/Layout/SentinelLoader";
import { Stack } from "@mui/material";
import { useState } from "react";
import { AdvancedAnalyticEvaluationInfo } from "../api/useGetAdvancedAnalysisList";
import { useGetEnvelopeAnalyticData } from "../api/useGetEnvelopeAnalyticData";
import { AdvancedAnalyticsList } from "./AdvancedAnalyticsList";

export const EnvelopeAnalytic = ({
  analytics,
}: {
  analytics: AdvancedAnalyticEvaluationInfo[];
}) => {
  const [selectedAnalytic, setSelectedAnalytic] =
    useState<AdvancedAnalyticEvaluationInfo>();

  return (
    <>
      <AdvancedAnalyticsList
        items={analytics.map((analytic) => ({
          id_value: analytic.id,
          ...analytic,
        }))}
        renderTextItem={(item) => item.name}
        onItemClicked={(item) => {
          setSelectedAnalytic(item);
        }}
        activeId={selectedAnalytic?.id}
      />
      {selectedAnalytic ? <EnvelopeChart analytic={selectedAnalytic} /> : null}
    </>
  );
};

function EnvelopeChart({
  analytic,
}: {
  analytic: AdvancedAnalyticEvaluationInfo;
}) {
  const { isLoading, data = { datapoints: [], envelopePoints: [] } } =
    useGetEnvelopeAnalyticData({ analytic });

  return (
    <Card>
      {isLoading ? (
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "center",
            gap: 4,
            flex: 1,
            p: 4,
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <SentinelLoader />
          <span style={{ display: "block" }}>Loading ...</span>
        </Stack>
      ) : (
        <Arearange.Custom
          // key={analytic.id}
          title={analytic.name}
          xAxisOptions={{
            type: "datetime",
          }}
          yAxis={{
            uom: analytic.uom,
            series: { type: "line", data: data.datapoints },
            areaRangeSeries: {
              type: "arearange",
              dashStyle: "Solid",
              borderWidth: 10,
              lineWidth: 3,
              color: "#225Fff20",
              data: data.envelopePoints,
            },
          }}
        />
      )}
    </Card>
  );
}
