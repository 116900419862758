import { BasicTable } from "@/components/BasicTable";
import { Box, Tab, Tabs } from "@mui/material";
import { AlarmStream, Result } from "../api/useGetDownloadData";
import { TableParser } from "@/utils/TableParser";
import { DataTransformer } from "@/utils/DataTransformer";
import { Card } from "@/components/Layout/Card";
import { useState } from "react";
import { AlarmDetails } from "../utils/parse-cyclic-data";
import { useTranslate } from "@/i18n/config";
import { useFormatTimestamp } from "@/utils/TimeHelpers";

const alarmGenres = [
  {
    title: "Alarm Count Duration",
    id: 0,
  },
  {
    title: "Alarm Stream",
    id: 1,
  },
] as const;

export const AlarmModalContent = ({
  alarmDetailsContent,
}: {
  alarmDetailsContent: AlarmDetails;
}) => {
  const translate = useTranslate();
  const [selectedAlarm, setSelectedAlarm] = useState<{
    title: string;
    id: number;
  }>(alarmGenres[0]);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setSelectedAlarm(alarmGenres[newValue]);
  };
  const { alarmSummary, alarmStream } = alarmDetailsContent;
  return (
    <>
      <Box
        sx={{
          paddingBottom: 2,
        }}
      >
        <Tabs
          value={selectedAlarm.id}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="download tabs"
        >
          {alarmGenres.map((alarm) => (
            <Tab
              label={translate(
                alarm.title === "Alarm Count Duration"
                  ? "download.alarm_count_duration"
                  : "download.alarm_stream",
              )}
              key={alarm.id}
            />
          ))}
        </Tabs>
      </Box>
      {selectedAlarm.id === 0 ? (
        <SummaryContent alarmSummaryContent={alarmSummary} />
      ) : (
        <StreamContent alarmStreamContent={alarmStream} />
      )}
    </>
  );
};

interface TransformedAlarmSummary {
  ErrorDescription: string;
  MTTRduration: number;
  CountPercentage: number;
  Error: number;
  Duration: number;
  Count: number;
  timeAmountPercentage: number;
  MTBFduration: number;
}
const SummaryContent = ({
  alarmSummaryContent,
}: {
  alarmSummaryContent: Result[];
}) => {
  const translate = useTranslate();

  const { columns, rows } =
    alarmSummaryContent.length > 0
      ? TableParser.parseData<TransformedAlarmSummary>({
          data: alarmSummaryContent.map((item) =>
            DataTransformer.dataTransformer<Result, TransformedAlarmSummary>({
              input: {
                ...item,
                CountPercentage: Number(item.CountPercentage.toFixed(2)),
                DurationPercentage: Number(item.DurationPercentage.toFixed(2)),
                MTTR: item.MTTR * 1000,
                MTBF: item.MTBF * 1000,
                Duration: item.Duration * 1000,
              },
              transform: {
                MTTR: "MTTRduration",
                MTBF: "MTBFduration",
                DurationPercentage: "timeAmountPercentage",
              },
            }),
          ),
          columnsMap: {
            Error: translate("code"),
            ErrorDescription: translate("description"),
            Count: translate("occurrences"),
            CountPercentage: translate("occurrences") + " %",
            MTBFduration: translate("mtbf"),
            MTTRduration: translate("mttr"),
            Duration: translate("duration"),
            timeAmountPercentage: translate("duration") + " %",
          },
          columnsOrder: [
            "Error",
            "ErrorDescription",
            "Count",
            "CountPercentage",
            "MTBFduration",
            "timeAmountPercentage",
            "Duration",
            "MTTRduration",
          ],
          // omitColumns: ["CumulativeCount", "CumulativeDuration", "Family"],
        })
      : { columns: [], rows: [] };
  return (
    <Card>
      <BasicTable
        columns={columns}
        rows={rows}
        fileName="alarms_summary_data"
      />
    </Card>
  );
};

const StreamContent = ({
  alarmStreamContent,
}: {
  alarmStreamContent: AlarmStream[];
}) => {
  const translate = useTranslate();
  const { formatTimestamp } = useFormatTimestamp();

  const { columns, rows } =
    alarmStreamContent.length > 0
      ? TableParser.parseData<AlarmStream>({
          data: alarmStreamContent,
          columnsMap: {
            EfficiencyFamilyDescription: translate("efficiency_category"),
            Error: translate("error"),
            ErrorDescription: translate("error_description"),
            FamilyDescription: translate("family_description"),
            Timestamp: "Timestamp",
            ZoneDescription: translate("zone_description"),
          },
          columnsOrder: [
            "Timestamp",
            "Error",
            "ErrorDescription",
            "FamilyDescription",
            "EfficiencyFamilyDescription",
            "ZoneDescription",
          ],
          dateFormatter: formatTimestamp,
        })
      : { columns: [], rows: [] };
  return (
    <Card>
      <BasicTable columns={columns} rows={rows} fileName="alarms_stream_data" />
    </Card>
  );
};
