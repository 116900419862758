import { ListSelectDropdown } from "@/components/ListSelectDropdown";
import { Box, MenuItem, Stack, Typography } from "@mui/material";
import { useGetEdgePcList } from "../api/useGetEdgePcList";
import {
  useA4GateContext,
  useA4GateContextDispatch,
} from "../context/a4gate-context";

export const PCListDropdown = () => {
  const { data: edgePcList } = useGetEdgePcList();
  const { setSelectedPC } = useA4GateContextDispatch();
  const { selectedPC } = useA4GateContext();
  return (
    <>
      {edgePcList && (
        <>
          <ListSelectDropdown
            menuProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    height: "25rem",
                  },
                },
              },
            }}
            data={edgePcList.listOfEdgePc}
            textlabel="PC"
            onChange={(edgePc) => setSelectedPC(edgePc)}
            defaultValue={selectedPC}
            renderItem={(item) => (
              <MenuItem key={item.edgePc} value={item.edgePc} divider>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    width: "100%",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "start",
                    }}
                  >
                    {item.edgePc}
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: item.isConnected ? "green" : "red",
                      color: "white",
                      height: "5px",
                      padding: "4px 4px",
                      borderRadius: "9999px",
                      aspectRatio: "1/1",
                      alignContent: "end",
                    }}
                  ></Box>
                </Stack>
              </MenuItem>
            )}
          />
        </>
      )}
    </>
  );
};
