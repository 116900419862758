import { ItemCard } from "@/components/ItemCard";
import { ListSelectDropdown } from "@/components/ListSelectDropdown";
import { NavButton } from "@/components/NavButton";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { ArrowBackOutlined } from "@mui/icons-material";
import { Button, Grid2, MenuItem, Stack, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { AcceptanceTestContentWrapper } from "../..";
import { useGetAcceptanceTestData } from "../../api/useGetAcceptanceTestData";
import { useGetLastSats } from "../../api/recent-sats/useGetLastSats";
import { useGetOeeStream } from "../../api/useGetOeeStream";
import { ControlPanel } from "../../components/ControlPanel";
import {
  AcceptanceTestContextProvider,
  useAcceptanceTestContext,
  useAcceptanceTestContextDispatch,
} from "../../context/acceptanceTest-context";
import { AcceptanceTestLoader } from "../../layout/AcceptanceTestLoader";
import { HistoricView } from "../HistoricView/AcceptanceTestHistoricView";
import { Loader } from "@/components/Layout/Loader";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { Card } from "@/components/Layout/Card";
import { SentinelLoader } from "@/components/Layout/SentinelLoader";
import { useTranslate } from "@/i18n/config";

const AcceptanceTestLive = () => {
  const {
    satConfiguration,
    satConfigurationDisabled,
    isSatActive,
    isSelectedPeriod,
    hasActivatedRequestAT,
    isClosedSat,
  } = useAcceptanceTestContext();
  const dispatch = useAcceptanceTestContextDispatch();
  const translate = useTranslate();
  const values = [
    { item: "Blocked", label: translate("acceptance_test.blocked") },
    { item: "Free", label: translate("acceptance_test.free") },
  ];

  return (
    <>
      {!isSelectedPeriod ? (
        <MachineViewNavBar>
          <ListSelectDropdown
            disabled={satConfigurationDisabled}
            textlabel={translate("actions.select")}
            defaultValue={
              satConfiguration === "Blocked"
                ? translate("acceptance_test.blocked")
                : translate("acceptance_test.free")
            }
            onChange={(item) => {
              if (item === "Blocked" || item === "Free")
                dispatch({ type: "sat-configuration", satConfiguration: item });
            }}
            data={values}
            renderItem={(item) => (
              <MenuItem key={item.item} value={item.label}>
                <Stack>
                  <Typography>{item.label}</Typography>
                </Stack>
              </MenuItem>
            )}
          />
          <NavButton
            to="/acceptance-test/historic"
            text={translate("view_historic")}
          />
        </MachineViewNavBar>
      ) : (
        <MachineViewNavBar>
          <Button
            startIcon={<ArrowBackOutlined />}
            onClick={() =>
              dispatch({ type: "selected-period", isSelectedPeriod: false })
            }
          >
            {translate("go_back_to_select")}
          </Button>
        </MachineViewNavBar>
      )}
      {isClosedSat && isSatActive ? (
        <Loader isLoading />
      ) : isSatActive && !hasActivatedRequestAT ? (
        <UploadDataWrapper />
      ) : (
        <StartTestGuide />
      )}
    </>
  );
};

const UploadDataWrapper = () => {
  const { isSatActive } = useAcceptanceTestContext();
  const dispatch = useAcceptanceTestContextDispatch();

  const { data: liveData, isLoading } = useGetAcceptanceTestData({
    isSatActive,
  });
  const { data: oeeStreamData } = useGetOeeStream({
    isSatActive,
  });

  useEffect(() => {
    if (liveData) {
      dispatch({
        type: "get-startUtc",
        startUtc: liveData.ValoriGenerali[0].startTimeUTC,
      });
      dispatch({
        type: "sat-configuration",
        satConfiguration: liveData.ValoriGenerali[0].isLimitedAT
          ? "Blocked"
          : "Free",
      });
    }
  }, [liveData]);

  return (
    <>
      {isLoading ? <AcceptanceTestLoader /> : null}
      {isSatActive && liveData?.ValoriRelated.length && oeeStreamData ? (
        <AcceptanceTestContentWrapper
          data={{
            tableData: liveData.DatiTabella,
            generalInfos: liveData.ValoriGenerali,
            customerInfo: liveData.ValoriUnrelated,
            machineInfo: liveData.ValoriRelated,
            isMachineParametric: liveData.isMachineParametric,
            oeeStream: oeeStreamData?.result,
            viewMode: "Live",
          }}
        />
      ) : null}
    </>
  );
};

const StartTestGuide = () => {
  const { formatTimestamp } = useFormatTimestamp();
  const dispatch = useAcceptanceTestContextDispatch();
  const { isSelectedPeriod, hasActivatedRequestAT } =
    useAcceptanceTestContext();
  const [selectedPeriod, setSelectedPeriod] = useState<{
    id: number;
    dataStartTimeLapse: number;
    dataEndTimeLapse: number;
    timeLapse: string;
  } | null>(null);

  const { data: list } = useGetLastSats();
  useEffect(() => {
    setSelectedPeriod(null);
    dispatch({ type: "selected-period", isSelectedPeriod: false });
  }, [list, dispatch]);

  const translate = useTranslate();

  return (
    <>
      {selectedPeriod && isSelectedPeriod ? (
        <>
          <AcceptanceTestContextProvider>
            <HistoricView
              id={selectedPeriod.id.toString()}
              startTime={selectedPeriod.dataStartTimeLapse}
              endTime={selectedPeriod.dataEndTimeLapse}
            />
          </AcceptanceTestContextProvider>
        </>
      ) : (
        <Stack
          direction="row"
          sx={{
            gap: 2,
            justifyContent: "center",
          }}
        >
          <Stack
            direction="column"
            sx={{
              gap: 2,
              width: "50%",
            }}
          >
            <Stack spacing={2}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                }}
              >
                {translate("acceptance_test.recent_sat", {
                  count: list?.Result.length,
                })}
              </Typography>
              <AnimatePresence presenceAffectsLayout mode="wait">
                <motion.div
                  key={JSON.stringify(list?.Result.length)}
                  variants={{
                    hidden: { opacity: 0 },
                    show: {
                      opacity: 1,
                    },
                  }}
                  initial="hidden"
                  animate="show"
                >
                  <Grid2 container spacing={2}>
                    {list ? (
                      list?.Result.map((item) => (
                        <Grid2
                          key={item.Id}
                          size={{
                            xs: 12,
                            md: 6,
                          }}
                        >
                          <ItemCard
                            item={item}
                            onClick={(i) => {
                              setSelectedPeriod({
                                dataStartTimeLapse: i.DataStartTimeLapse,
                                dataEndTimeLapse: i.DataEnd,
                                id: i.Id,
                                timeLapse: i.TimeLapse,
                              });
                              dispatch({
                                type: "selected-period",
                                isSelectedPeriod: true,
                              });
                            }}
                            subtitle={`${translate(
                              "acceptance_test.started",
                            )}: ${formatTimestamp({
                              timestamp: item.DataStartTimeLapse,
                              fmt: "DD HH:mm:ss ",
                            })}`}
                            title={formatTimestamp({
                              timestamp: item.DataStartTimeLapse,
                              fmt: "DDD",
                            })}
                            optionalLabel={`${translate(
                              "acceptance_test.ended",
                            )}: ${formatTimestamp({
                              timestamp: item.DataEnd,
                              fmt: "DD HH:mm:ss ",
                            })}`}
                            isAcceptanceTest
                          />
                        </Grid2>
                      ))
                    ) : (
                      <>
                        <Grid2
                          size={{
                            xs: 12,
                            md: 6,
                          }}
                        >
                          <SkeletonCard height={80} />
                        </Grid2>
                        <Grid2
                          size={{
                            xs: 12,
                            md: 6,
                          }}
                        >
                          <SkeletonCard height={80} />
                        </Grid2>
                        <Grid2
                          size={{
                            xs: 12,
                            md: 6,
                          }}
                        >
                          <SkeletonCard height={80} />
                        </Grid2>
                        <Grid2
                          size={{
                            xs: 12,
                            md: 6,
                          }}
                        >
                          <SkeletonCard height={80} />
                        </Grid2>
                        <Grid2
                          size={{
                            xs: 12,
                            md: 6,
                          }}
                        >
                          <SkeletonCard height={80} />
                        </Grid2>
                      </>
                    )}
                  </Grid2>
                </motion.div>
              </AnimatePresence>
            </Stack>
          </Stack>
          <Stack
            sx={{
              gap: 2,
              width: "50%",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                zIndex: 100,
              }}
            >
              {translate("acceptance_test.new")} SAT
            </Typography>
            <ControlPanel />
            {!hasActivatedRequestAT ? null : (
              <Card>
                <Stack
                  direction={"column"}
                  sx={{
                    gap: 2,
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6">
                    {translate("acceptance_test.will_start")}
                  </Typography>
                  <SentinelLoader />
                </Stack>
              </Card>
            )}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export const AcceptanceTestLiveScreen = () => {
  return (
    <AcceptanceTestContextProvider>
      <AcceptanceTestLive />
    </AcceptanceTestContextProvider>
  );
};
