import { ShiftsConfiguration } from "@/features/ShiftsConfiguration/types";
import { TranslationKey } from "@/i18n/config";
import { EventInput } from "@fullcalendar/core";
import { DateTime } from "luxon";

interface ShiftData {
  start: number | undefined;
  end: number | undefined;
  name: string;
  is_locked: boolean;
}

export function getFixedOffsetFromIANA(ianaZone: string) {
  // Usiamo una data fissa di gennaio per evitare DST
  const date = new Date("2024-01-01T00:00:00");

  // Otteniamo l'offset in minuti usando Intl
  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZone: ianaZone,
    timeZoneName: "longOffset",
  });

  // Estraiamo l'offset dalla stringa formattata
  const timeZoneOffset = formatter.format(date).split(" ").pop();
  const offsetMatch = timeZoneOffset?.match(/GMT([+-]\d{2}):(\d{2})/);

  if (offsetMatch) {
    const hours = parseInt(offsetMatch[1]);
    return `UTC${hours >= 0 ? "+" : "-"}${Math.abs(hours)}`;
  }

  return "UTC+0";
}

export function createEventsFromDbData({
  data,
  translate,
  timezone,
}: {
  data: ShiftsConfiguration[];
  translate: (key: TranslationKey) => string;
  timezone: string;
}): EventInput[] {
  if (!data || !timezone) return [];

  const events: EventInput[] = [];

  data.forEach((shiftConfig, dataIndex) => {
    const shifts: ShiftData[] = [
      {
        start: shiftConfig.shift_1_start,
        end: shiftConfig.shift_1_end,
        name: `${translate("time_selection.Shift")} 1`,
        is_locked: shiftConfig.is_locked,
      },
      {
        start: shiftConfig.shift_2_start,
        end: shiftConfig.shift_2_end,
        name: `${translate("time_selection.Shift")} 2`,
        is_locked: shiftConfig.is_locked,
      },
      {
        start: shiftConfig.shift_3_start,
        end: shiftConfig.shift_3_end,
        name: `${translate("time_selection.Shift")} 3`,
        is_locked: shiftConfig.is_locked,
      },
      {
        start: shiftConfig.shift_4_start,
        end: shiftConfig.shift_4_end,
        name: `${translate("time_selection.Shift")} 4`,
        is_locked: shiftConfig.is_locked,
      },
    ];

    shifts.forEach((shift, shiftIndex) => {
      if (!shift.start || !shift.end) return;

      try {
        const startDate = DateTime.fromMillis(shift.start).setZone(
          getFixedOffsetFromIANA(timezone),
        );
        const endDate = DateTime.fromMillis(shift.end).setZone(
          getFixedOffsetFromIANA(timezone),
        );

        if (!startDate.isValid || !endDate.isValid) {
          console.error("Invalid date detected:", {
            shift,
            startDate,
            endDate,
          });
          return;
        }

        events.push({
          id: `event-${dataIndex}-${shiftIndex}`,
          title: shift.name,
          start: startDate.toISO(),
          end: endDate.toISO(),
          allDay: false,
          is_locked: shift.is_locked,
          eventOffset: getFixedOffsetFromIANA(timezone),
        });
      } catch (error) {
        console.error("Error creating event:", error, { shift });
      }
    });
  });

  return events;
}
