import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { CategoryCode } from "@/features/MachineDetail/api/CyclicLive/useGetSentinelLiveData";
import { useLanguage } from "@/i18n/config";

type Payload = {
  machineName: string;
  timezoneOffset: number;
  utc: string;
  language_id: string;
};

export const DatiTabella = z.object({
  Id: z.number(),
  Stop: z.number(),
  Restart: z.number(),
  State: z.number(),
  StateString: z.string(),
  ErrorCode: z.number(),
  ErrorDescription: z.string(),
  Comment: z.string(),
  Duration: z.number(),
  Who: z.number(),
  deadTime: z.boolean(),
  color: z.string(),
  categoryCode: z.number(),
});

export type DatiTabella = z.infer<typeof DatiTabella>;

const ValoriGenerali = z.object({
  Availability: z.number(),
  SatDuration: z.number(),
  endTimeUTC: z.number(),
  color: z.string(),
  GoodProducts: z.number(),
  StartTime: z.number(),
  OEE: z.number(),
  Format: z.string(),
  Quality: z.number(),
  SetpointSpeed: z.number(),
  isActiveSat: z.boolean(),
  state: z.string(),
  Performance: z.number(),
  categoryCode: CategoryCode,
  startTimeUTC: z.number(),
  isLimitedAT: z.boolean(),
});
export type ValoriGenerali = z.infer<typeof ValoriGenerali>;

const ValoriRelated = z.object({
  ManualCorrectionRelated: z.number(),
  RelatedDownTimeSs: z.number(),
  RelatedRejects: z.number(),
  RelatedSpeedLosses: z.number(),
});
export type ValoriRelated = z.infer<typeof ValoriRelated>;

const ValoriUnrelated = z.object({
  ManualCorrectionUnrelated: z.number(),
  UnrelatedDownTimeSs: z.number(),
  UnrelatedRejects: z.number(),
  UnrelatedSpeedLosses: z.number(),
});
export type ValoriUnrelated = z.infer<typeof ValoriUnrelated>;

export const AcceptanceTestDataResult = z.object({
  DatiTabella: z.array(DatiTabella),
  ValoriGenerali: z.array(ValoriGenerali),
  ValoriRelated: z.array(ValoriRelated),
  ValoriUnrelated: z.array(ValoriUnrelated),
  isMachineParametric: z.boolean(),
  response: z.literal(true),
});
export type AcceptanceTestDataResult = z.infer<typeof AcceptanceTestDataResult>;

type AcceptanceTestDataResponse = ThingworxError | AcceptanceTestDataResult;

export const useGetAcceptanceTestData = ({
  isSatActive,
}: {
  isSatActive: boolean;
}) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const language = useLanguage();
  // TODO: hardcoded timezone and utc
  const payload = {
    machineName: machine!.machine,
    timezoneOffset: new Date().getTimezoneOffset(),
    utc: "user",
    language_id: language,
  };
  return useQuery<AcceptanceTestDataResult>({
    queryKey: ["acceptance-test-data", machine?.machine, language],
    queryFn: async () => {
      const response = await FetchClient<Payload, AcceptanceTestDataResponse>({
        appKey,
        payload,
        url: "dig.c.acceptanceTest_thing/Services/getData",
      });
      if (!response.response) throw new Error(response.errorString);
      return AcceptanceTestDataResult.parse(response);
    },
    enabled: isSatActive,
    refetchInterval: isSatActive ? 20 * 1000 : false,
  });
};
