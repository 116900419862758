import { useMutation, useQueryClient } from "@tanstack/react-query";
import { gCloudClient } from "@/services/gCloudClient";
import { encode } from "@/utils/b64_encoder";

export const useToggleEnableUser = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async ({
      email,
      isDisabled,
    }: {
      email: string;
      isDisabled: boolean;
    }) => {
      await gCloudClient<{ email: string }, null>({
        service: "TOGGLE_ENABLE_USER",
        queryStrings: {
          email: encode(email),
          isDisabled: isDisabled ? "true" : "false",
        },
      });
    },
    onSuccess() {
      client.invalidateQueries({ queryKey: ["get-colleagues"] });
    },
  });
};
