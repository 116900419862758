import { ShowEmptyData } from "@/components/ShowEmptyData";
import { BasicTable } from "@/components/BasicTable";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { TableParser } from "@/utils/TableParser";
import { Box } from "@mui/material";
import {
  useGetChangeOverList,
  ChangeOverInfo,
} from "../api/useGetChangeOverList";
import { DataTransformer } from "@/utils/DataTransformer";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { useFormatTimestamp } from "@/utils/TimeHelpers";

export const ChangeOverList = () => {
  const translate = useTranslate();
  const { formatTimestamp } = useFormatTimestamp();
  const { data: changeOverList, isLoading } = useGetChangeOverList();

  if (isLoading) return <AnalyticContainerSkeleton />;

  if (!changeOverList) return null;

  if (changeOverList.length === 0) {
    return <ShowEmptyData title={translate("change_over")} />;
  }

  const { columns, rows } = TableParser.parseData<ChangeOverInfo>({
    data: changeOverList.map((item) =>
      DataTransformer.dataTransformer({
        input: {
          ...item,
          ChangeDuration: item.ChangeDuration * 1000,
        },
        transform: {},
      }),
    ),
    columnsMap: {
      ChangeDuration: translate("change_over.change_duration"),
      Machine: translate("machine"),
      NewRecipe: translate("machine.new_recipe"),
      OldRecipe: translate("machine.old_recipe"),
      State: translate("state"),
      Timestamp: translate("date"),
    },
    columnsOrder: [
      "Timestamp",
      "Machine",
      "OldRecipe",
      "NewRecipe",
      "State",
      "ChangeDuration",
    ],
    dateFormatter: formatTimestamp,
  });

  const stateColumnDefinition = columns.findIndex(
    (item) => item.field === "State",
  );

  if (stateColumnDefinition !== -1) {
    columns[stateColumnDefinition].renderCell = (params) => {
      const { value } = params;
      return (
        <Box
          sx={{
            backgroundColor: value === "Start" ? "green" : "red",
            color: "white",
            padding: "8px 8px",
            borderRadius: "9999px",
            aspectRatio: "1/1",
          }}
        />
      );
    };
  }

  return (
    <Card>
      <BasicTable columns={columns} rows={rows} fileName="change_over_data" />
    </Card>
  );
};
