/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState } from "react";
import { HighchartsReact } from "highcharts-react-official";

import Highcharts from "highcharts/highstock";
import {
  Chart,
  SeriesClickEventObject,
  XrangePointOptionsObject,
} from "highcharts";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { useTranslate } from "@/i18n/config";
import { useDisclosure } from "@/hooks/useDisclosure";
import { RunStatesModal } from "../Summary/RunStatesModal";

interface XRangeContextMenuProps {
  data: XrangePointOptionsObject[];
  onClickRun: ({ start, end }: { start: number; end: number }) => void;
}

export const XRangeWithContextMenu = ({
  data,
  onClickRun,
}: XRangeContextMenuProps) => {
  const translate = useTranslate();
  const { formatTimestamp } = useFormatTimestamp();
  const [dateStart, setDateStart] = useState<number | null>(null);
  const [dateEnd, setDateEnd] = useState<number | null>(null);
  const { open, close, isOpen } = useDisclosure();
  const chartOptions = {
    chart: {
      type: "xrange",
      plotBorderWidth: 0,
      backgroundColor: "transparent",
      style: {
        fontFamily: "Montserrat",
        color: "white",
      },
      zoomType: "x",
      resetButton: {
        position: {
          align: "right",
          verticalAlign: "top",
          x: -30,
          y: 0,
        },
        relativeTo: "plotBox",
      },
      height: 100,
      events: {
        load: function () {
          const chart = this as Partial<Chart>;
          const points = chart.series ? chart.series[0].points : [];

          points.forEach((point) => {
            point.graphic?.element.addEventListener("contextmenu", (e) => {
              e.preventDefault();
              setDateStart(point.x);
              setDateEnd(point.x2!);
              open();
            });
          });
        },
      },
    },
    title: {
      text: "",
      // text: `${title} - ${translate("event_occurrences")}`,
      // align: "left",
      style: {
        color: "white",
      },
    },
    xAxis: {
      type: "datetime",
      // categories: xCategories,
      gridLineColor: "transparent",
      // lineColor: "transparent",
    },
    yAxis: {
      categories: [translate("acopos.run")],
      gridLineColor: "transparent",
      // lineColor: "transparent",
      title: null,
      // reversed: true,
      labels: {
        style: {
          color: "white",
        },
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      // @ts-ignore
      formatter: function (this) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias, @typescript-eslint/no-explicit-any
        const point: any = this;
        const dateStart = formatTimestamp({
          timestamp: point.x,
        });
        const dateEnd = formatTimestamp({
          timestamp: point.x2,
        });
        const color = point.color;
        const category = point.key;
        return `${translate("start")}: ${dateStart}<br>
          ${translate("end")}: ${dateEnd}<br>
          <span style="color:${color}">●</span> <b>${category}</b>
        `;
      },
      outside: true,
    },
    series: [
      {
        name: translate("acopos.run_other"),
        data,
        borderColor: "transparent",
        cursor: "pointer",
        events: {
          click: function (event: SeriesClickEventObject) {
            const point: XrangePointOptionsObject = event.point;
            onClickRun({ start: point.x!, end: point.x2! });
          },
        },
      },
    ],
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    accessibility: { enabled: false },
  };

  return (
    <>
      {isOpen && dateStart && dateEnd && (
        <RunStatesModal
          close={close}
          isOpen={isOpen}
          dateStart={dateStart}
          dateEnd={dateEnd}
        />
      )}
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </>
  );
};
