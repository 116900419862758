import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import {
  IntellectaError,
  IntellectaResult,
  intellectaResult,
} from "../../types";
import { intellectaClient } from "../intellectaClient";

type Response = IntellectaError | IntellectaResult;

type Payload = {
  user: string;
  chat_id: number;
};

export const usePinHistoricChatById = () => {
  const queryClient = useQueryClient();
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  return useMutation({
    mutationFn: async ({ chat_id }: { chat_id: number }) => {
      const response = await intellectaClient<Payload, Response>({
        url: "/user/historic/pin_chat",
        payload: { user: email, chat_id },
      });

      const validatedResponse = intellectaResult.parse(response);
      return validatedResponse;
    },
    onSettled: (data) => {
      if (data === "OK") {
        //* per scaricare di nuovo lo storico delle chat
        queryClient.invalidateQueries({
          queryKey: ["intellecta-historic-chat-list", email],
        });
      }
    },
  });
};
