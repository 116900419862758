import { Translations } from "@/i18n/config";

export const getTimeAgoFromTs = ({
  timestamp,
  translate,
  short = false, // Formato abbreviato opzionale
}: {
  timestamp: number;
  translate: (
    key: Translations,
    options?: { [key: string]: string | number },
  ) => string;
  short?: boolean;
}): string => {
  const now = Date.now();
  const elapsedSeconds = Math.floor((now - timestamp) / 1000);

  const secondsInMinute = 60;
  const secondsInHour = 60 * secondsInMinute;
  const secondsInDay = 24 * secondsInHour;
  const secondsInWeek = 7 * secondsInDay;

  if (elapsedSeconds < secondsInMinute) {
    return translate("relative_durations.now", { defaultValue: "adesso" });
  }

  let key: Translations;
  let count: number;

  if (elapsedSeconds < secondsInHour) {
    count = Math.floor(elapsedSeconds / secondsInMinute);
    key = short
      ? "relative_durations.minutes_ago_short"
      : count === 1
      ? "relative_durations.minute_ago"
      : "relative_durations.minutes_ago";
  } else if (elapsedSeconds < secondsInDay) {
    count = Math.floor(elapsedSeconds / secondsInHour);
    key = short
      ? "relative_durations.hours_ago_short"
      : count === 1
      ? "relative_durations.hour_ago"
      : "relative_durations.hours_ago";
  } else if (elapsedSeconds < secondsInWeek) {
    count = Math.floor(elapsedSeconds / secondsInDay);
    key = short
      ? "relative_durations.days_ago_short"
      : count === 1
      ? "relative_durations.day_ago"
      : "relative_durations.days_ago";
  } else {
    count = Math.floor(elapsedSeconds / secondsInWeek);
    key = short
      ? "relative_durations.weeks_ago_short"
      : count === 1
      ? "relative_durations.week_ago"
      : "relative_durations.weeks_ago";
  }

  return translate(key, { count, defaultValue: `{{count}} fa` });
};
