import { useMutation } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import { intellectaClient } from "../intellectaClient";
import {
  IntellectaError,
  IntellectaResult,
  intellectaResult,
  ShareMode,
} from "../../types";

type Response = IntellectaError | IntellectaResult;

type Payload = {
  user: string;
  user_notes?: string;
  message_id: number;
  mode: ShareMode;
  receiving_users: string[];
};

export const useShareMessage = () => {
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  return useMutation<Response, Error, Omit<Payload, "user">>({
    mutationFn: async (payload) => {
      const response = await intellectaClient<Payload, Response>({
        url: "/social/share_message",
        payload: {
          ...payload,
          user: email,
        },
      });

      return intellectaResult.parse(response);
    },
  });
};
