import { Grid2 } from "@mui/material";
import { LossType } from "../../types";
import { LongTermChart } from "./LongTermChart";
import { ShortTermChart } from "./ShortTermChart";

export const ChartRow = ({
  title,
  color,
  lossType,
}: {
  title: string;
  color: string;
  lossType: LossType;
}) => {
  return (
    <Grid2 container spacing={2}>
      <Grid2
        size={{
          lg: 6,
          xs: 12,
        }}
      >
        <LongTermChart title={title} color={color} lossType={lossType} />
      </Grid2>
      <Grid2
        size={{
          lg: 6,
          xs: 12,
        }}
      >
        <ShortTermChart title={title} lossType={lossType} />
      </Grid2>
    </Grid2>
  );
};
