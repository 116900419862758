import { BasicTable } from "@/components/BasicTable";
import { Card } from "@/components/Layout/Card";
import { TableParser } from "@/utils/TableParser";
import { Box } from "@mui/material";
import { ConnectionStatus } from "../api/useGetEdgePcData";
import { TableTitle } from "../layout/StyledText";
import { useTranslate } from "@/i18n/config";
import { unknown } from "zod";

export const FooterSide = ({
  connectionStatus,
}: {
  connectionStatus: ConnectionStatus[];
}) => {
  const translate = useTranslate();
  const { columns, rows } =
    connectionStatus.length > 0
      ? TableParser.parseData({
          data: connectionStatus,
          columnsMap: {
            connected: translate("connected"),
            driver_type: translate("driver"),
            port: translate("port"),
            channel: translate("channel"),
            ip_address: translate("ip_address"),
            device: translate("device"),
            timestamp: "Timestamp",
          },
          columnsOrder: [
            "channel",
            "device",
            "driver_type",
            "ip_address",
            "port",
            "timestamp",
            "connected",
          ],
        })
      : { columns: [], rows: [] };
  const connectedColumnDefinition = columns.findIndex(
    (item) => item.field === "connected",
  );
  if (connectedColumnDefinition !== -1) {
    columns[connectedColumnDefinition].renderCell = (params) => {
      const { value } = params;
      return (
        <Box
          sx={{
            backgroundColor:
              value === unknown ? "orange" : value ? "green" : "red",
            color: "white",
            padding: "8px 8px",
            borderRadius: "9999px",
            aspectRatio: "1/1",
          }}
        />
      );
    };
  }
  return (
    <Card>
      <TableTitle>{translate("machine_connection_status")}</TableTitle>
      <BasicTable
        columns={columns}
        rows={rows}
        sx={{ height: 300 }}
        fileName="machine_connection_status_data"
      />
    </Card>
  );
};
