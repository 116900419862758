// Funzione di utilità per estrarre i turni (shift_1, shift_2, shift_3, …)

import { ShiftsConfiguration } from "@/features/ShiftsConfiguration/types";
import { DateConfig } from "../Calendar";

// da un oggetto data (es. { calendar_date, shift_1_start, shift_1_end, shift_2_start, shift_2_end, ... })
export function getDayShifts(
  dayConfig: DateConfig,
): { start: number; end: number }[] {
  const shifts: { start: number; end: number }[] = [];
  let index = 1;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const startKey = `shift_${index}_start`;
    const endKey = `shift_${index}_end`;
    if (dayConfig[startKey] != null && dayConfig[endKey] != null) {
      shifts.push({
        start: dayConfig[startKey] as number,
        end: dayConfig[endKey] as number,
      });
      index++;
    } else {
      break;
    }
  }

  // Li ordiniamo in base all'ora di inizio (start)
  shifts.sort((a, b) => a.start - b.start);
  return shifts;
}

export function validateOverlaps(editedConfiguration: ShiftsConfiguration[]) {
  // Ordiniamo prima l'array per data
  editedConfiguration.sort(
    (a, b) =>
      new Date(a.calendar_date).getTime() - new Date(b.calendar_date).getTime(),
  );

  for (let i = 0; i < editedConfiguration.length; i++) {
    const currentDay = editedConfiguration[i];
    const currentShifts = getDayShifts(currentDay);

    // 1) Controllo sovrapposizioni all’interno della stessa giornata
    for (let j = 0; j < currentShifts.length - 1; j++) {
      const shiftA = currentShifts[j];
      const shiftB = currentShifts[j + 1];

      // Se l'end del turno j è più grande dello start del turno j+1, c’è sovrapposizione
      if (shiftA.end > shiftB.start) {
        return `Sovrapposizione rilevata nel giorno ${currentDay.calendar_date}`;
      }
    }

    // 2) Controllo con il giorno precedente
    if (i > 0 && currentShifts.length > 0) {
      const previousDay = editedConfiguration[i - 1];
      const previousShifts = getDayShifts(previousDay);
      if (previousShifts.length > 0) {
        const lastShiftPrevDay = previousShifts[previousShifts.length - 1];
        const firstShiftCurrentDay = currentShifts[0];
        // Se l'end dell'ultimo turno del giorno precedente supera lo start del primo turno dell’oggi, c’è overlap
        if (lastShiftPrevDay.end > firstShiftCurrentDay.start) {
          return `Sovrapposizione tra il giorno ${previousDay.calendar_date} e ${currentDay.calendar_date}`;
        }
      }
    }

    // 3) Controllo con il giorno successivo
    if (i < editedConfiguration.length - 1 && currentShifts.length > 0) {
      const nextDay = editedConfiguration[i + 1];
      const nextShifts = getDayShifts(nextDay);
      if (nextShifts.length > 0) {
        const lastShiftCurrentDay = currentShifts[currentShifts.length - 1];
        const firstShiftNextDay = nextShifts[0];
        // Se l'end dell’ultimo turno del giorno corrente supera lo start del primo turno del giorno dopo, c’è overlap
        if (lastShiftCurrentDay.end > firstShiftNextDay.start) {
          return `Sovrapposizione tra il giorno ${currentDay.calendar_date} e ${nextDay.calendar_date}`;
        }
      }
    }
  }

  // Se non troviamo alcuna sovrapposizione, ritorniamo null
  return null;
}
