// import { useEffect, useState } from "react";
import { Stack, Box } from "@mui/material";
import Grow from "@mui/material/Grow";
import { TransitionGroup } from "react-transition-group";
import { SocialNotification } from "./SocialNotification";
// import { Notification } from "../../../types";
import { socialStore } from "@/features/Intellecta/store/social-store";

export const SocialContent = ({
  onUsePrompt,
}: {
  onUsePrompt: (prompt: string) => void;
}) => {
  const { notifications } = socialStore();
  //   const [notifications, setNotifications] =
  //     useState<Notification[]>(initialNotifications);

  // Esempio: simuliamo l'arrivo di una nuova notifica dopo 5 secondi
  //   useEffect(() => {
  //     const timer = setTimeout(() => {
  //       const newNotification: Notification = {
  //         social_id: 999,
  //         title: "Nuova notifica simulata",
  //         is_read: false,
  //         is_pinned: false,
  //         sending_user: "new.user@mail.com",
  //         // Usiamo un timestamp maggiore di quelli già presenti
  //         // per assicurarci che sia in cima alla lista unpinned.
  //         timestamp: Math.floor(Date.now() / 1000),
  //       };

  //       setNotifications((prev) => [newNotification, ...prev]);
  //     }, 5000);

  //     return () => clearTimeout(timer);
  //   }, []);

  // Suddividiamo e ordiniamo i due tipi di notifiche
  const pinnedNotifications = notifications
    .filter((n) => n.is_pinned)
    .sort((a, b) => b.timestamp - a.timestamp);
  const unpinnedNotifications = notifications
    .filter((n) => !n.is_pinned)
    .sort((a, b) => b.timestamp - a.timestamp);

  // Creiamo un array finale che contiene prima le pinned e poi le unpinned
  const orderedNotifications = [
    ...pinnedNotifications,
    ...unpinnedNotifications,
  ];

  return (
    <Stack p={2} gap={1}>
      {/* TransitionGroup si occupa di gestire l'uscita/entrata animata degli elementi */}
      <TransitionGroup>
        {orderedNotifications.map((notification) => (
          // Grow serve a creare un'animazione di "comparsa" graduale
          <Grow
            key={notification.social_id}
            in
            style={{ transformOrigin: "0 0 0" }}
            timeout={500}
          >
            <Box mb={1}>
              <SocialNotification
                notification={notification}
                onUsePrompt={onUsePrompt}
              />
            </Box>
          </Grow>
        ))}
      </TransitionGroup>
    </Stack>
  );
};
