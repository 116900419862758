import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Box, Stack } from "@mui/material";
import { ChatMessageActionListIntellecta } from "./ChatMessageActionListIntellecta";
import { Message } from "../../store/chat-store";
import { ChatMessageHeader } from "./ChatMessageHeader";
import { ChatMessageLoader } from "./ChatMessageLoader";
import { ChatMessageBody } from "./ChatMessageBody";
import "./chat-markdown.css";
import { useTranslate } from "@/i18n/config";
import { ChatMessageActionListUser } from "./ChatMessageActionListUser";

export const ChatMessage = ({
  message,
  sender,
  image,
  id,
  disableFeedback = false,
  isLoading = false,
}: Omit<Message, "includeInHistoric"> & {
  disableFeedback?: boolean;
  isLoading?: boolean;
}) => {
  const [isLoadingSentry, setIsLoadingSentry] = useState(true);
  const [showActions, setShowActions] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // 🔹 Stato per tracciare la modale
  const translate = useTranslate();

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(message);
    toast.success(translate("user_feedback.copied_to_clipboard"));
  };

  useEffect(() => {
    const rnd = Math.round(Math.random() * 30 * 1000);
    const timer = setTimeout(() => {
      setIsLoadingSentry(false);
    }, rnd);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Stack
      onMouseEnter={() => {
        setShowActions(true);
      }}
      onMouseLeave={() => {
        if (!isModalOpen) setShowActions(false); // 🔹 Non nasconde le azioni se la modale è aperta
      }}
      sx={{
        gap: 1,
        borderBottom: "1px solid #ffffff15",
        p: 2,
        pt: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <ChatMessageHeader sender={sender} />

        {showActions && sender === "Intellecta" && !disableFeedback && (
          <ChatMessageActionListIntellecta
            onCopy={handleCopyToClipboard}
            messageId={id}
            onOpenModal={() => setIsModalOpen(true)} // 🔹 Apre la modale
            onCloseModal={() => setIsModalOpen(false)} // 🔹 Chiude la modale manualmente
          />
        )}

        {showActions && sender === "User" && (
          <ChatMessageActionListUser message={message} />
        )}
      </Box>
      {isLoading && <ChatMessageLoader />}
      {sender === "Sentry Mode" && isLoadingSentry && <ChatMessageLoader />}
      {sender === "Sentry Mode" && !isLoadingSentry && (
        <ChatMessageBody message={message} />
      )}
      {sender !== "Sentry Mode" && <ChatMessageBody message={message} />}
      {image && (
        <img
          src={`data:image/png;base64,${image}`}
          alt="Decoded Image"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      )}
    </Stack>
  );
};
