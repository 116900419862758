import { useState } from "react";
import { NumericFormat } from "react-number-format";
import { Box, Stack, Typography, Divider, Button } from "@mui/material";
import { Configuration } from "../api/useGetLineConfiguration";
import { StartConfiguration } from "@/features/OverallLineEfficiency/components/StartConfiguration";
import { StopConfiguration } from "@/features/OverallLineEfficiency/components/StopConfiguration";
import { Card } from "@/components/Layout/Card";
import { HistoricConfiguration } from "@/features/OverallLineEfficiency/Pages/HistoricLineDetail/api/useGetHistoricLineData";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { useTranslate } from "@/i18n/config";

type ConfigurationKeys = keyof Configuration["configuration"];
// const MappedConfigurationKeys: Record<ConfigurationKeys, string> = {
//   machineOutput: "Machine(s) Output",
//   machineReference: "Machine(s) Reference",
//   productionTarget: "Production Target",
//   scheduledArrivalTime: "Scheduled Arrival Time",
//   startTime: "Start Time",
//   targetOee: "Target OEE",
//   outputRate: "Output Rate",
//   referenceRate: "Reference Rate",
// };

const parseConfigurationValue = (
  key: ConfigurationKeys,
  value: number | string | string[],
  dateFormatter: (info: {
    timestamp: number;
    fmt?: string;
    skipTimezoneTransform?: boolean;
  }) => string,
) => {
  switch (key) {
    case "machineOutput":
    case "machineReference":
      if (!Array.isArray(value)) break;
      return value.map((el, i) => {
        return i > 0 ? `, ${el}` : el;
      });
    case "targetOee":
      return `${value}%`;

    case "scheduledArrivalTime":
    case "startTime":
      if (Array.isArray(value)) break;
      return dateFormatter({
        timestamp: typeof value === "string" ? parseInt(value, 10) : value,
      });

    case "productionTarget":
      if (Array.isArray(value)) break;
      return (
        <NumericFormat
          value={value}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
        />
      );
    default:
      return String(value);
  }
};

const parseConfigurationKeys = ({
  config,
}: {
  config: Configuration;
}): { [key: string]: string | string[] | number } => {
  const oldConfig = config.configuration;
  const newConfiguration: {
    [key: string]: string | string[] | number;
  } = {};

  if (config.type === "time_based") {
    newConfiguration.machineOutput = oldConfig.machineOutput;
    if (oldConfig.productionTarget)
      newConfiguration.productionTarget = oldConfig.productionTarget;
    if (oldConfig.scheduledArrivalTime)
      newConfiguration.scheduledArrivalTime = oldConfig.scheduledArrivalTime;
    if (oldConfig.startTime) newConfiguration.startTime = oldConfig.startTime;
  } else {
    return oldConfig;
  }

  return newConfiguration;
};

export const LineConfig = ({ config }: { config: Configuration }) => {
  const { formatTimestamp } = useFormatTimestamp();
  const showControlButtons = config.type !== "automatic";
  const parsedConfiguration = parseConfigurationKeys({ config });
  const translate = useTranslate();

  const MappedConfigurationKeys: Record<ConfigurationKeys, string> = {
    machineOutput: translate("line.machines_output"),
    machineReference: translate("line.machines_reference"),
    productionTarget: translate("line.production_target"),
    scheduledArrivalTime: translate("line.scheduled_arrival_time"),
    startTime: translate("acceptance_test.start_time"),
    targetOee: translate("line.target_oee"),
    outputRate: translate("line.output_rate"),
    referenceRate: translate("line.reference_rate"),
  };

  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <Stack
        sx={{
          gap: 2,
        }}
      >
        <Typography variant="h6">{translate("configuration")}</Typography>
        <Stack
          spacing={1.5}
          divider={<Divider />}
          sx={{
            maxHeight: 400,
            overflow: "auto",
            padding: 2,
          }}
        >
          {Object.entries(parsedConfiguration).map(([key, value]) => (
            <Stack
              key={key}
              direction="row"
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Box>{MappedConfigurationKeys[key as ConfigurationKeys]}</Box>
              <Box>
                {parseConfigurationValue(
                  key as ConfigurationKeys,
                  value,
                  formatTimestamp,
                )}
              </Box>
            </Stack>
          ))}
        </Stack>
        {showControlButtons ? (
          <Stack>
            {config.is_running ? (
              <StopConfiguration config_id={config.id} />
            ) : (
              <StartConfiguration config_id={config.id} />
            )}
          </Stack>
        ) : null}
      </Stack>
    </Card>
  );
};

export const HistoricLineConfigViewer = ({
  config,
}: {
  config: HistoricConfiguration[];
}) => {
  const { formatTimestamp } = useFormatTimestamp();
  const translate = useTranslate();

  const [page, setPage] = useState(0);

  const configPages = config.length;

  const showingConfig = config[page];

  const MappedConfigurationKeys: Record<ConfigurationKeys, string> = {
    machineOutput: translate("line.machines_output"),
    machineReference: translate("line.machines_reference"),
    productionTarget: translate("line.production_target"),
    scheduledArrivalTime: translate("line.scheduled_arrival_time"),
    startTime: translate("acceptance_test.start_time"),
    targetOee: translate("line.target_oee"),
    outputRate: translate("line.output_rate"),
    referenceRate: translate("line.reference_rate"),
  };

  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <Stack
        sx={{
          gap: 2,
        }}
      >
        <Typography variant="h6">{translate("configuration")}</Typography>
        <Stack
          spacing={1.5}
          divider={<Divider />}
          sx={{
            maxHeight: 400,
            overflow: "auto",
            padding: 2,
          }}
        >
          {showingConfig
            ? Object.entries(showingConfig).map(([key, value]) => (
                <Stack
                  key={key}
                  direction="row"
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  <Box>{MappedConfigurationKeys[key as ConfigurationKeys]}</Box>
                  <Box>
                    {parseConfigurationValue(
                      key as ConfigurationKeys,
                      value,
                      formatTimestamp,
                    )}
                  </Box>
                </Stack>
              ))
            : null}
        </Stack>

        {configPages > 1 ? (
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                if (page === 0) return;
                setPage((page) => page - 1);
              }}
              disabled={page === 0}
            >
              prev
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                if (page === configPages - 1) return;
                setPage((page) => page + 1);
              }}
              disabled={page === configPages - 1}
            >
              next
            </Button>
          </Stack>
        ) : null}
      </Stack>
    </Card>
  );
};
