import { useState } from "react";
import {
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  DeleteOutlined,
  DriveFileRenameOutlineOutlined,
  MoreVert,
} from "@mui/icons-material";
import { useTranslate } from "@/i18n/config";
import { useDisclosure } from "@/hooks/useDisclosure";
import { Prompt } from "../../types";
import { EditPromptModal } from "./EditPromptModal";
import { DeletePromptModal } from "./DeletePromptModal";
import { intellectaColors } from "../../utilities/colors";

export const PromptButton = ({
  prompt,
  onPromptClick,
}: {
  prompt: Prompt;
  onPromptClick: (prompt: Prompt) => void;
}) => {
  const translate = useTranslate();
  const {
    open: openEditPromptModal,
    isOpen: isOpenEditPromptModal,
    close: closeEditPromptModal,
  } = useDisclosure();
  const {
    open: openDeletePromptModal,
    isOpen: isOpenDeletePromptModal,
    close: closeDeletePromptModal,
  } = useDisclosure();
  const { close: hide, open: show, isOpen: isHover } = useDisclosure();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);

    if (!isHover) {
      hide();
    }
  };

  return (
    <>
      <Tooltip title={prompt.description} enterDelay={1000} leaveDelay={200}>
        <ListItemButton
          onClick={() => onPromptClick(prompt)}
          onMouseEnter={show}
          onMouseLeave={hide}
          sx={{
            background: `linear-gradient(135deg, ${intellectaColors.powerWordButtonBackgroundHover} 0%, ${intellectaColors.powerWordButtonBackground} 100%)`,
            borderRadius: 2,
            color: "white",
            justifyContent: "flex-start",
            boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
            transition: "all 0.2s",
            "&:hover": {
              background: `linear-gradient(135deg, ${intellectaColors.powerWordButtonBackgroundHover}80 0%, ${intellectaColors.powerWordButtonBackground}80 100%)`,
              boxShadow: "0px 2px 8px rgba(0,0,0,0.3)",
            },
            "&:active": {
              boxShadow: "inset 0px 2px 4px rgba(0,0,0,0.4)",
            },
          }}
        >
          <ListItemText
            primary={prompt.label}
            primaryTypographyProps={{ variant: "subtitle2" }}
          />
          <IconButton
            sx={{
              visibility: isHover ? "visible" : "hidden",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleClickMenu(e);
            }}
          >
            <MoreVert />
          </IconButton>
        </ListItemButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="historic-chat-menu"
        open={open}
        onClose={handleCloseMenu}
        transformOrigin={{ horizontal: "left", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "left", vertical: "center" }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            openEditPromptModal();
          }}
        >
          <ListItemIcon>
            <DriveFileRenameOutlineOutlined fontSize="small" />
          </ListItemIcon>
          {translate("actions.edit")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            openDeletePromptModal();
          }}
        >
          <ListItemIcon>
            <DeleteOutlined fontSize="small" />
          </ListItemIcon>
          {translate("actions.delete")}
        </MenuItem>
      </Menu>
      {isOpenEditPromptModal && (
        <EditPromptModal
          isOpen={isOpenEditPromptModal}
          close={closeEditPromptModal}
          prompt={prompt}
        />
      )}
      {isOpenDeletePromptModal && (
        <DeletePromptModal
          isOpen={isOpenDeletePromptModal}
          close={closeDeletePromptModal}
          prompt={prompt}
        />
      )}
    </>
  );
};
