import { TimeHelpers } from "@/utils/TimeHelpers";
import { formatNumber } from "@/utils/formatNumber";

export type ChartType = "duration" | "percentage" | "count";

/**
 *
 * @param value [number] Valore da formattare, varia a seconda del "chartType"
 * @param chartType ["duration" | "percentage" | "count"]
 *
 * @description Questa funzione serve a impostare il formato del valore visualizzato.
 * In caso di chartType "duration", si aspetterà "value" in millisecondi.
 * @returns [string] contenente il valore formattato
 */
export const formatValue = ({
  value,
  chartType,
}: {
  value: number;
  chartType: ChartType;
}): string => {
  const valAbs = Math.abs(value);
  if (chartType === "duration") {
    // Durata
    const valueMs = valAbs * 60 * 1000;
    return `${value >= 0 ? "" : "-"}${TimeHelpers.parseDurationToString({
      duration: valueMs,
    })}`;
  }
  if (chartType === "count") return value.toLocaleString(); // Numero
  return `${formatNumber(value)}%`; // Percentuale
};
