import { useState } from "react";
import { Card } from "@/components/Layout/Card";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Button, Collapse, Tab, Tabs, Typography } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { SelectLaps } from "./SelectLaps";
import { Lap } from "../../types";
import { useGetLaps } from "../../api/Common/useGetLaps";
import {
  useAcoposContext,
  useAcoposDispatchContext,
} from "../../context/acopos-context";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";

export const SelectLapsContainer = () => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetLaps();

  if (isLoading) return <SkeletonCard height={280} />;
  if (error) return <Card>{translate("user_feedback.an_error_occurred")}</Card>;
  if (!data) return <Card>{translate("user_feedback.no_data")}</Card>;
  if (Object.keys(data.laps).length === 0)
    return <Card>{translate("user_feedback.no_data_to_display")}</Card>;

  return <RunsTabs lapsData={data.laps} />;
};

const RunsTabs = ({ lapsData }: { lapsData: { [key: string]: Lap[] } }) => {
  const translate = useTranslate();
  const { selectedRun } = useAcoposContext();
  const dispatch = useAcoposDispatchContext();
  const [expand, setExpand] = useState(true);

  return (
    <Card sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Tabs
          value={selectedRun}
          aria-label="run selection tabs"
          onChange={(_: React.SyntheticEvent, newValue: string) => {
            dispatch({ type: "SET_SELECTED_RUN", item: newValue });
          }}
          sx={{ minHeight: "36px", height: "36px" }}
        >
          {Object.keys(lapsData).map((run) => (
            <Tab
              key={run}
              value={run}
              label={run}
              sx={{ minHeight: "36px", height: "36px" }}
            />
          ))}
        </Tabs>
        <Button onClick={() => setExpand(!expand)}>
          <Typography>{translate("acopos.lap_selection")}</Typography>
          {expand ? <ExpandLess /> : <ExpandMore />}
        </Button>
      </Box>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        {selectedRun && <SelectLaps runsLaps={lapsData[selectedRun]} />}
      </Collapse>
    </Card>
  );
};
