import { useFirebaseContext } from "@/context/firebase-context";
import { gCloudClient } from "@/services/gCloudClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { SERVER_ENUMERATOR } from "../config";

type Payload = {
  email: string;
  devices: number;
  role: 3 | 4;
  platformLvl: number;
  appEnabled: boolean;
  server: 0 | 1 | 2;
};

export const useCreateNewUser = () => {
  const { platformLvl, user } = useFirebaseContext();

  const client = useQueryClient();

  const email = user?.email as string;

  return useMutation({
    mutationFn: async (payload: Omit<Payload, "platformLvl" | "server">) => {
      return await gCloudClient<Payload, Response>({
        data: {
          ...payload,
          server: parseInt(SERVER_ENUMERATOR, 10) as 0 | 1 | 2,
          platformLvl,
        },
        service: "CREATE_USER",
      });
    },

    onSuccess: () => {
      toast.success("User created successfully");
      client.invalidateQueries({
        queryKey: ["colleagues-machines-access", email],
      });
    },
    onError: () => {
      toast.error("Error creating user");
    },
  });
};
