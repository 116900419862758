import { NivoSolidGauge } from "@/components/highcharts/gauge/NivoSolidGauge";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { Box, Stack } from "@mui/material";
import { GaugesData } from "../types";

export const GaugesLayout = ({ gaugesData }: { gaugesData: GaugesData[] }) => {
  const translate = useTranslate();
  return (
    <Card
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "space-between",
        }}
      >
        {gaugesData.map((item) => (
          <Box
            key={item.name}
            sx={{
              position: "relative",
              padding: 0.5,
              width: { xs: "18vw", md: "10vw" },
              height: { xs: "18vw", md: "10vw" },
              aspectRatio: "1/1",
              textAlign: "center",
              transition: "all .2s ease-in",
            }}
          >
            <NivoSolidGauge
              min={0}
              max={100}
              title={""}
              color={item.color}
              enableTrack={true}
              value={item.value}
              trackColor="#333"
            />
            <Box
              sx={{
                position: "absolute",
                color: "white",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 10,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                fontSize: "15px",
              }}
            >
              <Box>{item.name}</Box>
              <Box>
                {item.value
                  ? `${item.value.toFixed(1)}%`
                  : translate("user_feedback.no_data")}
              </Box>
            </Box>
          </Box>
        ))}
      </Stack>
    </Card>
  );
};
