import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  styled,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslate } from "@/i18n/config";
import { useFirebaseContext } from "@/context/firebase-context";
import { userConfigurationStore } from "../../store/user-configuration-store";
import { chatStore } from "../../store/chat-store";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: 8,
  boxShadow: "0px 4px 10px rgba(0,0,0,0.15)",
  "&:hover": {
    background: "rgba(255,255,255,0.07)",
  },
}));

export const TroubleShootingMachineSelector = () => {
  const [isOpen, setIsOpen] = useState(false);
  const translate = useTranslate();
  const { setMachine, lastMachineId, availableMachines, profile } =
    userConfigurationStore();
  const { user } = useFirebaseContext();
  const email = user?.email || "";
  const queryClient = useQueryClient();
  const [selectedMachine, setSelectedMachine] = useState("");
  const { clearChat } = chatStore();

  const machines = profile
    ? availableMachines.filter(
        (machine) =>
          machine.service_version_access.includes("troubleshooting") &&
          machine.profile.includes(profile),
      )
    : [];

  useEffect(() => {
    return () => setMachine(null);
  }, [setMachine]);

  useEffect(() => {
    if (lastMachineId) {
      setMachine(lastMachineId);
      setSelectedMachine(lastMachineId);
    } else {
      setMachine(null);
      setSelectedMachine("");
    }
  }, [lastMachineId, setMachine]);

  if (!machines.length) {
    return (
      <Box>
        <Typography variant="body2">
          {translate("intellecta.no_machines_access")}
        </Typography>
      </Box>
    );
  }

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const val = event.target.value as string;
    setSelectedMachine(val);
    setMachine(val);
    clearChat();
    queryClient.invalidateQueries({
      queryKey: ["intellecta-historic-chat-list", email],
    });
  };

  return (
    <StyledFormControl fullWidth>
      <InputLabel id="intellecta-troubleshooting-machine-select-label">
        {translate("machine")}
      </InputLabel>
      <Select
        labelId="intellecta-troubleshooting-machine-select-label"
        id="intellecta-troubleshooting-machine-select"
        value={selectedMachine}
        label={translate("machine")}
        onChange={handleChange}
        MenuProps={{
          slotProps: { paper: { sx: { borderRadius: "0 0 8px 8px" } } },
        }}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            background: "rgba(255,255,255,0.05)",
            borderRadius: isOpen ? "8px 8px 0 0" : "8px",
          },
        }}
      >
        <MenuItem key="None" value="">
          {translate("motors.no_selection")}
        </MenuItem>
        {machines.map(({ machine }) => (
          <MenuItem key={machine} value={machine}>
            {machine}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};
