import { useRef } from "react";
import { toast } from "react-hot-toast";
import { Button, TextField } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { useDisclosure } from "@/hooks/useDisclosure";
import { PowerWord } from "../../types";
import { usePowerWord } from "../../api/usePowerWord";
import { chatStore } from "../../store/chat-store";
import { scrollChat } from "../../utilities/scrollChat";
import { Modal } from "@/components/Modal";
import { sentryModeStore } from "../../store/sentry-mode-store";
import { userConfigurationStore } from "../../store/user-configuration-store";
import { PowerwordButton } from "./PowerwordButton";

export const PowerwordButtonAlarm = ({
  powerWord,
}: {
  powerWord: PowerWord;
}) => {
  const translate = useTranslate();
  const { addMessage, setChatId, setIsAnswering } = chatStore();
  const { version, machine } = userConfigurationStore();
  const { mutateAsync: callPowerWord } = usePowerWord();
  const { open, close, isOpen } = useDisclosure();
  const alarmCodeRef = useRef<HTMLInputElement>();
  const { resetTimer } = sentryModeStore();

  const onConfirm = () => {
    if (!alarmCodeRef.current?.value)
      return toast.error(translate("user_feedback.input_must_be_number"));

    if (!version)
      return toast.error(translate("user_feedback.an_error_occurred"));

    close();

    setIsAnswering(true);
    resetTimer();

    const inputAlarmCode = parseInt(alarmCodeRef.current.value);

    addMessage({
      sender: "User",
      message: powerWord.prompt,
    });

    scrollChat();

    callPowerWord(
      {
        endpoint: powerWord.endpoint,
        params: { alarm_code: inputAlarmCode },
      },
      {
        onError() {
          toast.error(translate("user_feedback.an_error_occurred"));
        },
      },
    )
      .then((response) => {
        addMessage({
          sender: "Intellecta",
          message: response.value,
          image: response.image,
          id: response.message_id,
        });
        setChatId(response.chat_id);
      })
      .finally(() => {
        scrollChat();
        setIsAnswering(false);
        resetTimer();
      });
  };

  const clickHandler = () => {
    if (version === "troubleshooting" && !machine) {
      toast.error(translate("intellecta.no_machine_selected"));
    } else {
      open();
    }
  };

  return (
    <>
      <Modal
        titleContent={translate("intellecta.insert_alarm_code")}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={isOpen}
        bodyContent={
          <TextField
            id="outlined-number"
            label={translate("intellecta.alarm_code")}
            type="number"
            inputRef={alarmCodeRef}
            margin="normal"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
          />
        }
        actions={
          <>
            <Button variant="outlined" onClick={close}>
              {translate("actions.cancel")}
            </Button>
            <Button variant="contained" onClick={onConfirm}>
              {translate("actions.confirm")}
            </Button>
          </>
        }
      />
      <PowerwordButton powerWord={powerWord} onClick={clickHandler} />
    </>
  );
};
