import { useQuery } from "@tanstack/react-query";
import { FetchClient } from "@/services/ApiClient";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { StatesLogResponse, StatesLogResult, statesLogResult } from "../types";
import { useCustomTimeRange } from "../store/useCustomTimeRange";
import { useLanguage } from "@/i18n/config";

type Payload = {
  machineName: string;
  dateStart: number;
  dateEnd: number;
  language_id: string;
};

export const useGetStatesLogDataHistoric = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { dateStart, dateEnd } = useCustomTimeRange();
  const language = useLanguage();

  return useQuery<StatesLogResult>({
    queryKey: [
      "states-log-data-historic",
      machine!.machine,
      dateEnd,
      dateStart,
      language,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, StatesLogResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
          dateStart: dateStart.toMillis(),
          dateEnd: dateEnd.toMillis(),
          language_id: language,
        },
        url: "dig.c.stopImputation_thing/Services/getStatesLogDataHistoric",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = statesLogResult.parse(response);

      return validatedResponse;
    },
  });
};
