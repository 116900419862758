import { useState } from "react";
import {
  Tab,
  Tabs,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  SelectChangeEvent,
  OutlinedInput,
  ListItemText,
} from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { CustomSelectTimeSelection } from "@/components/SelectTimeSelection/CustomSelectTimeSelection";
import { timezoneStore } from "@/store/useTimezoneStore";
import { Card } from "@/components/Layout/Card";
import { OverviewNavBar } from "@/pages/Overview/layout/OverviewNavBar/OverviewNavBar";
import { ExportPage } from "./pages/ExportPage";
import { AnomaliesPage } from "./pages/AnomaliesPage";
import { useGetMachinesWithTimezone } from "./api/getMachines";

const TABS = {
  EXPORT: 0,
  ANOMALIES: 1,
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

export function ControlArea() {
  const translate = useTranslate();
  const [selectedMachine, setSelectedMachine] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const { data: machines, error, isLoading } = useGetMachinesWithTimezone();
  const { setMachineTimezone } = timezoneStore();
  const [tab, setTab] = useState(TABS.EXPORT);
  const categories = [
    {
      name: "state",
      clientName: translate("state_other"),
    },
    {
      name: "alarm",
      clientName: translate("alarms"),
    },
    {
      name: "reject-causes",
      clientName: translate("reject_causes"),
    },
    {
      name: "physical-measures",
      clientName: translate("physicals"),
    },
    {
      name: "justification",
      clientName: translate("stop_imputation.imputation_other"),
    },
    {
      name: "counters",
      clientName: translate("counters"),
    },
    {
      name: "speeds",
      clientName: translate("speed_other"),
    },
  ];

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  return (
    <>
      <OverviewNavBar hasTimezone={true}>
        <CustomSelectTimeSelection
          exclude={["Year", "TwoWeeks", "ThreeMonths", "SixMonths", "Month"]}
          unavailableShortcuts={tab === TABS.EXPORT ? ["14d", "30d"] : []}
          maxDays={tab === TABS.EXPORT ? 7 : 30}
        />
        {error ? null : isLoading ? (
          <FormControl disabled size="small" sx={{ minWidth: 120 }}>
            <InputLabel id="machine-select-label">
              {translate("machine")}
            </InputLabel>
            <Select
              labelId="machine-select-label"
              id="machine-select"
              value={selectedMachine}
            >
              <MenuItem value="">---</MenuItem>
            </Select>
          </FormControl>
        ) : !machines ? null : (
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel id="machine-select-label">
              {translate("machine")}
            </InputLabel>
            <Select
              labelId="machine-select-label"
              id="machine-select"
              value={selectedMachine}
              label={translate("machine")}
              onChange={(e) => {
                const machineName = e.target.value as string;
                if (machineName) {
                  setSelectedMachine(machineName);
                  const foundMachine = machines.find(
                    (m) => m.name === machineName,
                  );
                  if (foundMachine) setMachineTimezone(foundMachine.timezone);
                }
              }}
              MenuProps={{ style: { maxHeight: 400 } }}
            >
              <MenuItem value="">---</MenuItem>
              {tab === TABS.EXPORT
                ? machines.map((m) => (
                    <MenuItem key={m.name} value={m.name}>
                      {m.name}
                    </MenuItem>
                  ))
                : machines
                    .filter((machine) => machine.isWatchdogBound === true)
                    .map((m) => (
                      <MenuItem key={m.name} value={m.name}>
                        {m.name}
                      </MenuItem>
                    ))}
            </Select>
          </FormControl>
        )}
        {tab === TABS.EXPORT && (
          <FormControl size="small" sx={{ width: 240 }}>
            <InputLabel id="categories-checkbox-label">
              {translate("actions.select_categories")}
            </InputLabel>
            <Select
              labelId="categories-checkbox-label"
              id="categories-checkbox"
              multiple
              value={selectedCategories}
              onChange={handleChange}
              input={
                <OutlinedInput label={translate("actions.select_categories")} />
              }
              renderValue={(arr) => {
                const formattedValues: string[] = [];
                arr.forEach((c) => {
                  const found = categories.find(
                    (category) => category.name === c,
                  );
                  if (found) formattedValues.push(found.clientName);
                });
                return formattedValues.join(", ");
              }}
            >
              {categories.map((category) => (
                <MenuItem key={category.name} value={category.name}>
                  <Checkbox
                    checked={selectedCategories.includes(category.name)}
                  />
                  <ListItemText primary={category.clientName} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </OverviewNavBar>
      <Tabs
        value={tab}
        aria-label="control area tabs"
        onChange={(_: React.SyntheticEvent, newValue: number) => {
          setSelectedMachine("");
          setTab(newValue);
        }}
      >
        <Tab value={TABS.EXPORT} label={translate("export")}></Tab>
        <Tab
          value={TABS.ANOMALIES}
          label={translate("anomalies.data_acquisition_anomalies")}
        ></Tab>
      </Tabs>
      <CustomTabPanel value={tab} index={TABS.EXPORT}>
        {selectedMachine === "" ? (
          <Card>{translate("motors.no_selection")}</Card>
        ) : (
          <ExportPage
            machineName={selectedMachine}
            categories={selectedCategories}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={TABS.ANOMALIES}>
        {selectedMachine === "" ? (
          <Card>{translate("motors.no_selection")}</Card>
        ) : (
          <AnomaliesPage machineName={selectedMachine} />
        )}
      </CustomTabPanel>
    </>
  );
}
