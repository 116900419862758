import { Box, Tab, Tabs, Typography, Stack, Drawer } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Social } from "./Social";
import { Historic } from "./Historic";
import { useCheckIfMessageBanner } from "@/hooks/useCheckIfInformationBanner";
import { userConfigurationStore } from "../../store/user-configuration-store";
import { useGetHistoricNotificationList } from "@/features/Intellecta/api/Social/useGetHistoricNotificationList";
import { useGetNewNotifications } from "@/features/Intellecta/api/Social/useGetNewNotifications";
import { socialStore } from "../../store/social-store";
import { RIGHT_DRAWER_WIDTH } from "../..";

export const RightDrawer = ({
  onUsePrompt,
}: {
  onUsePrompt: (prompt: string) => void;
}) => {
  const { showBanner } = useCheckIfMessageBanner();
  const translate = useTranslate();
  const { services } = userConfigurationStore();

  // Flag che indica se il servizio "social" è abilitato
  const socialEnabled = services.includes("social");

  // Utilizziamo lo store per la tab attiva
  const { activeRightDrawerTab, setActiveRightDrawerTab, isRightDrawerOpen } =
    socialStore();

  const { isLoading, error } = useGetHistoricNotificationList(socialEnabled);
  // Il polling delle nuove notifiche viene attivato da useGetNewNotifications
  useGetNewNotifications(socialEnabled);

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={isRightDrawerOpen}
      sx={{
        width: RIGHT_DRAWER_WIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: RIGHT_DRAWER_WIDTH,
          boxSizing: "border-box",
        },
      }}
    >
      <Stack sx={{
        overflow: "hidden",
        pt: showBanner ? "32px" : 0,
      }}>
        <Tabs
          value={activeRightDrawerTab}
          aria-label="intellecta social and historic tabs"
          onChange={(
            _: React.SyntheticEvent,
            newValue: "social" | "historic",
          ) => {
            setActiveRightDrawerTab(newValue);
          }}
          sx={{ pl: 2 }}
        >
          <Tab value="historic" label={translate("intellecta.historic")} />
          <Tab value="social" label="Social" />
        </Tabs>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}>
          {activeRightDrawerTab === "social" && socialEnabled && (
            <Social
              onUsePrompt={onUsePrompt}
              isLoading={isLoading}
              error={error}
            />
          )}
          {activeRightDrawerTab === "social" && !socialEnabled && (
            <Stack sx={{
              p: 2,
              gap: 1,
            }}>
              <Typography variant="h6">
                {translate("user_feedback.service_not_enabled")}
              </Typography>
            </Stack>
          )}
          {activeRightDrawerTab === "historic" && <Historic />}
        </Box>
      </Stack>
    </Drawer>
  );
};
