import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Button, Grid2, Stack, Typography } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { Card } from "@/components/Layout/Card";
import { ItemCard } from "@/components/ItemCard";
import { NavButton } from "@/components/NavButton";
import { CustomSelectTimeSelection } from "@/components/SelectTimeSelection/CustomSelectTimeSelection";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { translateItemTitle } from "@/utils/itemCardTranslate";
import { useGetHistoricData } from "../../api/Historic/useGetHistoricData";
import { useGetDataTimeList } from "../../api/Historic/useGetTimeList";
import { EfficiencyNavigatorLoader } from "../../layout/EfficiencyNavigatorLoader";
import { HistoricPieSection } from "../../components/Historic/HistoricPieSection";
import { HistoricCarouselSection } from "../../components/Historic/HistoricCarouselSection";
import { HistoricChartSection } from "../../components/Historic/HistoricChartSection";

export const EfficiencyNavigatorHistoricView = () => {
  const { formatTimestamp } = useFormatTimestamp();
  const [selectedPeriod, setSelectedPeriod] = useState<{
    id: number;
    dataStartTimeLapse: number;
    timeLapse: string;
  } | null>(null);

  const { data: list } = useGetDataTimeList();
  useEffect(() => {
    setSelectedPeriod(null);
  }, [list]);
  const translate = useTranslate();
  return (
    <>
      <MachineViewNavBar>
        <CustomSelectTimeSelection
          exclude={[
            "TwoWeeks",
            "Month",
            "ThreeMonths",
            "SixMonths",
            "Year",
            "Custom",
          ]}
          forceCalendar
        />
        {selectedPeriod ? (
          <Button onClick={() => setSelectedPeriod(null)}>
            {translate("go_back_to_select")}
          </Button>
        ) : null}
        <NavButton to="/efficiency-navigator" text={translate("view_live")} />{" "}
      </MachineViewNavBar>
      {selectedPeriod ? (
        <Stack
          key={selectedPeriod.id}
          sx={{
            gap: 2,
          }}
        >
          <EfficiencyNavigatorHistoric id={selectedPeriod.id.toString()} />
        </Stack>
      ) : (
        <Stack spacing={2}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
            }}
          >
            {translate("please_select_a_period")}
          </Typography>
          <AnimatePresence presenceAffectsLayout mode="wait">
            <motion.div
              key={JSON.stringify(list?.data.length)}
              variants={{
                hidden: { opacity: 0 },
                show: {
                  opacity: 1,
                },
              }}
              initial="hidden"
              animate="show"
            >
              <Grid2 container spacing={2}>
                {list?.data.map((item) => (
                  <Grid2
                    key={item.id}
                    size={{
                      xs: 6,
                      md: 3,
                    }}
                  >
                    <ItemCard
                      item={item}
                      onClick={(i) =>
                        setSelectedPeriod({
                          dataStartTimeLapse: i.dataStartTimeLapse,
                          id: i.id,
                          timeLapse: i.timeLapse,
                        })
                      }
                      subtitle={`${translate(
                        "durations.hour_other",
                      )} ${formatTimestamp({
                        timestamp: item.dataStartTimeLapse,
                        fmt: "HH:mm",
                      })}`}
                      title={translateItemTitle(item.timeLapse, translate)}
                      optionalLabel={formatTimestamp({
                        timestamp: item.dataStartTimeLapse,
                        fmt: "DDD",
                      })}
                    />
                  </Grid2>
                ))}
              </Grid2>
            </motion.div>
          </AnimatePresence>
        </Stack>
      )}
    </>
  );
};

const EfficiencyNavigatorHistoric = ({ id }: { id: string }) => {
  const { data: historicData, error, isLoading } = useGetHistoricData({ id });
  const translate = useTranslate();

  if (error) return <Card>{translate("user_feedback.an_error_occurred")}</Card>;
  if (isLoading) return <EfficiencyNavigatorLoader />;
  if (!historicData) return <Card>{translate("user_feedback.no_data")}</Card>;

  return (
    <Grid2
      container
      spacing={2}
      sx={{
        display: "flex",
      }}
    >
      <HistoricPieSection data={historicData.longTermData} />
      <HistoricCarouselSection data={historicData.longTermData} />
      <HistoricChartSection
        longTermData={historicData.longTermData}
        shortTermData={historicData.shortTermData}
      />
    </Grid2>
  );
};
