import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { intellectaClient } from "../intellectaClient";
import { IntellectaError, intellectaVersion } from "../../types";

const result = z.object({
  title: z.string(),
  sending_user: z.string(),
  user_notes: z.string(),
  query: z.string(),
  answer: z.string(),
  image: z.string().optional(),
  version: intellectaVersion,
  profile: z.string(),
  machine: z.string().optional(),
});

export type SharedMessageResult = z.infer<typeof result>;

type Response = IntellectaError | SharedMessageResult;

type Payload = {
  social_id: number;
};

export const useGetSharedMessage = ({ social_id }: { social_id: number }) => {
  return useQuery<SharedMessageResult, Error>({
    queryKey: ["intellecta-social-get-shared-message", social_id],
    queryFn: async () => {
      const response = await intellectaClient<Payload, Response>({
        url: "/social/get_shared_message",
        payload: { social_id },
      });

      if (response === "ERROR") {
        throw new Error("An error occurred");
      }

      const validatedResponse = result.parse(response);
      return validatedResponse;
    },
  });
};
