import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { Box, Grid2, Popover, Stack, Typography } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Piechart } from "@/components/highcharts/piechart/Piechart";
import {
  useGetSentinelLiveData,
  PieData,
  CategoryCode,
} from "@/features/MachineDetail/api/CyclicLive/useGetSentinelLiveData";
import { SmallCircleLayout } from "@/features/MachineDetail/components/CircleLayout";
import { GaugeLayout } from "@/features/MachineDetail/components/GaugeLayout";
import { ProductCounter } from "@/features/MachineDetail/components/ProductCounter";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { SpeedInfo } from "@/features/MachineDetail/components/SpeedInfo";
import { colors } from "@/styles/colors";
import { CurrentMachineState } from "@/features/MachineDetail/components/CurrentMachineState";
import { Recipe } from "@/features/MachineDetail/components/Recipe";
import { useGetRecipe } from "@/features/MachineDetail/api/useGetRecipe";
import { NavButton } from "@/components/NavButton";
import { useTimeSelection } from "@/store/useTimeSelection";
import { useCheckMachinePage } from "@/hooks/check-machine-page";
import { useMachineContext } from "@/context/machine-context";
import { LineDetailButton } from "@/features/MachineDetail/components/LineDetailButton";
import { CustomSelectTimeSelection } from "@/components/SelectTimeSelection/CustomSelectTimeSelection";
import { LiveLoadingView } from "./LiveLoadingView";

export const SentinelLiveView = () => {
  const translate = useTranslate();
  const { machine } = useMachineContext();
  const { hasAccess } = useCheckMachinePage("historicMachineDetail");

  return (
    <>
      <MachineViewNavBar>
        <Stack
          direction={{ sm: "row", xs: "column" }}
          sx={{
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <CustomSelectTimeSelection
            exclude={["TwoWeeks", "Month", "ThreeMonths", "SixMonths", "Year"]}
            unavailableShortcuts={["14d", "30d"]}
            maxDays={14}
          />
          {hasAccess ? (
            <NavButton
              to="/machine-detail/sentinel/historic"
              text={translate("view_historic")}
            />
          ) : null}
          {machine?.lineId && <LineDetailButton lineId={machine.lineId} />}
        </Stack>
      </MachineViewNavBar>
      <LiveViewContent />
    </>
  );
};

const LiveViewContent = () => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetSentinelLiveData();

  if (isLoading) {
    return <LiveLoadingView />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  return data ? (
    <Stack sx={{
      gap: 2,
    }}>
      <TopRow {...data} />
      <KpiInfo {...data} />
      <SpeedInfo />
    </Stack>
  ) : null;
};

function Alarm({
  alarmDescription,
  firstAlarm,
}: {
  alarmDescription: string;
  firstAlarm: number;
}) {
  const { hasAccess } = useCheckMachinePage("alarms");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();
  const translate = useTranslate();
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <SmallCircleLayout
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={() => {
          if (hasAccess) {
            navigate("/alarms");
          }
        }}
        sx={{
          fontSize: {
            xs: ".75rem",
            sm: "1rem",
          },
          textAlign: {
            xs: "center",
          },
          cursor: "pointer",
        }}
      >
        <Typography
          sx={{
            color: "red",
          }}
        >
          {translate("alarms")}
        </Typography>
        <Box>{firstAlarm === 0 ? translate("no_alarm") : firstAlarm}</Box>
      </SmallCircleLayout>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{alarmDescription}</Typography>
      </Popover>
    </>
  );
}

function TopRow({
  wasteCounterMachine,
  alarmDescription,
  firstAlarm,
  stateColor,
  stateString,
  recipe,
  recipeChanged,
  recipeClient,
  pieData,
  categoryCode,
  totalProduct,
  goodCounter,
  wasteCounterInteraction,
  wasteCounterLine,
}: {
  wasteCounterMachine: number;
  alarmDescription: string;
  stateColor: string;
  stateString: string;
  recipe: string;
  recipeChanged: boolean;
  recipeClient: string;
  pieData: PieData[];
  firstAlarm: number;
  categoryCode: CategoryCode;
  totalProduct: number;
  goodCounter: number;
  wasteCounterInteraction: number;
  wasteCounterLine: number;
}) {
  const { data: recipeContent } = useGetRecipe();
  const { timeSelection } = useTimeSelection();
  const { hasAccess } = useCheckMachinePage("stateLog");
  const translate = useTranslate();
  return (
    <Grid2
      container
      columns={12}
      spacing={2}
      sx={{
        alignItems: "center",
      }}
    >
      <Grid2
        size={{
          xs: 6,
          md: 2,
        }}
      >
        <ProductCounter
          page={"machineDetail"}
          counter={totalProduct}
          text={translate("machine.products")}
          goodCounter={goodCounter}
          machineWaste={wasteCounterMachine}
          lineWaste={wasteCounterLine}
          interactionWaste={wasteCounterInteraction}
        />
      </Grid2>
      <Grid2
        size={{
          xs: 6,
          md: 2,
        }}
        sx={{
          justifyContent: "center",
        }}
      >
        <Alarm alarmDescription={alarmDescription} firstAlarm={firstAlarm} />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          md: 4,
        }}
      >
        <Stack
          sx={{
            gap: 2,
          }}
        >
          <CurrentMachineState
            categoryCode={categoryCode}
            color={stateColor}
            state={stateString}
            linkTo={hasAccess ? "/state-log" : undefined}
            dateTimeHandler={() => {
              useTimeSelection.setState((prevState) => ({
                ...prevState,
                dates: {
                  dateStart:
                    timeSelection === "Day"
                      ? DateTime.now().minus({ days: 1 })
                      : timeSelection === "Week"
                      ? DateTime.now().minus({ weeks: 1 })
                      : timeSelection === "Month"
                      ? DateTime.now().minus({ months: 1 })
                      : DateTime.now().minus({ hours: 8 }),
                  dateEnd: DateTime.now(),
                },
              }));
            }}
          />
          <Recipe
            recipe={recipe}
            recipeChanged={recipeChanged}
            recipeClient={recipeClient}
            recipeContent={recipeContent}
          />
        </Stack>
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          md: 4,
        }}
        sx={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            paddingX: "5%",
            height: {
              sx: "200px",
              md: "auto",
            },
          }}
        >
          <Piechart data={pieData} label="" title="" version="machineDetail" />
        </Box>
      </Grid2>
    </Grid2>
  );
}

function KpiInfo({
  availability,
  performance,
  quality,
  oee,
  elapsedTime,
  inactiveTime,
  uptime,
  totalTime,
}: {
  availability: number;
  performance: number;
  quality: number;
  oee: number;
  elapsedTime: number;
  inactiveTime: number;
  uptime: number;
  totalTime: number;
}) {
  const translate = useTranslate();
  return (
    <Grid2
      id="machine-detail-gauge"
      container
      columns={8}
      sx={{
        alignItems: "center",
      }}
    >
      <Grid2
        size={{
          md: 1,
        }}
        sx={{
          alignItems: "center",
          flex: 1,
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <GaugeLayout
          dimension="small"
          type="percentage"
          min={0}
          max={100}
          color={colors.kpi.availability}
          title={translate("kpi.availability")}
          value={availability}
        />
      </Grid2>
      <Grid2
        size={{
          md: 1,
        }}
        sx={{
          alignItems: "center",
          flex: 1,
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <GaugeLayout
          dimension="small"
          type="percentage"
          color={colors.kpi.performance}
          min={0}
          max={100}
          title={translate("kpi.performance")}
          value={performance}
        />
      </Grid2>
      <Grid2
        size={{
          md: 1,
        }}
        sx={{
          alignItems: "center",
          flex: 1,
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <GaugeLayout
          dimension="small"
          min={0}
          color={colors.kpi.quality}
          max={100}
          type="percentage"
          title={translate("kpi.quality")}
          value={quality}
        />
      </Grid2>
      <Grid2
        size={{
          md: 2,
        }}
        sx={{
          alignItems: "center",
          flex: 1,
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <GaugeLayout
          dimension="large"
          color={colors.kpi.oee}
          min={0}
          max={100}
          type="percentage"
          title={translate("kpi.oee")}
          value={oee}
        />
      </Grid2>
      <Grid2
        size={{
          md: 1,
        }}
        sx={{
          alignItems: "center",
          flex: 1,
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <GaugeLayout
          dimension="small"
          color={colors.kpi.elapsedTime}
          min={0}
          max={totalTime * 1000}
          type="duration"
          title={translate("machine.elapsed_time")}
          value={elapsedTime * 1000}
        />
      </Grid2>
      <Grid2
        size={{
          md: 1,
        }}
        sx={{
          alignItems: "center",
          flex: 1,
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <GaugeLayout
          dimension="small"
          type="duration"
          min={0}
          color={colors.kpi.inactiveTime}
          max={totalTime * 1000}
          title={translate("machine.idle_time")}
          value={inactiveTime * 1000}
        />
      </Grid2>
      <Grid2
        size={{
          md: 1,
        }}
        sx={{
          alignItems: "center",
          flex: 1,
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <GaugeLayout
          dimension="small"
          type="duration"
          color={colors.kpi.uptime}
          min={0}
          max={totalTime * 1000}
          title={translate("machine.running_time")}
          value={uptime * 1000}
        />
      </Grid2>
    </Grid2>
  );
}
