import { XrangePointOptionsObject } from "highcharts/highstock";
import { StateLogDataEntry } from "../api/useGetData";

export const mapXrangeData = ({
  data,
}: {
  data: StateLogDataEntry[];
}): { categories: string[]; chartData: XrangePointOptionsObject[] } => {
  const chartData: XrangePointOptionsObject[] = [];
  const categories: string[] = [];

  data.forEach((occurrence) => {
    if (!categories.includes(occurrence.name)) categories.push(occurrence.name);
  });

  data.forEach((occurrence) => {
    const index = categories.indexOf(occurrence.name);
    if (index !== -1) {
      chartData.push({
        name: occurrence.name,
        x: occurrence.start,
        x2: occurrence.end,
        y: index,
        color: occurrence.color,
        custom: {
          isError: occurrence.isError,
          isProduction: occurrence.isProduction,
        },
      });
    }
  });

  return { categories, chartData };
};
