import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import {
  IntellectaError,
  IntellectaResult,
  IntellectaVersion,
  intellectaResult,
} from "../types";
import { intellectaClient } from "./intellectaClient";

type Response = IntellectaError | IntellectaResult;

type Payload = {
  user: string;
  version: IntellectaVersion;
  profile: string;
};

export const useSetActiveProfile = () => {
  const queryClient = useQueryClient();
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  return useMutation({
    mutationFn: async ({
      version,
      profile,
    }: {
      version: IntellectaVersion;
      profile: string;
    }) => {
      const response = await intellectaClient<Payload, Response>({
        url: "/set_active_profile",
        payload: { user: email, version, profile },
      });

      const validatedResponse = intellectaResult.parse(response);
      return validatedResponse;
    },
    onSettled: (data) => {
      if (data === "OK") {
        //* per scaricare di nuovo la configurazione dell'utente
        queryClient.invalidateQueries({
          queryKey: ["intellecta-config", email],
        });
      }
    },
  });
};
