import { useState, useEffect } from "react";
import { useAcoposDispatchContext } from "../context/acopos-context";
import { useTranslate } from "@/i18n/config";
import { useCheckIfCanRenderAnalytic } from "../utils/useCheckIfCanRenderAnalytic";
import { Box, Stack, Typography } from "@mui/material";
import { SelectSegment } from "../components/Selections/SelectSegment";
import { SelectShuttlesDeviances } from "../components/Selections/SelectShuttles";
import { DeviancesAnalytics } from "../components/Monitoring/DeviancesAnalytics";
import { Card } from "@/components/Layout/Card";
import { SelectDeviancesTimelapse } from "../components/Monitoring/SelectDeviancesTimelapse";
import { Timelapse } from "../types";

export const DeviancesMonitoring = () => {
  const translate = useTranslate();
  const dispatch = useAcoposDispatchContext();
  const [timelapse, setTimelapse] = useState<Timelapse>("week");
  const { canRenderAnalytic, noSegmentSelected, noShuttlesSelected } =
    useCheckIfCanRenderAnalytic({
      hasRunsSelection: false,
      hasLapsSelection: false,
    });

  useEffect(() => {
    dispatch({ type: "SET_MAX_DAYS_CALENDAR", item: 30 });
  }, []);

  return (
    <Stack
      sx={{
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: ".5rem",
        }}
      >
        <SelectSegment />
        <SelectDeviancesTimelapse
          timelapse={timelapse}
          setTimelapse={setTimelapse}
        />
        <SelectShuttlesDeviances />
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        {canRenderAnalytic ? (
          <DeviancesAnalytics timelapse={timelapse} />
        ) : (
          <Card sx={{ height: 400 }}>
            <Typography sx={{ color: "red" }} variant="h4">
              {translate("motors.no_selection")}
            </Typography>
            {noSegmentSelected && (
              <Typography variant="h6">
                {translate("acopos.select_at_least_one_segment")}
              </Typography>
            )}
            {noShuttlesSelected && (
              <Typography variant="h6">
                {translate("acopos.select_at_least_one_shuttle")}
              </Typography>
            )}
          </Card>
        )}
      </Box>
    </Stack>
  );
};
