import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { Box, Stack, Typography } from "@mui/material";
import { LossType } from "../../types";
import { useGetLongTermData } from "../../api/useGetLongTermData";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { BarChart } from "@/components/highcharts/barchart/Barchart";

export const LongTermChart = ({
  title,
  color,
  lossType,
}: {
  title: string;
  color: string;
  lossType: LossType;
}) => {
  const translate = useTranslate();
  const { data, error, isLoading } = useGetLongTermData();

  if (isLoading) return <SkeletonCard height={300} />;

  if (error)
    return (
      <Card sx={{ height: 300 }}>
        {translate("user_feedback.an_error_occurred")}
      </Card>
    );

  if (!data)
    return (
      <Card sx={{ height: 300 }}>
        {translate("user_feedback.no_data_to_display")}
      </Card>
    );

  const categoryValue =
    lossType === "machine"
      ? data.oeeMachineStatus.machine
      : lossType === "process"
      ? data.oeeMachineStatus.process
      : data.oeeMachineStatus.line;

  const barChartData =
    lossType === "machine"
      ? data.machineLosses
      : lossType === "process"
      ? data.processLosses
      : data.lineLosses;

  const dataSorted = barChartData.sort((a, b) =>
    a.value < b.value ? 1 : b.value < a.value ? -1 : 0,
  );

  const categories = dataSorted.map((element) => element.lossName);
  const realData = dataSorted.map((element) => ({
    name: element.lossName,
    y: +element.value.toFixed(2),
    custom: {
      benchmark: +element.valueBm.toFixed(2),
      mttr: TimeHelpers.parseDurationToString({
        duration: element.mttr * 60 * 1000,
      }),
      mttrBm: TimeHelpers.parseDurationToString({
        duration: element.mttrBm * 60 * 1000,
      }),
    },
  }));

  const targetData = dataSorted.map((element) => ({
    name: element.lossName,
    y: +element.valueBm.toFixed(2),
  }));

  const benchmarksSum = targetData
    .reduce((acc, curr) => acc + curr.y, 0)
    .toFixed(2);

  return (
    <Card
      sx={{
        height: 300,
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: 1, alignItems: "baseline" }}>
          <Typography>{title}</Typography>
          <Typography sx={{ color: "#bbb", fontSize: ".6rem" }}>
            {translate("long_term")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              color: "#bbb",
            }}
          >
            {translate("overall")} |
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: "#bbb",
            }}
          >
            {translate("current").toLowerCase()}
          </Typography>
          <Typography>{categoryValue.toFixed(2)}%</Typography>
          <Typography
            variant="caption"
            sx={{
              color: "#bbb",
            }}
          >
            {translate("benchmark").toLowerCase()}
          </Typography>
          <Typography>{benchmarksSum}%</Typography>
        </Box>
      </Stack>
      <Box sx={{ height: 240 }}>
        <BarChart.Custom
          categories={categories}
          filename={fileNameFactory({
            name: "efficiency_navigator",
          })}
          height={240}
          series={[
            {
              name: translate("target"),
              type: "column",
              color: `${color}50`,
              data: targetData,
              // @ts-expect-error Highcharts non riconosce le proprietà personalizzate, ma funzionano a runtime.
              borderRadiusTopRight: 4,
              borderRadiusTopLeft: 4,
            },
            {
              name: translate("real"),
              type: "column",
              data: realData,
              color: color,
              pointPadding: 0.1,
              pointPlacement: -0.1,
              // @ts-expect-error Highcharts non riconosce le proprietà personalizzate, ma funzionano a runtime.
              borderRadiusTopRight: 4,
              borderRadiusTopLeft: 4,
            },
          ]}
          uom="%"
          xAxisMax={realData.length > 5 ? 4 : undefined}
          scrollbarEnabled={realData.length > 5 ? true : false}
          tooltip={{
            enabled: true,
            options: {
              useHTML: true,
              formatter: function (this) {
                const point = this.points?.[1].point as unknown as {
                  name: string;
                  custom: {
                    benchmark: number;
                    mttr: string;
                    mttrBm: string;
                  };
                };
                if (point) {
                  return `
                      <small>${point.name}</small><br />
                      ${translate("value")}: <b>${this.points?.[1].y}%</b><br />
                      ${translate("mttr")}: <b>${point.custom.mttr}</b><br />
                      ${translate("benchmark")}: <b>${
                        point.custom.benchmark
                      }</b><br />
                      ${translate("mttr")} ${translate("benchmark")}: <b>${
                        point.custom.mttrBm
                      }</b>
                      `;
                }
              },
            },
          }}
        />
      </Box>
    </Card>
  );
};
