import { Box, Stack, Typography } from "@mui/material";
import { toast } from "react-hot-toast";
import { useTranslate } from "@/i18n/config";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { HistoricChat } from "./HistoricChat";
import { Chat } from "../../../types";
import { useGetHistoricChatList } from "@/features/Intellecta/api/Historic/useGetHistoricChatList";
import { userConfigurationStore } from "../../../store/user-configuration-store";
import { getGroupedChatsByDate } from "../../../utilities/getGroupedChatsByDate";

export const Historic = () => {
  const translate = useTranslate();
  const { data, error, isLoading } = useGetHistoricChatList();
  const { version, profile } = userConfigurationStore();

  if (error) {
    return toast.error(translate("user_feedback.an_error_occurred"));
  }

  if (isLoading) {
    return (
      <Stack p={2} gap={1}>
        <SkeletonCard height="42px" sx={{ borderRadius: 4 }} />
        <SkeletonCard height="42px" sx={{ borderRadius: 4 }} />
        <SkeletonCard height="42px" sx={{ borderRadius: 4 }} />
      </Stack>
    );
  }

  if (!data) {
    return toast.error(translate("user_feedback.no_data"));
  }

  const historicChats = [...data.chat_history];
  const filteredByVersionAndProfile = historicChats.filter(
    (chat) => chat.version === version && chat.profile === profile,
  );

  const pinnedChats: Chat[] = [];
  const unpinnedChats: Chat[] = [];
  filteredByVersionAndProfile.forEach((chat) => {
    if (chat.is_pinned) {
      pinnedChats.push(chat);
    } else {
      unpinnedChats.push(chat);
    }
  });
  const sortedPinnedChats = getGroupedChatsByDate({
    chats: pinnedChats,
    translate,
  });
  const sortedUnpinnedChats = getGroupedChatsByDate({
    chats: unpinnedChats,
    translate,
  });

  return (
    <Stack p={1} gap={1}>
      {Object.keys(sortedPinnedChats).map((relativeDuration) => (
        <Box key={"pinned-" + relativeDuration}>
          <Typography
            variant="caption"
            key={"label-pinned-" + relativeDuration}
            pl={1}
            color="#ccc"
            // fontWeight="bold"
          >
            {relativeDuration}
          </Typography>
          {sortedPinnedChats[relativeDuration].map((chat) => (
            <HistoricChat key={chat.id} chat={chat} />
          ))}
        </Box>
      ))}
      {Object.keys(sortedUnpinnedChats).map((relativeDuration) => (
        <Box key={"unpinned-" + relativeDuration}>
          <Typography
            variant="caption"
            key={"label-unpinned-" + relativeDuration}
            pl={1}
            color="#ccc"
            // fontWeight="bold"
          >
            {relativeDuration}
          </Typography>
          {sortedUnpinnedChats[relativeDuration].map((chat) => (
            <HistoricChat key={chat.id} chat={chat} />
          ))}
        </Box>
      ))}
    </Stack>
  );
};
