import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { Card } from "@/components/Layout/Card";
import { SentinelLoader } from "@/components/Layout/SentinelLoader";
import { Stack } from "@mui/material";
import { useState } from "react";
import { AdvancedAnalyticEvaluationInfo } from "../api/useGetAdvancedAnalysisList";
import { useGetAnomalyDetectionData } from "../api/useGetAnomalyDetectionData";
import { AdvancedAnalyticsList } from "./AdvancedAnalyticsList";
import { useTranslate } from "@/i18n/config";
import { fileNameFactory } from "@/utils/fileNameFactory";

export const AnomalyDetection = ({
  analytics,
}: {
  analytics: AdvancedAnalyticEvaluationInfo[];
}) => {
  const [selectedAnalytic, setSelectedAnalytic] =
    useState<AdvancedAnalyticEvaluationInfo>();

  return (
    <>
      <AdvancedAnalyticsList
        items={analytics.map((analytic) => ({
          id_value: analytic.id,
          ...analytic,
        }))}
        renderTextItem={(item) => item.name}
        onItemClicked={(item) => {
          setSelectedAnalytic(item);
        }}
        activeId={selectedAnalytic?.id}
      />
      {selectedAnalytic ? <AnomalyChart analytic={selectedAnalytic} /> : null}
    </>
  );
};

function AnomalyChart({
  analytic,
}: {
  analytic: AdvancedAnalyticEvaluationInfo;
}) {
  const translate = useTranslate();
  const { data = { anomalies: [], datapoints: [] }, isLoading } =
    useGetAnomalyDetectionData({
      analytic,
    });
  const values = data.datapoints.map((item) => [item.timestamp, item.value]);

  return (
    <Card>
      {isLoading ? (
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "center",
            gap: 4,
            flex: 1,
            p: 4,
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <SentinelLoader />
          <span style={{ display: "block" }}>
            {translate("user_feedback.loading")}
          </span>
        </Stack>
      ) : (
        <LineChart.Custom
          filename={fileNameFactory({
            name: analytic.name,
          })}
          title={analytic.name}
          xAxisOptions={{
            plotLines: data.anomalies,
          }}
          yAxis={[
            {
              uom: analytic.uom,

              series: [
                {
                  type: "line",
                  data: values,
                  name: analytic.name,
                  color: "#00ff00",
                },
              ],
            },
          ]}
        />
      )}
    </Card>
  );
}
