import { Fragment, memo } from "react";
import { toast } from "react-hot-toast";
import { MachineNodeView } from "@/features/OverallLineEfficiency/components/CustomNodes";
import { Node, NodeProps, NodeTypes, useNodes, useNodeId } from "reactflow";
import { CustomHandle } from "../custom-handles/CustomHandle";
import { useRemoveNode } from "../hooks/useRemoveNode";
import { NodeBlockData } from "../types";
import { PlaceholderMachineNode } from "./view/PlaceholderNode";
import { Box, Dialog, IconButton, Stack } from "@mui/material";
import { DeleteOutline, EditOutlined, UndoOutlined } from "@mui/icons-material";
import { useDisclosure } from "@/hooks/useDisclosure";
import { UpdateNodeEditor } from "../components/NodeEditorModal";
import { NodeEditorContextProvider } from "../context/NodeEditorContextProvider";
import { useOeeLineContextState } from "../../LineOverview/context/useOeeLineContextState";
import { useOeeLineContextDispatch } from "../../LineOverview/context/useOeeLineContextDispatch";
import { useLineEditorDispatch } from "../context/useLineEditorDispatch";
import { useTranslate } from "@/i18n/config";

/**
 * @description used to render nodes in the line efficiency editor
 */

export type CustomMachineLineEditNode = Node<NodeBlockData, "machine">;

const MachineNode = memo((props: NodeProps<NodeBlockData>) => {
  const { open, isOpen, close } = useDisclosure();
  const { remove } = useRemoveNode();
  const nodeId = useNodeId();

  const { machinesInfo } = useOeeLineContextState();
  const dispatch = useLineEditorDispatch();
  const lineConfigDispatch = useOeeLineContextDispatch();
  const currentConfig = machinesInfo.find(
    (el) => el.machine_name === props.data.name,
  );
  const translate = useTranslate();

  const deleteNode = () => {
    nodeId && remove(nodeId);
    lineConfigDispatch({
      type: "remove machine info",
      machine_name: props.data.name,
    });
  };

  const handles = Object.entries(props.data.handles)
    .filter(([, edge]) => edge.enabled)
    .map(([id, edge]) => {
      return edge.enabled && <CustomHandle key={id} type={edge.type} id={id} />;
    });

  const nodes = useNodes();

  const node = nodes.find((node) => node.id === nodeId);

  if (!node) {
    return null;
  }

  if (!nodeId) {
    return null;
  }

  // const handleEnable = () => {
  //   console.log("enable machine");
  // };

  // const handleDisable = () => {
  //   console.log("disable machine");
  // };

  return (
    <Fragment>
      <Dialog open={isOpen} onClose={close} fullWidth maxWidth="lg">
        <NodeEditorContextProvider handles={props.data.handles}>
          <UpdateNodeEditor
            close={close}
            isPlaceholder={props.data.isPlaceholder}
            nodeId={nodeId}
            nodeName={props.data.name}
            position={node.position}
          />
        </NodeEditorContextProvider>
      </Dialog>
      <Box
        sx={{
          position: "relative",
          width: node?.width ?? 450,
          height: node?.height ?? 150,
        }}
      >
        {handles}
        <Stack
          direction="row"
          sx={{
            gap: 2,
            padding: 1,
            position: "absolute",
            right: "0",
          }}
        >
          {/* fare attiva / disattiva */}
          {/* <IconButton aria-label="delete" size="small" onClick={handleEnable}>
            <ToggleOffOutlined fontSize="inherit" />
          </IconButton>
          <IconButton aria-label="delete" size="small" onClick={handleDisable}>
            <ToggleOnOutlined fontSize="inherit" />
          </IconButton> */}
          <IconButton aria-label="delete" size="small" onClick={() => open()}>
            <EditOutlined fontSize="inherit" />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={async () => {
              deleteNode();
              toast((t) => (
                <Box>
                  {translate("line.deleted")}
                  <IconButton
                    size="small"
                    aria-label="undo"
                    onClick={() => {
                      if (node) {
                        dispatch({ type: "add node", node: node });
                      }
                      if (currentConfig) {
                        lineConfigDispatch({
                          type: "add machine info",
                          machine_info: currentConfig,
                        });
                      }
                      toast.dismiss(t.id);
                    }}
                  >
                    <UndoOutlined fontSize="inherit" />
                  </IconButton>
                </Box>
              ));
            }}
          >
            <DeleteOutline fontSize="inherit" />
          </IconButton>
        </Stack>
        {props.data.isPlaceholder ? (
          <PlaceholderMachineNode model={props.data.name} />
        ) : (
          <MachineNodeView
            machineName={props.data.name}
            data={{
              rejected_products: 0,
              total_products: 0,
              categoryCode: 1,
              extraCapacity: 10,
              lineLosses: 20,
              machineLosses: 30,
              machine_type: "cyclic",
              machineLogic: "parametric",
              oee: 10,
              processLosses: 20,
              hasBatch: false,
              hasSpeed: false,
              response: true,
              state: translate("production"),
              products: 0,
              machineTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }}
          />
        )}
      </Box>
    </Fragment>
  );
});

export const machineEditNodeTypes: NodeTypes = {
  machine: MachineNode,
};
