import { Card } from "@/components/Layout/Card";
import { Stack, Button } from "@mui/material";
import {
  useAcceptanceTestContext,
  useAcceptanceTestContextDispatch,
} from "../context/acceptanceTest-context";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useSetEnableSat } from "../api/useSetEnableSat";
import { useSetIsPause } from "../api/useSetIsPause";
import { useTranslate } from "@/i18n/config";

export const ControlPanel = () => {
  const { isSatActive, hasActivatedRequestAT } = useAcceptanceTestContext();
  return (
    <Card>
      <Stack
        direction="row"
        sx={{
          gap: 10,
          justifyContent: "center",
        }}
      >
        {!isSatActive && !hasActivatedRequestAT ? (
          <StartButton />
        ) : (
          <PlayControl />
        )}
      </Stack>
    </Card>
  );
};

const PlayControl = () => {
  const { isPauseSat, hasActivatedRequestAT } = useAcceptanceTestContext();
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      {isPauseSat ? (
        <PauseButton />
      ) : (
        <RunningButton disabled={hasActivatedRequestAT} />
      )}
      <CloseButton />
    </Stack>
  );
};

const StartButton = () => {
  const { satConfiguration } = useAcceptanceTestContext();
  const dispatch = useAcceptanceTestContextDispatch();
  const { mutate: setEnable } = useSetEnableSat();
  const translate = useTranslate();
  return (
    <Button
      endIcon={
        <PlayCircleOutlineOutlinedIcon fontSize={"large"} onClick={() => {}} />
      }
      variant="outlined"
      onClick={() => {
        dispatch({
          type: "sat-configuration-disabled",
          satConfigurationDisabled: true,
        });
        setEnable({
          isActive: true,
          isLimitedLogic: satConfiguration === "Blocked" ? true : false,
        });
        dispatch({ type: "test-is-active", isSatActive: true });
        dispatch({ type: "close-sat", isClosedSat: false });
      }}
    >
      {translate("acceptance_test.start_test")}
    </Button>
  );
};

const PauseButton = () => {
  const dispatch = useAcceptanceTestContextDispatch();
  const { mutate: setSatInPause } = useSetIsPause();
  const translate = useTranslate();

  return (
    <Button
      variant="outlined"
      onClick={() => {
        setSatInPause({ isPause: false });
        dispatch({ type: "check-sat-isPaused", isPauseSat: false });
      }}
      endIcon={<PlayCircleOutlineOutlinedIcon fontSize={"large"} />}
    >
      {translate("acceptance_test.pause")}
    </Button>
  );
};

const RunningButton = ({ disabled }: { disabled: boolean }) => {
  const dispatch = useAcceptanceTestContextDispatch();
  const { mutate: setSatInPause } = useSetIsPause();
  const translate = useTranslate();

  return (
    <Button
      disabled={disabled}
      variant="outlined"
      onClick={() => {
        setSatInPause({ isPause: true });
        dispatch({ type: "check-sat-isPaused", isPauseSat: true });
      }}
      endIcon={<PauseCircleOutlineOutlinedIcon fontSize={"large"} />}
      color="success"
    >
      {translate("acceptance_test.running")}
    </Button>
  );
};

const CloseButton = () => {
  const dispatch = useAcceptanceTestContextDispatch();
  const { mutate: setEnable } = useSetEnableSat();
  const translate = useTranslate();

  return (
    <Button
      onClick={() => {
        dispatch({
          type: "sat-configuration-disabled",
          satConfigurationDisabled: false,
        });
        setEnable({ isActive: false, isLimitedLogic: true });
        dispatch({ type: "test-is-active", isSatActive: false });
        dispatch({ type: "close-sat", isClosedSat: true });
      }}
      variant="outlined"
      endIcon={<CancelOutlinedIcon fontSize={"large"} />}
      color="error"
    >
      {translate("acceptance_test.close")}
    </Button>
  );
};
