import { useState } from "react";
import { toast } from "react-hot-toast";
import {
  Typography,
  Stack,
  Box,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { DeleteOutline, PushPin, PushPinOutlined } from "@mui/icons-material";
import { useTranslate } from "@/i18n/config";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useMarkNotificationAsRead } from "@/features/Intellecta/api/Social/useMarkNotificationAsRead";
import { usePinNotificationById } from "@/features/Intellecta/api/Social/usePinNotificationById";
import { useUnpinNotificationById } from "@/features/Intellecta/api/Social/useUnpinNotificationById";
import { getTimeAgoFromTs } from "../../../utilities/getTimeAgoFromTs";
import { Notification } from "../../../types";
import { intellectaColors } from "../../../utilities/colors";
import { SharedMessageModal } from "./SharedMessageModal";
import { useDeleteNotificationById } from "@/features/Intellecta/api/Social/useDeleteNotificationById";

export const SocialNotification = ({
  notification,
  onUsePrompt,
}: {
  notification: Notification;
  onUsePrompt: (prompt: string) => void;
}) => {
  const translate = useTranslate();
  const { close, isOpen, open } = useDisclosure();
  const { mutateAsync: markNotificationAsRead } = useMarkNotificationAsRead();
  const { mutateAsync: pinNotification, isPending: isPinning } =
    usePinNotificationById();
  const { mutateAsync: unpinNotification, isPending: isUnpinning } =
    useUnpinNotificationById();
  const { mutateAsync: deleteNotification } = useDeleteNotificationById();
  const [showActions, setShowActions] = useState(false);

  const handleReadNotification = () => {
    if (!notification.is_read) {
      markNotificationAsRead({ social_id: notification.social_id });
    }
    open();
  };

  const handlePin = (event: React.MouseEvent) => {
    event.stopPropagation();
    pinNotification(
      { social_id: notification.social_id },
      {
        onSuccess() {
          toast.success(
            translate("intellecta.notification_pinned_successfully"),
          );
        },
        onError() {
          toast.error(translate("user_feedback.an_error_occurred"));
        },
      },
    );
  };

  const handleUnpin = (event: React.MouseEvent) => {
    event.stopPropagation();
    unpinNotification(
      { social_id: notification.social_id },
      {
        onSuccess() {
          toast.success(
            translate("intellecta.notification_unpinned_successfully"),
          );
        },
        onError() {
          toast.error(translate("user_feedback.an_error_occurred"));
        },
      },
    );
  };

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    deleteNotification(
      { social_id: notification.social_id },
      {
        onSuccess() {
          toast.success(
            translate("intellecta.notification_deleted_successfully"),
          );
        },
        onError() {
          toast.error(translate("user_feedback.an_error_occurred"));
        },
      },
    );
  };

  return (
    <>
      <Stack
        borderRadius={2}
        p={2}
        pt={1}
        boxShadow={notification.is_read ? 0 : 4}
        sx={{
          cursor: "pointer",
          transition: "all 0.3s",
          background: notification.is_read
            ? `linear-gradient(135deg, ${intellectaColors.powerWordButtonBackgroundHover} 0%, ${intellectaColors.powerWordButtonBackground} 100%)`
            : `linear-gradient(135deg, ${intellectaColors.unreadNotificationBackground} 0%, ${intellectaColors.unreadNotificationBackground}90 100%)`,
          ":hover": { boxShadow: 6 },
        }}
        onClick={handleReadNotification}
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="caption"
            color="textSecondary"
            fontWeight={notification.is_read ? "regular" : "bold"}
            sx={{ flexShrink: 0 }} // Impedisce che il testo venga spinto via
          >
            {getTimeAgoFromTs({
              timestamp: notification.timestamp * 1000,
              translate,
            })}
          </Typography>

          <Box
            display="flex"
            alignItems="center"
            sx={{
              marginLeft: "auto",
              gap: 1,
            }}
          >
            <Tooltip title={translate("actions.delete")}>
              <IconButton
                sx={{
                  visibility:
                    isPinning || isUnpinning
                      ? "hidden"
                      : showActions
                      ? "visible"
                      : "hidden",
                }}
                onClick={handleDelete}
              >
                <DeleteOutline fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip
              title={
                notification.is_pinned
                  ? translate("actions.unpin")
                  : translate("actions.pin")
              }
            >
              <Box
                sx={{
                  width: 24,
                  height: 24,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isPinning || isUnpinning ? (
                  <CircularProgress size={20} />
                ) : notification.is_pinned ? (
                  <IconButton
                    sx={{
                      color: intellectaColors.pinnedItem,
                      visibility: "visible",
                    }}
                    onClick={handleUnpin}
                  >
                    <PushPin fontSize="small" />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{
                      color: "inherit",
                      visibility: showActions ? "visible" : "hidden",
                    }}
                    onClick={handlePin}
                  >
                    <PushPinOutlined fontSize="small" />
                  </IconButton>
                )}
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <Typography
          variant="caption"
          fontWeight={notification.is_read ? 700 : 900}
        >
          {notification.sending_user}
        </Typography>
        <Typography
          variant="body2"
          pt=".25rem"
          fontWeight={notification.is_read ? "regular" : "bold"}
        >
          {notification.title}
        </Typography>
      </Stack>
      {isOpen && (
        <SharedMessageModal
          close={close}
          isOpen={isOpen}
          socialId={notification.social_id}
          onUsePrompt={onUsePrompt}
        />
      )}
    </>
  );
};
