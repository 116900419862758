import { useEffect, useState } from "react";
import { ref, get, set } from "firebase/database";
import { getAuth } from "firebase/auth";
import { useLanguage } from "@/i18n/config";
import { db } from "@/lib/sentinel-app";
import { environment } from "../config";

type ReleaseMessage = {
  id: string;
  isEnabled: boolean;
  contentIt: string;
  contentEn: string;
  contentEs: string;
  contentDe: string;
};

export const useCheckIfReleaseMessage = (): {
  showMessage: boolean;
  content: string;
  dismissMessage: (doNotShowAgain: boolean) => Promise<void>;
} => {
  const [showMessage, setShowMessage] = useState(false);
  const [content, setContent] = useState("");
  const [releaseMessageId, setReleaseMessageId] = useState("");
  const language = useLanguage();

  useEffect(() => {
    const checkReleaseMessage = async () => {
      if (environment !== "production") return;

      const releaseMessageRef = ref(db, "releaseMessage");
      const snapshot = await get(releaseMessageRef);
      const releaseMessage: ReleaseMessage = snapshot.val();

      if (releaseMessage && releaseMessage.isEnabled) {
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
          // Gestire il caso in cui l'utente non è autenticato
          return;
        }

        const userUID = user.uid;
        const releaseMsgId = releaseMessage.id;
        setReleaseMessageId(releaseMsgId);

        // Controllo se la modale è già stata mostrata in questa sessione
        const hasShownInSession = sessionStorage.getItem(
          `shownReleaseMessage_${releaseMsgId}`,
        );

        if (hasShownInSession) {
          // La modale è già stasta mostrata in questa sessione, non fare nulla
          return;
        }

        const userReleaseMessageRef = ref(
          db,
          `users/${userUID}/hasReadReleaseMessage/${releaseMsgId}`,
        );

        const userSnapshot = await get(userReleaseMessageRef);
        const hasReadCurrentReleaseMessage = userSnapshot.val();

        if (hasReadCurrentReleaseMessage === true) {
          // L'utente ha letto il messaggio, non fare nulla
        } else {
          if (hasReadCurrentReleaseMessage === null) {
            // La chiave non esiste, la creo e la imposto su false
            await set(userReleaseMessageRef, false);
          }
          // Mostro il messaggio all'utente in base alla lingua selezionata
          setShowMessage(true);
          if (language === "de") setContent(releaseMessage.contentDe);
          if (language === "it") setContent(releaseMessage.contentIt);
          if (language === "es") setContent(releaseMessage.contentEs);
          if (language === "en") setContent(releaseMessage.contentEn);
          // Segno che la modale è stata mostrata in questa sessione
          sessionStorage.setItem(`shownReleaseMessage_${releaseMsgId}`, "true");
        }
      }
    };

    checkReleaseMessage();
  }, [language]);

  const dismissMessage = async (doNotShowAgain: boolean) => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      // Gestire il caso in cui l'utente non è autenticato
      return;
    }

    const userReleaseMessageRef = ref(
      db,
      `users/${user.uid}/hasReadReleaseMessage/${releaseMessageId}`,
    );

    if (doNotShowAgain) {
      // Imposto il valore a true per indicare che l'utente ha letto il messaggio e non vuole più vederlo
      await set(userReleaseMessageRef, true);
    }
    // Nascondo il messaggio
    setShowMessage(false);
  };

  return {
    showMessage,
    content,
    dismissMessage,
  };
};
