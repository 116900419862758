import { useMachineContext } from "@/context/machine-context";
import { Box, Button } from "@mui/material";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { OverviewPages, PageName } from "src/constants/sentinelNavigations";
import { Loader } from "./Layout/Loader";
import { useTranslate } from "@/i18n/config";
import { useGetUserOverviewPages } from "@/pages/Overview/api/useGetUserOverviewPages";

export const ProtectedRoute = ({
  children,
  page,
}: PropsWithChildren<{
  page: PageName;
}>) => {
  const { allPages, isLoading } = useMachineContext();

  if (isLoading) {
    return <Loader isLoading />;
  }

  if (!allPages.includes(page)) {
    return <NotAvailable />;
  }
  return <>{children}</>;
};

export const UserProtectedRoute = ({
  page,
  children,
}: PropsWithChildren<{
  page: OverviewPages;
}>) => {
  const { isLoading, data: pages } = useGetUserOverviewPages();

  if (isLoading) {
    return <Loader isLoading />;
  }

  if (!pages) {
    return <NotAvailable />;
  }

  if (!pages.includes(page)) {
    return <NotAvailable href="/overview/home" />;
  }
  return <>{children}</>;
};

const NotAvailable = ({ href = "/" }: { href?: string }) => {
  const translate = useTranslate();
  return (
    <Box>
      <h1>{translate("not_available")}</h1>
      <p>{translate("page_not_available")}</p>
      <Link to={href}>
        <Button>{translate("actions.back")}</Button>
      </Link>
    </Box>
  );
};

// import { useMachineContext } from "@/context/machine-context";
// import { useGetUserOverviewPages } from "@/pages/Overview/Overview";
// import { Box, Button } from "@mui/material";
// import { PropsWithChildren } from "react";
// import { Link } from "react-router-dom";
// import { OverviewPages, PageName } from "src/constants/sentinelNavigations";
// import { Loader } from "./Layout/Loader";
// import { useTranslate } from "@/i18n/config";

// export const ProtectedRoute = ({
//   children,
//   page,
// }: PropsWithChildren<{
//   page: PageName;
// }>) => {
//   const { allPages } = useMachineContext();
//   console.log(allPages);
//   console.log(page);

//   if (allPages.length === 0) return null;

//   if (!allPages.includes(page)) {
//     console.log(
//       "allPages non include la pagina in questione, renderizzo Not Available",
//     );
//     return <NotAvailable />;
//   }
//   return <>{children}</>;
// };

// export const UserProtectedRoute = ({
//   page,
//   children,
// }: PropsWithChildren<{
//   page: OverviewPages;
// }>) => {
//   const { isLoading, data: pages } = useGetUserOverviewPages();

//   if (isLoading) {
//     console.log("sta caricando");
//     return <Loader isLoading />;
//   }

//   if (!pages) {
//     console.log("non ho pages, renderizzo Not Available");
//     return <NotAvailable />;
//   }

//   if (!pages.includes(page)) {
//     console.log(
//       "pages non include la pagina in questione, renderizzo NotAvailable",
//     );
//     return <NotAvailable href="/overview/home" />;
//   }
//   return <>{children}</>;
// };

// const NotAvailable = ({ href = "/" }: { href?: string }) => {
//   const translate = useTranslate();
//   return (
//     <Box>
//       <h1>{translate("not_available")}</h1>
//       <p>{translate("page_not_available")}</p>
//       <Link to={href}>
//         <Button>{translate("actions.back")}</Button>
//       </Link>
//     </Box>
//   );
// };
