import { Skeleton, styled } from "@mui/material";

export const LightSkeletonContainer = styled(Skeleton)(() => ({
  backgroundColor: "#1C2539",
  color: "#fff",
  width: "100%",
  transform: "none",
  minHeight: "100%",
  backgroundImage: "none",
  padding: "1rem",
  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
  display: "flex",
  flexDirection: "column",
  gap: ".5rem",
  overflowY: "auto",
  borderRadius: ".25rem",
}));
