import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { ListSelectDropdown } from "@/components/ListSelectDropdown";
import { MenuItem, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetRejectCauseDetail } from "../api/useGetRejectCausesDetail";
import { useGetRejectCausesList } from "../api/useGetRejectCausesList";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { useMachineContext } from "@/context/machine-context";

export const RejectCauseDetail = () => {
  const translate = useTranslate();
  const { data: rejectCauseList, error } = useGetRejectCausesList();
  const [selectedCause, setSelectedCause] = useState<string>();

  useEffect(() => {
    if (rejectCauseList && rejectCauseList.length > 0) {
      setSelectedCause(rejectCauseList[0].rejectCause);
    }
  }, [rejectCauseList]);

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!rejectCauseList) {
    return null;
  }

  return (
    <Card>
      {rejectCauseList.length > 0 ? (
        <ListSelectDropdown
          data={rejectCauseList}
          textlabel="Reject Cause"
          defaultValue={rejectCauseList[0].rejectCause}
          onChange={(item) => {
            setSelectedCause(item);
          }}
          renderItem={(item) => (
            <MenuItem key={item.rejectCause} value={item.rejectCause} divider>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {item.rejectCauseClient}
                </Typography>
              </Stack>
            </MenuItem>
          )}
        />
      ) : null}
      {selectedCause ? (
        <RejectCauseDetailChart rejectCause={selectedCause} />
      ) : null}
    </Card>
  );
};

function RejectCauseDetailChart({ rejectCause }: { rejectCause: string }) {
  const translate = useTranslate();
  const { data } = useGetRejectCauseDetail({
    rejectCause,
  });
  const { machine } = useMachineContext();
  const machineName = machine?.machine;

  if (!data) return null;

  return (
    <LineChart.Custom
      filename={fileNameFactory({
        name: "reject_cause_detail",
        machine: machineName,
      })}
      yAxis={[
        {
          uom: translate("waste_other"),
          series: [
            {
              color: "#F7B500",
              name: data.cause,
              data: data.data || [],
              type: "line",
              step: "left",
              marker: {
                enabled: false,
              },
            },
          ],
        },
      ]}
      tooltip={{
        enabled: true,
      }}
      xAxisOptions={{ scrollbar: { enabled: false } }}
      title={translate("reject_causes.reject_cause_detail")}
      xAxisType="datetime"
      lineType={"line"}
    />
  );
}
