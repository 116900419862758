import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { useDisclosure } from "@/hooks/useDisclosure";
import { Modal } from "@/components/Modal";
import { PowerWord } from "../../types";
import { chatStore } from "../../store/chat-store";
import { userConfigurationStore } from "../../store/user-configuration-store";
import { usePowerWord } from "../../api/usePowerWord";
import { sentryModeStore } from "../../store/sentry-mode-store";
import { scrollChat } from "../../utilities/scrollChat";
import { PowerwordButton } from "./PowerwordButton";

export const PowerwordButtonTextInputTt = ({
  powerWord,
}: {
  powerWord: PowerWord;
}) => {
  const translate = useTranslate();
  const { addMessage, setChatId, setIsAnswering } = chatStore();
  const { version, profile, machine } = userConfigurationStore();
  const { mutateAsync: callPowerWord } = usePowerWord();
  const { open, close, isOpen } = useDisclosure();
  const { resetTimer } = sentryModeStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{ query: string }>();

  const onConfirm = handleSubmit(async (data) => {
    if (!version || !profile)
      return toast.error(translate("user_feedback.an_error_occurred"));

    close();
    setIsAnswering(true);
    resetTimer();

    addMessage({
      sender: "User",
      message: `Cosa puoi dirmi riguardo all'item **${data.query.trim()}**?`,
    });

    scrollChat();

    try {
      const response = await callPowerWord({
        endpoint: powerWord.endpoint,
        params: { user_input: data.query.trim() },
      });

      addMessage({
        sender: "Intellecta",
        message: response.value,
        image: response.image,
        id: response.message_id,
      });
      setChatId(response.chat_id);
    } catch {
      toast.error(translate("user_feedback.an_error_occurred"));
    } finally {
      scrollChat();
      setIsAnswering(false);
      resetTimer();
      reset();
    }
  });

  const clickHandler = () => {
    if (version === "troubleshooting" && !machine) {
      toast.error(translate("intellecta.no_machine_selected"));
    } else {
      open();
    }
  };

  return (
    <>
      <Modal
        titleContent={translate("intellecta.query_insert")}
        maxWidth="xs"
        fullWidth
        open={isOpen}
        bodyContent={
          <TextField
            {...register("query", {
              required: translate("intellecta.query_cannot_be_empty"),
              validate: (value) =>
                value.trim() !== "" ||
                translate("intellecta.query_cannot_be_empty"),
            })}
            fullWidth
            id="outlined-query"
            label={translate("intellecta.query")}
            type="text"
            InputLabelProps={{ shrink: true }}
            margin="normal"
            error={!!errors.query}
            helperText={errors.query?.message}
          />
        }
        actions={
          <>
            <Button variant="outlined" onClick={close}>
              {translate("actions.cancel")}
            </Button>
            <Button variant="contained" onClick={onConfirm}>
              {translate("actions.confirm")}
            </Button>
          </>
        }
      />
      <PowerwordButton powerWord={powerWord} onClick={clickHandler} />
    </>
  );
};
