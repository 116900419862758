import { useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Card } from "@/components/Layout/Card";
import { ForecastAccuracyChart } from "@/features/PredictiveAnalytics/components/ForecastAccuracyChart";
import { NeuralNetworkSummary } from "@/features/PredictiveAnalytics/components/NeuralNetworkSummary";
import { SelfOrganizingMap } from "@/features/PredictiveAnalytics/components/SelfOrganizingMap";
import { useIsolatorContext } from "../../context/useIsolatorContext";
import { useTranslate } from "@/i18n/config";
import { QuantizazionErrorChart } from "../../components/Diagnostic/QuantizationErrorChart";
import { QuantizationContextProvider } from "../../context/quantization-context";

export const SystemDiagnostic = () => {
  return (
    <QuantizationContextProvider>
      <Page />
    </QuantizationContextProvider>
  );
};

type Analytic = "SOM" | "Decision tree" | "Neural network";

const analytics: Analytic[] = ["SOM", "Decision tree", "Neural network"];

const Page = () => {
  const translate = useTranslate();
  const [selectedAnalytic, setSelectedAnalytic] = useState<Analytic>("SOM");
  const { batches } = useIsolatorContext();

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
      }}
    >
      <Card sx={{ width: 320, height: 400, overflowY: "auto" }}>
        <Typography variant="h6">HVAC</Typography>
        {batches.length === 0 ? (
          <div>{translate("isolator.no_batch_selected")}</div>
        ) : (
          <FormControl>
            <RadioGroup
              onChange={(
                _: React.ChangeEvent<HTMLInputElement>,
                value: string,
              ) => {
                setSelectedAnalytic(value as Analytic);
              }}
              value={selectedAnalytic}
            >
              {analytics.map((item) => (
                <FormControlLabel
                  key={`radio-${item}`}
                  control={<Radio />}
                  value={item}
                  label={item}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </Card>
      {batches.length > 0 && (
        <Stack
          key={`fuffa-charts-${JSON.stringify(batches)}-${Math.random()}`}
          sx={{
            gap: 2,
            width: "calc(100% - 320px)",
          }}
        >
          {selectedAnalytic === "SOM" ? (
            <>
              <QuantizazionErrorChart />
              <SelfOrganizingMap />
            </>
          ) : (
            <>
              <ForecastAccuracyChart />
              {selectedAnalytic === "Neural network" && (
                <NeuralNetworkSummary />
              )}
            </>
          )}
        </Stack>
      )}
    </Box>
  );
};
