import { DataGridPremium } from "@mui/x-data-grid-premium";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { Stack, Button, Typography, Box } from "@mui/material";
import { DateTime } from "luxon";
import { TableTitle } from "../layout/StyledText";
import { Card } from "@/components/Layout/Card";
import { useState } from "react";
import { NetworkProps } from "../api/useGetEdgePcData";
import { TableRowsGroupParser } from "../utils/rows-grouping-parse";
import { useTranslate } from "@/i18n/config";
import { colors } from "@/styles/colors";

export const Network = ({
  networkPcA,
}: {
  networkPcA: NetworkProps[] | [];
}) => {
  const translate = useTranslate();
  const [currentIndex, setCurrentIndex] = useState(networkPcA.length - 1);

  const previousScanHandler = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const nextScanHandler = () => {
    if (currentIndex < networkPcA.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <Card>
      <Stack>
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
          }}
        >
          {currentIndex === -1 || currentIndex === 0 ? (
            <Stack />
          ) : (
            <Button variant="outlined" onClick={previousScanHandler}>
              {translate("actions.back")}
            </Button>
          )}
          <Typography
            sx={{
              textAlign: "center",
            }}
          >
            Network scan PC A
          </Typography>
          {currentIndex !== networkPcA.length - 1 ? (
            <Button variant="outlined" onClick={nextScanHandler}>
              {translate("actions.next")}
            </Button>
          ) : (
            <Stack />
          )}
        </Stack>
        <TableLayout data={networkPcA[currentIndex]} />
      </Stack>
    </Card>
  );
};

const TableLayout = ({ data }: { data: NetworkProps }) => {
  const startScan = data
    ? DateTime.fromISO(data.timestamp.start_scan).toFormat(
        "dd/MM/yyyy HH:mm:ss",
      )
    : "";
  const duration = data
    ? TimeHelpers.parseDurationToString({
        duration: data.timestamp.scan_time_seconds * 1000,
      })
    : "";

  /**handle table data */
  const uniqueArray = data
    ? Object.entries(data.networks).flatMap((ipAddress) => [...ipAddress])
    : [];

  const columnsDefinition = [
    {
      idName: "ip",
      displayName: "IP",
    },
    {
      idName: "host",
      displayName: "Host Address",
    },
    {
      idName: "mac",
      displayName: "MAC",
    },
    {
      idName: "rtt",
      displayName: "RTT",
    },
  ];

  const { columns, rows } = uniqueArray
    ? TableRowsGroupParser.parse({
        columnsDefinitions: columnsDefinition,
        rowValues: uniqueArray,
      })
    : { columns: [], rows: [] };

  return (
    <>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-around",
        }}
      >
        <TableTitle textAlign="center">Start: {startScan}</TableTitle>
        <TableTitle textAlign="center">Duration: {duration}</TableTitle>
      </Stack>
      <Box style={{ height: 400, width: "100%" }}>
        <DataGridPremium
          sx={{
            "--DataGrid-containerBackground": colors.palette.darkBlue,
          }}
          columns={columns}
          rows={rows}
          initialState={{
            rowGrouping: {
              model: ["ip"],
            },
            columns: {
              columnVisibilityModel: { ip: false },
            },
          }}
        />
      </Box>
    </>
  );
};
