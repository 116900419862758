import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { Stack, Box, Grid2 } from "@mui/material";

export const MaterialsCounters = ({
  duration,
  count,
}: {
  duration: number;
  count: number;
}) => {
  const translate = useTranslate();
  return (
    <Grid2
      container
      spacing={2}
      sx={{
        textAlign: "center",
      }}
    >
      <Grid2
        size={{
          xs: 12,
          sm: 6,
        }}
      >
        <Card sx={{ height: "fill-content", minHeight: "auto" }}>
          <Stack>
            <Box>{translate("materials.material_lack_duration")}</Box>
            <Box>{TimeHelpers.parseDurationToString({ duration })}</Box>
          </Stack>
        </Card>
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 6,
        }}
      >
        <Card sx={{ height: "fill-content", minHeight: "auto" }}>
          <Stack>
            <Box>{translate("materials.material_lack_count")}</Box>
            <Box>{count}</Box>
          </Stack>
        </Card>
      </Grid2>
    </Grid2>
  );
};
