import { BasicTable } from "@/components/BasicTable";
import { Card } from "@/components/Layout/Card";
import { TableParser } from "@/utils/TableParser";
import { Stack } from "@mui/material";
import { IsWorking } from "../api/useGetEdgePcData";
import { TableTitle } from "../layout/StyledText";

function formatTableData(rawData: IsWorking[]) {
  const tableDataArray: Array<{
    timestamp: number;
    service: string;
    working: string;
  }> = rawData.flatMap((obj) => {
    const parsedValue = JSON.parse(obj.value);
    return Object.entries(parsedValue).map(([service, working]) => ({
      timestamp: obj.timestamp,
      service,
      working: working as string,
    }));
  });
  return tableDataArray;
}

export const Services = ({
  data,
}: {
  data: { pcA: IsWorking[]; pcB: IsWorking[] };
}) => {
  const tableDataPcA = formatTableData(data.pcA);
  const tableDataPcB = formatTableData(data.pcB);
  const { columns, rows } =
    tableDataPcA.length > 0
      ? TableParser.parseData({
          data: tableDataPcA,
          columnsMap: {
            timestamp: "Timestamp",
            service: "Service",
            working: "Working",
          },
          columnsOrder: ["timestamp", "service", "working"],
        })
      : { columns: [], rows: [] };

  const { columns: columnsB, rows: rowsB } =
    tableDataPcB.length > 0
      ? TableParser.parseData({
          data: tableDataPcB,
          columnsMap: {
            timestamp: "Timestamp",
            service: "Service",
            working: "Working",
          },
          columnsOrder: ["timestamp", "service", "working"],
        })
      : { columns: [], rows: [] };

  return (
    <Stack
      direction={{ md: "row", xs: "column" }}
      sx={{
        gap: 2,
      }}
    >
      <Card>
        <TableTitle>PC A</TableTitle>
        <BasicTable columns={columns} rows={rows} fileName="pc_a_data" />
      </Card>
      <Card>
        <TableTitle>PC B</TableTitle>
        <BasicTable columns={columnsB} rows={rowsB} fileName="pc_b_data" />
      </Card>
    </Stack>
  );
};
