import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { useLanguage } from "@/i18n/config";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { TimeSelection, useTimeSelection } from "@/store/useTimeSelection";
import { FetchClient } from "@/services/ApiClient";
import { ThingworxError } from "src/types/error";

export type StateLogResponse = ThingworxError | StateLogResult;

export const StateLogDataEntry = z.object({
  name: z.string(),
  color: z.string(),
  start: z.number(),
  end: z.number(),
  duration: z.number(),
  percentage: z.number(),
  isError: z.boolean().optional(),
  isProduction: z.boolean().optional(),
});

export type StateLogDataEntry = z.infer<typeof StateLogDataEntry>;

const StateLogResult = z.object({
  data: z.array(StateLogDataEntry),
  rootCauseGroupedData: z.array(StateLogDataEntry),
  response: z.literal(true),
});

export type StateLogResult = z.infer<typeof StateLogResult>;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  dateStart: number;
  dateEnd: number;
  language_id: string;
};

export const useGetData = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { dates, timeSelection } = useTimeSelection();
  const language = useLanguage();

  return useQuery<StateLogResult>({
    queryKey: [
      "state-log-data",
      machine!.machine,
      dates.dateEnd,
      dates.dateStart,
      timeSelection,
      language,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, StateLogResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
          timeSelection,
          dateStart: dates.dateStart.toMillis(),
          dateEnd: dates.dateEnd.toMillis(),
          language_id: language,
        },
        url: "dig.c.stateLog_thing/Services/getData",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = StateLogResult.parse(response);

      return validatedResponse;
    },
  });
};
