import { useState } from "react";
import { toast } from "react-hot-toast";
import { z } from "zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  TextField,
  DialogActions,
  Button,
  Box,
  Tabs,
  Tab,
  Autocomplete,
  TextField as MuiTextField,
  Paper,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { useGetAvailableUsers } from "../../api/Social/useGetAvailableUsers";
import { ShareMode } from "../../types";
import { useShareMessage } from "../../api/Social/useShareMessage";

interface IFormInput {
  email?: string;
  intellectaUsers?: string[];
  notes?: string;
}

const emailSchema = z.string().email({ message: "Badly formatted email" });

export const ShareModal = ({
  isOpen,
  close,
  messageId,
}: {
  isOpen: boolean;
  close: () => void;
  messageId?: number;
}) => {
  const translate = useTranslate();
  const [tab, setTab] = useState<ShareMode>("intellecta");
  const { data, error, isLoading } = useGetAvailableUsers();
  const { mutateAsync: shareMessage, isPending: isSharing } = useShareMessage();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<IFormInput>({
    mode: "onChange", // Validazione in tempo reale
    defaultValues: {
      intellectaUsers: [],
      email: "",
      notes: "",
    },
  });

  if (!data) return null;
  if (error) return toast.error(translate("user_feedback.an_error_occurred"));
  if (isLoading) return null;

  const handleClose = () => {
    close();
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (!messageId)
      return toast.error(translate("user_feedback.an_error_occurred"));
    close();
    shareMessage(
      {
        message_id: messageId,
        mode: tab,
        receiving_users:
          tab === "email"
            ? data.email
              ? [data.email]
              : []
            : data.intellectaUsers || [],
        user_notes: data.notes,
      },
      {
        onSuccess() {
          toast.success(translate("intellecta.message_shared_successfully"));
        },
        onError: () => {
          toast.error(translate("user_feedback.an_error_occurred"));
        },
      },
    );
  };

  // Controllo se ci sono utenti selezionati in intellecta
  const selectedUsers = watch("intellectaUsers");

  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose} disableScrollLock>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogTitle>{translate("intellecta.share_message_1")}</DialogTitle>
        <DialogContent>
          <Tabs
            value={tab}
            onChange={(_, newValue) => setTab(newValue)}
            variant="fullWidth"
          >
            <Tab label="Intellecta" value="intellecta" />
            <Tab label={translate("email")} value="email" />
          </Tabs>

          <Stack
            sx={{
              gap: 2,
              mt: 2,
            }}
          >
            {tab === "intellecta" && (
              <Controller
                name="intellectaUsers"
                control={control}
                rules={{
                  validate: (value) =>
                    (value && value.length > 0) ||
                    translate("select_at_least_one_user"),
                }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    multiple
                    options={data.users_list}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    onChange={(_, newValue) => onChange(newValue)}
                    value={value || []}
                    slots={{
                      paper(props) {
                        return (
                          <Paper
                            {...props}
                            style={{ maxHeight: 240, overflowY: "auto" }}
                          />
                        );
                      },
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option}>
                        <Checkbox checked={selected} />
                        <ListItemText primary={option} />
                      </li>
                    )}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        label={translate("select_user_other")}
                        size="small"
                        error={!!errors.intellectaUsers}
                        helperText={errors.intellectaUsers?.message}
                      />
                    )}
                  />
                )}
              />
            )}

            {tab === "email" && (
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Please enter an email address",
                  validate: (value) =>
                    emailSchema.safeParse(value).success ||
                    translate("error.badly_formatted_email"),
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label={translate("email")}
                    size="small"
                    placeholder="test@mail.com"
                    type="email"
                    onChange={onChange}
                    value={value || ""}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
            )}
            <Controller
              name="notes"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label={translate("note_other")}
                  size="small"
                  placeholder={translate("user_feedback.write_your_notes_here")}
                  multiline
                  rows={2}
                  onChange={onChange}
                  value={value || ""}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {translate("actions.cancel")}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={
              isSharing || tab === "email"
                ? !isValid
                : !selectedUsers || selectedUsers.length === 0
            }
          >
            {translate("actions.confirm")}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
