import { useTranslate } from "@/i18n/config";
import { NavButton } from "@/components/NavButton";
import { CustomSelectTimeSelection } from "@/components/SelectTimeSelection/CustomSelectTimeSelection";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { Grid2 } from "@mui/material";
import { PieSection } from "../../components/Live/PieSection";
import { CarouselSection } from "../../components/Live/CarouselSection";
import { ChartSection } from "../../components/Live/ChartSection";

export const EfficiencyNavigatorLive = () => {
  const translate = useTranslate();

  return (
    <>
      <MachineViewNavBar>
        <CustomSelectTimeSelection
          exclude={[
            "TwoWeeks",
            "Month",
            "ThreeMonths",
            "SixMonths",
            "Year",
            "Custom",
          ]}
        />
        <NavButton
          to="/efficiency-navigator/historic"
          text={translate("view_historic")}
        />
      </MachineViewNavBar>
      <Grid2
        container
        spacing={2}
        sx={{
          display: "flex",
        }}
      >
        <PieSection />
        <CarouselSection />
        <ChartSection />
      </Grid2>
    </>
  );
};
