import { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { Drawer, IconButton, Box, Tooltip, keyframes } from "@mui/material";
import {
  ChevronRight,
  ChevronLeft,
  NotificationsOutlined,
} from "@mui/icons-material";
import { Loader } from "@/components/Layout/Loader";
import { useGetUserConfiguration } from "./api/useGetUserConfiguration";
import { ChatPanel } from "./components/ChatPanel";
import { LeftDrawer } from "./components/LeftDrawer";
import { RightDrawer } from "./components/RightDrawer";
import { InfoDialog } from "./components/InfoDialog";
import { NoVersionsMessage } from "./components/ChatPanel/NoVersionsMessage";
import { useCheckIfMessageBanner } from "@/hooks/useCheckIfInformationBanner";
import { useTranslate } from "@/i18n/config";
import { useScreenSize } from "./hooks/useScreenSize";
import { socialStore } from "./store/social-store";

export const LEFT_DRAWER_WIDTH = 270;
export const RIGHT_DRAWER_WIDTH = 330;
export const ICON_WIDTH = 40;

export const Intellecta = () => {
  const [userInput, setUserInput] = useState("");
  const [isShownAlert, setIsShownAlert] = useState(true);
  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState(true);
  const [animationState, setAnimationState] = useState<"fadeIn" | "fadeOut">(
    "fadeIn",
  );
  const [isBellVisible, setIsBellVisible] = useState(false);

  const { isLoading, data, error } = useGetUserConfiguration();
  const { showBanner } = useCheckIfMessageBanner();
  const { isSmallScreen } = useScreenSize();
  const translate = useTranslate();

  // Estraiamo dallo store lo stato relativo al RightDrawer e alle notifiche
  const {
    hasNewSocialNotification,
    setHasNewSocialNotification,
    isRightDrawerOpen,
    setIsRightDrawerOpen,
    setActiveRightDrawerTab,
  } = socialStore();

  const currentTranslateX = isRightDrawerOpen
    ? `-${RIGHT_DRAWER_WIDTH}px`
    : "0";

  useEffect(() => {
    if (isSmallScreen) {
      setIsLeftDrawerOpen(false);
      setIsRightDrawerOpen(false);
    }
  }, [isSmallScreen, setIsRightDrawerOpen]);

  useEffect(() => {
    // Quando arriva una nuova notifica, resettiamo la visibilità e l'animazione
    if (hasNewSocialNotification) {
      setIsBellVisible(true);
      setAnimationState("fadeIn");
    }
  }, [hasNewSocialNotification]);

  const fadeInScale = useMemo(
    () => keyframes`
    0% {
      opacity: 0;
      transform: translateX(${currentTranslateX}) scale(0.5);
    }
    100% {
      opacity: 1;
      transform: translateX(${currentTranslateX}) scale(1);
    }
  `,
    [currentTranslateX],
  );

  const fadeOutScale = useMemo(
    () => keyframes`
    0% {
      opacity: 1;
      transform: translateX(${currentTranslateX}) scale(1);
    }
    100% {
      opacity: 0;
      transform: translateX(${currentTranslateX}) scale(0.5);
    }
  `,
    [currentTranslateX],
  );

  if (error) return toast.error(translate("user_feedback.an_error_occurred"));
  if (isLoading || !data) return <Loader isLoading />;

  const toggleLeftDrawer = () => {
    setIsLeftDrawerOpen(!isLeftDrawerOpen);
  };

  const toggleRightDrawer = () => {
    setIsRightDrawerOpen(!isRightDrawerOpen);
  };

  const handleNotificationClick = () => {
    setAnimationState("fadeOut");
    setTimeout(() => {
      setIsBellVisible(false);
      setHasNewSocialNotification(false);
      if (!isRightDrawerOpen) {
        setIsRightDrawerOpen(true);
      }
      setActiveRightDrawerTab("social");
    }, 300); // Aspetta la fine dell'animazione
  };

  return (
    <>
      {/* MODALE DISCLAIMER */}
      <InfoDialog isOpen={isShownAlert} close={() => setIsShownAlert(false)} />
      {/* APERTURA LEFT DRAWER */}
      {!isSmallScreen && (
        <Tooltip
          title={translate("intellecta.show_hide_sidebar")}
          enterDelay={2000}
          leaveDelay={200}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleLeftDrawer}
            sx={{
              position: "absolute",
              top: showBanner ? 44 : 12,
              left: 12,
              zIndex: 1,
              transform: isLeftDrawerOpen
                ? `translateX(${LEFT_DRAWER_WIDTH}px)`
                : "translateX(0)",
              transition: "transform 0.16s ease-in-out",
            }}
          >
            {isLeftDrawerOpen ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </Tooltip>
      )}
      {/* CAMPANELLA NOTIFICHE */}
      {hasNewSocialNotification && isBellVisible && (
        <IconButton
          color="inherit"
          aria-label="open notifications"
          onClick={handleNotificationClick}
          sx={{
            position: "absolute",
            top: showBanner ? 92 : 60,
            right: 12,
            zIndex: 1,
            transform: `translateX(${currentTranslateX})`,
            animation: `${
              animationState === "fadeIn" ? fadeInScale : fadeOutScale
            } 0.3s ease-in-out`,
            animationFillMode: "forwards",
            pointerEvents: animationState === "fadeOut" ? "none" : "auto", // Disabilita clic dopo fadeOut
          }}
        >
          <NotificationsOutlined />
        </IconButton>
      )}
      {/* APERTURA RIGHT DRAWER */}
      {!isSmallScreen && (
        <Tooltip
          title={translate("intellecta.show_hide_sidebar")}
          enterDelay={2000}
          leaveDelay={200}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleRightDrawer}
            sx={{
              position: "absolute",
              top: showBanner ? 44 : 12,
              right: 12,
              zIndex: 1,
              transform: isRightDrawerOpen
                ? `translateX(-${RIGHT_DRAWER_WIDTH}px)`
                : "translateX(0)",
              transition: "transform 0.16s ease-in-out",
            }}
          >
            {isRightDrawerOpen ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </Tooltip>
      )}

      {/* ROOT */}
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          transition: (theme) =>
            theme.transitions.create(["width"], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
        }}
      >
        {/* LEFT DRAWER */}
        {!isSmallScreen && (
          <Drawer
            variant="persistent"
            open={isLeftDrawerOpen}
            sx={{
              width: LEFT_DRAWER_WIDTH,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: LEFT_DRAWER_WIDTH,
                boxSizing: "border-box",
                marginLeft: "64px",
              },
            }}
          >
            <LeftDrawer onPromptClick={(prompt) => setUserInput(prompt)} />
          </Drawer>
        )}

        {/* CHAT */}
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            minWidth: 400,
            maxWidth: isSmallScreen
              ? "80vw"
              : isLeftDrawerOpen || isRightDrawerOpen
              ? "50vw"
              : "60vw",
            width: "100%",
            mx:
              isLeftDrawerOpen || isRightDrawerOpen
                ? `${ICON_WIDTH + 24}px`
                : "auto",
            height: "100%",
            transition: (theme) =>
              theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
          }}
        >
          {data.versions.length > 0 ? (
            <ChatPanel
              userInput={userInput}
              setUserInput={setUserInput}
              isLeftDrawerOpen={isLeftDrawerOpen}
              isRightDrawerOpen={isRightDrawerOpen}
            />
          ) : (
            <NoVersionsMessage />
          )}
        </Box>

        {/* RIGHT DRAWER */}
        {!isSmallScreen && (
          <RightDrawer onUsePrompt={(prompt) => setUserInput(prompt)} />
        )}
      </Box>
    </>
  );
};
