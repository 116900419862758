import { z } from "zod";
import { BarChartData, Phase, Subphase } from "../../types";
import { StackedBarchart } from "@/components/highcharts/barchart/StackedBarchart";
import { SeriesBarOptions } from "highcharts";
import { readablePhase } from "../../utils/readablePhase";
import { StatesLogLegend } from "@/features/OverallLineEfficiency/components/StatesLogLegend";
import { Box } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { CHART_COLORS } from "../../utils/chartColors";
import { TimeHelpers } from "@/utils/TimeHelpers";

const parseData = ({
  data,
}: {
  data: BarChartData;
}): { series: SeriesBarOptions[]; hasFailedPhases: boolean } => {
  const assignColor = (phase: Phase | Subphase): string => {
    switch (phase) {
      case "leak_test":
        return CHART_COLORS[3];
      case "vhp":
        return CHART_COLORS[2];
      case "pre_production":
        return CHART_COLORS[1];
      case "production":
        return CHART_COLORS[0];
      default:
        return CHART_COLORS[4];
    }
  };

  const phases: Phase[] = ["leak_test", "vhp", "pre_production", "production"];
  const series: SeriesBarOptions[] = [];
  let hasFailedPhases = false;

  phases.forEach((phase) => {
    const phaseEntriesCurrentBatch = data.currentBatch.filter(
      (entry) => entry.phase === phase,
    );

    const phaseEntriesBenchmark = data.benchmark.filter(
      (entry) => entry.phase === phase,
    );
    if (
      phaseEntriesCurrentBatch.length > 1 ||
      phaseEntriesBenchmark.length > 1
    ) {
      hasFailedPhases = true;
    }

    if (phaseEntriesBenchmark.length > phaseEntriesCurrentBatch.length) {
      phaseEntriesBenchmark.forEach((benchmarkEntry, i) => {
        const phaseData: number[] = [];
        phaseData.push(
          phaseEntriesCurrentBatch[i]
            ? phaseEntriesCurrentBatch[i].duration / 1000 / 60 / 60
            : 0,
          benchmarkEntry.duration / 1000 / 60 / 60,
        );

        series.push({
          type: "bar",
          name: readablePhase(benchmarkEntry.phase),
          data: phaseData,
          color: assignColor(benchmarkEntry.phase),
        });
      });
    } else {
      phaseEntriesCurrentBatch.forEach((currentBatchEntry, i) => {
        const phaseData: number[] = [];
        phaseData.push(
          currentBatchEntry.duration / 1000 / 60 / 60,
          phaseEntriesBenchmark[i]
            ? phaseEntriesBenchmark[i].duration / 1000 / 60 / 60
            : 0,
        );

        series.push({
          type: "bar",
          name: readablePhase(currentBatchEntry.phase),
          data: phaseData,
          color: assignColor(currentBatchEntry.phase),
        });
      });
    }
  });

  //? per avere a dx le fasi più recenti
  series.reverse();

  return { series, hasFailedPhases };
};

export const BarChart = ({
  data,
  isProduction,
  batchName,
}: {
  data: BarChartData;
  isProduction: boolean;
  batchName: string;
}) => {
  const translate = useTranslate();
  const { series, hasFailedPhases } = parseData({ data });

  return (
    <Box>
      <StackedBarchart
        title={
          isProduction
            ? translate("production_time")
            : translate("time_to_production")
        }
        // height={hasFailedPhases ? 280 : 300}
        height={hasFailedPhases ? 230 : 250}
        legend={{ enabled: hasFailedPhases ? false : true }}
        tooltip={{
          enabled: true,
          options: {
            formatter: function () {
              const parseResult = z
                .object({
                  point: z.object({
                    category: z.string(),
                    y: z.number(),
                    color: z.string(),
                  }),
                  series: z.object({
                    name: z.string(),
                  }),
                })
                .safeParse(this);
              if (!parseResult.success) return "";

              const {
                point: { category, y, color },
                series: { name },
              } = parseResult.data;
              return `
                ${category}<br>
                <span style="color:${color}">● </span>${name}: <b>${TimeHelpers.parseDurationToString(
                  { duration: y * 60 * 60 * 1000 },
                )}</b>
              `;
            },
          },
        }}
        uom=""
        yAxisTitleOptions={{
          text: translate("durations.hour_other"),
          style: { color: "white" },
        }}
        categories={[batchName, translate("benchmark")]}
        series={series}
      />
      {hasFailedPhases && (
        <StatesLogLegend
          legendData={{
            "Leak Test": CHART_COLORS[3],
            VHP: CHART_COLORS[2],
            "Pre Production": CHART_COLORS[1],
            Production: CHART_COLORS[0],
          }}
        />
      )}
    </Box>
  );
};
