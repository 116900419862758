import { Box, Divider, List, Stack, useTheme } from "@mui/material";
import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { AppShell } from "@/components/Layout/AppShell";
import { Drawer } from "@/components/Layout/Drawer";
import { PersonalizableHeader } from "@/components/Layout/PersonalizableHeader/PersonalizableHeader";
import { Nav } from "@/components/Layout/Navigations/Nav";
import { HomeOutlined } from "@mui/icons-material";
import { overviewPages } from "../../constants/sentinelNavigations";
import { useCheckIfMessageBanner } from "@/hooks/useCheckIfInformationBanner";
import { InformationBanner } from "@/components/Layout/InformationBanner";
import { ReleaseMessageModal } from "@/components/Layout/ReleaseMessageModal";
import { useGetUserOverviewPages } from "./api/useGetUserOverviewPages";

export const Overview = () => {
  const { showBanner } = useCheckIfMessageBanner();

  const location = useLocation();

  const { pathname } = location;

  const isIntellecta = pathname.includes("intellecta");

  return (
    <Box>
      {showBanner && <InformationBanner />}
      <ReleaseMessageModal />
      <SidebarOverview />
      <AppShell
        // @ts-ignore
        component="main"
        sx={{
          flexGrow: 1,
          px: isIntellecta ? 0 : 4,
          py: isIntellecta ? 0 : 2,
          mt: isIntellecta ? 0 : showBanner ? "32px" : 0,
          float: "right",
          zIndex: -1,
          position: "relative",
        }}
      >
        <Outlet />
      </AppShell>
    </Box>
  );
};

export const SidebarOverview = () => {
  const [open, setOpen] = useState(false);
  const { palette } = useTheme();
  const { data: pages = [], isLoading } = useGetUserOverviewPages();

  const pagesToRender = overviewPages.filter((page) => {
    if (isLoading) {
      return false;
    }

    return pages.includes(page.name);
  });

  return (
    <Drawer
      variant="permanent"
      open={open}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      sx={{
        boxShadow:
          "0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);",
        border: "none",
      }}
    >
      <Stack
        sx={{
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <PersonalizableHeader open={open} />
          <Divider
            variant="middle"
            sx={{ borderColor: palette.text.secondary }}
          />

          <List disablePadding>
            <Nav
              text={"Home"}
              to={"/overview/home"}
              icon={HomeOutlined}
              open={open}
            />
            {pagesToRender.map((page) => (
              <Nav
                key={page.name}
                text={page.text}
                to={page.to}
                icon={page.icon}
                open={open}
              />
            ))}
          </List>
        </Box>
      </Stack>
    </Drawer>
  );
};
