import { SettingSectionLayout } from "../components/SettingSectionLayout";
import { AssignMachines } from "../components/AssignMachines";
import { Card } from "@/components/Layout/Card";
import {
  Stack,
  Divider,
  FormHelperText,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useCreateNewUser } from "../../../lambda/useCreateNewUser";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { ManageUsersTable } from "../components/ManageUsersTable";
import { useTranslate } from "@/i18n/config";

export const UsersManagement = () => {
  const translate = useTranslate();
  return (
    <Stack
      divider={<Divider />}
      sx={{
        gap: 2,
      }}
    >
      <SettingSectionLayout
        title={translate("create_user")}
        description={translate("create_user_description")}
        render={<CreateUser />}
      />
      <SettingSectionLayout
        title={translate("manage_users")}
        description={translate("manage_users_description")}
        render={<ManageUsersTable />}
      />
      <SettingSectionLayout
        title={translate("assign_machines")}
        description={translate("assign_machines_description")}
        render={<AssignMachines />}
      />
    </Stack>
  );
};

/**
 * role 3 = user
 * role 4 = admin
 * based on old role logic, mapped to new role logic inside the lambda
 */

const CreateUser = () => {
  const translate = useTranslate();

  const { mutate: createUser, isPending } = useCreateNewUser();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<{
    email: string;
    appEnabled: boolean;
    role: 3 | 4;
    devices: number;
  }>({
    defaultValues: {
      email: "",
      appEnabled: false,
      role: 4,
      devices: 0,
    },
  });

  return (
    <Card>
      <form
        onSubmit={handleSubmit((data) => {
          createUser(
            {
              appEnabled: data.appEnabled,
              email: data.email,
              role: data.role,
              devices: data.devices,
            },
            {
              onSuccess() {
                reset();
              },
            },
          );
        })}
      >
        <Stack
          sx={{
            gap: 2,
          }}
        >
          <Controller
            control={control}
            name="email"
            rules={{
              required: translate("settings.enter_email"),
              validate: {
                isEmail: (value) => {
                  return (
                    z.string().email().safeParse(value).success ||
                    translate("user_feedback.invalid_email")
                  );
                },
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size="small"
                helperText={
                  errors.email ? (
                    <FormHelperText error>
                      {errors.email && errors.email.message}
                    </FormHelperText>
                  ) : null
                }
                label={translate("settings.enter_email")}
                margin="normal"
                variant="outlined"
              />
            )}
          />
          <Controller
            control={control}
            name="role"
            render={({ field }) => {
              return (
                <FormControl fullWidth>
                  <InputLabel id="role">{translate("user_role")}</InputLabel>
                  <Select
                    {...field}
                    onChange={(e) => {
                      if (e.target.value === 3 || e.target.value === 4)
                        field.onChange(e);
                    }}
                    defaultValue={4}
                    size="small"
                    label={translate("user_role")}
                    error={!!errors.role}
                  >
                    <MenuItem value={4}>
                      {translate("roles.base_user")}
                    </MenuItem>
                    <MenuItem value={3}>{translate("roles.admin")}</MenuItem>
                  </Select>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="devices"
            render={({ field }) => {
              return (
                <FormControl fullWidth>
                  <InputLabel id="role">
                    {translate("device", { count: 2 })}
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.target.value as number);
                    }}
                    defaultValue={0}
                    size="small"
                    label={translate("device", { count: 2 })}
                    error={!!errors.role}
                  >
                    {[0, 1, 2, 3, 5, 10].map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="appEnabled"
            render={({ field }) => {
              return (
                <FormControlLabel
                  control={<Switch {...field} />}
                  label={translate("settings.enable_mobile_app")}
                  labelPlacement="start"
                />
              );
            }}
          />
        </Stack>

        <Stack
          direction="row"
          sx={{
            gap: 2,
            justifyContent: "flex-end",
          }}
        >
          <Button
            disabled={isPending}
            type="submit"
            color="success"
            variant="contained"
          >
            {translate("actions.save")}
          </Button>
        </Stack>
      </form>
    </Card>
  );
};
