import { useState } from "react";
import { Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { CustomSelectTimeSelection } from "@/components/SelectTimeSelection/CustomSelectTimeSelection";
import { Card } from "@/components/Layout/Card";
import { useGetData } from "./api/useGetData";
import { StatesLogChart } from "./components/StatesLogChart";
import { StatesLogTable } from "./components/StatesLogTable";

export const StateLog = () => {
  const translate = useTranslate();
  const [dateRange, setDateRange] = useState<{
    min: number;
    max: number;
  } | null>(null);
  const [isGrouped, setIsGrouped] = useState(false);
  const { data, isLoading, error } = useGetData();

  if (isLoading) {
    return (
      <>
        <MachineViewNavBar>
          <CustomSelectTimeSelection
            exclude={["TwoWeeks", "Month", "ThreeMonths", "SixMonths", "Year"]}
            unavailableShortcuts={["14d", "30d"]}
            maxDays={14}
          />
        </MachineViewNavBar>
        <Stack
          sx={{
            gap: 2,
          }}
        >
          <SkeletonCard height={250}></SkeletonCard>
          <SkeletonCard height={250}></SkeletonCard>
        </Stack>
      </>
    );
  }

  if (error) {
    return (
      <>
        <MachineViewNavBar>
          <CustomSelectTimeSelection
            exclude={["TwoWeeks", "Month", "ThreeMonths", "SixMonths", "Year"]}
            unavailableShortcuts={["14d", "30d"]}
            maxDays={14}
          />
        </MachineViewNavBar>
        <Stack
          sx={{
            gap: 2,
          }}
        >
          <Card>{translate("user_feedback.an_error_occurred")}</Card>
        </Stack>
      </>
    );
  }

  if (!data) {
    return (
      <>
        <MachineViewNavBar>
          <CustomSelectTimeSelection
            exclude={["TwoWeeks", "Month", "ThreeMonths", "SixMonths", "Year"]}
            unavailableShortcuts={["14d", "30d"]}
            maxDays={14}
          />
        </MachineViewNavBar>
        <Stack
          sx={{
            gap: 2,
          }}
        >
          <Card>{translate("user_feedback.no_data")}</Card>
        </Stack>
      </>
    );
  }

  const handleSetExtremes = ({ min, max }: { min: number; max: number }) => {
    setDateRange({ min, max });
  };

  const handleIsGrouped = (isGrouped: boolean) => {
    setIsGrouped(isGrouped);
  };

  return (
    <>
      <MachineViewNavBar>
        <CustomSelectTimeSelection
          exclude={["TwoWeeks", "Month", "ThreeMonths", "SixMonths", "Year"]}
          unavailableShortcuts={["14d", "30d"]}
          maxDays={14}
        />
      </MachineViewNavBar>
      <Stack
        sx={{
          gap: 2,
        }}
      >
        <Card>
          <StatesLogChart
            key={"state-log-chart-grouped-" + isGrouped}
            data={isGrouped ? data.rootCauseGroupedData : data.data}
            onSetExtremes={handleSetExtremes}
            isGrouped={isGrouped}
            setIsGrouped={handleIsGrouped}
          />
        </Card>
        <StatesLogTable
          key={"state-log-table-grouped-" + isGrouped}
          data={isGrouped ? data.rootCauseGroupedData : data.data}
          extremes={dateRange}
        />
      </Stack>
    </>
  );
};
