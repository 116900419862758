import { useEffect, useRef } from "react";
import { Card } from "@/components/Layout/Card";
import { colors } from "@/styles/colors";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import {
  Stack,
  Typography,
  Divider,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { useKpiDifferences } from "@/features/ConfigurableOee/hooks/useKpiDifferences";
import {
  ComparisonKpiType,
  KpiProperty,
} from "@/features/ConfigurableOee/api/useGetConfigurableOeeData";
import { Counter } from "../Counter";

export const KpiInfoDifferences = ({
  comparisonKpi,
  isChartVisible,
  onVisibilityHandle,
}: {
  comparisonKpi: ComparisonKpiType;
  isChartVisible: boolean;
  onVisibilityHandle: () => void;
}) => {
  const kpiInfos = useKpiDifferences(comparisonKpi);

  const prevKpiInfosRef = useRef<KpiProperty>({ ...kpiInfos });
  const translate = useTranslate();

  useEffect(() => {
    prevKpiInfosRef.current = { ...kpiInfos };
  }, [kpiInfos]);

  return (
    <Card sx={{ width: "100%", height: "100%" }}>
      <Stack
        direction="column"
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>{translate("configurable_oee.differences")}</Typography>{" "}
          <Tooltip
            title={
              isChartVisible
                ? translate("configurable_oee.hide_chart")
                : translate("configurable_oee.show_chart")
            }
            placement="top"
            arrow
          >
            <IconButton sx={{ marginTop: 0 }} onClick={onVisibilityHandle}>
              {isChartVisible ? (
                <VisibilityOffOutlined sx={{ fontSize: 18 }} />
              ) : (
                <VisibilityOutlined sx={{ fontSize: 18 }} />
              )}
            </IconButton>
          </Tooltip>
        </Box>
        <Divider sx={{ marginBottom: 2 }} />
        <Box
          sx={{
            paddingX: 1,
          }}
        >
          <Stack spacing={1}>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: 4,
                    height: "70%",
                    bgcolor: colors.kpi.oee,
                  }}
                ></Box>
                <Typography>{translate("kpi.oee")}</Typography>
              </Box>
              <Counter
                from={prevKpiInfosRef.current.oee}
                to={kpiInfos.oee}
                reverse={false}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                gap: 5,
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: 4,
                    height: "70%",
                    bgcolor: colors.kpi.availability,
                  }}
                ></Box>
                <Typography>{translate("kpi.availability")}</Typography>
              </Box>
              <Counter
                from={prevKpiInfosRef.current.availability}
                to={kpiInfos.availability}
                reverse={false}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                gap: 5,
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: 4,
                    height: "70%",
                    bgcolor: colors.kpi.performance,
                  }}
                ></Box>
                <Typography>{translate("kpi.performance")}</Typography>
              </Box>
              <Counter
                from={prevKpiInfosRef.current.performance}
                to={kpiInfos.performance}
                reverse={false}
              />
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
};
