import { z } from "zod";

const intellectaError = z.literal("ERROR");

export type IntellectaError = z.infer<typeof intellectaError>;

const intellectaErrorObj = z.object({
  value: z.literal("ERROR"),
});

export type IntellectaErrorObj = z.infer<typeof intellectaErrorObj>;

export const intellectaResult = z.literal("OK");

export type IntellectaResult = z.infer<typeof intellectaResult>;

export const intellectaResultObj = z.object({
  value: z.literal("OK"),
});

export type IntellectaResultObj = z.infer<typeof intellectaResultObj>;

export const intellectaVersion = z.union([
  z.literal("troubleshooting"),
  z.literal("data-analysis"),
  z.literal("line-audit"),
]);

export type IntellectaVersion = z.infer<typeof intellectaVersion>;

export const profile = z.union([
  z.literal("sentinel_3"),
  z.literal("intellecta_ecommerce"),
]);

export type Profile = z.infer<typeof profile>;

export const profileInfo = z.object({
  name: z.string(),
  is_active: z.boolean(),
});

export type ProfileInfo = z.infer<typeof profileInfo>;

export const version = z.object({
  name: intellectaVersion,
  profiles: z.array(profileInfo),
});

export type Version = z.infer<typeof version>;

export const service = z.union([z.literal("sentry"), z.literal("social")]);

export type Service = z.infer<typeof service>;

export const role = z.union([
  z.literal("base"),
  z.literal("intermediate"),
  z.literal("premium"),
]);

export type Role = z.infer<typeof role>;

export const prompt = z.object({
  id: z.number(),
  label: z.string(),
  prompt: z.string(),
  description: z.string(),
  version: intellectaVersion,
  profile: z.string(),
});

export type Prompt = z.infer<typeof prompt>;

export const powerWord = z.object({
  label: z.string(),
  endpoint: z.string(),
  prompt: z.string(),
  description: z.string(),
  version: z.array(intellectaVersion),
  type: z.enum([
    "standard",
    "specific-alarm",
    "machine-ts",
    "text-input-tt",
    "strain-input-tt",
  ]),
  profile: z.array(z.string()),
});

export type PowerWord = z.infer<typeof powerWord>;

export const machine = z.object({
  machine: z.string(),
  service_version_access: z.array(intellectaVersion),
  profile: z.array(z.string()),
});

export type Machine = z.infer<typeof machine>;

export const config = z.object({
  powerwords: z.array(powerWord),
  prompts: z.array(prompt),
  machines: z.array(machine),
  versions: z.array(version),
  services: z.array(service),
  role,
});

export type Config = z.infer<typeof config>;

export const chart = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("linechart"),
    values: z.array(z.tuple([z.number(), z.number()])),
  }),
  z.object({
    type: z.literal("piechart"),
    categories: z.array(z.string()),
    values: z.array(z.number()),
  }),
  z.object({
    type: z.literal("barchart"),
    categories: z.array(z.string()),
    values: z.array(z.number()),
  }),
  z.object({
    type: z.literal("scatterplot"),
    values: z.array(z.tuple([z.number(), z.number()])),
  }),
]);

export type Chart = z.infer<typeof chart>;

export const message = z.union([
  z.object({
    id: z.number(),
    query: z.string(),
    answer: z.string(),
    timestamp: z.number(),
    mode: z.literal("standard"),
    sources: z.array(z.string()),
    image: z.string().nullish(),
  }),
  z.object({
    id: z.number(),
    query: z.literal(null), // null in caso di sentry
    answer: z.string(),
    timestamp: z.number(),
    mode: z.literal("sentry"),
    sources: z.array(z.string()),
    image: z.string().nullish(),
  }),
]);

export type Message = z.infer<typeof message>;

export const chat = z.object({
  id: z.number(),
  title: z.string(),
  timestamp: z.number(),
  version: intellectaVersion,
  is_pinned: z.boolean(),
  profile: z.string(),
});

export type Chat = z.infer<typeof chat>;

export const notification = z.object({
  social_id: z.number(),
  title: z.string(),
  timestamp: z.number(),
  sending_user: z.string(),
  is_pinned: z.boolean(),
  is_read: z.boolean(),
});

export type Notification = z.infer<typeof notification>;

export const shareMode = z.union([z.literal("intellecta"), z.literal("email")]);

export type ShareMode = z.infer<typeof shareMode>;

export const rightDrawerTab = z.union([
  z.literal("social"),
  z.literal("historic"),
]);

export type RightDrawerTab = z.infer<typeof rightDrawerTab>;
