import { toast } from "react-hot-toast";
import { useTranslate } from "@/i18n/config";
import { PowerWord } from "../../types";
import { chatStore } from "../../store/chat-store";
import { usePowerWord } from "../../api/usePowerWord";
import { scrollChat } from "../../utilities/scrollChat";
import { sentryModeStore } from "../../store/sentry-mode-store";
import { userConfigurationStore } from "../../store/user-configuration-store";
import { PowerwordButton } from "./PowerwordButton";

export const PowerwordButtonStandard = ({
  powerWord,
}: {
  powerWord: PowerWord;
}) => {
  const translate = useTranslate();
  const { addMessage, setChatId, setIsAnswering } = chatStore();
  const { version, machine } = userConfigurationStore();
  const { mutateAsync: callPowerWord } = usePowerWord();
  const { resetTimer } = sentryModeStore();

  const onClick = async () => {
    if (!version)
      return toast.error(translate("user_feedback.an_error_occurred"));

    //? in versione 'troubleshooting' devo prima selezionare una macchina, fatta eccezione per 'help'
    if (
      version === "troubleshooting" &&
      !machine &&
      powerWord.endpoint !== "/powerword/help"
    ) {
      return toast.error("No machine selected");
    }

    setIsAnswering(true);
    resetTimer();

    addMessage({
      sender: "User",
      message: powerWord.prompt,
    });

    scrollChat();

    callPowerWord(
      {
        endpoint: powerWord.endpoint,
      },
      {
        onError() {
          toast.error(translate("user_feedback.an_error_occurred"));
        },
      },
    )
      .then((response) => {
        addMessage({
          sender: "Intellecta",
          message: response.value,
          image: response.image,
          id: response.message_id,
        });
        setChatId(response.chat_id);
      })
      .finally(() => {
        scrollChat();
        setIsAnswering(false);
        resetTimer();
      });
  };

  return <PowerwordButton powerWord={powerWord} onClick={onClick} />;
};
