import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { Box } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { ONE_DAY } from "@/utils/durationsInMilliseconds";

export const DateTimeRangePicker = ({
  maxDays,
  onPickDates,
}: {
  maxDays?: number;
  onPickDates: ({
    dateStart,
    dateEnd,
  }: {
    dateStart: DateTime;
    dateEnd: DateTime;
  }) => void;
}) => {
  const translate = useTranslate();
  const [dateStart, setDateStart] = useState(
    DateTime.now().minus({ weeks: 1 }),
  );
  const [dateEnd, setDateEnd] = useState(DateTime.now());

  useEffect(() => {
    // Chiamare handleOnPick ogni volta che dateStart o dateEnd cambia
    onPickDates({ dateStart, dateEnd });
  }, [dateStart, dateEnd, onPickDates]);

  const onChangeDateStart = (newValue: DateTime | null) => {
    if (!newValue) return;

    if (maxDays) {
      const newRange = dateEnd.toMillis() - newValue.toMillis();
      const maxRange = maxDays * ONE_DAY;
      //? controllo se la differenza tra dateEnd e nuovo valore
      //? è maggiore rispetto a ONE_DAY * maxDays e reimposto dateEnd al max
      if (newRange > maxRange) {
        setDateStart(newValue);
        setDateEnd(newValue.plus(maxRange));
      } else {
        setDateStart(newValue);
      }
    } else {
      setDateStart(newValue);
    }
  };

  const onChangeDateEnd = (newValue: DateTime | null) => {
    if (!newValue) return;

    if (maxDays) {
      const newRange = newValue.toMillis() - dateStart.toMillis();
      const maxRange = maxDays * ONE_DAY;
      //? controllo se la differenza tra nuovo valore e dateStart
      //? è maggiore rispetto a ONE_DAY * maxDays e reimposto dateStart al max
      if (newRange > maxRange) {
        setDateStart(newValue.minus(maxRange));
        setDateEnd(newValue);
      } else {
        setDateEnd(newValue);
      }
    } else {
      setDateEnd(newValue);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
      }}
    >
      <DateTimePicker
        label={translate("durations.from")}
        value={dateStart}
        maxDateTime={dateEnd}
        onChange={onChangeDateStart}
        format="dd/MM/yyyy HH:mm"
        ampm={false}
        disableFuture
        slotProps={{
          textField: {
            size: "small",
          },
        }}
      />
      <DateTimePicker
        label={translate("durations.to")}
        value={dateEnd}
        minDateTime={dateStart}
        onChange={onChangeDateEnd}
        format="dd/MM/yyyy HH:mm"
        ampm={false}
        disableFuture
        slotProps={{
          textField: {
            size: "small",
          },
        }}
      />
    </Box>
  );
};
