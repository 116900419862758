import { TimeHelpers } from "@/utils/TimeHelpers";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid-premium";
import { ColumnDefinition } from "../api/useGetDownloadData";
import { GenericsDataWithDynamicKeys } from "./parse-cyclic-data";

export class DownloadParser {
  static parse({
    columnsDefinitions,
    rowValues,
    dateFormatter,
  }: {
    columnsDefinitions: ColumnDefinition[];
    rowValues: GenericsDataWithDynamicKeys[];
    customColumns?: GridColDef[];
    dateFormatter?: (info: {
      timestamp: number;
      fmt?: string;
      skipTimezoneTransform?: boolean;
      skipLocale?: boolean;
    }) => string;
  }): {
    columns: GridColDef[];

    rows: GridRowsProp;
  } {
    const parsedColumns: GridColDef[] = columnsDefinitions.map((item) => ({
      field: item.idName,
      headerName: item.displayName,
      width: 160,
    }));

    const timestampColDef = parsedColumns.findIndex(
      (item) => item.field === "dataStartTimelapse",
    );
    if (timestampColDef !== -1) {
      parsedColumns[timestampColDef] = {
        ...parsedColumns[timestampColDef],
        type: "dateTime",
        valueGetter: (value) => {
          const date =
            value &&
            dateFormatter &&
            dateFormatter({
              timestamp: +new Date(value),
              skipLocale: true,
            });
          return value ? new Date(date) : null;
        },
        valueFormatter: (value) => {
          const date =
            value &&
            dateFormatter &&
            dateFormatter({
              timestamp: +new Date(value),
              skipTimezoneTransform: true,
            });
          return date;
        },
      };
    }

    const alarmDetailsColDef = parsedColumns.findIndex(
      (item) => item.field === "alarmDetails",
    );
    if (alarmDetailsColDef !== -1) {
      parsedColumns[alarmDetailsColDef] = {
        ...parsedColumns[alarmDetailsColDef],
        disableExport: true,
      };
    }

    const recipeColDef = parsedColumns.findIndex(
      (item) => item.field === "recipe",
    );
    if (recipeColDef !== -1) {
      parsedColumns[recipeColDef] = {
        ...parsedColumns[recipeColDef],
        disableExport: true,
      };
    }

    const anomaliesColDef = parsedColumns.findIndex(
      (item) => item.field === "hasAnomalies",
    );
    if (anomaliesColDef !== -1) {
      parsedColumns[anomaliesColDef] = {
        ...parsedColumns[anomaliesColDef],
        disableExport: true,
      };
    }

    const parsedRows: GridRowsProp = rowValues.map((rowItem, index) => ({
      ...rowItem,
      allTurnTime: TimeHelpers.parseDurationToString({
        duration: rowItem.allTurnTime * 1000,
      }),
      uptime: TimeHelpers.parseDurationToString({
        duration: rowItem.uptime * 1000,
      }),
      oee: Number((rowItem.oee || 0).toFixed(2)),
      availability: Number((rowItem.availability || 0).toFixed(2)),
      quality: Number((rowItem.quality || 0).toFixed(2)),
      performance: Number((rowItem.performance || 0).toFixed(2)),
      id: index,
    }));

    return {
      columns: parsedColumns,
      rows: parsedRows,
    };
  }
}
