import { RuleSchema } from "../api/useGetEventsData/getRules";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { Rule } from "../components/Rule";
import { Card } from "@/components/Layout/Card";
import { useDisclosure } from "@/hooks/useDisclosure";
import { NewRuleModal } from "../components/NewRuleModal";
import { RuleContextProvider } from "../context/RuleContextProvider";
import { useTranslate } from "@/i18n/config";

export const RuleDefinition = ({
  rules,
  machines,
}: {
  rules: RuleSchema[];
  machines: string[];
}) => {
  const { open, close, isOpen } = useDisclosure();
  const translate = useTranslate();

  return (
    <RuleContextProvider>
      <NewRuleModal isOpen={isOpen} close={close} machines={machines} />
      <Stack
        sx={{
          gap: 1,
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button variant="contained" onClick={open}>
            {translate("events.new_rule")}
          </Button>
        </Box>
        <Card sx={{ display: "flex", gap: 2 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              flex: 3,
            }}
          >
            {translate("events.rule_name")}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              flex: 3,
            }}
          >
            {translate("events.rule_definition")}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              flex: 2,
            }}
          >
            {translate("machine")}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              flex: 2,
            }}
          >
            {translate("company")}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              flex: 4,
            }}
          >
            {translate("events.rule")}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              flex: 0.5,
            }}
          >
            {translate("events.active")}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              flex: 0.5,
            }}
          >
            {translate("events.event_loop")}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              flex: 3,
            }}
          >
            {translate("events.last_update")}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              flex: 4,
            }}
          >
            {translate("events.modified_by")}
          </Typography>
          <Typography sx={{ flex: 0.5 }}></Typography>
          <Typography sx={{ flex: 0.5 }}></Typography>
        </Card>
        <Stack divider={<Divider />}>
          {rules.length === 0 && <p>{translate("events.no_rules")}</p>}
          {rules.map((rule) => (
            <Rule key={rule.timestamp} info={rule} />
          ))}
        </Stack>
      </Stack>
    </RuleContextProvider>
  );
};
