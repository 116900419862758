import {
  FormGroup,
  FormControlLabel,
  Switch as SwitchMUI,
} from "@mui/material";

export const Switch = ({
  isChecked,
  label,
  onChange,
  disabled = false,
}: {
  isChecked: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  label: string;
  disabled?: boolean;
}) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <SwitchMUI
            checked={isChecked}
            onChange={onChange}
            inputProps={{ "aria-label": "controlled" }}
            disabled={disabled}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};
