import { useState } from "react";
import { useTranslate } from "@/i18n/config";
import {
  Box,
  Button,
  Grid2,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Card } from "@/components/Layout/Card";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { TestCircleLayoutLoader } from "@/features/MachineDetail/components/CircleLayout";
import { useGetImputationStatsData } from "@/features/StopJustification/api/useGetImputationStatsData";
import { useDisclosure } from "@/hooks/useDisclosure";
import { ImputationCategoriesEditModal } from "@/features/StopJustification/components/ImputationCategoriesEditModal";
import { CircularProgressBar } from "@/features/StopJustification/components/CircularProgressBar";
import { parseStatsData } from "@/features/StopJustification/utils/parseStatsData";
import { colors } from "@/styles/colors";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { useMachineContext } from "@/context/machine-context";

export const ImputationStats = () => {
  const translate = useTranslate();

  const { machine } = useMachineContext();
  const machineName = machine?.machine;

  const { data, isLoading, error } = useGetImputationStatsData();
  const [selectedState, setSelectedState] = useState(translate("all"));
  const { open, close, isOpen } = useDisclosure();

  if (isLoading)
    return (
      <Grid2
        container
        columns={12}
        spacing={2}
        sx={{
          alignItems: "center",
        }}
      >
        <Grid2
          container
          columns={12}
          size={2.5}
          sx={{
            gap: 1,
            alignItems: "center",
          }}
        >
          <Grid2 size={12}>
            <TestCircleLayoutLoader height={"12vmax"} />
          </Grid2>
        </Grid2>
        <Grid2
          container
          columns={12}
          size={1.5}
          sx={{
            gap: 2,
          }}
        >
          <Grid2 size={12}>
            <TestCircleLayoutLoader height={"8vmax"} />
          </Grid2>
          <Grid2 size={12}>
            <TestCircleLayoutLoader height={"8vmax"} />
          </Grid2>
        </Grid2>
        <Grid2 size={8}>
          <SkeletonCard height={400} />
        </Grid2>
      </Grid2>
    );

  if (error) return <div>{translate("user_feedback.an_error_occurred")}</div>;

  if (!data) return <div>{translate("user_feedback.no_data")}</div>;

  const { categories, countData, percentageData } = parseStatsData(
    data.data,
    selectedState,
    translate("all"),
  );

  const {
    shutdownImputed,
    shutdownOccurrences,
    stopImputed,
    stopOccurrences,
    totalImputed,
    totalOccurrences,
  } = data.count;

  const handleSelectBatch = (
    _: React.MouseEvent<HTMLElement>,
    newBatch: string,
  ) => {
    if (!newBatch) return;
    setSelectedState(newBatch);
  };

  return (
    <>
      {isOpen && (
        <ImputationCategoriesEditModal close={close} isOpen={isOpen} />
      )}
      <Grid2
        container
        columns={12}
        sx={{
          alignItems: "center",
        }}
      >
        <Grid2
          container
          columns={12}
          size={2.5}
          sx={{
            gap: 1,
            alignItems: "center",
          }}
        >
          <Grid2 size={12}>
            <CircularProgressBar
              color={colors.states.production}
              max={100}
              min={0}
              title={translate("stop_imputation.imputed")}
              subTitle={`${totalImputed} / ${totalOccurrences}`}
              value={
                totalOccurrences > 0
                  ? (100 / totalOccurrences) * totalImputed
                  : 0
              }
              dimension="extralarge"
            />
          </Grid2>
        </Grid2>
        <Grid2
          container
          columns={12}
          size={1.5}
          sx={{
            gap: 2,
          }}
        >
          <Grid2 size={12}>
            <CircularProgressBar
              color={colors.states.production}
              max={100}
              min={0}
              title={translate("machine.stop")}
              subTitle={`${stopImputed} / ${stopOccurrences}`}
              value={
                stopOccurrences > 0 ? (100 / stopOccurrences) * stopImputed : 0
              }
              dimension="medium"
            />
          </Grid2>
          <Grid2 size={12}>
            <CircularProgressBar
              color={colors.states.production}
              max={100}
              min={0}
              title={translate("machine.shutdown")}
              subTitle={`${shutdownImputed} / ${shutdownOccurrences}`}
              value={
                shutdownOccurrences > 0
                  ? (100 / shutdownOccurrences) * shutdownImputed
                  : 0
              }
              dimension="medium"
            />
          </Grid2>
        </Grid2>
        <Grid2 size={8}>
          <Card>
            <Box
              sx={{
                p: 1,
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ToggleButtonGroup
                value={selectedState}
                exclusive
                onChange={handleSelectBatch}
                aria-label={translate("variable_other")}
                sx={{ backgroundColor: "#1F293F" }}
              >
                {[translate("all"), ...Object.keys(data.data)].map((batch) => (
                  <ToggleButton key={batch} value={batch}>
                    {batch === "Intentional No Production"
                      ? translate("category.5")
                      : batch}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
              <Button variant="outlined" onClick={open}>
                {translate("stop_imputation.manage_categories")}
              </Button>
            </Box>
            <BarChart.Custom
              title={translate(
                "stop_imputation.imputations_count_and_duration",
              )}
              filename={fileNameFactory({
                name: "imputation_stats",
                machine: machineName,
              })}
              uom="%"
              tooltip={{ enabled: true }}
              showLogarithmicToggle
              categories={categories}
              scrollbarEnabled={countData.length > 6}
              xAxisMax={countData.length > 4 ? 5 : countData.length - 1}
              series={[
                {
                  type: "column",
                  name: translate("count"),
                  data: countData,
                  color: colors.categories.line,
                },
                {
                  type: "column",
                  name: translate("math.percentage"),
                  data: percentageData,
                  color: colors.states.stop,
                },
              ]}
            />
          </Card>
        </Grid2>
      </Grid2>
    </>
  );
};
