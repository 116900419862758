import { NivoSolidGauge } from "@/components/highcharts/gauge/NivoSolidGauge";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { Stack, Typography, Divider, Box } from "@mui/material";

type Props = {
  value: number;
  title: string;
  label: string;
  color: string;
  trackColor: string;
  legendList: {
    label: string;
    color: string;
    value: number;
  }[];
};

export const GaugeWithLegend = ({
  value,
  color,
  label,
  title,
  legendList,
  trackColor,
}: Props) => {
  const translate = useTranslate();
  return (
    <Card key={title}>
      <Stack direction="column">
        <Typography>{title}</Typography>
        <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 4,
            justifyContent: "space-around",
            alignItems: "flex-start",
          }}
        >
          <Box>
            <Box
              key={label}
              sx={{
                position: "relative",
                padding: 0.5,
                width: { xs: "18vw", md: "10vw" },
                height: { xs: "18vw", md: "10vw" },
                aspectRatio: "1/1",
                textAlign: "center",
                transition: "all .2s ease-in",
              }}
            >
              <NivoSolidGauge
                min={0}
                max={100}
                title={""}
                color={color}
                enableTrack={true}
                value={value}
                trackColor={trackColor}
              />
              <Box
                sx={{
                  position: "absolute",
                  color: "white",
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 10,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  fontSize: "15px",
                }}
              >
                <Box>{label}</Box>
                <Box>
                  {value ? `${value}%` : translate("evaluation.no_data")}
                </Box>
              </Box>
            </Box>
          </Box>
          <Stack spacing={1}>
            {legendList.map((item, index) => (
              <Box
                key={index}
                sx={{
                  flex: 1,
                  display: "flex",
                  gap: 5,
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: 4,
                      height: "70%",
                      bgcolor: item.color,
                    }}
                  ></Box>
                  <Typography>{item.label}</Typography>
                </Box>
                <Typography>{item.value}%</Typography>
              </Box>
            ))}
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
};
