import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { Stack, Divider, Typography } from "@mui/material";
import { useGetSpeed } from "../api/useGetSpeed";
import { Card } from "@/components/Layout/Card";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { useTranslate } from "@/i18n/config";
import { useTimeSelection } from "@/store/useTimeSelection";
import { useMachineContext } from "@/context/machine-context";
import { fileNameFactory } from "@/utils/fileNameFactory";

export function SpeedInfo() {
  const { data, isLoading, error } = useGetSpeed();
  const translate = useTranslate();
  const { timeSelection, dates } = useTimeSelection();
  const { machine } = useMachineContext();
  const machineName = machine?.machine;

  if (isLoading) {
    return <SkeletonCard height={300} />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  return (
    <Card>
      <Stack
        divider={<Divider />}
        sx={{
          gap: 2,
        }}
      >
        <LineChart.Custom
          filename={fileNameFactory({
            name: "speed",
            machine: machineName,
          })}
          key={`${timeSelection}-${dates.dateStart}-${dates.dateEnd}`}
          height={220}
          title={translate("speed")}
          plotOptions={{
            line: {
              marker: {
                enabled: false,
              },
              step: "left",
            },
          }}
          legend={{
            enabled: true,
            options: {
              align: "right",
              verticalAlign: "top",
              itemMarginTop: -62,
              x: -50,
            },
          }}
          yAxis={[
            {
              uom: "",
              series: data.data.map((speed) => ({
                data: speed.data,
                type: "line",
                name: speed.name,
                color: speed.color,
                uom: "cpm",
              })),
            },
          ]}
        />
        <Stack
          direction="row"
          sx={{
            width: "100%",
          }}
        >
          {data.data.map((speed) => {
            return (
              <Stack
                key={speed.description}
                sx={{
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    flex: 4,
                  }}
                >
                  {speed.description}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    flex: 1,
                    textAlign: "right",
                  }}
                >
                  {speed.data[speed.data.length - 1]?.[1]}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Card>
  );
}
