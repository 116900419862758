import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useTimeSelection } from "@/store/useTimeSelection";
import { useState } from "react";
import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { renderToString } from "react-dom/server";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { useMachineContext } from "@/context/machine-context";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { AlarmData } from "../api/useGetAlarmsColumnData";
import { AlarmsViews } from "..";

export function AlarmColumns({
  alarmData,
  alarmsViews,
  onChangeBlocking,
  onChangeWarning,
  onChangeSignalings,
}: {
  alarmData: AlarmData[];
  alarmsViews: AlarmsViews;
  onChangeBlocking: (showBlocking: boolean) => void;
  onChangeWarning: (showWarning: boolean) => void;
  onChangeSignalings: (showSignalings: boolean) => void;
}) {
  const [sortBy, setSortBy] = useState<"count" | "duration">("count");
  const translate = useTranslate();

  const { machine } = useMachineContext();
  const machineName = machine?.machine;

  const { timeSelection } = useTimeSelection();

  const data = alarmData.sort((a, b) => {
    if (sortBy === "count") {
      return b.Count - a.Count;
    } else {
      return b.Duration - a.Duration;
    }
  });

  const alarmCount = data.map((x) => ({
    y: x.CountPercentage,
    custom: {
      duration: TimeHelpers.parseDurationToString({
        duration: x.Duration * 1000,
      }),
      mttr: TimeHelpers.parseDurationToString({ duration: x.MTTR * 1000 }),
      mtbf: TimeHelpers.parseDurationToString({ duration: x.MTBF * 1000 }),
      description: x.ErrorDescription,
      count: x.Count,
    },
  }));
  const alarmDuration = alarmData.map((x) => x.DurationPercentage);
  const categories = alarmData.map((x) => x.Error.toString());

  return (
    <Card>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography>{translate("alarms.count_duration_percentage")}</Typography>
        <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={alarmsViews.blocking}
                onChange={(e) => onChangeBlocking(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={translate("alarms.blocking")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={alarmsViews.warning}
                onChange={(e) => onChangeWarning(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={translate("alarms.warning")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={alarmsViews.signalings}
                onChange={(e) => onChangeSignalings(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={translate("alarms.signalings")}
          />
        </FormGroup>
      </Box>
      <BarChart.Custom
        yAxisOptions={{ max: 100 }}
        showLogarithmicToggle={true}
        categories={categories}
        filename={fileNameFactory({
          machine: machineName,
          name: "alarms",
          date: timeSelection,
        })}
        title=""
        uom="%"
        legend={{
          enabled: true,
        }}
        tooltip={{
          enabled: true,
          options: {
            useHTML: true,

            formatter: function () {
              /**
               * this is a ts hack to get the custom property working,
               * in this case they would be inside only the first point
               */

              const point = this.points?.[0].point as unknown as {
                custom: {
                  duration: string;
                  mttr: string;
                  mtbf: string;
                  description: string;
                  count: number;
                };
              };

              const count = this.points?.[0];
              const duration = this.points?.[1];

              if (point) {
                return renderToString(
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: 3 }}
                  >
                    <div>
                      {translate("count")} %:{" "}
                      <b>{count ? count.y?.toFixed(2) : 0}%</b>
                    </div>
                    <div>
                      {translate("count")}: <b>{point.custom.count}</b>
                    </div>
                    <div>
                      {translate("duration_ratio")}:{" "}
                      <b>{duration ? duration.y?.toFixed(2) : 0}%</b>
                    </div>
                    <div>
                      {translate("duration")}: <b>{point.custom.duration}</b>
                    </div>
                    <div>
                      {translate("mttr")}: <b>{point.custom.mttr}</b>
                    </div>
                    <div>
                      {translate("mtbf")}: <b>{point.custom.mtbf}</b>
                    </div>
                    <div>
                      {translate("description")}:{" "}
                      <b>{point.custom.description}</b>
                    </div>
                  </div>,
                );
              }
            },
          },
        }}
        series={[
          {
            type: "column",
            name: translate("count"),
            data: alarmCount,
            color: "#F07802",
          },
          {
            type: "column",
            name: translate("duration"),
            data: alarmDuration,
            color: "#F4C604",
          },
        ]}
      />
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">
          {translate("sort_by")}
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value as "count" | "duration")}
        >
          <FormControlLabel
            value="count"
            control={<Radio />}
            label={translate("count")}
          />
          <FormControlLabel
            value="duration"
            control={<Radio />}
            label={translate("duration")}
          />
        </RadioGroup>
      </FormControl>
    </Card>
  );
}
