import { Box, Stack, Typography } from "@mui/material";
import { DurationProgressBar, ProgressBar } from "./ProgressBar";
import { TitleWithTooltip } from "./TitleWithTooltip";
import { Duration } from "luxon";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";

interface StateCardProps {
  title: string;
  id: number;
  duration: number;
  referencePerformance: number;
  referenceAvailability: number;
  referenceOee: number;
  oeeGain: number;
  performanceGain: number;
  availabilityGain: number;
  onClick: (params: {
    stateName: string;
    id: number;
    isClientRelevance: boolean;
  }) => void;
  isEdited: boolean;
  referenceTotalDuration: number;
  isClientRelevance: boolean;
  view: "live" | "historic";
}

const formatDuration = (durationInSeconds: number) => {
  const duration = Duration.fromObject({ seconds: durationInSeconds });
  if (duration.as("weeks") >= 1) {
    return duration.toFormat("w 'w' d 'd'");
  }
  if (duration.as("days") >= 1) {
    return duration.toFormat("d 'd' h 'h'");
  }
  if (duration.as("hours") >= 1) {
    return duration.toFormat("h 'h' m 'm'");
  }
  if (duration.as("minutes") >= 1) {
    return duration.toFormat("m 'm' s 's'");
  }
  return duration.toFormat("s 's'");
};

export const StateCard = ({
  title,
  id,
  availabilityGain,
  performanceGain,
  oeeGain,
  referenceOee,
  referencePerformance,
  referenceAvailability,
  duration,
  onClick,
  isEdited,
  referenceTotalDuration,
  isClientRelevance,
  view,
}: StateCardProps) => {
  const translate = useTranslate();
  return (
    <Card
      key={id}
      role={view === "live" ? "button" : undefined}
      onClick={
        view === "live"
          ? () => {
              onClick({
                stateName: title,
                id,
                isClientRelevance: !isClientRelevance,
              });
            }
          : undefined
      }
      sx={{
        display: "flex",
        flexDirection: "column",
        minWidth: "200px",
        backgroundColor: "#192231",
        color: "#fff",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
        transition: "transform 0.3s, box-shadow 0.3s",
        position: "relative", // Necessario per l'overlay
        "&:hover": {
          transform: view === "live" ? "scale(1.03)" : "none",
          boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.3)",
        },
        cursor: view === "live" ? "pointer" : "auto",
        height: "100%",
        opacity: view === "live" ? 1 : 0.75,
        borderColor: isEdited ? "#4CAF50" : "#192231",
        // Effetto vetrato quando view !== "live"
        ...(view !== "live" && {
          pointerEvents: "none",
          "&::after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.1)", // Leggero velo bianco
            backdropFilter: "blur(.02px)", // Effetto vetro sfocato
            borderRadius: "inherit",
          },
        }),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {<TitleWithTooltip title={title} />}
        {isEdited && (
          <Box
            sx={{
              width: "fit-content",
              px: 1.2,
              py: 0.1,
              borderRadius: "10px",
              bgcolor: "#ffff",
              mb: "5px",
            }}
          >
            <Typography
              sx={{
                fontSize: "10px",
                alignItems: "center",
                textAlign: "center",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              {translate("configurable_oee.edited")}
            </Typography>
          </Box>
        )}
      </Box>
      <Stack spacing={1.3}>
        <Box>
          <ProgressBar
            referenceValue={referenceOee}
            gain={oeeGain}
            label={translate("configurable_oee.oee_impact")}
          />
        </Box>
        <Box>
          <ProgressBar
            referenceValue={referenceAvailability}
            gain={availabilityGain}
            label={translate("kpi.availability")}
          />
        </Box>
        <Box>
          <ProgressBar
            referenceValue={referencePerformance}
            gain={performanceGain}
            label={translate("kpi.performance")}
          />
        </Box>
        <Box>
          <DurationProgressBar
            totalDuration={referenceTotalDuration}
            duration={duration}
            durationLabel={formatDuration(duration)}
            label={translate("duration")}
          />
        </Box>
      </Stack>
    </Card>
  );
};
