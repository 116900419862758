import { useMutation } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import { intellectaClient } from "./intellectaClient";
import { IntellectaError, IntellectaResult, intellectaResult } from "../types";

type Response = IntellectaError | IntellectaResult;

type Payload = {
  user: string;
  rating: number;
  user_notes: string;
  message_id: number; //? univoco per ogni messaggio
};

export const useLeaveFeedback = () => {
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  return useMutation<Response, Error, Omit<Payload, "user">>({
    mutationFn: async (payload) => {
      const response = await intellectaClient<Payload, Response>({
        url: "/feedback",
        payload: { ...payload, user: email },
      });

      if (response === "ERROR") throw new Error();

      const validatedResponse = intellectaResult.parse(response);
      return validatedResponse;
    },
  });
};
