import { ImputationCategory, ImputationCategoriesData } from "../../types";
import { Grid2, Typography } from "@mui/material";
import { ImputationSubCategoryCell } from "./ImputationSubCategoryCell";
import { ImputationCategoryCell } from "./ImputationCategoryCell";

export const ImputationSubCategoryRow = ({
  data,
  categories,
}: {
  data: ImputationCategoriesData;
  categories: ImputationCategory[];
}) => {
  return (
    <Grid2
      container
      columns={12}
      sx={{
        alignItems: "center",
        paddingY: 2,
      }}
    >
      <Grid2 size={2}>
        <Typography>{data.subCategoryId}</Typography>
      </Grid2>
      <Grid2 size={5}>
        <ImputationSubCategoryCell
          id={data.subCategoryId}
          value={data.subCategory}
          isEditable={data.isEditable}
        />
      </Grid2>
      <Grid2 size={5}>
        <ImputationCategoryCell
          categoryId={data.categoryId}
          subCategoryId={data.subCategoryId}
          categories={categories}
          isEditable={data.isEditable}
        />
      </Grid2>
    </Grid2>
  );
};
