import { useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { useMachineContext } from "@/context/machine-context";
import { useTimeSelection, TimeSelection } from "../../store/useTimeSelection";
import { ShortCutsRange } from "../Calendars/range-shortcuts";
import { CustomDateTimeRangePicker } from "../Calendars/CustomDateTimeRangePicker";

const LONG_TIME_SELECTIONS: TimeSelection[] = [
  "TwoWeeks",
  "Month",
  "ThreeMonths",
  "SixMonths",
  "Year",
];

export const CustomSelectTimeSelection = ({
  forceCalendar = false,
  exclude = [],
  isMachinePage = true,
  unavailableShortcuts,
  maxDays,
}: {
  forceCalendar?: boolean;
  exclude?: TimeSelection[];
  isMachinePage?: boolean;
  unavailableShortcuts?: ShortCutsRange[];
  maxDays?: number;
}) => {
  const { machine } = useMachineContext();
  const {
    timeSelection,
    setTimeSelection,
    setAvailableSelection,
    availableSelection,
  } = useTimeSelection();
  const translate = useTranslate();

  useEffect(
    () => {
      if (machine?.machineLogic === "static") {
        LONG_TIME_SELECTIONS.forEach((longTimeSelection) => {
          if (!exclude.includes(longTimeSelection))
            exclude.push(longTimeSelection);
        });
      }
      if (exclude.includes(timeSelection)) {
        setTimeSelection("Shift");
      }
      setAvailableSelection({ filterBy: exclude });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <FormControl>
        <InputLabel id="time-selection-view-label">
          {translate("view")}
        </InputLabel>
        <Select
          labelId="time-selection-view-label"
          label={translate("view")}
          value={timeSelection}
          id="time-selection-view"
          name="time-selection-view"
          size="small"
          onChange={(e) => setTimeSelection(e.target.value as TimeSelection)}
          sx={{
            fontWeight: "bold",
            width: "10rem",
          }}
        >
          {availableSelection.map((selection) => (
            <MenuItem
              key={selection}
              value={selection}
              disabled={
                isMachinePage &&
                selection === "Batch" &&
                machine?.hasBatch === false
                  ? true
                  : false
              }
            >
              {translate(`time_selection.${selection}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {forceCalendar ? (
        <CustomDateTimeRangePicker
          unavailableShortcuts={unavailableShortcuts}
          maxDays={maxDays}
        />
      ) : timeSelection === "Custom" ? (
        <CustomDateTimeRangePicker
          unavailableShortcuts={unavailableShortcuts}
          maxDays={maxDays}
        />
      ) : null}
    </>
  );
};
