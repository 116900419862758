import { Card } from "@/components/Layout/Card";
import { Box, Divider, Stack, Typography } from "@mui/material";

const PluginCard = ({ title, value }: { title: string; value: boolean }) => {
  return (
    <Card>
      <Typography variant="h6">{title}</Typography>
      <Divider />
      <Stack
        direction={"row"}
        spacing={1}
        sx={{
          justifyContent: "space-between",
          marginTop: ".5rem",
        }}
      >
        <Typography>Status</Typography>
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: "50%",
            bgcolor: value ? "green" : "red",
          }}
        />
      </Stack>
    </Card>
  );
};

export const Plugins = ({
  fastData,
  httpServer,
  opcuaServer,
}: {
  opcuaServer: boolean;
  fastData: boolean;
  httpServer: boolean;
}) => {
  return (
    <Stack direction="row" spacing={2}>
      <PluginCard title="HTTP Server" value={httpServer} />
      <PluginCard title="Fast Data" value={fastData} />
      <PluginCard title="OPC UA Server" value={opcuaServer} />
    </Stack>
  );
};
