import { Box, styled, Typography } from "@mui/material";
import { ChartType, formatValue } from "../utils/formatValue";

export const DualProgressBar = styled(Box)(() => ({
  position: "relative",
  height: 5,
  borderRadius: 3,
  backgroundColor: "#9e9e9e40",
  overflow: "hidden",
}));

const ProgressSegment = styled(Box)<{
  value: number;
  color: string;
  left?: string;
}>(({ value, color, left = "0%" }) => ({
  position: "absolute",
  height: "100%",
  width: `${value}%`,
  left: left,
  backgroundColor: color,
  transition: "width 0.3s ease",
}));

interface ProgressBarProps {
  valueCurrent: number;
  valueBenchmark: number;
  label: string;
  chartType: ChartType;
}

export const ProgressBar = ({
  valueCurrent,
  valueBenchmark,
  label,
  chartType,
}: ProgressBarProps) => {
  // Calcolo del gap come differenza
  const gap = valueCurrent - valueBenchmark;
  // Impostare il colore in base al gap
  const color = gap === 0 ? "#fff" : gap > 0 ? "#f44336" : "#4caf50"; // Verde/rosso
  const gapWidth = Math.abs(gap);
  const isPositiveGap = gap < 0;
  const gapSegmentStart = isPositiveGap
    ? valueCurrent
    : valueCurrent - gapWidth;

  // Determinare i valori minimi e massimi
  const minValue = Math.min(valueCurrent, valueBenchmark);
  const maxValue = Math.max(valueCurrent, valueBenchmark);
  const range = maxValue || 1; // Evitare divisione per 0

  // Calcolare la larghezza della barra in base al tipo
  const baseWidth =
    chartType === "percentage" ? valueBenchmark : (minValue / range) * 100;
  const diffWidth =
    chartType === "percentage"
      ? gap
      : // ? valueBenchmark - valueCurrent
        ((maxValue - minValue) / range) * 100;

  return (
    <Box
      sx={{
        mt: -1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 1,
        }}
      >
        <Typography
          sx={{
            fontSize: ".7rem",
            flexGrow: 1,
          }}
        >
          {label}
        </Typography>
        <Typography
          sx={{
            fontSize: ".7rem",
            color,
            fontWeight: "medium",
          }}
        >
          {formatValue({ value: gap, chartType })}
        </Typography>
      </Box>
      <DualProgressBar>
        {/* Barra base (grigia) */}
        <ProgressSegment value={baseWidth} color="#fff" sx={{ opacity: 0.5 }} />
        {/* Barra differenza (blu/verde/rossa) */}
        <ProgressSegment
          value={chartType === "percentage" ? gapWidth : diffWidth}
          color={color}
          left={
            chartType === "percentage" ? `${gapSegmentStart}%` : `${baseWidth}%`
          }
          sx={{ opacity: 0.8 }}
        />
      </DualProgressBar>
    </Box>
  );
};
