import { toast } from "react-hot-toast";
import {
  Dialog,
  DialogContent,
  Stack,
  CircularProgress,
  DialogTitle,
  Typography,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { useTranslate } from "@/i18n/config";
import {
  SharedMessageResult,
  useGetSharedMessage,
} from "@/features/Intellecta/api/Social/useGetSharedMessage";
import { intellectaColors } from "@/features/Intellecta/utilities/colors";
import { userConfigurationStore } from "@/features/Intellecta/store/user-configuration-store";
import { useSetActiveProfile } from "@/features/Intellecta/api/useSetActiveProfile";
import { useNewChat } from "@/features/Intellecta/hooks/useNewChat";
import { ONE_SECOND } from "@/utils/durationsInMilliseconds";
import { ChatMessageBody } from "../../ChatMessage/ChatMessageBody";

export const SharedMessageModal = ({
  isOpen,
  close,
  socialId,
  onUsePrompt,
}: {
  isOpen: boolean;
  close: () => void;
  socialId: number;
  onUsePrompt: (prompt: string) => void;
}) => {
  const translate = useTranslate();
  const { availableMachines, availableVersionsAndProfiles, setLastMachineId } =
    userConfigurationStore();
  const { mutate: setActiveProfile } = useSetActiveProfile();
  const { startNewChat } = useNewChat();
  const { data, error, isLoading } = useGetSharedMessage({
    social_id: socialId,
  });

  const handleClose = () => {
    close();
  };

  if (error) {
    toast.error(translate("user_feedback.an_error_occurred"));
    return null;
  }

  const handleUsePrompt = ({ data }: { data: SharedMessageResult }) => {
    let hasSameConfiguration = false;
    let hasMachine = false;
    const foundVersion = availableVersionsAndProfiles.find(
      (el) => el.name === data.version,
    );
    if (foundVersion) {
      const foundProfile = foundVersion.profiles.find(
        (el) => el.name === data.profile,
      );
      if (foundProfile) {
        if (data.machine) {
          const foundMachine = availableMachines.find(
            (el) => el.machine === data.machine,
          );
          if (foundMachine) {
            const hasVersionAndProfile =
              foundMachine.profile.includes(data.profile) &&
              foundMachine.service_version_access.includes(data.version);
            if (hasVersionAndProfile) {
              hasSameConfiguration = true;
              hasMachine = true;
            }
          }
        } else {
          hasSameConfiguration = true;
        }
      }
    }

    if (hasSameConfiguration) {
      setActiveProfile({ version: data.version, profile: data.profile });
    } else {
      toast(translate("intellecta.interaction_different_configuration"), {
        icon: "❗️",
        duration: ONE_SECOND * 5,
      });
    }

    if (hasMachine) {
      setLastMachineId(data.machine!);
    }

    startNewChat();
    onUsePrompt(data.query);
    handleClose();
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose} maxWidth="lg">
      {isLoading ? (
        <DialogContent>
          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "center",
              py: 4,
              height: "20vh",
            }}
          >
            <CircularProgress />
          </Stack>
        </DialogContent>
      ) : data ? (
        <>
          <DialogTitle sx={{ textAlign: "center" }}>{data.title}</DialogTitle>
          <DialogContent>
            <Stack gap={2}>
              {data.user_notes !== "" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      maxWidth: "80%",
                      bgcolor: intellectaColors.powerWordButtonBackgroundHover,
                      p: 2,
                      borderRadius: 2,
                    }}
                  >
                    {data.user_notes}
                  </Typography>
                </Box>
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Typography variant="body2" sx={{ mb: 1, fontWeight: "bold" }}>
                  {data.sending_user}
                </Typography>
                <Box
                  sx={{
                    maxWidth: "80%",
                    bgcolor: intellectaColors.unreadNotificationBackground,
                    p: 2,
                    borderRadius: 2,
                  }}
                >
                  <ChatMessageBody message={data.query} />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography variant="body2" sx={{ mb: 1, fontWeight: "bold" }}>
                  Intellecta
                </Typography>
                <Box
                  sx={{
                    maxWidth: "80%",
                    bgcolor: intellectaColors.powerWordButtonBackgroundHover,
                    p: 2,
                    borderRadius: 2,
                  }}
                >
                  <ChatMessageBody message={data.answer} />
                </Box>
              </Box>

              {data.image && (
                <Box sx={{ textAlign: "center" }}>
                  <img
                    src={`data:image/png;base64,${data.image}`}
                    alt="Decoded Image"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    }}
                  />
                </Box>
              )}
            </Stack>
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <Button variant="outlined" onClick={handleClose} sx={{ mr: 1 }}>
              {translate("actions.cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleUsePrompt({ data });
              }}
            >
              {translate("intellecta.use_prompt")}
            </Button>
          </DialogActions>
        </>
      ) : null}
    </Dialog>
  );
};
