import { Translations } from "@/i18n/config";
import { Chat } from "../types";

export function getGroupedChatsByDate({
  chats,
  translate,
}: {
  chats: Chat[];
  translate: (
    key: Translations,
    options?: { [key: string]: string | number },
  ) => string;
}) {
  const now = new Date();
  const startOfToday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
  ).getTime();

  const groupedChats: Record<string, Chat[]> = {};

  chats
    .sort((a, b) => b.timestamp - a.timestamp) // Ordina per timestamp decrescente
    .forEach((chat) => {
      const chatDate = new Date(chat.timestamp * 1000);
      const startOfChatDay = new Date(
        chatDate.getFullYear(),
        chatDate.getMonth(),
        chatDate.getDate(),
      ).getTime();

      const msPerDay = 24 * 60 * 60 * 1000; // Millisecondi in un giorno
      const daysAgo = Math.round((startOfToday - startOfChatDay) / msPerDay);
      const weeksAgo = Math.floor(daysAgo / 7);

      let key: string;
      if (daysAgo === 0) {
        key = translate("relative_durations.today");
      } else if (daysAgo === 1) {
        key = translate("relative_durations.yesterday");
      } else if (daysAgo < 7) {
        key = translate("relative_durations.days_ago", { count: daysAgo });
      } else if (weeksAgo === 1) {
        key = translate("relative_durations.week_ago");
      } else {
        key = translate("relative_durations.weeks_ago", { count: weeksAgo });
      }

      if (!groupedChats[key]) {
        groupedChats[key] = [];
      }
      groupedChats[key].push(chat);
    });

  return groupedChats;
}
