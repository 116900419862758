import { z } from "zod";
import { DateTime } from "luxon";
import { useQuery } from "@tanstack/react-query";
import { useLanguage } from "@/i18n/config";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { StateLogDataEntry } from "@/features/StateLog/api/useGetData";
import { FetchClient } from "@/services/ApiClient";
import { ThingworxError } from "src/types/error";
import { useCustomTimeRange } from "../store/useCustomTimeRange";

type StatesRejectsResponse = ThingworxError | StatesRejectsResult;
const StatesRejectsData = z.object({
  wasteCounterData: z.array(z.array(z.number())),
  totalCounterData: z.array(z.array(z.number())),
  stateLogData: z.array(StateLogDataEntry),
});

type StatesRejectsData = z.infer<typeof StatesRejectsData>;

const StatesRejectsResult = z.object({
  response: z.literal(true),
  data: StatesRejectsData,
});

type StatesRejectsResult = z.infer<typeof StatesRejectsResult>;

type Payload = {
  machineName: string;
  dateStart: DateTime;
  dateEnd: DateTime;
  language_id: string;
};

export const useGetStatesAndRejects = () => {
  const { machine } = useMachineContext();

  const { dateStart, dateEnd } = useCustomTimeRange();

  const { appKey } = useFirebaseContext();

  const language = useLanguage();

  return useQuery<StatesRejectsData>({
    queryKey: [
      "states-counters",
      machine?.machine,
      dateStart,
      dateEnd,
      language,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, StatesRejectsResponse>({
        appKey,
        payload: {
          dateStart,
          dateEnd,
          machineName: machine?.machine || "",
          language_id: language,
        },
        url: "dig.c.fillerAdvancedAnalytics_thing/Services/getStatesCountersData",
      });

      if (!response.response) throw new Error(response.errorString);

      return StatesRejectsResult.parse(response).data;
    },
  });
};
