import { useFirebaseContext } from "@/context/firebase-context";
import { UNAVAILABLE_TIME_SELECTIONS } from "@/features/OverallLineEfficiency/constants";
import { useLanguage } from "@/i18n/config";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { QueryBuilder } from "@/utils/query-builder";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type Payload =
  | {
      config: "automatic";
      timeSelection: TimeSelection;
      line_id: number;
      config_id: number;
      language_id: string;
    }
  | {
      config: "manual";
      line_id: number;
      config_id: number;
      language_id: string;
    };

type LineProductsProjectionResponse =
  | ThingworxError
  | LineProductsProjectionResult;

const LineProductsProjectionResult = z.object({
  response: z.literal(true),
  products: z.array(z.array(z.number())),
  products_projection: z.array(z.array(z.number())),
  machine_output: z.string(),
});

type LineProductsProjectionResult = z.infer<
  typeof LineProductsProjectionResult
>;

export const useGetLineProductsProjection = ({
  config,
  line_id,
  config_id,
}: {
  config: "automatic" | "manual";
  line_id: number;
  config_id: number;
}) => {
  const { appKey } = useFirebaseContext();
  const { timeSelection } = useTimeSelection();
  const allowedTimeSelection = UNAVAILABLE_TIME_SELECTIONS.includes(
    timeSelection,
  )
    ? "Shift"
    : timeSelection;
  const language = useLanguage();

  const queryKey = QueryBuilder.buildWithCondition({
    baseQuery: [
      "line-products-projection",
      line_id,
      config_id,
      config,
      language,
    ],
    condition: config === "automatic",
    query: [allowedTimeSelection],
  });

  const payload: Payload =
    config === "automatic"
      ? {
          config,
          timeSelection: allowedTimeSelection,
          line_id,
          config_id,
          language_id: language,
        }
      : {
          config,
          line_id,
          config_id,
          language_id: language,
        };

  return useQuery({
    queryKey,
    queryFn: async () => {
      const response = await FetchClient<
        Payload,
        LineProductsProjectionResponse
      >({
        appKey,
        payload,
        url: "dig.c.plantOverview_thing/Services/getLineProductsProjection",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return LineProductsProjectionResult.parse(response);
    },
  });
};
