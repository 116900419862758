import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Grid2,
} from "@mui/material";
import { FilterListOutlined } from "@mui/icons-material";
import { Card } from "@/components/Layout/Card";
import { StateInfo } from "@/features/ConfigurableOee/api/useGetConfigurableOeeData";
import { useTranslate } from "@/i18n/config";
import { StateCard } from "./StateCard/StateCardLayout";
import { formatValue } from "../../utils/utilities";

type FilterOptions = {
  key: string;
  label: string;
};

export const ConfigurableStateCardList = ({
  title,
  items,
  statesLength,
  referenceOee,
  referenceAvailability,
  referencePerformance,
  referenceTotalDuration,
  onClick,
  view,
}: {
  title: string;
  items: StateInfo[];
  statesLength: number;
  referenceOee: number;
  referenceAvailability: number;
  referencePerformance: number;
  referenceTotalDuration: number;
  onClick: (params: {
    stateName: string;
    id: number;
    isClientRelevance: boolean;
  }) => void;
  view: "live" | "historic";
}) => {
  const translate = useTranslate();
  const [toggleFilter, setToggleFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedFilter, setSelectedFilter] = useState<FilterOptions>({
    key: "occurred-states",
    label: translate("configurable_oee.occurred_states"),
  });

  const filterOptions: FilterOptions[] = [
    { key: "all", label: translate("all") },
    { key: "edited", label: translate("configurable_oee.edited_other") },
    {
      key: "occurred-states",
      label: translate("configurable_oee.occurred_states"),
    },
  ];

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (filter: FilterOptions) => {
    setSelectedFilter(filter);
    setAnchorEl(null);
  };

  const filteredItems = items.filter(
    (item) => item.isClientRelevance !== item.isIdleRelevance,
  );

  const statesWithoutZeroDuration = items.filter((item) => item.duration > 0);
  const statesList =
    selectedFilter.key === "edited"
      ? filteredItems
      : selectedFilter.key === "occurred-states"
      ? statesWithoutZeroDuration
      : items;

  return (
    <Card
      sx={{
        padding: 0,
        backgroundColor: "#0d1626",
        paddingBottom: 2,
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: 1,
          }}
        >
          <Typography
            sx={{
              px: 1,
              fontWeight: "bold",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              px: 1,
              color: "#aaaa",
            }}
          >
            {items.length}/{statesLength}
          </Typography>
        </Box>

        <IconButton
          onClick={(e) => {
            setToggleFilter(!toggleFilter);
            handleClick(e);
          }}
        >
          <FilterListOutlined fontSize="small" />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: -130,
          }}
          slotProps={{ list: { "aria-labelledby": "basic-button" } }}
        >
          {filterOptions.map((filter) => (
            <MenuItem
              sx={{
                backgroundColor:
                  selectedFilter.key === filter.key ? "#FFFFFF29" : undefined,
              }}
              key={filter.key}
              onClick={() => handleClose(filter)}
            >
              {filter.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Box
        sx={{
          height: "450px",
          overflowY: "auto",
          paddingX: 2,
          paddingTop: 1,
          width: "100%",
        }}
      >
        {statesList.length ? (
          <Grid2 container columnSpacing={2} rowSpacing={2}>
            <AnimatePresence>
              {statesList.map((state, index) => {
                return (
                  <Grid2
                    key={index}
                    size={{
                      xs: 12,
                      sm: 12,
                      md: 12,
                      lg: 4,
                      xl: 3,
                    }}
                  >
                    <motion.div
                      key={state.id}
                      initial={{ opacity: 0, scale: 0.75, x: -20 }}
                      animate={{ opacity: 1, scale: 1, x: 0 }}
                      exit={{ opacity: 0, scale: 0.75, x: 20 }}
                      style={{ height: "100%" }}
                    >
                      <StateCard
                        title={state.stateName}
                        referenceOee={referenceOee}
                        referencePerformance={referencePerformance}
                        referenceAvailability={referenceAvailability}
                        duration={state.duration}
                        oeeGain={formatValue(state.oeeGain)}
                        performanceGain={formatValue(state.performanceGain)}
                        availabilityGain={formatValue(state.availabilityGain)}
                        id={state.id}
                        onClick={onClick}
                        isEdited={
                          state.isClientRelevance !== state.isIdleRelevance
                        }
                        referenceTotalDuration={referenceTotalDuration}
                        isClientRelevance={state.isClientRelevance}
                        view={view}
                      />
                    </motion.div>
                  </Grid2>
                );
              })}
            </AnimatePresence>
          </Grid2>
        ) : (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>{translate("configurable_oee.no_state")}</Typography>
          </Box>
        )}
      </Box>
    </Card>
  );
};
