import { useEffect, useRef, useState } from "react";
import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { ItemCard } from "@/components/ItemCard";
import { Card } from "@/components/Layout/Card";
import { NavButton } from "@/components/NavButton";
import { useTranslate } from "@/i18n/config";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import {
  Button,
  createTheme,
  Grid2,
  Stack,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useGetHistoricConfigurableData } from "../api/HistoricData/useGetHistoricConfigurableData";
import { useGetHistoricTimeLapses } from "../api/HistoricData/useGetHistoricTimeLapses";
import { ConfigurableLoaderLayout } from "../components/Layout/LoaderLayout";
import { convertKpiToArray, customBarChartColors } from "../utils/utilities";
import { useMachineContext } from "@/context/machine-context";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { translateItemTitle } from "@/utils/itemCardTranslate";
import { TopSection } from "../components/TopSection";
import { ConfigManager } from "../components/ConfigSection/ConfigManager";
import { CustomSelectTimeSelection } from "@/components/SelectTimeSelection/CustomSelectTimeSelection";

export const ConfigurableOeeViewHistoric = () => {
  const [selectedPeriod, setSelectedPeriod] = useState<{
    dateStart: number;
    id: number;
    timeLapse: string;
  } | null>(null);
  const translate = useTranslate();
  const { formatTimestamp } = useFormatTimestamp();
  const { data: historicTimelapses } = useGetHistoricTimeLapses();

  useEffect(() => {
    setSelectedPeriod(null);
  }, [historicTimelapses]);

  return (
    <>
      <MachineViewNavBar>
        <CustomSelectTimeSelection
          exclude={[
            // "Recipe",
            // "Batch",
            "TwoWeeks",
            "Month",
            "ThreeMonths",
            "SixMonths",
            "Year",
            "Custom",
          ]}
          forceCalendar
        />
        {selectedPeriod ? (
          <Button onClick={() => setSelectedPeriod(null)}>
            {translate("go_back_to_select")}
          </Button>
        ) : null}
        <NavButton to="/configurable-oee" text={translate("view_live")} />
      </MachineViewNavBar>
      {selectedPeriod ? (
        <HistoricDataContent id={selectedPeriod.id} />
      ) : (
        <Stack spacing={2}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
            }}
          >
            {translate("please_select_a_period")}
          </Typography>
          <AnimatePresence presenceAffectsLayout mode="wait">
            <motion.div
              key={JSON.stringify(historicTimelapses?.availableTS)}
              variants={{
                hidden: { opacity: 0 },
                show: {
                  opacity: 1,
                },
              }}
              initial="hidden"
              animate="show"
            >
              <Grid2 container spacing={2}>
                {historicTimelapses?.availableTS.map((item) => (
                  <Grid2
                    key={item.id}
                    size={{
                      xs: 6,
                      md: 3,
                    }}
                  >
                    <ItemCard
                      item={item}
                      onClick={(i) =>
                        setSelectedPeriod({
                          dateStart: i.dateStart,
                          timeLapse: i.timelapse,
                          id: i.id,
                        })
                      }
                      subtitle={`${translate(
                        "durations.hour_other",
                      )} ${formatTimestamp({
                        timestamp: item.dateStart,
                        fmt: "HH:mm",
                      })}`}
                      title={translateItemTitle(item.timelapse, translate)}
                      optionalLabel={formatTimestamp({
                        timestamp: item.dateStart,
                        fmt: "DDD ",
                      })}
                    />
                  </Grid2>
                ))}
              </Grid2>
            </motion.div>
          </AnimatePresence>
        </Stack>
      )}
    </>
  );
};

const HistoricDataContent = ({ id }: { id: number }) => {
  const globalTheme = useTheme();
  const { data, isLoading } = useGetHistoricConfigurableData({ id });
  const { machine } = useMachineContext();
  const [isChartVisible, setIsChartVisible] = useState(false);
  const graphRef = useRef<HTMLDivElement>(null);
  const machineName = machine?.machine;

  /**
   * @description - breakpoint customization : ho applicato questa customizzazione
   * per adattare il layout dallo schermo del pc in poi
   */
  const customTheme = createTheme({
    ...globalTheme,
    breakpoints: {
      values: {
        xs: 0,
        sm: 1000, // Personalizzazione per small breakpoint
        md: 1050, // Personalizzazione per medium breakpoint
        lg: 1400,
        xl: 1800,
      },
    },
  });

  useEffect(() => {
    if (isChartVisible && graphRef.current) {
      graphRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [isChartVisible]);

  const translate = useTranslate();
  return (
    <ThemeProvider theme={customTheme}>
      {isLoading ? <ConfigurableLoaderLayout /> : null}
      {data ? (
        <Stack
          sx={{
            gap: 2,
          }}
        >
          <TopSection
            standardAvailability={data.standardAvailability}
            standardOee={data.standardOee}
            customAvailability={data.customAvailability}
            customOee={data.customOee}
            standardPerformance={data.standardPerformance}
            customPerformance={data.customPerformance}
            comparisonKpi={data.comparisonKpi}
            onVisibilityHandle={() =>
              setIsChartVisible((prevValue) => !prevValue)
            }
            isChartVisible={isChartVisible}
          />
          <ConfigManager
            states={data.statesInfo}
            referenceOee={data.customOee}
            referenceAvailability={data.customAvailability}
            referencePerformance={data.customPerformance}
            referenceTotalDuration={data.totalDuration}
            view="historic"
          />
          {isChartVisible && (
            <Card ref={graphRef}>
              <BarChart.Custom
                title=""
                uom="%"
                tooltip={{
                  enabled: true,
                }}
                filename={fileNameFactory({
                  name: "configurable_oee",
                  machine: machineName,
                })}
                legend={{ enabled: false }}
                categories={[
                  translate("kpi.oee"),
                  translate("kpi.availability"),
                  translate("kpi.performance"),
                  translate("machine.idle_time"),
                  translate("machine.downtime"),
                ]}
                series={[
                  {
                    name: translate("configurable_oee.standard"),
                    type: "column",
                    data: convertKpiToArray(data.comparisonKpi.standardKpi).map(
                      (value, index) => {
                        return {
                          name: value[0],
                          y:
                            typeof value[1] === "string"
                              ? parseFloat(value[1])
                              : value[1],
                          color: customBarChartColors[index],
                        };
                      },
                    ),
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    borderRadiusTopRight: 4,
                    borderRadiusTopLeft: 4,
                  },
                  {
                    name: translate("configurable_oee.custom"),
                    type: "column",
                    data: convertKpiToArray(data.comparisonKpi.customKpi).map(
                      (value, index) => {
                        return {
                          name: value[0],
                          y:
                            typeof value[1] === "string"
                              ? parseFloat(value[1])
                              : value[1],
                          color: `${customBarChartColors[index]}50`,
                        };
                      },
                    ),
                    pointPadding: 0.1,
                    pointPlacement: -0.1,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    borderRadiusTopRight: 4,
                    borderRadiusTopLeft: 4,
                  },
                ]}
              />
            </Card>
          )}
        </Stack>
      ) : null}
    </ThemeProvider>
  );
};
