import { Box, Tooltip, Typography, Zoom } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslate } from "@/i18n/config";
import { PhaseCardLayout } from "@/features/Diagnostic/pages/LYO/components/PhaseCard/PhaseCardLayout";
import { mapStatus } from "../../utils/mapStatus";
import { Evaluation, Evaluations } from "../../types";
import { SummaryPieChart } from "../Summary/SummaryPieChart";

interface MotorCardProps {
  name: string;
  id: number;
  description: string;
  pieData: Evaluations;
  active: boolean;
  setMotorId: React.Dispatch<React.SetStateAction<number | null>>;
  setMotorName: React.Dispatch<React.SetStateAction<string | null>>;
  lastStatus: Evaluation;
  healthStatus: Evaluation;
  totalFrames: number;
}

export const MotorCard = ({
  name,
  id,
  description,
  pieData,
  active,
  setMotorId,
  setMotorName,
  totalFrames,
  healthStatus,
  lastStatus,
}: MotorCardProps) => {
  const translate = useTranslate();
  const goodObj = pieData["good"];
  const goodOverall = goodObj?.globalCount || 0;
  const goodFrames = goodObj?.frameCount || 0;
  const goodPreFrames = goodObj?.preFrameCount || 0;

  const handleClick = () => {
    if (active) {
      setMotorId(null);
      setMotorName(null);
    } else {
      setMotorId(id);
      setMotorName(name);
    }
  };

  return (
    <PhaseCardLayout
      sx={{
        padding: 1,
        minWidth: 160,
        minHeight: 260,
        gap: 0.5,
        aspectRatio: "8/13",
        textAlign: "center",
        backgroundColor: active ? "#444959" : "#1F293F",
        transition: "all .2s ease-in",
      }}
      aria-haspopup="true"
      role="button"
      onClick={handleClick}
      initial={{ opacity: 0, scale: 0.75, x: 20 }}
      animate={{ opacity: 1, scale: 1, x: 0 }}
      exit={{ opacity: 0 }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Typography variant="caption" sx={{ color: "#ccc" }}>
          {translate("health_status")}
        </Typography>
        <Tooltip
          title={healthStatus}
          placement="top"
          slots={{
            transition: Zoom,
          }}
        >
          <Box
            sx={{
              width: "20%",
              height: "5px",
              backgroundColor: mapStatus(healthStatus),
              borderRadius: "5px",
            }}
          ></Box>
        </Tooltip>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "1px",
          backgroundColor: "#fff",
          marginBottom: "1px",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {name.length > 16 ? (
          <Tooltip
            title={name}
            slots={{
              transition: Zoom,
            }}
          >
            <Typography
              noWrap
              sx={{
                fontWeight: "bold",
                color: "#ddd",
                fontSize: "0.9rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {`${name.slice(0, 12)}...`}
            </Typography>
          </Tooltip>
        ) : (
          <Typography
            noWrap
            sx={{
              fontWeight: "bold",
              color: "#ddd",
              fontSize: "0.9rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {name}
          </Typography>
        )}
        <Tooltip
          title={description}
          slots={{
            transition: Zoom,
          }}
        >
          <InfoOutlinedIcon sx={{ color: "#ddd" }} />
        </Tooltip>
      </Box>
      <SummaryPieChart
        title={translate("status")}
        goodFrames={goodOverall}
        totalFrames={totalFrames}
        pieData={pieData}
      />
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}
      >
        <Typography variant="button" sx={{ color: "#ddd" }}>
          {translate("frame")}: {goodFrames}/{totalFrames}
        </Typography>
        <Typography variant="button" sx={{ color: "#ddd" }}>
          {translate("pre_frame")}: {goodPreFrames}/{totalFrames}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Typography variant="caption" sx={{ color: "#ccc" }}>
          {translate("last_status")}
        </Typography>
        <Tooltip
          title={lastStatus}
          slots={{
            transition: Zoom,
          }}
        >
          <Box
            sx={{
              width: "20%",
              height: "5px",
              backgroundColor: mapStatus(lastStatus),
              borderRadius: "5px",
            }}
          ></Box>
        </Tooltip>
      </Box>
    </PhaseCardLayout>
  );
};
