import { Box, IconButton, Typography } from "@mui/material";
import { LockOutlined, LockOpenOutlined } from "@mui/icons-material";
import { DayGauge } from "./DayGauge";
import { EventInput } from "@fullcalendar/core";

interface HeaderWithGaugeProps {
  dateInfo: { date: Date };
  shifts: EventInput[];
  handleLockToggle: (date: string) => void;
  isEditMode: boolean;
  lockedDays: Set<string>;
  dateFormat: string;
  formatTimestamp: (args: {
    timestamp: number;
    skipTimezoneTransform?: boolean;
    fmt: string;
  }) => string;
  currentView: "timeGridWeek" | "dayGridMonth";
}

export const HeaderWithGauge: React.FC<HeaderWithGaugeProps> = ({
  dateInfo,
  shifts,
  handleLockToggle,
  isEditMode,
  lockedDays,
  dateFormat,
  formatTimestamp,
  currentView,
}) => {
  const calculateDayPercentage = (date: Date): number => {
    // Convert input date to UTC day boundaries
    const startOfDayUTC = new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        0,
        0,
        0,
        0,
      ),
    );
    const endOfDayUTC = new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        23,
        59,
        59,
        999,
      ),
    );

    // Get all shifts that could overlap with this day
    const relevantShifts = shifts.filter((shift) => {
      const shiftStartUTC = new Date(shift.start as string);
      const shiftEndUTC = new Date(shift.end as string);

      // Convert shift times to UTC
      return shiftStartUTC <= endOfDayUTC && shiftEndUTC >= startOfDayUTC;
    });

    // Calculate covered minutes for each hour of the day in UTC
    const hoursCovered = new Array(24).fill(0);

    relevantShifts.forEach((shift) => {
      const shiftStartUTC = new Date(shift.start as string);
      const shiftEndUTC = new Date(shift.end as string);

      // Clamp times to the current UTC day
      const effectiveStartUTC = new Date(
        Math.max(shiftStartUTC.getTime(), startOfDayUTC.getTime()),
      );
      const effectiveEndUTC = new Date(
        Math.min(shiftEndUTC.getTime(), endOfDayUTC.getTime()),
      );

      // Calculate which UTC hours this shift covers
      let currentHour = effectiveStartUTC.getUTCHours();
      const endHour = effectiveEndUTC.getUTCHours();

      // Handle case where shift ends at midnight (00:00) of next day
      const adjustedEndHour =
        endHour === 0 &&
        effectiveEndUTC.getUTCDate() > effectiveStartUTC.getUTCDate()
          ? 24
          : endHour;

      while (currentHour <= adjustedEndHour) {
        if (currentHour < 24) {
          // Ensure we don't access beyond array bounds
          hoursCovered[currentHour] = 1;
        }
        currentHour++;
      }
    });

    // Calculate total covered hours
    const totalCoveredHours = hoursCovered.reduce((sum, hour) => sum + hour, 0);

    // Calculate percentage based on 24 hours
    return (totalCoveredHours / 24) * 100;
  };

  const date =
    dateInfo.date instanceof Date ? dateInfo.date : new Date(dateInfo.date);
  const dateKey = formatTimestamp({
    timestamp: +date,
    skipTimezoneTransform: true,
    fmt: dateFormat === "en-GB" ? "EEE dd LLL yyyy" : "EEE, MMM dd, yyyy",
  });

  const normalizedDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1,
  ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
  const isLocked = lockedDays.has(normalizedDate);
  const coveragePercentage = calculateDayPercentage(date);

  if (currentView === "dayGridMonth") {
    const weekday = formatTimestamp({
      timestamp: +date,
      fmt: "EEEE",
      skipTimezoneTransform: true,
    });

    return (
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            justifyContent: "center",
          }}
        >
          <Typography>{weekday}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <DayGauge percentage={coveragePercentage} />
          <Typography>{dateKey}</Typography>
        </Box>
        <IconButton
          onClick={() => handleLockToggle(normalizedDate)}
          color={isLocked ? "warning" : "primary"}
          disabled={!isEditMode}
          size="small"
        >
          {isLocked ? <LockOutlined /> : <LockOpenOutlined />}
        </IconButton>
      </Box>
    </Box>
  );
};
