import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Tab, Tabs } from "@mui/material";
import { ArrowBackOutlined } from "@mui/icons-material";
import { useTranslate } from "@/i18n/config";
import { AppShell } from "@/components/Layout/AppShell";
import { OverviewNavBar } from "@/pages/Overview/layout/OverviewNavBar/OverviewNavBar";
import { SidebarOverview } from "@/pages/Overview/Overview";
import { useIsAllowed } from "@/hooks/useIsAllowed";
import { UserRole } from "@/context/firebase-context";
import { useCheckIfMessageBanner } from "@/hooks/useCheckIfInformationBanner";
import { InformationBanner } from "@/components/Layout/InformationBanner";
import { ReleaseMessageModal } from "@/components/Layout/ReleaseMessageModal";
import { Settings } from "./pages/Settings";
import { UsersManagement } from "./pages/UsersManagement";

const Sections = {
  settings: 0,
  usersManagement: 1,
};

const renderSection = ({ section }: { section: number }) => {
  switch (section) {
    case Sections.settings:
      return <Settings />;
    case Sections.usersManagement:
      return <UsersManagement />;
    default:
      throw new Error("Unknown section");
  }
};

export const UserSettings = () => {
  const { showBanner } = useCheckIfMessageBanner();
  const navigate = useNavigate();
  const [section, setSection] = useState(Sections.settings);

  const translate = useTranslate();
  const allowAdminManagement = useIsAllowed([
    UserRole.SUPER_USER_SENTINEL,
    UserRole.SUPER_USER,
  ]);

  return (
    <>
      {showBanner && <InformationBanner />}
      <ReleaseMessageModal />
      <SidebarOverview />
      <AppShell
        // @ts-ignore
        component="main"
        sx={{
          flexGrow: 1,
          px: 4,
          py: 2,
          mt: showBanner ? "32px" : 0,
          float: "right",
          zIndex: -1,
          position: "relative",
        }}
      >
        <OverviewNavBar hasTimezone>
          <Button
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackOutlined />}
          >
            {translate("go_back")}
          </Button>
        </OverviewNavBar>

        <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }}>
          <Tabs
            value={section}
            aria-label="settings tabs"
            onChange={(_: React.SyntheticEvent, newValue: number) => {
              setSection(newValue);
            }}
          >
            <Tab value={Sections.settings} label={translate("settings")} />
            {allowAdminManagement ? (
              <Tab
                value={Sections.usersManagement}
                label={translate("users_management")}
              />
            ) : null}
          </Tabs>
        </Box>
        {renderSection({ section })}
      </AppShell>
    </>
  );
};
