import { z } from "zod";
import { toast } from "react-hot-toast";
import { DateTime } from "luxon";
import { useForm, Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  TextField,
  Button,
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { useTranslate } from "@/i18n/config";
import { getMachinesByLayer } from "../../utils/getMachinesByLayer";
import { Line } from "../../api/useGetUserProductionLines";
import { useSetManualConfig } from "../../Pages/LineOverview/api/useSetManualConfig";

const TimebasedConfigSchema = z.object({
  line_output_ids: z.array(z.string()),
  production_target: z.number().min(0),
  scheduled_arrival_time: z.date().transform((v) => DateTime.fromJSDate(v)),
});

type TimebasedConfigSchema = z.infer<typeof TimebasedConfigSchema>;

export const TimebasedConfigForm = ({
  line,
  onSubmit,
}: {
  line: Line;
  onSubmit: () => void;
}) => {
  const translate = useTranslate();
  const { mutate: setManualConfig } = useSetManualConfig();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<TimebasedConfigSchema>({
    defaultValues: {
      line_output_ids: [],
    },
  });
  const lineOutputIds = watch("line_output_ids");
  const machinesByLayer = getMachinesByLayer(line.line_machines);

  return (
    <form
      onSubmit={handleSubmit((data) => {
        setManualConfig(
          {
            config_type: "time_based",
            line_id: line.line_id,
            line_outputs_ids: data.line_output_ids,
            production_target: data.production_target,
            scheduled_arrival_time: data.scheduled_arrival_time,
          },
          {
            onSuccess: () => {
              toast.success(
                translate("configurable_oee.configured_successfully"),
              );
              onSubmit();
            },
            onError: () =>
              toast.error(translate("user_feedback.an_error_occurred")),
          },
        );
      })}
    >
      <Stack
        sx={{
          gap: 4,
          p: 2,
        }}
      >
        <FormControl fullWidth>
          <InputLabel size="small" id="demo-simple-select-label">
            {translate("overall_line_efficiency.machine_line_output")}
          </InputLabel>
          <Controller
            name="line_output_ids"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required",
              },
            }}
            defaultValue={lineOutputIds}
            render={({ field: { onChange, value, ...rest } }) => (
              <Select
                {...rest}
                multiple
                value={value || []} // Ensure value is an array for multiple selections
                onChange={(event) => {
                  const selectedValues = [...event.target.value].filter(
                    Boolean,
                  ); // Filtra per evitare undefined
                  // console.log("Selected values (from event):", selectedValues);

                  // Gestisci manualmente l'aggiornamento dello stato
                  onChange(selectedValues);
                }}
                size="small"
                label="Machine line output"
                error={!!errors.line_output_ids}
                renderValue={(selected) => selected.join(", ")} // Show selected items
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300, // Imposta l'altezza massima del menu
                    },
                  },
                  disableScrollLock: true, // Questo evita che la scrollbar del body venga bloccata
                }}
              >
                {Object.keys(machinesByLayer).map((layer, i) => {
                  return (
                    <Box
                      key={`box-${layer}`}
                      // per ovviare strano bug che non permette selezione su primo elemento
                      sx={{ display: i === 0 ? "none" : undefined }}
                    >
                      <Typography
                        key={layer}
                        sx={{
                          p: 1,
                        }}
                      >
                        {layer}
                      </Typography>
                      <List component="div" disablePadding>
                        {machinesByLayer[layer].map((machine) => (
                          <ListItem key={layer + machine} disablePadding>
                            <ListItemButton
                              key={machine}
                              onClick={() => {
                                const selectedMachine = machine.trim(); // Trim per sicurezza
                                // console.log(
                                //   "Machine selezionata:",
                                //   selectedMachine,
                                // );
                                // console.log("Valori attuali:", value);

                                if (value.length === 0) {
                                  onChange([...value, selectedMachine]);
                                  // console.log(
                                  //   "Aggiungo macchina:",
                                  //   selectedMachine,
                                  // );
                                } else {
                                  if (value.includes(selectedMachine)) {
                                    onChange(
                                      value.filter(
                                        (machine) =>
                                          machine !== selectedMachine,
                                      ),
                                    );
                                    // console.log(
                                    //   "Rimuovo macchina:",
                                    //   selectedMachine,
                                    // );
                                  } else {
                                    const firstSelectedMachine =
                                      value[0]?.trim();
                                    const selectedLayerMachine =
                                      line.line_machines.find(
                                        (el) =>
                                          el.name.trim() ===
                                          firstSelectedMachine,
                                      );
                                    // console.log(
                                    //   "Macchina trovata:",
                                    //   selectedLayerMachine,
                                    // );

                                    if (selectedLayerMachine) {
                                      const selectedLayer = `Layer ${selectedLayerMachine.line_layer}`;
                                      if (selectedLayer === layer) {
                                        onChange([...value, selectedMachine]);
                                        // console.log(
                                        //   "Aggiungo macchina:",
                                        //   selectedMachine,
                                        // );
                                      } else {
                                        toast.error(
                                          "You can only select machines sharing the same line layer",
                                        );
                                        // console.log(
                                        //   "Non puoi selezionare macchine da diversi layer.",
                                        // );
                                      }
                                    }
                                  }
                                }
                              }}
                            >
                              <Checkbox
                                checked={value?.indexOf(machine.trim()) > -1}
                              />
                              <ListItemText primary={machine} />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  );
                })}
              </Select>
            )}
          />
          {errors.line_output_ids ? (
            <FormHelperText error>
              {errors.line_output_ids.message}
            </FormHelperText>
          ) : null}

          <FormHelperText error={!!errors.line_output_ids}>
            {translate("overall_line_efficiency.line_efficiency_time_series")}
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <Controller
            name="production_target"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required",
              },
              min: {
                value: 0,
                message: "Can't be less than 0",
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <NumericFormat
                  customInput={TextField}
                  size="small"
                  value={value}
                  name="production_target"
                  label={translate("line.production_target")}
                  helperText={
                    errors.production_target && errors.production_target.message
                  }
                  thousandSeparator
                  error={!!errors.production_target}
                  onValueChange={(v) => onChange(Number(v.value))}
                />
              );
            }}
          />
          <FormHelperText>
            {translate("overall_line_efficiency.production_target_description")}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <Controller
            name="scheduled_arrival_time"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required",
              },
              validate: {
                isFuture: (v) => v > DateTime.now() || "Can't be in the past",
              },
            }}
            render={({
              field: { ref, onBlur, onChange: controllerOnChange, ...field },
              fieldState: { error },
            }) => {
              return (
                <DateTimePicker
                  {...field}
                  onChange={(date) => date && controllerOnChange(date)}
                  value={field.value || null}
                  label={translate("line.scheduled_arrival_time")}
                  minDate={DateTime.now()}
                  inputRef={ref}
                  slotProps={{
                    textField: {
                      onBlur,
                      error: !!error,
                      helperText: error?.message,
                      size: "small",
                    },
                  }}
                />
              );
            }}
          />
        </FormControl>

        <Stack
          direction="row"
          sx={{
            gap: 4,
            justifyContent: "end",
          }}
        >
          <Button
            sx={{ alignSelf: "end" }}
            variant="outlined"
            onClick={onSubmit}
          >
            {translate("actions.cancel")}
          </Button>
          <Button sx={{ alignSelf: "end" }} variant="contained" type="submit">
            {translate("actions.save")}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
