import { TranslationKey, useTranslate } from "@/i18n/config";
import { ONE_SECOND } from "@/utils/durationsInMilliseconds";
import { Box, keyframes, Typography } from "@mui/material";
import { useMemo } from "react";
import { Typewriter } from "react-simple-typewriter";

const rotateAlternate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  6.5% {
    transform: rotate(-360deg);
  }
  50% {
    transform: rotate(-360deg);
  }
  50.001% {
    transform: rotate(0deg);
  }
  56.5% {
    transform: rotate(360deg);
  }
  99.999% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

export const ShortTermSuggestionLoader = () => {
  const translate = useTranslate();

  const loadingKeys: TranslationKey[] = [
    "intellecta.loading_message_1",
    "intellecta.loading_message_2",
    "intellecta.loading_message_3",
    "intellecta.loading_message_4",
    "intellecta.loading_message_5",
    "intellecta.loading_message_6",
  ];

  const randomMessages = useMemo(() => {
    const shuffled = [...loadingKeys].sort(() => Math.random() - 0.5);
    return shuffled.slice(0, 3).map((key) => translate(key));
  }, [translate]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "auto",
        p: 1,
      }}
    >
      {/* Riservo spazio per il timestamp (invisibile) */}
      <Typography
        variant="caption"
        sx={{
          display: "inline-flex",
          borderRadius: 2,
          px: ".5rem",
          py: "0.25rem",
          gap: 1,
          alignItems: "center",
          visibility: "hidden",
        }}
      >
        ...
      </Typography>

      {/* Contenitore icona + testo, in riga, allineati in alto */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.5rem",
          alignItems: "flex-start",
          mt: 1,
        }}
      >
        {/* Logo ruotante */}
        <Box
          component="img"
          src="/assets/img/intellecta-logo.png"
          alt="Intellecta logo"
          sx={{
            width: "1.5rem",
            height: "1.5rem",
            borderRadius: "50%",
            transformOrigin: "center center",
            animation: `${rotateAlternate} 4s infinite linear`,
          }}
        />

        {/* Testo in grigio con effetto macchina da scrivere */}
        <Box sx={{ color: "#ccc", fontSize: "14px" }}>
          <Typewriter
            words={randomMessages}
            loop
            cursor
            cursorStyle="|"
            typeSpeed={30}
            deleteSpeed={30}
            delaySpeed={ONE_SECOND * 5}
          />
        </Box>
      </Box>
    </Box>
  );
};
