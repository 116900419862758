import { Box, Grid2, Icon, Stack, Tooltip, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { DonutChart } from "@/components/highcharts/donutchart/DonutChart";
import { CircularGauge } from "@/components/highcharts/gauge/CircularGauge";
import { AnomaliesResult } from "../api/getAnomaliesData";
import { CardAnomaly } from "./card_anomaly";

export function AnomaliesOverview({ data }: { data: AnomaliesResult }) {
  const translate = useTranslate();

  return (
    <Grid2 container spacing={1}>
      <Grid2
        size={{
          xs: 12,
          lg: 4,
        }}
      >
        <Card sx={{ height: 300, position: "relative" }}>
          {data.totalAnomalies > 0 ? (
            <>
              <DonutChart
                key={data.totalAnomalies}
                title={translate("anomalies.data_acquisition_anomalies")}
                totalValue={data.totalAnomalies}
                titleOptions={{ align: "center" }}
                seriesOptions={{
                  type: "pie",
                  name: translate("count"),
                  data: [
                    {
                      name: translate("anomalies.solved_anomalies"),
                      y: data.resolvedAnomalies,
                      color: "#00b00c",
                    },
                    {
                      name: translate("anomalies.remaining_anomalies"),
                      y: data.totalAnomalies - data.resolvedAnomalies,
                      color: "#D62422",
                    },
                  ],
                }}
              />
              <Icon sx={{ position: "absolute", top: ".5rem", right: ".5rem" }}>
                <Tooltip title={translate("anomalies.anomaly_explanation")}>
                  <InfoOutlined />
                </Tooltip>
              </Icon>
            </>
          ) : (
            <Typography>
              {translate("user_feedback.no_data_to_display")}
            </Typography>
          )}
        </Card>
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          lg: 4,
        }}
      >
        <Stack
          sx={{
            height: 300,
            gap: 1,
          }}
        >
          <Box
            sx={{
              height: 100,
            }}
          >
            <CardAnomaly
              text={translate("anomalies.anomaly_mean_duration")}
              data={data.meanDuration}
              datatype="time"
            />
          </Box>
          <Box
            sx={{
              height: 100,
            }}
          >
            <CardAnomaly
              text={translate("anomalies.watchdog_anomaly_threshold")}
              data={data.currentThreshold}
              datatype="number"
              tooltipDescription={translate(
                "anomalies.watchdog_anomaly_threshold_description",
              )}
            />
          </Box>
          <Box
            sx={{
              height: 100,
            }}
          >
            <CardAnomaly
              text={translate("anomalies.solved_anomalies_percentage")}
              data={data.resolvedAnomaliesPercentage}
              datatype="perc"
            />
          </Box>
        </Stack>
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          lg: 4,
        }}
      >
        <Card sx={{ height: 300, position: "relative" }}>
          <CircularGauge
            max={100}
            color="#00b00c"
            title={translate("anomalies.data_integrity_percentage")}
            value={data.goodTimePercentage}
            label={`${
              Number.isInteger(data.goodTimePercentage)
                ? data.goodTimePercentage
                : data.goodTimePercentage.toFixed(2)
            }%`}
          />
          <Icon sx={{ position: "absolute", top: ".5rem", right: ".5rem" }}>
            <Tooltip
              title={translate(
                "anomalies.data_integrity_percentage_description",
              )}
            >
              <InfoOutlined />
            </Tooltip>
          </Icon>
        </Card>
      </Grid2>
    </Grid2>
  );
}
