import { useFirebaseContext } from "@/context/firebase-context";
import { useQueries } from "@tanstack/react-query";
import { getRuleListResult, getRules } from "./getRules";
import { getAlertListResult, getAlerts } from "./getAlerts";
import { getMachineListResult, getMachines } from "./getMachines";

export const useGetEventsData = () => {
  const { appKey, user, platformLvl } = useFirebaseContext();
  const account = user?.email as string;

  return useQueries({
    queries: [
      {
        queryKey: ["list-of-rules", account, platformLvl],
        queryFn: async () => {
          const response = await getRules({ appKey, account, platformLvl });

          if (!response.response) throw new Error(response.errorString);

          return getRuleListResult.parse(response).data;
        },
      },
      {
        queryKey: ["list-of-alerts", account, platformLvl],
        queryFn: async () => {
          const response = await getAlerts({ appKey, account, platformLvl });

          if (!response.response) throw new Error(response.errorString);

          return getAlertListResult.parse(response).data;
        },
        staleTime: Infinity,
      },
      {
        queryKey: ["machine-list"],
        queryFn: async () => {
          const response = await getMachines({ appKey, account });

          if (!response) return [];

          return getMachineListResult.parse(response).machineArray;
        },
        gcTime: 0,
        staleTime: Infinity,
      },
    ],
  });
};
