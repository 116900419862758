import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Frame } from "../../types";
import { useTranslate } from "@/i18n/config";
import { EVALUATION_COLORS } from "../../utils/evaluationColors";
import { Close, FiberManualRecord } from "@mui/icons-material";
import { useMotorsDispatchContext } from "../../context/useMotorsDispatchContext";

export const SelectedFrame = ({ frame }: { frame: Frame }) => {
  const translate = useTranslate();
  const dispatch = useMotorsDispatchContext();

  const handleRemoveFrame = () => {
    dispatch({ type: "REMOVE_FRAME", frame });
  };

  return (
    <Stack
      sx={{
        display: "flex",
        p: 1,
        backgroundColor: "#1F293F",
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>{frame.timelapse}</Typography>
        <IconButton size="small" onClick={handleRemoveFrame}>
          <Close />
        </IconButton>
      </Box>
      <Typography variant="caption">
        {translate("start")}: {new Date(frame.dateStart).toLocaleString()}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="caption">{translate("evaluation")}:</Typography>
        <FiberManualRecord
          sx={{ color: EVALUATION_COLORS[frame.status].selected }}
        />
      </Box>
    </Stack>
  );
};
