import { Card } from "@/components/Layout/Card";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { Stack, Box, Grid2 } from "@mui/material";
import { useGetCountDurationSelector } from "../api/useGetCountDurationSelector";
import { useTranslate } from "@/i18n/config";

export const ChangeOverSummaryCounters = () => {
  const { data, isLoading } = useGetCountDurationSelector();
  const translate = useTranslate();
  if (isLoading) return <AnalyticContainerSkeleton />;

  if (!data) return null;

  return (
    <Grid2
      container
      spacing={2}
      sx={{
        textAlign: "center",
      }}
    >
      <Grid2
        size={{
          xs: 12,
          sm: 6,
        }}
      >
        <Card sx={{ height: "fill-content", minHeight: "auto" }}>
          <Stack>
            <Box>{translate("change_over.recipe_change_duration")}</Box>
            <Box>
              {TimeHelpers.parseDurationToString({
                duration: data.Duration * 1000,
              })}
            </Box>
          </Stack>
        </Card>
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 6,
        }}
      >
        <Card sx={{ height: "fill-content", minHeight: "auto" }}>
          <Stack>
            <Box>
              {" "}
              {translate("change_over.recipe_change_amount", {
                amount: data.Count,
              })}
            </Box>
          </Stack>
        </Card>
      </Grid2>
    </Grid2>
  );
};
