import { Box, CircularProgress, Typography } from "@mui/material";

interface DayGaugeProps {
  percentage: number;
}

export const DayGauge: React.FC<DayGaugeProps> = ({ percentage }) => {
  const getColor = (): "error" | "warning" | "success" => {
    if (percentage < 20) return "error";
    if (percentage < 80) return "warning";
    return "success";
  };

  const adjustedPercentage = percentage === 0 ? 3 : percentage;

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        value={100}
        sx={{
          position: "absolute",
          color: "#ededed50",
        }}
        size={30}
        thickness={4}
      />
      <CircularProgress
        variant="determinate"
        value={Math.min(100, adjustedPercentage)}
        color={getColor()}
        size={30}
        thickness={4}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ fontSize: "0.5rem" }}
        >
          {`${Math.round(percentage)}%`}
        </Typography>
      </Box>
    </Box>
  );
};
