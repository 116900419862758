import { z } from "zod";

export const aiSuggest = z.object({
  mttr: z.number(),
  mttrBm: z.number(),
  mttrGap: z.number().optional(),
  category: z.string(),
  error: z.string(),
  nEvents: z.number(),
  nEventsBm: z.number(),
  nEventsGap: z.number().optional(),
  efficiencyLost: z.number(),
  efficiency: z.number().optional(),
  efficiencyBm: z.number().optional(),
});

export type AiSuggest = z.infer<typeof aiSuggest>;

export const loss = z.object({
  valueBm: z.number(),
  nEventsBm: z.number(),
  mttr: z.number(),
  lossName: z.string(),
  mttrBm: z.number(),
  value: z.number(),
  nEvents: z.number(),
});

export type Loss = z.infer<typeof loss>;

export const oeeMachineStatus = z.object({
  process: z.number(),
  extraCapacity: z.number(),
  line: z.number(),
  machine: z.number(),
  noData: z.number(),
  oee: z.number(),
});

export type OeeMachineStatus = z.infer<typeof oeeMachineStatus>;

export const lossesData = z.object({
  chartData: z.array(z.array(z.number())),
  lastValue: z.number(),
  benchmarkValue: z.number(),
});

export type LossesData = z.infer<typeof lossesData>;

export const lossType = z.union([
  z.literal("machine"),
  z.literal("line"),
  z.literal("process"),
]);

export type LossType = z.infer<typeof lossType>;
