import { useState } from "react";
import {
  Avatar,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  Divider,
  useTheme,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useQueryClient } from "@tanstack/react-query";
import { signOut } from "firebase/auth";
import { useTranslate } from "@/i18n/config";
import { useFirebaseContext } from "@/context/firebase-context";

const parseUserInitials = (userEmail: string): string => {
  const namesSection = userEmail.split("@")[0];
  const names = namesSection.split(".");
  let initials = "";
  names.forEach((name, i) => {
    if (i > 1) return;
    initials += name[0].toUpperCase();
  });
  return initials;
};

export const UserMenu = () => {
  const translate = useTranslate();
  const client = useQueryClient();
  const { palette } = useTheme();
  const { authService, user } = useFirebaseContext();
  const email = user?.email || "";
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    localStorage.clear();
    sessionStorage.clear();
    client.clear();
    signOut(authService);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          borderRadius: 3,
          overflow: "hidden",
          "& .MuiTouchRipple-ripple .MuiTouchRipple-child": {
            backgroundColor: palette.info.dark,
          },
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            bgcolor: palette.info.dark,
            borderRadius: 2,
            color: "#fff",
            fontSize: 14,
          }}
        >
          {parseUserInitials(email)}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "left", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
      >
        <MenuItem disabled>
          <Typography>{email}</Typography>
        </MenuItem>
        <Divider />
        {/* <MenuItem
          onClick={() => {
            console.log(
              "pensare ad un menu per i settings. cosa ci mettiamo? rimozione storico? poi?",
            );
            handleClose();
          }}
        >
          <ListItemIcon>
            <SettingsOutlined fontSize="small" />
          </ListItemIcon>
          {translate("settings")}
        </MenuItem> */}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {translate("logout")}
        </MenuItem>
      </Menu>
    </>
  );
};
