import { Stack } from "@mui/material";
import { ImputationStats } from "./ImputationStats";
import { ImputationLog } from "./ImputationLog";

export const PageContent = () => {
  return (
    <Stack
      sx={{
        gap: 2,
        marginTop: 2,
      }}
    >
      <ImputationStats />
      <ImputationLog />
    </Stack>
  );
};
