import { useState } from "react";
import { useTranslate } from "@/i18n/config";
import { Tab, Tabs } from "@mui/material";
import { TableParser } from "@/utils/TableParser";
import { BasicTable } from "@/components/BasicTable";
import { Card } from "@/components/Layout/Card";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { XRangeChart } from "@/components/highcharts/xrange/XRange";
import { useGetImputationLogData } from "@/features/StopJustification/api/useGetImputationLogData";
import { ImputationStatesModal } from "@/features/StopJustification/components/ImputationStatesModal";
import { ImputationLogData } from "@/features/StopJustification/types";
import { useDisclosure } from "@/hooks/useDisclosure";
import { StatesLog } from "./StatesLog";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { parseImputationsLogData } from "@/features/StopJustification/utils/parseImputationsLogData";

const SECTIONS = {
  CHART: 0,
  TABLE: 1,
};

export const ImputationLog = () => {
  const translate = useTranslate();
  const { formatTimestamp } = useFormatTimestamp();
  const [section, setSection] = useState(SECTIONS.CHART);
  const { data, isLoading, error } = useGetImputationLogData();
  const [dateStart, setDateStart] = useState<number | null>(null);
  const [dateEnd, setDateEnd] = useState<number | null>(null);
  const { open, close, isOpen } = useDisclosure();

  if (isLoading)
    return (
      <>
        <SkeletonCard height={400} />
        <SkeletonCard height={400} />
      </>
    );

  if (error) return <div>{translate("user_feedback.an_error_occurred")}</div>;

  if (!data) return <Card>{translate("user_feedback.no_data")}</Card>;

  if (data.data.length === 0)
    return <Card>{translate("user_feedback.no_data")}</Card>;

  const clonedData: ImputationLogData[] = data.data.map((entry) => {
    return { ...entry };
  });
  const { categories, chartData } = parseImputationsLogData(data.data);

  const { rows, columns } = TableParser.parseData<ImputationLogData>({
    data: clonedData,
    columnsMap: {
      id: "id",
      category: translate("stop_imputation.imputation"),
      subCategory: translate("stop_imputation.sub_category"),
      start: `${translate("date")} ${translate("start")}`,
      end: `${translate("date")} ${translate("end")}`,
      shift: translate("timelapse.shift"),
      whoImputed: translate("stop_imputation.imputed_by"),
      hasExpired: translate("stop_imputation.expired"),
      color: translate("color"),
    },
    columnsOrder: [
      "id",
      "start",
      "end",
      "category",
      "subCategory",
      "shift",
      "whoImputed",
      "hasExpired",
    ],
    dateColumns: ["start", "end"],
    omitColumns: ["color"],
    dateFormatter: formatTimestamp,
  });

  return (
    <>
      {isOpen && dateStart && dateEnd && (
        <ImputationStatesModal
          close={close}
          isOpen={isOpen}
          dateStart={dateStart}
          dateEnd={dateEnd}
        />
      )}
      <Tabs
        value={section}
        aria-label="stop imputation tabs"
        onChange={(_: React.SyntheticEvent, newValue: number) => {
          setSection(newValue);
        }}
      >
        <Tab value={SECTIONS.CHART} label={translate("chart")} />
        <Tab value={SECTIONS.TABLE} label={translate("table")} />
      </Tabs>
      {section === SECTIONS.CHART && (
        <Card>
          <XRangeChart
            categories={categories}
            data={chartData}
            title={translate("stop_imputation.imputations_log")}
            tooltipFormatter={function () {
              const point: Highcharts.XrangePointOptionsObject = this.point;
              const dateStart = formatTimestamp({
                timestamp: point.x!,
              });
              const dateEnd = formatTimestamp({
                timestamp: point.x2!,
              });
              const color = point.color;
              const category = categories[point.y!];
              const subCategory: string =
                point.custom?.subCategory || "Not defined";
              return `<span style="color:${color}">●</span> <b>${category}</b><br>
              ${translate(
                "stop_imputation.sub_category",
              )}: <b>${subCategory}</b><br>
              ${translate("start")}: <b>${dateStart}</b><br>
              ${translate("end")}: <b>${dateEnd}</b>`;
            }}
            labelsWidth={150}
            xAxisOptions={{
              min: data.dates.dateStart,
              max: data.dates.dateEnd,
            }}
            seriesOptions={{
              cursor: "pointer",
              events: {
                click: function (event) {
                  const { x, x2 } = event.point;
                  setDateStart(x);
                  setDateEnd(x2!);
                  open();
                },
              },
            }}
          />
          <StatesLog min={data.dates.dateStart} max={data.dates.dateEnd} />
        </Card>
      )}
      {section === SECTIONS.TABLE && (
        <Card>
          <BasicTable
            rows={rows}
            columns={columns}
            handleRowClick={(params) => {
              const row = params.row as ImputationLogData;
              const { start, end } = row;
              setDateStart(start);
              setDateEnd(end);
              open();
            }}
          />
        </Card>
      )}
    </>
  );
};
