import { Box } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridRowOrderChangeParams,
  GridRowParams,
  GridRowProps,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { enUS, itIT, esES, deDE } from "@mui/x-data-grid/locales";
import { useLanguage } from "@/i18n/config";
import { useMachineContext } from "@/context/machine-context";
import { colors } from "@/styles/colors";

export interface CustomTableProps {
  columns: GridColDef[];
  rows: GridRowProps[] | unknown[];
  loading?: boolean;
  sx?: React.ComponentProps<typeof Box>;
  fileName?: string;
  allowRowReordering?: boolean;
  handleRowOrderChange?: (params: GridRowOrderChangeParams) => void;
  handleRowClick?: (params: GridRowParams) => void;
  isMultiMachine?: boolean;
}

export const BasicTable = ({
  rows,
  columns,
  loading = false,
  sx,
  fileName = "table_data",
  allowRowReordering = false,
  handleRowOrderChange,
  handleRowClick,
  isMultiMachine,
}: CustomTableProps) => {
  const { machine } = useMachineContext();
  const language = useLanguage();

  return (
    <Box
      sx={[
        {
          height: 400,
          width: "100%",
        },
        ...(Array.isArray(sx) ? sx : [sx]).filter(Boolean),
      ]}
    >
      <DataGridPremium
        sx={{
          "--DataGrid-containerBackground": colors.palette.darkBlue,
        }}
        localeText={
          language === "en"
            ? enUS.components.MuiDataGrid.defaultProps.localeText
            : language === "it"
            ? itIT.components.MuiDataGrid.defaultProps.localeText
            : language === "es"
            ? esES.components.MuiDataGrid.defaultProps.localeText
            : language === "de"
            ? deDE.components.MuiDataGrid.defaultProps.localeText
            : enUS.components.MuiDataGrid.defaultProps.localeText
        }
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            csvOptions: {
              fileName: isMultiMachine
                ? fileName
                : `${machine?.machine}-${fileName}`,
            },
            excelOptions: {
              fileName: isMultiMachine
                ? fileName
                : `${machine?.machine}-${fileName}`,
            },
          },
        }}
        columns={columns.map((col) => ({
          ...col,
          headerAlign: "left",
          flex: 1,
          align: "left",
        }))}
        loading={loading}
        rows={rows}
        rowReordering={allowRowReordering ? true : false}
        onRowOrderChange={handleRowOrderChange}
        onRowClick={handleRowClick}
      />
    </Box>
  );
};
