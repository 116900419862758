import { Box, Divider, Grid2, Stack, Typography } from "@mui/material";
import { Loss, LossesData } from "../../types";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { formatNumber } from "@/utils/formatNumber";
import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { ShortTermSuggestionHistoric } from "./ShortTermSuggestionHistoric";

export const HistoricChartRow = ({
  title,
  barChartData,
  categoryValue,
  color,
  lineChartData,
}: {
  title: string;
  barChartData: Loss[];
  categoryValue: number;
  color: string;
  lineChartData: LossesData;
}) => {
  const translate = useTranslate();

  const dataSorted = barChartData.sort((a, b) =>
    a.value < b.value ? 1 : b.value < a.value ? -1 : 0,
  );

  const categories = dataSorted.map((element) => element.lossName);
  const realData = dataSorted.map((element) => ({
    name: element.lossName,
    y: +element.value.toFixed(2),
    custom: {
      benchmark: +element.valueBm.toFixed(2),
      mttr: TimeHelpers.parseDurationToString({
        duration: element.mttr * 60 * 1000,
      }),
      mttrBm: TimeHelpers.parseDurationToString({
        duration: element.mttrBm * 60 * 1000,
      }),
    },
  }));

  const targetData = dataSorted.map((element) => ({
    name: element.lossName,
    y: +element.valueBm.toFixed(2),
  }));

  const benchmarksSum = targetData
    .reduce((acc, curr) => acc + curr.y, 0)
    .toFixed(2);

  const { chartData, benchmarkValue, lastValue } = lineChartData;
  // per sparkline
  const highestValue = Math.max(...chartData.map((record) => record[1]));
  const difference = benchmarkValue - lastValue;
  const shortTermColor = difference >= 0 ? "green" : "red";

  return (
    <Grid2 container spacing={2}>
      <Grid2
        size={{
          lg: 6,
          xs: 12,
        }}
      >
        <Card
          sx={{
            height: 300,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: 1, alignItems: "baseline" }}>
              <Typography>{title}</Typography>
              <Typography sx={{ color: "#bbb", fontSize: ".6rem" }}>
                {translate("long_term")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  color: "#bbb",
                }}
              >
                {translate("overall")} |
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: "#bbb",
                }}
              >
                {translate("current").toLowerCase()}
              </Typography>
              <Typography>{categoryValue.toFixed(2)}%</Typography>
              <Typography
                variant="caption"
                sx={{
                  color: "#bbb",
                }}
              >
                {translate("benchmark").toLowerCase()}
              </Typography>
              <Typography>{benchmarksSum}%</Typography>
            </Box>
          </Stack>
          <Box sx={{ height: 240 }}>
            <BarChart.Custom
              categories={categories}
              filename={fileNameFactory({
                name: "efficiency_navigator",
              })}
              height={240}
              series={[
                {
                  name: translate("target"),
                  type: "column",
                  color: `${color}50`,
                  data: targetData,
                  // @ts-expect-error Highcharts non riconosce le proprietà personalizzate, ma funzionano a runtime.
                  borderRadiusTopRight: 4,
                  borderRadiusTopLeft: 4,
                },
                {
                  name: translate("real"),
                  type: "column",
                  data: realData,
                  color: color,
                  pointPadding: 0.1,
                  pointPlacement: -0.1,
                  // @ts-expect-error Highcharts non riconosce le proprietà personalizzate, ma funzionano a runtime.
                  borderRadiusTopRight: 4,
                  borderRadiusTopLeft: 4,
                },
              ]}
              uom="%"
              xAxisMax={realData.length > 5 ? 4 : undefined}
              scrollbarEnabled={realData.length > 5 ? true : false}
              tooltip={{
                enabled: true,
                options: {
                  useHTML: true,
                  formatter: function (this) {
                    const point = this.points?.[1].point as unknown as {
                      name: string;
                      custom: {
                        benchmark: number;
                        mttr: string;
                        mttrBm: string;
                      };
                    };
                    if (point) {
                      return `
                      <small>${point.name}</small><br />
                      ${translate("value")}: <b>${this.points?.[1].y}%</b><br />
                      ${translate("mttr")}: <b>${point.custom.mttr}</b><br />
                      ${translate("benchmark")}: <b>${
                        point.custom.benchmark
                      }</b><br />
                      ${translate("mttr")} ${translate("benchmark")}: <b>${
                        point.custom.mttrBm
                      }</b>
                      `;
                    }
                  },
                },
              }}
            />
          </Box>
        </Card>
      </Grid2>
      <Grid2
        size={{
          lg: 6,
          xs: 12,
        }}
      >
        <Card sx={{ height: 300, display: "flex", flexDirection: "column" }}>
          <Stack
            sx={{
              gap: 1,
              flex: 1,
            }}
          >
            <Box sx={{ display: "flex", gap: 1, alignItems: "baseline" }}>
              <Typography>{title}</Typography>
              <Typography sx={{ color: "#bbb", fontSize: ".6rem" }}>
                {translate("short_term")}
              </Typography>
            </Box>
            <Grid2 container>
              <Grid2 size={9}>
                <LineChart.Custom
                  title=""
                  height={100}
                  exportingEnabled={false}
                  xAxisOptions={{
                    lineColor: "transparent",
                    gridLineColor: "transparent",
                    tickColor: "transparent",
                    labels: { enabled: false },
                  }}
                  yAxis={[
                    {
                      uom: "%",
                      options: {
                        plotLines: [
                          {
                            value: benchmarkValue,
                            color: shortTermColor,
                            dashStyle: "LongDash",
                          },
                        ],
                        lineColor: "transparent",
                        gridLineColor: "transparent",
                        tickColor: "transparent",
                        max: highestValue > 90 ? 100 : null,
                      },
                      series: [
                        {
                          type: "line",
                          data: chartData,
                          color: shortTermColor,
                          name: title,
                        },
                      ],
                    },
                  ]}
                  tooltip={{ enabled: true, options: { valueSuffix: "%" } }}
                />
              </Grid2>
              <Grid2 size={3}>
                <Stack
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography>{lastValue.toFixed(2)}%</Typography>
                  <Box
                    sx={{
                      backgroundColor: shortTermColor,
                      borderRadius: 2,
                      paddingX: "1rem",
                      paddingY: "0.25rem",
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                    >
                      {`${difference > 0 ? "-" : "+"} ${formatNumber(
                        Math.abs(difference),
                      )}%`}
                    </Typography>
                  </Box>
                </Stack>
              </Grid2>
            </Grid2>
            <Divider />
            <ShortTermSuggestionHistoric />
          </Stack>
        </Card>
      </Grid2>
    </Grid2>
  );
};
