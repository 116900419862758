import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FetchClient } from "@/services/ApiClient";
import { useFirebaseContext } from "@/context/firebase-context";
import { ThingworxError } from "src/types/error";
import { toast } from "react-hot-toast";
import { ChartType } from "../types/variable";
import { useTranslate } from "@/i18n/config";

type Payload = {
  variables: number[];
  chart_type: ChartType;
  chart_name: string;
  account: string;
};

type Response =
  | ThingworxError
  | {
      response: true;
    };

export const useCreateChart = () => {
  const translate = useTranslate();
  const { appKey, user } = useFirebaseContext();
  const email = user?.email as string;

  const client = useQueryClient();

  return useMutation({
    mutationFn: async (payload: Omit<Payload, "account">) => {
      const response = await FetchClient<Payload, Response>({
        appKey,
        url: "dig.c.customChartsDev_thing/services/createChart",
        payload: {
          ...payload,
          account: email,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }
    },
    onSuccess: () => {
      toast.success(translate("custom_charts.chart_created_successfully"));
      client.invalidateQueries({ queryKey: ["user-chart", email] });
    },
  });
};
