import { DateTime } from "luxon";
import { create } from "zustand";
import * as Sentry from "@sentry/react";

export type TimeSelection =
  | "Shift"
  | "Day"
  | "Week"
  | "TwoWeeks"
  | "Recipe"
  | "Batch"
  | "Custom"
  | "Month"
  | "ThreeMonths"
  | "SixMonths"
  | "Year";

type TimeSelectionStore = {
  timeSelection: TimeSelection;
  setTimeSelection: (selection: TimeSelection) => void;
  setAvailableSelection: ({ filterBy }: { filterBy: TimeSelection[] }) => void;
  availableSelection: TimeSelection[];
  dates: {
    dateStart: DateTime;
    dateEnd: DateTime;
  };
};

const defaultTimeSelection: TimeSelection[] = [
  "Shift",
  "Day",
  "Week",
  "TwoWeeks",
  "Recipe",
  "Batch",
  "Custom",
  "Month",
  "ThreeMonths",
  "SixMonths",
  "Year",
];

export const useTimeSelection = create<TimeSelectionStore>((set) => {
  // Recupero il valore salvato in localStorage o imposto quello di default
  const initialTimeSelection =
    (localStorage.getItem("timeSelection") as TimeSelection) || "Shift";

  // Imposta subito il tag per Sentry quando lo stato è inizializzato
  Sentry.setTag("time_selection", initialTimeSelection);

  return {
    timeSelection: initialTimeSelection,
    setTimeSelection(selection) {
      Sentry.setTag("time_selection", selection);
      localStorage.setItem("timeSelection", selection);
      set((state) => ({ ...state, timeSelection: selection }));
    },
    availableSelection: defaultTimeSelection,
    setAvailableSelection({ filterBy }: { filterBy: TimeSelection[] }) {
      set((state) => ({
        ...state,
        availableSelection: defaultTimeSelection.filter(
          (value) => !filterBy.includes(value),
        ),
      }));
    },
    dates: {
      dateStart: DateTime.now()
        .minus({ days: 1 })
        .set({ second: 0, millisecond: 0 }), // Arrotonda a 0 secondi e millisecondi
      dateEnd: DateTime.now().set({ second: 0, millisecond: 0 }), // Arrotonda a 0 secondi e millisecondi
    },
  };
});
