import { formatNumber } from "@/utils/formatNumber";
import {
  HighchartsChart,
  Chart,
  XAxis,
  YAxis,
  Tooltip,
  Title,
  BoxPlotSeries,
  ScatterSeries,
  Legend,
  LineSeries,
} from "react-jsx-highcharts";

import { HighchartsCustomProvider } from "../highcharts";
import { useTranslate } from "@/i18n/config";

interface BoxPlotProps {
  title: string;
  seriesName: string;
  categories: string[];
  boxplotData: number[][];
  scatterData: number[][];
  trendData?: number[][];
  yAxisTitle?: string;
}

export const BoxPlot = ({
  title,
  seriesName,
  categories,
  boxplotData,
  scatterData,
  trendData,
}: BoxPlotProps) => {
  const translate = useTranslate();
  return (
    <HighchartsCustomProvider>
      <HighchartsChart accessibility={{ enabled: false }} plotOptions={{}}>
        <Chart backgroundColor={"transparent"} type="boxplot" />
        <Title style={{ color: "white" }} align="left">
          {title}
        </Title>
        <Tooltip useHTML />
        <XAxis labels={{ padding: 8 }} categories={categories}></XAxis>
        <Legend
          itemStyle={{ color: "white" }}
          itemHoverStyle={{ color: "lightblue" }}
        ></Legend>
        <YAxis
          title={{
            text: `${translate("weight")} (g)`,
            style: { color: "white" },
          }}
          showLastLabel
        >
          <BoxPlotSeries
            name={seriesName}
            fillColor="transparent"
            lineWidth={2}
            medianColor="white"
            medianDashStyle="LongDash"
            tooltip={{
              headerFormat: "<em>{point.key}</em><br>",
              pointFormatter: function (this) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const point = this as unknown as any;
                return `
                <b><span style='color:${point.series.color}'>●</span> ${
                  point.series.name
                }</b><br>
                ${translate("math.maximum")}: ${formatNumber(point.high)}<br>
                ${translate("math.upper_quartile")}: ${formatNumber(
                  point.q3,
                )}<br>
                ${translate("math.median")}: ${formatNumber(point.median)}<br>
                ${translate("math.lower_quartile")}: ${formatNumber(
                  point.q1,
                )}<br>
                ${translate("math.minimum")}: ${formatNumber(point.low)}
                `;
              },
            }}
            data={boxplotData}
          />
          <ScatterSeries
            name="Outliers"
            marker={{ fillColor: "orange", enabled: true, symbol: "dot" }}
            tooltip={{
              headerFormat: "{point.key}<br>",
              pointFormat: `<b><span style="color:orange">●</span> {series.name}</b><br>${translate(
                "value",
              )}: {point.y}`,
            }}
            data={scatterData}
          />
          {trendData && (
            <LineSeries name="Trend" data={trendData} color="#ff00c3" />
          )}
        </YAxis>
      </HighchartsChart>
    </HighchartsCustomProvider>
  );
};
