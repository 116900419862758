import { useEffect } from "react";
import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import { socialStore } from "../../store/social-store";
import { IntellectaError, notification } from "../../types";
import { intellectaClient } from "../intellectaClient";

type Response = IntellectaError | Result;

type Payload = {
  user: string;
};

const result = z.object({
  share_history: z.array(notification),
});

type Result = z.infer<typeof result>;

export const useGetHistoricNotificationList = (isEnabled: boolean) => {
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  const queryResult = useQuery<Result, Error>({
    queryKey: ["intellecta-historic-notification-list", email],
    enabled: isEnabled,
    queryFn: async () => {
      const response = await intellectaClient<Payload, Response>({
        url: "/social/get_historic",
        payload: { user: email },
      });

      if (response === "ERROR") {
        throw new Error("An error occurred");
      }

      const validatedResponse = result.parse(response);
      return validatedResponse;
    },
  });

  // Update store Zustand con configurazione utente
  // Compliant con @tanstack/react-query v6
  const { data } = queryResult;

  useEffect(() => {
    if (data) {
      socialStore.setState((state) => ({
        ...state,
        notifications: data.share_history,
      }));
    }
  }, [data]);

  return queryResult;
};
