import { useFirebaseContext } from "@/context/firebase-context";
import { useQuery } from "@tanstack/react-query";
import { BarchartProps } from "../../../../../components/highcharts/barchart/Barchart";
import { LineData } from "../types";
import { FetchClient } from "@/services/ApiClient";
import { z } from "zod";
import { ThingworxError } from "src/types/error";

type AssetDataResponse = ThingworxError | AssetDataResult;

const AssetDataResult = z.discriminatedUnion("chartType", [
  z.object({
    chartType: z.literal("linechart"),
    data: z.array(LineData),
    response: z.literal(true),
    analyticName: z.string(),
  }),
  z.object({
    chartType: z.literal("barchart"),
    data: BarchartProps,
    response: z.literal(true),
    analyticName: z.string(),
    uom: z.string().optional(),
  }),
]);

type AssetDataResult = z.infer<typeof AssetDataResult>;

export const useGetAssetChart = ({ asset_id }: { asset_id: number }) => {
  const { appKey } = useFirebaseContext();

  return useQuery<AssetDataResult>({
    queryKey: ["asset-chart", asset_id],
    queryFn: async () => {
      const response = await FetchClient<
        { asset_id: number },
        AssetDataResponse
      >({
        appKey,
        url: "dig.c.lyophilizer_thing/Services/getAssetData",
        payload: {
          asset_id,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = AssetDataResult.parse(response);
      return validatedResponse;
    },
    refetchInterval: 1000 * 30,
    gcTime: 0,
  });
};
