import { RuleSchema } from "../api/useGetEventsData/getRules";

export const queryRules = ({
  rules,
  query,
}: {
  rules: RuleSchema[];
  query: string;
}): RuleSchema[] => {
  const queryLower = query.toLowerCase();
  const matchingRules = rules.filter(
    (rule) =>
      rule.account.toLowerCase().includes(queryLower) ||
      rule.company.toLowerCase().includes(queryLower) ||
      rule.machine.toLowerCase().includes(queryLower) ||
      rule.ruleDescription.toLowerCase().includes(queryLower) ||
      rule.ruleName.toLowerCase().includes(queryLower) ||
      rule.data[0].alertType.toLowerCase().includes(query) ||
      rule.data[0].values.value_1.toString().includes(query) ||
      rule.data[0].propertyNameClient.toLowerCase().includes(query),
  );
  return matchingRules;
};
