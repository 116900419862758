import { useState } from "react";
import { ImputationCategory } from "../../types";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useUpdateCategory } from "../../api/useUpdateCategory";

export const ImputationCategoryCell = ({
  categoryId,
  subCategoryId,
  categories,
  isEditable,
}: {
  categoryId: number;
  subCategoryId: number;
  categories: ImputationCategory[];
  isEditable: boolean;
}) => {
  const [selectedCategory, setSelectedCategory] = useState(categoryId);
  const { mutate: updateCategory } = useUpdateCategory();

  const handleChange = (event: SelectChangeEvent<number>) => {
    const newCategoryId =
      typeof event.target.value === "string"
        ? parseInt(event.target.value)
        : event.target.value;
    updateCategory(
      { categoryId: newCategoryId, subCategoryId },
      {
        onSuccess: (data) => {
          if (data.response) setSelectedCategory(newCategoryId);
        },
      },
    );
  };

  return (
    <Select
      value={selectedCategory}
      onChange={handleChange}
      disabled={!isEditable}
    >
      {categories.map((category) => (
        <MenuItem key={category.categoryId} value={category.categoryId}>
          {category.category}
        </MenuItem>
      ))}
    </Select>
  );
};
