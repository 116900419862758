import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { useDownloadDocument } from "../api/useDownloadDocument";
import { Download } from "@mui/icons-material";

export const DownloadableDocument = ({
  name,
  filesPath,
  lang,
}: {
  name: string;
  filesPath: string;
  lang: string;
}) => {
  const { downloadDocument, isLoading } = useDownloadDocument({
    filePath: filesPath,
    fileName: name,
  });

  return (
    <Box
      sx={{
        border: "solid 1px #ffffff60",
        display: "flex",
        justifyContent: isLoading ? "center" : "space-between",
        alignItems: "center",
        paddingX: 2,
        paddingY: 3,
        borderRadius: 3,
        width: "100%",
      }}
    >
      {isLoading ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        <>
          <Typography color="gray">{lang.toUpperCase()}</Typography>
          <Typography>{name}</Typography>
          <IconButton onClick={downloadDocument}>
            <Download />
          </IconButton>
        </>
      )}
    </Box>
  );
};
