import { useFirebaseContext } from "@/context/firebase-context";
import { useTranslate } from "@/i18n/config";
import { FetchClient } from "@/services/ApiClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type Payload = {
  config_id: number;
};

type Result = ThingworxError | SetResult;

const SetResult = z.discriminatedUnion("success", [
  z.object({
    success: z.literal(true),
    response: z.literal(true),
  }),
  z.object({
    success: z.literal(false),
    response: z.literal(true),
    reason: z.literal("expired"),
  }),
]);

type SetResult = z.infer<typeof SetResult>;

export const useSetRunningTrue = () => {
  const translate = useTranslate();
  const { appKey } = useFirebaseContext();

  const client = useQueryClient();

  return useMutation({
    mutationFn: async ({ config_id }: { config_id: number }) => {
      const response = await FetchClient<Payload, Result>({
        appKey,
        url: "dig.c.plantOverview_thing/Services/setRunningTrue",
        payload: {
          config_id,
        },
      });

      if (!response.response) throw new Error("No response");
      return response;
    },
    onSuccess(data) {
      if (!data.success) {
        if (data.reason === "expired") {
          toast.error(
            translate(
              "overall_line_efficiency.configuration_already_completed",
            ),
          );
        } else {
          toast.error(
            translate("overall_line_efficiency.failed_to_start_configuration"),
          );
        }

        return;
      }
      toast.success(translate("overall_line_efficiency.configuration_started"));

      client.invalidateQueries({ queryKey: ["line-configuration"] });
    },
  });
};
