import { Box, Tooltip, Typography, Zoom } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslate } from "@/i18n/config";
import { PhaseCardLayout } from "@/features/Diagnostic/pages/LYO/components/PhaseCard/PhaseCardLayout";
import { AnalyticBarChart } from "../Summary/AnalyticBarChart";
import { mapStatus } from "../../utils/mapStatus";
import { Evaluation } from "../../types";

interface AnalyticInfo {
  description: string;
  status: {
    [key: string]: number; //? good: 4, bad: 2
  };
  frame_status: {
    [key: string]: number; //? good: 4, bad: 2
  };
  pre_frame_status: {
    [key: string]: number; //? good: 4, bad: 2
  };
  totalFrames: number;
}

interface AnalyticCardProps {
  name: string;
  active: boolean;
  setAnalytic: React.Dispatch<React.SetStateAction<string | null>>;
  info: AnalyticInfo;
}

interface BarSeriesData {
  name: string;
  color: string;
  data: number[];
  frameData: number[];
  preFrameData: number[];
}

const processData = (
  data: AnalyticInfo,
): {
  goodFrames: number;
  goodPreFrames: number;
  barSeriesData: BarSeriesData[];
} => {
  const goodFrames = data.frame_status["good"] ? data.frame_status["good"] : 0;
  const goodPreFrames = data.pre_frame_status["good"]
    ? data.pre_frame_status["good"]
    : 0;
  const barSeriesData: BarSeriesData[] = [];

  for (const evaluation in data.status) {
    if (Object.prototype.hasOwnProperty.call(data.status, evaluation)) {
      const name = evaluation;
      const color = mapStatus(evaluation as Evaluation);
      const statusValue = data.status[evaluation];
      const frameValue = data.frame_status[evaluation];
      const preFrameValue = data.pre_frame_status[evaluation];

      const barData: BarSeriesData = {
        name,
        color,
        data: [statusValue],
        frameData: [frameValue],
        preFrameData: [preFrameValue],
      };

      barSeriesData.push(barData);
    }
  }

  return {
    goodFrames,
    goodPreFrames,
    barSeriesData,
  };
};

export const AnalyticCard = ({
  name,
  active,
  setAnalytic,
  info,
}: AnalyticCardProps) => {
  const translate = useTranslate();
  const handleClick = () => {
    if (active) return;
    if (!active) setAnalytic(name);
  };

  const { goodFrames, goodPreFrames, barSeriesData } = processData(info);

  return (
    <PhaseCardLayout
      sx={{
        padding: 1,
        minWidth: 160,
        minHeight: 160,
        gap: 0.5,
        aspectRatio: "1/1",
        textAlign: "center",
        backgroundColor: active ? "#444959" : "#1F293F",
        transition: "all .2s ease-in",
      }}
      aria-haspopup="true"
      role="button"
      onClick={handleClick}
      initial={{ opacity: 0, scale: 0.75, x: 20 }}
      animate={{ opacity: 1, scale: 1, x: 0 }}
      exit={{ opacity: 0 }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          height: "60px",
        }}
      >
        {name.length > 40 ? (
          <Tooltip
            title={name}
            slots={{
              transition: Zoom,
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#ddd",
                fontSize: "0.9rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {`${name.slice(0, 36)}...`}
            </Typography>
          </Tooltip>
        ) : (
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#ddd",
              fontSize: "0.9rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {name}
          </Typography>
        )}
        <Tooltip
          title={info.description}
          slots={{
            transition: Zoom,
          }}
        >
          <InfoOutlinedIcon sx={{ color: "#ddd" }} />
        </Tooltip>
      </Box>
      <Box sx={{ height: "12px", width: "100%" }}>
        <AnalyticBarChart data={barSeriesData} />
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}
      >
        <Typography variant="button" sx={{ color: "#ddd" }}>
          {translate("frame")}: {goodFrames}/{info.totalFrames}
        </Typography>
        <Typography variant="button" sx={{ color: "#ddd" }}>
          {translate("pre_frame")}: {goodPreFrames}/{info.totalFrames}
        </Typography>
      </Box>
    </PhaseCardLayout>
  );
};
