import { SwiperSlide } from "swiper/react";
import { Grid2, Box } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { AiSuggestItem } from "../AiSuggestItem";
import { Carousel } from "../Carousel";
import { LongTermDataResult } from "../../api/useGetLongTermData";

export const HistoricCarouselSection = ({
  data,
}: {
  data: Omit<LongTermDataResult, "response">;
}) => {
  const translate = useTranslate();

  return (
    <Grid2
      sx={{
        height: "30vh",
        display: "flex",
        flexDirection: "column",
        position: "relative", // Per posizionare correttamente i dots
      }}
      size={{
        lg: 8,
        xs: 12,
      }}
    >
      {data.aiSuggest.length > 0 ? (
        <Box sx={{ height: "100%" }}>
          <Carousel
            slidesPerView={1}
            spaceBetween={20}
            loop={data.aiSuggest.length > 1 ? true : false}
          >
            {data.aiSuggest
              .sort((a, b) =>
                a.efficiencyLost < b.efficiencyLost
                  ? 1
                  : b.efficiencyLost < a.efficiencyLost
                  ? -1
                  : 0,
              )
              .map((item, index) => (
                <SwiperSlide key={`efficiency-slide-${index}`}>
                  <AiSuggestItem data={item} key={index} />
                </SwiperSlide>
              ))}
          </Carousel>
        </Box>
      ) : (
        <Card
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {translate("user_feedback.no_data_to_display")}
        </Card>
      )}
    </Grid2>
  );
};
