import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { AnimatePresence, motion } from "framer-motion";
import { Button, Grid2, Stack, Typography } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { NavButton } from "@/components/NavButton";
import { Period } from "@/features/RejectCauses";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { ItemCard } from "@/components/ItemCard";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import {
  useGetTimeSelectionList,
  TimeSelectionsListData,
} from "@/features/RejectCauses/api/useGetTimeSelectionsList";
import { CustomSelectTimeSelection } from "@/components/SelectTimeSelection/CustomSelectTimeSelection";
import { translateItemTitle } from "@/utils/itemCardTranslate";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { PageContent } from "./components/PageContent";
import { useCustomTimeRange } from "../../store/useCustomTimeRange";

export const StopJustificationHistoricView = () => {
  const { formatTimestamp } = useFormatTimestamp();
  const [selectedPeriod, setSelectedPeriod] = useState<Period | null>(null);
  const {
    data: list,
    isLoading,
    error,
  } = useGetTimeSelectionList({ isEnabled: true });
  const translate = useTranslate();

  useEffect(() => {
    setSelectedPeriod(null);
  }, [list]);

  if (isLoading) {
    return (
      <>
        <MachineViewNavBar>
          <Stack
            direction={{ sm: "row", xs: "column" }}
            sx={{
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <CustomSelectTimeSelection
              exclude={[
                "Custom",
                "TwoWeeks",
                "Month",
                "ThreeMonths",
                "SixMonths",
                "Year",
              ]}
              forceCalendar
            />
            <NavButton to="/stop-justification" text={translate("view_live")} />
          </Stack>
        </MachineViewNavBar>
        <AnalyticContainerSkeleton />
      </>
    );
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!list) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  const updateDates = (timeSelectionInfo: TimeSelectionsListData) => {
    const start = DateTime.fromSeconds(timeSelectionInfo.dateStart / 1000);
    const end = DateTime.fromSeconds(timeSelectionInfo.dateEnd / 1000);
    useCustomTimeRange.setState(() => ({
      dateStart: start,
      dateEnd: end,
    }));
    setSelectedPeriod({ start, end });
  };

  return (
    <>
      <MachineViewNavBar>
      <Stack
            direction={{ sm: "row", xs: "column" }}
            sx={{
              gap: "1rem",
              alignItems: "center",
            }}
          >
              <CustomSelectTimeSelection
                exclude={[
              "TwoWeeks",
              "Month",
              "ThreeMonths",
              "SixMonths",
              "Year",
              "Custom",
            ]}
            forceCalendar
          />
          {selectedPeriod ? (
            <Button onClick={() => setSelectedPeriod(null)}>
              {translate("go_back_to_select")}
            </Button>
          ) : null}
          <NavButton to="/stop-justification" text={translate("view_live")} />
        </Stack>
      </MachineViewNavBar>
      {selectedPeriod ? (
        <PageContent />
      ) : (
        <Stack spacing={2}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
            }}
          >
            {translate("please_select_a_period")}
          </Typography>
          <AnimatePresence presenceAffectsLayout mode="wait">
            <motion.div
              key={JSON.stringify(list)}
              variants={{
                hidden: { opacity: 0 },
                show: {
                  opacity: 1,
                },
              }}
              initial="hidden"
              animate="show"
            >
              <Grid2 container spacing={2}>
                {list?.map((item) => (
                  <Grid2
                    key={item.id}
                    size={{
                      xs: 6,
                      md: 3,
                    }}
                  >
                    <ItemCard
                      item={item}
                      onClick={(i) => updateDates(i)}
                      // onClick={(i) => setSelectedPeriod(i.id.toString())}
                      subtitle={`${translate(
                        "durations.from",
                      )}: ${formatTimestamp({
                        timestamp: item.dateStart,
                        fmt: "DD HH:mm:ss ",
                      })}`}
                      secondarySubtitle={`${translate(
                        "durations.to",
                      )}: ${formatTimestamp({
                        timestamp: item.dateEnd,
                        fmt: "DD HH:mm:ss ",
                      })}`}
                      title={translateItemTitle(item.timelapse, translate)}
                    />
                  </Grid2>
                ))}
              </Grid2>
            </motion.div>
          </AnimatePresence>
          {(list?.length == 0 || !list) && (
            <Typography>
              {translate(
                "user_feedback.no_options_available_user_different_time_selection",
              )}
            </Typography>
          )}
        </Stack>
      )}
    </>
  );
};
