import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { QueryBuilder } from "@/utils/query-builder";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { useLanguage } from "@/i18n/config";

type AdjustmentsDataResponse = ThingworxError | AdjustmentsResult;

const Adjustment = z.object({
  PropertyName: z.string(),
  OldValue: z.union([z.number(), z.string()]),
  NewValue: z.union([z.number(), z.string()]),
  Timestamp: z.number(),
});

export type Adjustment = z.infer<typeof Adjustment>;

const AdjustmentsResult = z.object({
  response: z.literal(true),
  result: z.array(Adjustment),
});

export type AdjustmentsResult = z.infer<typeof AdjustmentsResult>;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  dateStart?: DateTime;
  dateEnd?: DateTime;
  language_id: string;
};

export const useGetAdjustmentsData = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const { timeSelection, dates } = useTimeSelection();
  const language = useLanguage();

  const query = QueryBuilder.buildWithCondition({
    baseQuery: ["machine-adjustments", timeSelection, machine!.machine, language],
    condition: timeSelection === "Custom",
    query: [dates.dateStart, dates.dateEnd],
  });

  const payload = {
    machineName: machine!.machine,
    timeSelection,
    language_id: language,
  };

  return useQuery<Adjustment[]>({
    queryKey: query,
    queryFn: async () => {
      const response = await FetchClient<Payload, AdjustmentsDataResponse>({
        appKey,
        url: "dig.c.adjustment_thing/Services/getDataNew",
        payload:
          timeSelection === "Custom"
            ? {
                ...payload,
                dateStart: dates?.dateStart,
                dateEnd: dates?.dateEnd,
              }
            : payload,
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return AdjustmentsResult.parse(response).result;
    },
    refetchInterval: timeSelection === "Custom" ? false : 1000 * 30,
  });
};
