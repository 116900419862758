import { useTranslate } from "@/i18n/config";
import { mapXrangeData } from "@/features/StateLog/utils/mapXRangeData";
import { CustomChart } from "@/components/highcharts/customchart/CustomChart";
import { Card } from "@/components/Layout/Card";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { useGetStatesAndRejects } from "../api/useGetStatesAndRejects";

export const RejectsStatesComparison = () => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetStatesAndRejects();

  if (isLoading) {
    return <AnalyticContainerSkeleton />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  const { categories, chartData } = mapXrangeData({
    data: data.stateLogData,
  });

  return (
    <Card>
      <CustomChart
        title={translate("reject_causes.rejects_and_states")}
        series1={{
          type: "xrange",
          yAxis: {
            categories: categories,
          },
          series: {
            type: "xrange",
            name: translate("state_other"),
            borderColor: "transparent",
            turboThreshold: 10000,
            data: chartData,
          },
        }}
        series2={{
          type: "line",
          yAxis: {
            gridLineColor: "transparent",
            opposite: true,
          },
          series: {
            type: "line",
            name: translate("reject_other"),
            zIndex: 10,
            color: "#F7B500",
            data: data.wasteCounterData,
            tooltip: {
              headerFormat:
                "<span style='color: #F7B500'>● </span>{series.name}<br>",
              pointFormatter: function (this) {
                const point = data.wasteCounterData.find(
                  (el) => el[0] === this.x,
                );
                return point
                  ? `<b>
                  ${new Date(point[0]).toLocaleString()}<br>
                  ${translate("reject_causes.rejects_count")}: ${
                    point[1]
                  }</b><br>
                `
                  : "";
              },
            },
          },
        }}
      />
    </Card>
  );
};
