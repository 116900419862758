import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useTranslate } from "@/i18n/config";
import { toast } from "react-hot-toast";

export type StateChangedInfo = {
  id: number;
  stateName: string;
  isClientRelevance: boolean;
};

interface Payload {
  machineName: string;
  stateConfigured: StateChangedInfo;
}
export const useSetConfiguration = () => {
  const translate = useTranslate();
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      stateConfigured,
    }: {
      stateConfigured: {
        id: number;
        isClientRelevance: boolean;
        stateName: string;
      };
    }) => {
      await FetchClient<Payload, null>({
        appKey,
        payload: { machineName: machine!.machine, stateConfigured },
        url: "dig.c.configurableOee_thing/services/setCustomEfficiencyConfiguration",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["configurable-oee", machine?.machine],
      });
    },
    onError: () => {
      toast.error(translate("user_feedback.an_error_occurred"));
    },
  });
};
