import { z } from "zod";
import { useMutation } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { ThingworxError } from "src/types/error";
import { Service, service, userServicesStore } from "@/context/services-store";

const permissionsResult = z.object({
  response: z.literal(true),
  services: z.array(service),
});

export type PermissionsResult = z.infer<typeof permissionsResult>;

interface PermissionsPayload {
  machineName: string;
  account: string;
}

type PermissionsResponse = ThingworxError | PermissionsResult;

export const useGetMachinePermissions = () => {
  const { appKey, user } = useFirebaseContext();

  const mutation = useMutation<Service[], Error, { machineName: string }>({
    mutationFn: async ({ machineName }: { machineName: string }) => {
      if (!user?.email) {
        throw new Error();
      }
      const payload: PermissionsPayload = {
        machineName,
        account: user.email,
      };
      const response = await FetchClient<
        PermissionsPayload,
        PermissionsResponse
      >({
        appKey,
        payload,
        url: "dig.c.serviceManagement_thing/Services/getMachineServicesByEmail",
      });
      if (!response.response) {
        throw new Error(response.errorString);
      }
      const data = permissionsResult.parse(response).services;
      return data;
    },
    onSuccess: (data) => {
      userServicesStore.getState().setServices(data);
    },
  });

  return mutation;
};
