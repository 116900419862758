import { useTranslate } from "@/i18n/config";
import { Stack } from "@mui/material";
import { LongTermDataResult } from "../../api/useGetLongTermData";
import { ShortTermData } from "../../api/useGetShortTermData";
import { efficiencyNavigatorColors } from "../../utils/efficiencyNavigatorColors";
import { HistoricChartRow } from "./HistoricChartRow";

export const HistoricChartSection = ({
  longTermData,
  shortTermData,
}: {
  longTermData: Omit<LongTermDataResult, "response">;
  shortTermData: ShortTermData;
}) => {
  const translate = useTranslate();
  return (
    <Stack
      sx={{
        gap: 2,
        width: "100%",
      }}
    >
      <HistoricChartRow
        title={translate("losses.machine_losses")}
        barChartData={longTermData.machineLosses}
        categoryValue={longTermData.oeeMachineStatus.machine}
        color={efficiencyNavigatorColors.category.machine}
        lineChartData={shortTermData.machineLosses}
      />
      <HistoricChartRow
        title={translate("losses.process_losses")}
        barChartData={longTermData.processLosses}
        categoryValue={longTermData.oeeMachineStatus.process}
        color={efficiencyNavigatorColors.category.process}
        lineChartData={shortTermData.processLosses}
      />
      <HistoricChartRow
        title={translate("losses.line_losses")}
        barChartData={longTermData.lineLosses}
        categoryValue={longTermData.oeeMachineStatus.line}
        color={efficiencyNavigatorColors.category.line}
        lineChartData={shortTermData.lineLosses}
      />
    </Stack>
  );
};
