import { useEffect, useState } from "react";
import { Grid2 } from "@mui/material";
import { PhaseEl } from "../../types";
import { Card } from "@/components/Layout/Card";
import { Asset } from "./Asset";
import { readablePhase } from "../../utils/readablePhase";
import { SubphaseListSelect } from "./SubphaseListSelect";
import { PHASE_ORDER } from "../../utils/phaseOrder";

export const PhaseListSelect = ({
  phaseList,
  batchId,
}: {
  phaseList: PhaseEl[];
  batchId: number;
}) => {
  const [selectedPhaseId, setSelectedPhaseId] = useState<number | null>(null);
  const [selectedPhase, setSelectedPhase] = useState<PhaseEl | null>(null);

  useEffect(() => {
    return () => {
      setSelectedPhase(null);
      setSelectedPhaseId(null);
    };
  }, []);

  phaseList.sort(
    (a, b) => PHASE_ORDER.indexOf(a.name) - PHASE_ORDER.indexOf(b.name),
  );

  return (
    <>
      <Grid2
        size={{
          xs: 1,
          md: 1,
        }}
      >
        <Card
          key={"condition-phase-list" + batchId}
          initial={{ opacity: 0, scale: 0.75, x: 20 }}
          animate={{ opacity: 1, scale: 1, x: 0 }}
          exit={{ opacity: 0 }}
          sx={{
            maxHeight: 300,
            overflow: "auto",
          }}
        >
          {phaseList.map((phase) => (
            <Asset
              key={phase.id}
              type="Other"
              active={selectedPhaseId === phase.id}
              name={readablePhase(phase.name)}
              hasWarn={phase.hasWarn}
              id={phase.id}
              onClick={(id) => {
                if (id === selectedPhaseId) {
                  setSelectedPhaseId(null);
                  setSelectedPhase(null);
                } else {
                  setSelectedPhaseId(id);
                  const foundPhase = phaseList.find((Phase) => Phase.id === id);
                  if (foundPhase) setSelectedPhase(foundPhase);
                }
              }}
            />
          ))}
        </Card>
      </Grid2>
      {selectedPhase && selectedPhaseId ? (
        <SubphaseListSelect
          key={selectedPhaseId}
          subphaseList={selectedPhase.subphaseList}
          phaseId={selectedPhaseId}
        />
      ) : null}
    </>
  );
};
