import { Grid2, Stack, useMediaQuery } from "@mui/material";
import { GaugeSkeleton } from "@/features/MachineDetail/components/CircleLayout";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";

export const SkeletonLoaderLayout = () => {
  const smallScreen = useMediaQuery("(min-width:728px)");
  return (
    <Stack
      sx={{
        gap: 2,
      }}
    >
      <Grid2
        sx={{
          display: "flex",
          gap: 2,
        }}
      >
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </Grid2>
      <Grid2
        container
        spacing={smallScreen ? 2 : 1}
        sx={{
          textAlign: "center",
        }}
      >
        <Grid2
          size={{
            md: 2.4,
            xs: 6,
          }}
        >
          <SkeletonCard />
        </Grid2>
        <Grid2
          size={{
            md: 2.4,
            xs: 6,
          }}
        >
          <SkeletonCard />
        </Grid2>
        <Grid2
          size={{
            md: 2.4,
            xs: 6,
          }}
        >
          <SkeletonCard />
        </Grid2>
        <Grid2
          size={{
            md: 2.4,
            xs: 6,
          }}
        >
          <SkeletonCard />
        </Grid2>
        <Grid2
          size={{
            md: 2.4,
            xs: 6,
          }}
        >
          <SkeletonCard />
        </Grid2>
        <Grid2
          size={{
            md: 2.4,
            xs: 6,
          }}
        >
          <SkeletonCard />
        </Grid2>
        <Grid2
          size={{
            md: 2.4,
            xs: 6,
          }}
        >
          <SkeletonCard />
        </Grid2>
        <Grid2
          size={{
            md: 2.4,
            xs: 6,
          }}
        >
          <SkeletonCard />
        </Grid2>
        <Grid2
          size={{
            md: 2.4,
            xs: 6,
          }}
        >
          <SkeletonCard />
        </Grid2>
        <Grid2
          size={{
            md: 2.4,
            xs: 6,
          }}
        >
          <SkeletonCard />
        </Grid2>
      </Grid2>
      <Grid2
        container
        spacing={2}
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Grid2
          spacing={2}
          direction={"column"}
          size={{
            md: 4,
            xs: 12,
          }}
        >
          <Stack
            sx={{
              gap: 2,
              justifyContent: "space-around",
              alignItems: "center",
              height: "100%",
            }}
          >
            <SkeletonCard />
            <SkeletonCard />
          </Stack>
        </Grid2>
        <Grid2
          direction="row"
          spacing={1}
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <GaugeSkeleton
            width={smallScreen ? 150 : 100}
            height={smallScreen ? 150 : 100}
          />
          <GaugeSkeleton
            width={smallScreen ? 150 : 100}
            height={smallScreen ? 150 : 100}
          />
          <GaugeSkeleton width={100} height={100} />
          <GaugeSkeleton width={100} height={100} />
        </Grid2>
      </Grid2>
      <SkeletonCard height={300} />
    </Stack>
  );
};
