import { useFirebaseContext } from "@/context/firebase-context";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { Batch } from "../types";
import { useLyoContext } from "../context/lyo-context";
import { FetchClient } from "@/services/ApiClient";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { useEffect } from "react";
const MACHINE_NAME = "Demo";

type BatchListResponse = ThingworxError | BatchListResult;

const BatchListResult = z.object({
  response: z.literal(true),
  batches: z.array(Batch),
});

type BatchListResult = z.infer<typeof BatchListResult>;

type Payload = {
  from: string;
  to: string;
  machineName: string;
};

export const useGetBatchList = ({ from, to }: { from: Date; to: Date }) => {
  const { appKey } = useFirebaseContext();

  const [, dispatch] = useLyoContext();

  const str_from = DateTime.fromJSDate(new Date(from))
    .startOf("day")
    .toISO() as string;
  const str_to = DateTime.fromJSDate(new Date(to))
    .endOf("day")
    .toISO() as string;

  const query = useQuery<BatchListResult>({
    queryKey: ["batch-list", from, to],
    queryFn: async () => {
      const response = await FetchClient<Payload, BatchListResponse>({
        appKey,
        url: "dig.c.lyophilizer_thing/Services/getBatches",
        payload: {
          from: str_from || "",
          to: str_to || "",
          machineName: MACHINE_NAME,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return BatchListResult.parse(response);
    },
    staleTime: 0,
  });

  useEffect(() => {
    if (query.data) {
      dispatch({ type: "set batches", batches: query.data.batches });
    }
  }, [query.data, dispatch]);

  return query;
};
