import { Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { efficiencyNavigatorColors } from "../../utils/efficiencyNavigatorColors";
import { ChartRow } from "./ChartRow";

export const ChartSection = () => {
  const translate = useTranslate();

  return (
    <Stack
      sx={{
        gap: 2,
        width: "100%",
      }}
    >
      <ChartRow
        title={translate("losses.machine_losses")}
        color={efficiencyNavigatorColors.category.machine}
        lossType="machine"
      />
      <ChartRow
        title={translate("losses.process_losses")}
        color={efficiencyNavigatorColors.category.process}
        lossType="process"
      />
      <ChartRow
        title={translate("losses.line_losses")}
        color={efficiencyNavigatorColors.category.line}
        lossType="line"
      />
    </Stack>
  );
};
