import { useEffect, useState } from "react";
import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import { ONE_MINUTE } from "@/utils/durationsInMilliseconds";
import { socialStore } from "../../store/social-store";
import { IntellectaError, notification } from "../../types";
import { intellectaClient } from "../intellectaClient";

type Response = IntellectaError | Result;

type Payload = {
  user: string;
  last_timestamp: number | null;
};

const result = z.object({
  new_shared_messages: z.array(notification),
});

type Result = z.infer<typeof result>;

export const useGetNewNotifications = (isEnabled: boolean) => {
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  const [startPolling, setStartPolling] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setStartPolling(true);
    }, ONE_MINUTE);

    return () => clearTimeout(timer);
  }, []);

  const {
    notifications,
    addNotification,
    setHasNewSocialNotification,
    isRightDrawerOpen,
    activeRightDrawerTab,
  } = socialStore.getState();

  const latestTimestamp = notifications.length
    ? Math.max(...notifications.map((n) => n.timestamp))
    : null;

  const query = useQuery<Result, Error>({
    enabled: startPolling && isEnabled,
    queryKey: ["intellecta-new-notifications", email, latestTimestamp],
    queryFn: async () => {
      const response = await intellectaClient<Payload, Response>({
        url: "/social/get_new_shared_messages",
        payload: { user: email, last_timestamp: latestTimestamp },
      });

      if (response === "ERROR") {
        throw new Error();
      }

      const validatedResponse = result.parse(response);
      return validatedResponse;
    },
    refetchInterval: ONE_MINUTE,
  });

  useEffect(() => {
    if (query.isSuccess && query.data) {
      // Aggiungi le notifiche allo store
      query.data.new_shared_messages.forEach((notif) => {
        addNotification(notif);
      });

      // Se arrivano notifiche nuove e la tab non è "social" o il drawer è chiuso
      // attiva il badge della campanellina
      if (query.data.new_shared_messages.length > 0) {
        if (!isRightDrawerOpen || activeRightDrawerTab !== "social") {
          setHasNewSocialNotification(true);
        }
      }
    }
  }, [
    query.isSuccess,
    query.data,
    addNotification,
    setHasNewSocialNotification,
    isRightDrawerOpen,
    activeRightDrawerTab,
  ]);

  return query;
};
