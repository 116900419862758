import { Grid2, Stack } from "@mui/material";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";

export const EfficiencyNavigatorLoader = () => {
  return (
    <Stack
      sx={{
        gap: 2,
      }}
    >
      <Grid2 container spacing={2}>
        <Grid2
          size={{
            lg: 4,
            xs: 12,
          }}
        >
          <SkeletonCard height="30vh" />
        </Grid2>
        <Grid2
          size={{
            lg: 8,
            xs: 12,
          }}
        >
          <SkeletonCard height="30vh" />
        </Grid2>
      </Grid2>
      <Grid2 container spacing={2}>
        <Grid2
          size={{
            md: 6,
            xs: 12,
          }}
        >
          <SkeletonCard height={300} />
        </Grid2>
        <Grid2
          size={{
            md: 6,
            xs: 12,
          }}
        >
          <SkeletonCard height={300} />
        </Grid2>
      </Grid2>
      <Grid2 container spacing={2}>
        <Grid2
          size={{
            md: 6,
            xs: 12,
          }}
        >
          <SkeletonCard height={300} />
        </Grid2>
        <Grid2
          size={{
            md: 6,
            xs: 12,
          }}
        >
          <SkeletonCard height={300} />
        </Grid2>
      </Grid2>
      <Grid2 container spacing={2}>
        <Grid2
          size={{
            md: 6,
            xs: 12,
          }}
        >
          <SkeletonCard height={300} />
        </Grid2>
        <Grid2
          size={{
            md: 6,
            xs: 12,
          }}
        >
          <SkeletonCard height={300} />
        </Grid2>
      </Grid2>
    </Stack>
  );
};
