import { useRef, useEffect, useState } from "react";
import { Tooltip, Typography } from "@mui/material";

export const TitleWithTooltip = ({ title }: { title: string }) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      const element = textRef.current;
      if (element) {
        setIsOverflowing(element.scrollWidth > element.clientWidth);
      }
    };

    checkOverflow();

    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [title]);

  return (
    <Tooltip
      title={isOverflowing ? title : ""}
      disableHoverListener={!isOverflowing}
      placement="top"
    >
      <Typography
        ref={textRef}
        align="center"
        sx={{
          fontSize: "11px",
          paddingBottom: ".5rem",
          paddingTop: 0,
          fontWeight: "bold",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          paddingRight: "1rem",
        }}
        component={"div"}
      >
        {title}
      </Typography>
    </Tooltip>
  );
};
