import { TranslationKey, Translations } from "@/i18n/config";

export const translateItemTitle = (
  title: string,
  translate: (key: Translations) => string,
): string => {
  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (weekDays.includes(title)) {
    return translate(`days.${title.toLowerCase()}` as TranslationKey);
  }

  const dateRangeRegex =
    /^([A-Za-z]+)\s+(\d+),\s+\d+\s+-\s+([A-Za-z]+)\s+(\d+),\s+\d+$/;
  const match = title.match(dateRangeRegex);

  if (match) {
    const [, startMonth, startDay, endMonth, endDay] = match;
    const translatedStartMonth = months.includes(startMonth)
      ? translate(`months.${startMonth.toLowerCase()}` as TranslationKey)
      : startMonth;
    const translatedEndMonth = months.includes(endMonth)
      ? translate(`months.${endMonth.toLowerCase()}` as TranslationKey)
      : endMonth;

    return `${startDay} ${translatedStartMonth} - ${endDay} ${translatedEndMonth} `;
  }

  if (title.startsWith("Shift-")) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [prefix, number] = title.split("-");
    return `${translate("timelapse.shift")} ${number}`;
  }

  if (title.startsWith("Week")) {
    const weekNumber = title.slice(4); // Prende il numero dopo "Week"
    return `${translate("durations.week")} ${weekNumber}`;
  }

  if (title.startsWith("Recipe")) {
    return `${translate("machine.recipe")}`;
  }

  return title;
};

export const translateIntervals = (
  title: string,
  translate: (key: Translations) => string,
): string => {
  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (weekDays.includes(title)) {
    return translate(`days.${title.toLowerCase()}` as TranslationKey);
  }

  const dateRangeRegex =
    /^([A-Za-z]+)\s+(\d+),\s+\d+\s+-\s+([A-Za-z]+)\s+(\d+),\s+\d+$/;
  const match = title.match(dateRangeRegex);

  if (match) {
    const [, startMonth, startDay, endMonth, endDay] = match;
    const translatedStartMonth = months.includes(startMonth)
      ? translate(`months.${startMonth.toLowerCase()}` as TranslationKey)
      : startMonth;
    const translatedEndMonth = months.includes(endMonth)
      ? translate(`months.${endMonth.toLowerCase()}` as TranslationKey)
      : endMonth;

    return `${startDay} ${translatedStartMonth} - ${endDay} ${translatedEndMonth} `;
  }

  if (title.includes("Shift-")) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [prefix, number] = title.split("-");
    return title.replace(/Shift-(\d+)/, (_, number) => {
      const translatedShift = translate("timelapse.shift");
      return `${translatedShift} ${number}`;
    });
  }

  if (title.includes("Week")) {
    return title.replace(/Week(\d+)/, (_, number) => {
      const translatedWeek = translate("durations.week");
      return `${translatedWeek} ${number}`;
    });
  }

  //ipotizzando che passi anche recipe
  if (title.includes("Recipe")) {
    return title.replace(/Recipe(\d+)/, (_, number) => {
      const translatedWeek = translate("machine.recipe");
      return `${translatedWeek} ${number}`;
    });
  }

  return title;
};

export const translateReportField = (
  value: string,
  translate: (key: Translations) => string,
): string => {
  // Handle report types (weekly, monthly, etc.)
  const reportTypes: Record<string, string> = {
    weekly: "reports.type_weekly",
    monthly: "reports.type_monthly",
    daily: "reports.type_daily",
    yearly: "reports.type_yearly",
  };

  if (reportTypes[value.toLowerCase()]) {
    return translate(reportTypes[value.toLowerCase()] as TranslationKey);
  }

  // Handle week format (week-49)
  const weekMatch = value.toLowerCase().match(/^week[- ](\d+)$/);
  if (weekMatch) {
    return `${translate("durations.week")} ${weekMatch[1]}`;
  }

  return value;
};
