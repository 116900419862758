import { Box, Button, Stack, Typography } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { StateInfo } from "@/features/ConfigurableOee/api/useGetConfigurableOeeData";
import { ConfigurableStateCardList } from "./ConfigurableStateCardList";
import { Modal } from "@/components/Modal";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useTranslate } from "@/i18n/config";
import {
  StateChangedInfo,
  useSetConfiguration,
} from "../../api/useSetCustomEfficiency";
import { Loader } from "@/components/Layout/Loader";
import { toast } from "react-hot-toast";

export const ConfigManager = ({
  states,
  referenceOee,
  referenceAvailability,
  referencePerformance,
  referenceTotalDuration,
  isFetchingData,
  view,
}: {
  states: StateInfo[];
  referenceOee: number;
  referenceAvailability: number;
  referencePerformance: number;
  referenceTotalDuration: number;
  isFetchingData?: boolean;
  view: "live" | "historic";
}) => {
  const translate = useTranslate();
  const { open, close, isOpen } = useDisclosure();
  const { mutate: setConfiguration } = useSetConfiguration();
  const [isPostUpdating, setIsPostUpdating] = useState(false);
  const [selectedState, setSelectedState] = useState<StateChangedInfo | null>(
    null,
  );
  const [modalAskContent, setModalAskContent] = useState<ReactNode>("");

  /* //! se isClientRelevance è true significa non impattante e viceversa*/
  const left = states.filter((state) => state.isClientRelevance);
  const right = states.filter((state) => !state.isClientRelevance);

  const handleLeftCard = ({
    stateName,
    id,
    isClientRelevance,
  }: {
    stateName: string;
    id: number;
    isClientRelevance: boolean;
  }) => {
    setSelectedState({ stateName, id, isClientRelevance });
    setModalAskContent(
      <>
        {translate("configurable_oee.ask_message")}{" "}
        <Typography component="span" sx={{ fontWeight: "bold" }}>
          {stateName}
        </Typography>{" "}
        {translate("configurable_oee.ask_message_2")}?
      </>,
    );
    open();
  };

  const handleRightCard = ({
    stateName,
    id,
    isClientRelevance,
  }: {
    stateName: string;
    id: number;
    isClientRelevance: boolean;
  }) => {
    setSelectedState({ stateName, id, isClientRelevance });
    setModalAskContent(
      <>
        {translate("configurable_oee.ask_message")}{" "}
        <Typography component="span" sx={{ fontWeight: "bold" }}>
          {stateName}
        </Typography>{" "}
        {translate("configurable_oee.ask_message_3")}?
      </>,
    );
    open();
  };

  const onConfirm = () => {
    if (!selectedState?.id) return;
    setConfiguration(
      { stateConfigured: selectedState },
      {
        onSuccess: () => {
          setIsPostUpdating(true);
        },
      },
    );
    close();
  };

  useEffect(() => {
    if (isPostUpdating && !isFetchingData) {
      toast.success(translate("configurable_oee.configured_successfully"));
      setIsPostUpdating(false);
    }
  }, [isPostUpdating, isFetchingData, translate]);

  return (
    <>
      {isPostUpdating && <Loader isLoading={true} />}
      <Modal
        titleContent={translate("configurable_oee.configure_state")}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={isOpen && !!selectedState}
        bodyContent={
          <Box>
            <Typography>{modalAskContent}</Typography>
          </Box>
        }
        actions={
          <>
            <Button variant="outlined" onClick={close}>
              {translate("actions.cancel")}
            </Button>
            <Button variant="contained" onClick={onConfirm}>
              {translate("actions.confirm")}
            </Button>
          </>
        }
      />
      <Stack
        direction="row"
        spacing={2}
        sx={{
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <ConfigurableStateCardList
            title={translate("configurable_oee.states_not_impacting_oee")}
            items={left}
            statesLength={states.length}
            referenceOee={referenceOee}
            referencePerformance={referencePerformance}
            referenceAvailability={referenceAvailability}
            referenceTotalDuration={referenceTotalDuration}
            onClick={handleLeftCard}
            view={view}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <ConfigurableStateCardList
            title={translate("configurable_oee.states_impacting_oee")}
            items={right}
            statesLength={states.length}
            referenceOee={referenceOee}
            referencePerformance={referencePerformance}
            referenceAvailability={referenceAvailability}
            referenceTotalDuration={referenceTotalDuration}
            onClick={handleRightCard}
            view={view}
          />
        </Box>
      </Stack>
    </>
  );
};
