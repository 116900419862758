import { Box } from "@mui/material";
import { AssetLayout } from "./AssetLayout";
import { Evaluation } from "../../types";
import { Check, PriorityHigh } from "@mui/icons-material";
import { EVALUATION_COLORS } from "../../utils/evaluationColors";

const assetVariants = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: {
    y: 10,
    opacity: 0,
  },
};

type Props =
  | {
      type: "Tag";
      name: string;
      id: number;
      onClick: (asset_id: number) => void;
      active: boolean;
      evaluation: Evaluation;
    }
  | {
      type: "Other";
      name: string;
      id: number;
      onClick: (asset_id: number) => void;
      active: boolean;
      hasWarn: boolean;
    };

export const Asset = (props: Props) => {
  const { type, active, id, name, onClick } = props;

  return (
    <AssetLayout
      key={id}
      variants={assetVariants}
      sx={{ backgroundColor: active ? "#3E4657" : "transparent" }}
      onClick={() => {
        onClick(id);
      }}
    >
      <Box>{name}</Box>
      {type === "Tag" ? (
        <Box
          sx={{
            backgroundColor: EVALUATION_COLORS[props.evaluation].selected,
            aspectRatio: "1/1",
            width: ".75rem",
            height: ".75rem",
            borderRadius: ".75rem",
          }}
        />
      ) : props.hasWarn ? (
        <PriorityHigh sx={{ color: EVALUATION_COLORS.warning.selected }} />
      ) : (
        <Check sx={{ color: EVALUATION_COLORS.good.selected }} />
      )}
    </AssetLayout>
  );
};
