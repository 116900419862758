import { Stack, Grid2 } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { NavButton } from "@/components/NavButton";
import { CustomSelectTimeSelection } from "@/components/SelectTimeSelection/CustomSelectTimeSelection";
import {
  TestCircleLayoutLoader,
  GaugeSkeleton,
} from "@/features/MachineDetail/components/CircleLayout";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";

export const ProcessLoadingView = () => {
  const translate = useTranslate();
  return (
    <>
      <MachineViewNavBar>
        <CustomSelectTimeSelection
          exclude={[
            "TwoWeeks",
            "Month",
            "ThreeMonths",
            "SixMonths",
            "Year",
            "Custom",
          ]}
        />
        <NavButton
          to="/machine-detail/process/historic"
          text={translate("view_historic")}
        />
      </MachineViewNavBar>
      <Stack
        sx={{
          gap: 2,
        }}
      >
        <Grid2
          container
          columns={12}
          spacing={2}
          sx={{
            alignItems: "center",
          }}
        >
          <Grid2
            size={{
              xs: 6,
              md: 4,
            }}
          >
            <TestCircleLayoutLoader height={"12vmax"} />
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              md: 4,
            }}
          >
            <Stack
              sx={{
                gap: 2,
              }}
            >
              <SkeletonCard height={100} />
              <SkeletonCard height={100} />
            </Stack>
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              md: 4,
            }}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TestCircleLayoutLoader height={"12vmax"} />
          </Grid2>
        </Grid2>
        <Grid2
          id="machine-detail-gauge"
          container
          columns={12}
          sx={{
            alignItems: "center",
          }}
        >
          <Grid2
            size={{
              md: 1,
            }}
            sx={{
              alignItems: "center",
              flex: 1,
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <GaugeSkeleton
              sx={{
                padding: 1,
                margin: "auto",
                width: "9vmax",
                height: "9vmax",
              }}
            />
          </Grid2>

          <Grid2
            size={{
              md: 1,
            }}
            sx={{
              alignItems: "center",
              flex: 1,
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <GaugeSkeleton
              sx={{
                padding: 1,
                margin: "auto",
                width: "9vmax",
                height: "9vmax",
              }}
            />
          </Grid2>
          <Grid2
            size={{
              md: 1,
            }}
            sx={{
              alignItems: "center",
              flex: 1,
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <GaugeSkeleton
              sx={{
                padding: 1,
                margin: "auto",
                width: "9vmax",
                height: "9vmax",
              }}
            />
          </Grid2>
          <Grid2
            size={{
              md: 1,
            }}
            sx={{
              alignItems: "center",
              flex: 1,
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <GaugeSkeleton
              sx={{
                padding: 1,
                margin: "auto",
                width: "9vmax",
                height: "9vmax",
              }}
            />
          </Grid2>
          <Grid2
            size={{
              md: 1,
            }}
            sx={{
              alignItems: "center",
              flex: 1,
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <GaugeSkeleton
              sx={{
                padding: 1,
                margin: "auto",
                width: "9vmax",
                height: "9vmax",
              }}
            />
          </Grid2>
        </Grid2>
        <SkeletonCard height={300} />
      </Stack>
    </>
  );
};
