import { useState, useEffect } from "react";
import {
  ComparisonKpiType,
  KpiProperty,
} from "../api/useGetConfigurableOeeData";

export const useKpiDifferences = (props: ComparisonKpiType) => {
  const [kpiInfosDifferences, setKpiInfosDifferences] = useState<KpiProperty>({
    oee: 0,
    availability: 0,
    idleTime: 0,
    scheduledTime: 0,
    notScheduledTime: 0,
    performance: 0,
  });

  const oeeDiff = +(props.customKpi.oee - props.standardKpi.oee).toFixed(2);
  const availabilityDiff = +(
    props.customKpi.availability - props.standardKpi.availability
  ).toFixed(2);
  const performanceDiff = +(
    props.customKpi.performance - props.standardKpi.performance
  ).toFixed(2);
  const idleTimeDiff = +(
    props.customKpi.idleTime - props.standardKpi.idleTime
  ).toFixed(2);
  const scheduledTimeDiff = +(
    props.customKpi.scheduledTime - props.standardKpi.scheduledTime
  ).toFixed(2);
  const notScheduledTimeDiff = +(
    props.customKpi.notScheduledTime - props.standardKpi.notScheduledTime
  ).toFixed(2);

  useEffect(() => {
    setKpiInfosDifferences({
      oee: oeeDiff,
      availability: availabilityDiff,
      performance: performanceDiff,
      idleTime: idleTimeDiff,
      scheduledTime: scheduledTimeDiff,
      notScheduledTime: notScheduledTimeDiff,
    });
  }, [
    props,
    oeeDiff,
    availabilityDiff,
    performanceDiff,
    idleTimeDiff,
    scheduledTimeDiff,
    notScheduledTimeDiff,
  ]);

  return kpiInfosDifferences;
};
