import { useState } from "react";
import { z } from "zod";
import { XrangePointOptionsObject } from "highcharts/highstock";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { useDisclosure } from "@/hooks/useDisclosure";
import { XRangeChart } from "@/components/highcharts/xrange/XRange";
import { StateLogDataEntry } from "../api/useGetData";
import { getCustomHeightFromN } from "../utils/getCustomHeightFromN";
import { ErrorStateModal } from "./ErrorStateModal";
import { DATA_BATCH_SIZE, getDataBatches } from "../utils/getDataBatches";
import { mapXrangeData } from "../utils/mapXRangeData";

export const StatesLogChart = ({
  data,
  onSetExtremes,
  isGrouped,
  setIsGrouped,
}: {
  data: StateLogDataEntry[];
  onSetExtremes: ({ min, max }: { min: number; max: number }) => void;
  isGrouped: boolean;
  setIsGrouped: (isGrouped: boolean) => void;
}) => {
  const { categories, chartData } = mapXrangeData({
    data,
  });

  const isBigData = data.length > DATA_BATCH_SIZE;
  const dataBatches = getDataBatches(chartData);

  return isBigData ? (
    <ChartBigData
      categories={categories}
      dataBatches={dataBatches}
      onSetExtremes={onSetExtremes}
      isGrouped={isGrouped}
      setIsGrouped={setIsGrouped}
    />
  ) : (
    <Chart
      categories={categories}
      data={chartData}
      onSetExtremes={onSetExtremes}
      isGrouped={isGrouped}
      setIsGrouped={setIsGrouped}
    />
  );
};

const Chart = ({
  data,
  onSetExtremes,
  isGrouped,
  setIsGrouped,
  categories,
}: {
  data: XrangePointOptionsObject[];
  onSetExtremes: ({ min, max }: { min: number; max: number }) => void;
  isGrouped: boolean;
  setIsGrouped: (isGrouped: boolean) => void;
  categories: string[];
}) => {
  const translate = useTranslate();
  const { open, isOpen, close } = useDisclosure();
  const [errorStateTimestamp, setErrorStateTimestamp] = useState<{
    start: number | null;
    end: number | null;
  }>({ start: null, end: null });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsGrouped(event.target.checked);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={isGrouped}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={translate("state_log.grouped_by_root_cause")}
          />
        </FormGroup>
      </Box>
      <XRangeChart
        key={"state-log-chart-" + data.length}
        enableStepZoomOut={true}
        categories={categories}
        customHeight={getCustomHeightFromN(categories.length)}
        data={data}
        title={translate("state_log.machine_states_log")}
        xAxisOptions={{
          events: {
            afterSetExtremes: (e) => {
              onSetExtremes({ min: e.min, max: e.max });
            },
          },
        }}
        seriesOptions={{
          events: {
            click: function (event) {
              const point: XrangePointOptionsObject = event.point;
              const parseResult = z
                .object({
                  custom: z.object({
                    isError: z.boolean().optional(),
                  }),
                })
                .safeParse(point);
              if (!parseResult.success) return;

              const {
                custom: { isError },
              } = parseResult.data;
              if (isError) {
                setErrorStateTimestamp({
                  start: point.x!,
                  end: point.x2!,
                });
                open();
              }
            },
          },
        }}
      />
      {isOpen && errorStateTimestamp.start && errorStateTimestamp.end && (
        <ErrorStateModal
          isOpen={isOpen}
          close={close}
          dateStart={errorStateTimestamp.start}
          dateEnd={errorStateTimestamp.end}
        />
      )}
    </>
  );
};

const ChartBigData = ({
  dataBatches,
  onSetExtremes,
  isGrouped,
  setIsGrouped,
  categories,
}: {
  dataBatches: { [key: string]: XrangePointOptionsObject[] };
  onSetExtremes: ({ min, max }: { min: number; max: number }) => void;
  isGrouped: boolean;
  setIsGrouped: (isGrouped: boolean) => void;
  categories: string[];
}) => {
  const translate = useTranslate();
  const [errorStateTimestamp, setErrorStateTimestamp] = useState<{
    start: number | null;
    end: number | null;
  }>({ start: null, end: null });
  const [selectedBatch, setSelectedBatch] = useState(
    Object.keys(dataBatches)[0],
  );
  const [batchData, setBatchData] = useState(dataBatches[selectedBatch]);
  const { open, isOpen, close } = useDisclosure();

  const handleSelectBatch = (
    _: React.MouseEvent<HTMLElement>,
    newBatch: string,
  ) => {
    if (!newBatch) return;
    setSelectedBatch(newBatch);
    setBatchData(dataBatches[newBatch]);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsGrouped(event.target.checked);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 25,
        }}
      >
        <ToggleButtonGroup
          value={selectedBatch}
          exclusive
          onChange={handleSelectBatch}
          aria-label="Variables"
          sx={{ backgroundColor: "#1F293F", alignSelf: "center" }}
        >
          {Object.keys(dataBatches).map((batch) => (
            <ToggleButton key={batch} value={batch}>
              {batch}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={isGrouped}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={translate("state_log.grouped_by_root_cause")}
          />
        </FormGroup>
      </Box>
      <XRangeChart
        key={"state-log-chart-batches-" + batchData.length}
        enableStepZoomOut={true}
        categories={categories}
        customHeight={getCustomHeightFromN(categories.length)}
        data={batchData}
        title={translate("state_log.machine_states_log")}
        xAxisOptions={{
          events: {
            afterSetExtremes: (e) => {
              onSetExtremes({ min: e.min, max: e.max });
            },
          },
        }}
        seriesOptions={{
          events: {
            click: function (event) {
              const point: XrangePointOptionsObject = event.point;
              const parseResult = z
                .object({
                  custom: z.object({
                    isError: z.boolean().optional(),
                  }),
                })
                .safeParse(point);
              if (!parseResult.success) return;

              const {
                custom: { isError },
              } = parseResult.data;
              if (isError) {
                setErrorStateTimestamp({
                  start: point.x!,
                  end: point.x2!,
                });
                open();
              }
            },
          },
        }}
      />
      {isOpen && errorStateTimestamp.start && errorStateTimestamp.end && (
        <ErrorStateModal
          isOpen={isOpen}
          close={close}
          dateStart={errorStateTimestamp.start}
          dateEnd={errorStateTimestamp.end}
        />
      )}
    </>
  );
};
