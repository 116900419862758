import { useTranslate } from "@/i18n/config";
import {
  Box,
  CircularProgress,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { EVALUATION_COLORS } from "../../../context/acopos-context";
import { useGetSystemHealth } from "../../../api/Common/useGetSystemHealth";

export const SystemHealthStatus = () => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetSystemHealth();
  if (error) return <div>{translate("user_feedback.an_error_occurred")}</div>;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: ".3rem",
        border: "1px solid #2f3a52",
        p: ".5rem",
        borderRadius: ".5rem",
      }}
    >
      <Typography>
        {translate("health_status.system_health_status")}:
      </Typography>
      {isLoading && <CircularProgress sx={{ maxHeight: 24, maxWidth: 24 }} />}
      {data && data.healthStatus && (
        <Tooltip
          title={translate(`evaluation.${data.healthStatus}`)}
          placement="top"
          slots={{
            transition: Zoom,
          }}
        >
          <Box
            sx={{
              width: "24px",
              height: "8px",
              backgroundColor: EVALUATION_COLORS[data.healthStatus].selected,
              borderRadius: "5px",
            }}
          ></Box>
        </Tooltip>
      )}
    </Box>
  );
};
