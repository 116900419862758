import { useMutation, useQueryClient } from "@tanstack/react-query";
import { gCloudClient } from "@/services/gCloudClient";
import { encode } from "@/utils/b64_encoder";

export const useDeleteUser = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async ({ email }: { email: string }) => {
      await gCloudClient<{ email: string }, null>({
        service: "DELETE_USER",
        queryStrings: {
          email: encode(email),
        },
      });
    },
    onSuccess() {
      client.invalidateQueries({ queryKey: ["get-colleagues"] });
    },
  });
};
