import { Box, Stack, Typography } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import {
  ResSchema,
  useGetDecompositionData,
} from "../../api/Diagnostic/useGetDecompositionData";
import { Card } from "@/components/Layout/Card";
import { ContextInformation } from "@/features/Motors/components/Layouts/ContextInformation";
import { ScatterPlot } from "@/components/highcharts/scatter/Scatter";
import { useDisclosure } from "@/hooks/useDisclosure";
import { DecompositionDetailModal } from "./DecompositionDetailModal";
import { useState } from "react";
import { EVALUATION_COLORS } from "../../context/acopos-context";

export const DecompositionAnalytics = ({
  analytics,
}: {
  analytics: number[];
}) => {
  const { data, isLoading, error } = useGetDecompositionData({ analytics });
  const translate = useTranslate();

  if (isLoading)
    return <AnalyticContainerSkeleton width="calc(100% - 320px)" />;

  if (error)
    return (
      <Card sx={{ width: "calc(100% - 320px)" }}>
        {translate("user_feedback.an_error_occurred")}
      </Card>
    );

  if (!data)
    return (
      <Card sx={{ width: "calc(100% - 320px)" }}>
        {translate("user_feedback.no_data")}
      </Card>
    );

  return (
    <Stack
      sx={{
        gap: 1,
        width: "calc(100% - 320px)",
      }}
    >
      {data.data.length > 0 ? (
        data.data.map((analytic, index) => (
          <Analytic
            key={`${analytic.analyticName}-${index}`}
            props={analytic}
          />
        ))
      ) : (
        <Card sx={{ height: 400 }}>
          <Typography sx={{ color: "red" }} variant="h4">
            {translate("motors.no_selection")}
          </Typography>
          <Typography variant="h6">
            {translate("acopos.select_at_least_one_analytic")}
          </Typography>
        </Card>
      )}
    </Stack>
  );
};

interface DecompositionPoint {
  x: number;
  y: number;
  custom: {
    runId: number;
    lapId: number;
    segmentId: number;
    shuttleId: number;
  };
}

const Analytic = ({ props }: { props: ResSchema }) => {
  const translate = useTranslate();
  const { open, close, isOpen } = useDisclosure();
  const [info, setInfo] = useState<
    | {
        runId: number;
        lapId: number;
        segmentId: number;
        shuttleId: number;
      }
    | undefined
  >(undefined);
  const { analyticName, analyticId, data, contextInfo, chartType, thresholds } =
    props;
  const chartData: DecompositionPoint[] = data.map((entry) => {
    return {
      x: entry.timestamp,
      y: entry.value,
      custom: {
        runId: entry.runId,
        lapId: entry.lapId,
        segmentId: entry.segmentId,
        shuttleId: entry.shuttleId,
      },
    };
  });

  return (
    <>
      {isOpen && info && (
        <DecompositionDetailModal
          info={info}
          analyticId={analyticId}
          isOpen={isOpen}
          close={close}
        />
      )}
      <Card>
        <Box>
          <ContextInformation props={contextInfo} key={analyticName} />
          {chartType === "scatter" && (
            <ScatterPlot.Custom
              title={analyticName}
              yAxis={[
                {
                  uom: "",
                  series: [
                    {
                      type: "scatter",
                      name: analyticName,
                      data: chartData,
                      cursor: "pointer",
                      point: {
                        events: {
                          click: function () {
                            const point = this as unknown as DecompositionPoint;
                            if (point) {
                              setInfo({
                                runId: point.custom.runId,
                                lapId: point.custom.lapId,
                                segmentId: point.custom.segmentId,
                                shuttleId: point.custom.shuttleId,
                              });
                              open();
                            }
                          },
                        },
                      },
                    },
                  ],
                  options: { min: 0, max: 1 },
                  thresholds: [
                    {
                      text: translate("threshold.warning_upper"),
                      color: EVALUATION_COLORS.warning.selected,
                      value: thresholds.warning_upper,
                    },
                    {
                      text: translate("threshold.critical_upper"),
                      color: EVALUATION_COLORS.critical.selected,
                      value: thresholds.critical_upper,
                    },
                  ],
                },
              ]}
              scrollbarEnabled={false}
              tooltip={{
                enabled: true,
                options: {
                  formatter: function () {
                    const point = this.point as unknown as DecompositionPoint;
                    if (point) {
                      return `
                        <span style="color:${
                          this.color
                        }">●</span> ${analyticName}<br>
                        ${new Date(point.x).toLocaleString()}<br>
                        ${translate("acopos.lap")} ${point.custom.lapId}<br>
                        ${translate("value")}: <b>${point.y}</b>
                      `;
                    }
                    return `
                      ${analyticName}<br>
                      ${new Date(this.x!).toLocaleString()}<br>
                      ${translate("value")}: <b>${this.y}</b>
                    `;
                  },
                },
              }}
            />
          )}
        </Box>
      </Card>
    </>
  );
};
