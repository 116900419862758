import { ShowChart, BarChart } from "@mui/icons-material";
import {
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Button,
} from "@mui/material";
import { Fragment, useState } from "react";
import { SquaredRadioButton } from "../../layout/SquaredRadioButton";
import { Loader } from "@/components/Layout/Loader";

import {
  useCreateChartContext,
  useCreateChartDispatch,
} from "../../context/create-chart-context";

import { useGetMachines } from "../../api/useGetMachines";
import { ChartType } from "../../types/variable";
import { useTranslate } from "@/i18n/config";

export const SelectChartType = () => {
  const translate = useTranslate();
  const [machine, setMachine] = useState("");
  const [chartType, setChartType] = useState<ChartType | null>(null);

  const { closeModal } = useCreateChartContext();
  const dispatch = useCreateChartDispatch();

  const { data: machines = [], isLoading } = useGetMachines();

  if (isLoading) {
    return <Loader isLoading />;
  }

  const allowConfirm = machine && chartType;

  const onConfirmHandler = () => {
    if (machine && chartType) {
      dispatch({
        type: "set chart type",
        chart_type: chartType,
        machine: machine,
      });
    }
  };

  return (
    <Fragment>
      <DialogTitle>{translate("custom_charts.create_chart")}</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            gap: 2,
          }}
        >
          <Typography variant="body1">{translate("select_machine")}</Typography>
          <FormControl fullWidth>
            <InputLabel id="machine-label">{translate("machine")}</InputLabel>
            <Select
              labelId="machine-label"
              id="machine"
              value={machine}
              label={translate("machine")}
              onChange={(e) => {
                setMachine(e.target.value as string);
              }}
            >
              {machines?.map((machine) => (
                <MenuItem key={machine} value={machine}>
                  {machine}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="body1">
            {translate("custom_charts.select_chart_type")}
          </Typography>
          <Stack
            direction="row"
            sx={{
              gap: 8,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SquaredRadioButton
              activeValue={chartType}
              onClick={() => setChartType("timeseries")}
              value="timeseries"
            >
              Timeseries
              <ShowChart />
            </SquaredRadioButton>
            <SquaredRadioButton
              activeValue={chartType}
              onClick={() => setChartType("aggregated")}
              value="aggregated"
            >
              {translate("custom_charts.aggregated")}
              <BarChart />
            </SquaredRadioButton>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeModal}>
          {translate("actions.cancel")}
        </Button>
        <Button
          sx={{ color: "white" }}
          variant="contained"
          color="success"
          onClick={onConfirmHandler}
          disabled={!allowConfirm}
        >
          {translate("actions.confirm")}
        </Button>
      </DialogActions>
    </Fragment>
  );
};
