import { Dispatch, SetStateAction } from "react";
import { Variable } from "../api/useGetVariableList";
import { Box, Typography } from "@mui/material";
import { Condition } from "./Condition";
import { useTranslate } from "@/i18n/config";

interface ConditionsProps {
  variables: Variable[];
  machine: string;
  setSelectedVariables: Dispatch<SetStateAction<Variable[]>>;
}

export const Conditions = ({
  variables,
  machine,
  setSelectedVariables,
}: ConditionsProps) => {
  const translate = useTranslate();
  return (
    <>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Typography sx={{ flex: 0.5 }}></Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            flex: 2,
          }}
        >
          {translate("machine")}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            flex: 3,
          }}
        >
          {translate("variable")}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            flex: 2,
          }}
        >
          {translate("math.operator")}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            flex: 1,
          }}
        >
          {translate("value")} 1
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            flex: 1,
          }}
        >
          {translate("value")} 2
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            flex: 1,
          }}
        >
          {translate("events.cumulative")}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            flex: 1,
          }}
        >
          {translate("events.enable_duration")}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            flex: 2,
          }}
        >
          {translate("duration")}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            flex: 1.2,
          }}
        >
          {translate("uom")}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            flex: 1,
          }}
        >
          {translate("threshold")}
        </Typography>
      </Box>
      {variables.map((variable) => (
        <Condition
          key={variable.propertyName}
          variable={variable}
          machine={machine}
          setSelectedVariables={setSelectedVariables}
        />
      ))}
    </>
  );
};
