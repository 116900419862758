import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import { intellectaClient } from "../intellectaClient";
import { userConfigurationStore } from "../../store/user-configuration-store";
import {
  IntellectaErrorObj,
  IntellectaResultObj,
  intellectaResultObj,
} from "../../types";

type Response = IntellectaErrorObj | IntellectaResultObj;

type Payload = {
  user: string;
  prompt_id: number;
};

export const useDeletePrompt = () => {
  const queryClient = useQueryClient();
  const { user } = useFirebaseContext();
  const email = user?.email || "";
  const { version } = userConfigurationStore();

  return useMutation({
    mutationFn: async ({ prompt_id }: { prompt_id: number }) => {
      if (!version) {
        throw new Error();
      }

      const response = await intellectaClient<Payload, Response>({
        url: "/prompt/delete",
        payload: {
          user: email,
          prompt_id,
        },
      });

      if (response.value === "ERROR") throw new Error();

      const validatedResponse = intellectaResultObj.parse(response);
      return validatedResponse;
    },
    onSuccess(data) {
      if (data.value === "OK") {
        //* per scaricare di nuovo i prompt
        queryClient.invalidateQueries({
          queryKey: ["intellecta-config", email],
        });
      }
    },
  });
};
