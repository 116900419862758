import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { useLanguage } from "@/i18n/config";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { FetchClient } from "@/services/ApiClient";
import { ONE_SECOND } from "@/utils/durationsInMilliseconds";
import { ThingworxError } from "src/types/error";
import { lossesData } from "../types";

export const shortTermData = z.object({
  machineLosses: lossesData,
  lineLosses: lossesData,
  processLosses: lossesData,
});

export type ShortTermData = z.infer<typeof shortTermData>;

export const shortTermDataResult = z.object({
  response: z.literal(true),
  data: shortTermData,
});

export type ShortTermDataResult = z.infer<typeof shortTermDataResult>;

export type ShortTermDataResponse = ThingworxError | ShortTermDataResult;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  language_id: string;
};

export const useGetShortTermData = () => {
  const { machine } = useMachineContext();
  const { timeSelection } = useTimeSelection();
  const { appKey } = useFirebaseContext();
  const language = useLanguage();

  return useQuery<ShortTermDataResult>({
    queryKey: [
      "efficiency-navigator-short-term",
      machine!.machine,
      timeSelection,
      language,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, ShortTermDataResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
          timeSelection,
          language_id: language,
        },
        url: "dig.c.benchmark_thing/Services/getShortTermData",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return shortTermDataResult.parse(response);
    },
    refetchInterval: ONE_SECOND * 30,
  });
};
