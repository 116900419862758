import { ImputationStatsData } from "../types";

//? valutare di ordinare in ordine decrescente
//? bisogna chiaramente riordinare anche le categories
export const parseStatsData = (
  data: { [key: string]: ImputationStatsData[] },
  selectedState: string,
  defaultState: string,
): {
  categories: string[];
  countData: number[];
  percentageData: number[];
} => {
  const categories: string[] = [];
  const countData: number[] = [];
  const percentageData: number[] = [];

  if (selectedState === defaultState) {
    const states = Object.keys(data);
    states.forEach((state) => {
      data[state].forEach((imputation) => {
        const { category, occurrences, percentage } = imputation;
        const index = categories.indexOf(category);
        if (index !== -1) {
          const countValueToUpdate = countData[index];
          const percentageValueToUpdate = percentageData[index];
          countData.splice(index, 1, countValueToUpdate + occurrences);
          percentageData.splice(
            index,
            1,
            percentageValueToUpdate + occurrences,
          );
        } else {
          categories.push(category);
          countData.push(occurrences);
          percentageData.push(percentage);
        }
      });
    });
  } else {
    data[selectedState].forEach((imputation) => {
      const { category, occurrences, percentage } = imputation;
      categories.push(category);
      countData.push(occurrences);
      percentageData.push(percentage);
    });
  }

  return { categories, countData, percentageData };
};
