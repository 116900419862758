import { ProductionBox } from "@/components/CustomIcons/ProductionBox";
import { Box, Popover, Typography } from "@mui/material";
import { useState } from "react";
import { TestCircleLayout } from "./CircleLayout";
import { useTranslate } from "@/i18n/config";

export const ProductCounter = ({
  counter = 0,
  size = "large",
  color = "#51A5DE",
  text,
  interactionWaste,
  lineWaste,
  goodCounter,
  machineWaste,
  page,
}: {
  counter?: number;
  size?: "large" | "small" | "extrasmall";
  color?: string;
  text: string;
  goodCounter?: number;
  machineWaste?: number;
  lineWaste?: number;
  interactionWaste?: number;
  page?: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const translate = useTranslate();
  const open = Boolean(anchorEl);

  return (
    <>
      <TestCircleLayout
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          fontSize: {
            xs: ".75rem",
            sm: "1rem",
          },
        }}
        size={size}
      >
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "grid",
            },
            backgroundColor: color,
            borderRadius: 9999,
            height: "56px",
            width: "56px",
            aspectRatio: 1 / 1,
            placeItems: "center",
          }}
        >
          <ProductionBox width={36} height={36} />
        </Box>
        <Box>{counter}</Box>
        <Box>{text}</Box>
      </TestCircleLayout>
      {page === "machineDetail" ? (
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
            padding: 2,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box sx={{ p: 1 }}>
            <Typography>{`${translate("good")}: ${goodCounter}`}</Typography>
            <Typography>{`${translate(
              "waste_machine",
            )}: ${machineWaste}`}</Typography>
            <Typography>{`${translate(
              "waste_line",
            )}: ${lineWaste}`}</Typography>
            <Typography>{`${translate(
              "waste_interaction",
            )}: ${interactionWaste}`}</Typography>
          </Box>
        </Popover>
      ) : null}
    </>
  );
};
