import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { batch } from "../../types";
import { MAX_BATCHES_NUMBER } from "../../context/isolator-context";
import { useIsolatorDispatchContext } from "../../context/useIsolatorDispatchContext";

export type GetBatchesAndRecipesResponse =
  | ThingworxError
  | GetBatchesAndRecipesResult;

type Payload = {
  machineName: string;
  dateStart: number;
  dateEnd: number;
};

const getBatchesAndRecipesResult = z.object({
  response: z.literal(true),
  batches: z.array(batch),
  recipes: z.array(z.string()),
});

export type GetBatchesAndRecipesResult = z.infer<
  typeof getBatchesAndRecipesResult
>;

export const useGetBatchesAndRecipes = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { dates } = useTimeSelection();
  const dispatch = useIsolatorDispatchContext();

  return useQuery<GetBatchesAndRecipesResult>({
    queryKey: [
      "batches-recipes-data",
      machine!.machine,
      dates.dateEnd,
      dates.dateStart,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, GetBatchesAndRecipesResponse>(
        {
          appKey,
          payload: {
            machineName: machine!.machine,
            dateStart: dates.dateStart.toMillis(),
            dateEnd: dates.dateEnd.toMillis(),
          },
          url: "dig.c.isolator_thing/Services/getBatchesAndRecipes",
        },
      );

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getBatchesAndRecipesResult.parse(response);

      if (validatedResponse.batches.length > 0) {
        if (validatedResponse.batches.length >= MAX_BATCHES_NUMBER) {
          dispatch({
            type: "UPDATE_BATCHES",
            item: validatedResponse.batches.slice(0, MAX_BATCHES_NUMBER),
          });
        } else {
          dispatch({ type: "UPDATE_BATCHES", item: validatedResponse.batches });
        }
      } else {
        dispatch({ type: "CLEAR_BATCHES" });
      }

      return validatedResponse;
    },
    gcTime: 0,
  });
};
