import { useQueryClient } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import { sentryModeStore } from "../store/sentry-mode-store";
import { chatStore } from "../store/chat-store";

export const useNewChat = () => {
  const { user } = useFirebaseContext();
  const email = user?.email || "";
  const queryClient = useQueryClient();
  const { resetTimer } = sentryModeStore();
  const { clearChat } = chatStore();

  const startNewChat = () => {
    clearChat();
    resetTimer();
    queryClient.invalidateQueries({
      queryKey: ["intellecta-historic-chat-list", email],
    });
  };

  return { startNewChat };
};
