import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { Stack, Typography, styled, Skeleton, Grid2 } from "@mui/material";

export const MachineInfoLoaderLayout = styled(Skeleton)(() => ({
  backgroundColor: "#151B2F",
  color: "#fff",
  width: "100%",
  backgroundImage: "none",
  padding: "1rem",
  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  borderRadius: ".25rem",
  transform: "none",
  maxWidth: "100%",
}));

export const LoaderLayout = () => {
  return (
    <Stack
      sx={{
        gap: 2,
        height: "calc(100vh - 128px)",
      }}
    >
      <Grid2 container spacing={2}>
        <Grid2
          size={{
            xs: 6,
            md: 3,
          }}
        >
          <MachineInfoLoaderLayout>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
            ></Typography>
          </MachineInfoLoaderLayout>
        </Grid2>

        <Grid2
          size={{
            xs: 6,
            md: 3,
          }}
        >
          <MachineInfoLoaderLayout>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
            ></Typography>
          </MachineInfoLoaderLayout>
        </Grid2>

        <Grid2
          size={{
            xs: 6,
            md: 3,
          }}
        >
          <MachineInfoLoaderLayout>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
            ></Typography>
          </MachineInfoLoaderLayout>
        </Grid2>

        <Grid2
          size={{
            xs: 6,
            md: 3,
          }}
        >
          <MachineInfoLoaderLayout>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
            ></Typography>
          </MachineInfoLoaderLayout>
        </Grid2>

        <Grid2
          size={{
            xs: 6,
            md: 3,
          }}
        >
          <MachineInfoLoaderLayout>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
            ></Typography>
          </MachineInfoLoaderLayout>
        </Grid2>

        <Grid2
          size={{
            xs: 6,
            md: 3,
          }}
        >
          <MachineInfoLoaderLayout>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
            ></Typography>
          </MachineInfoLoaderLayout>
        </Grid2>

        <Grid2
          size={{
            xs: 6,
            md: 3,
          }}
        >
          <MachineInfoLoaderLayout>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
            ></Typography>
          </MachineInfoLoaderLayout>
        </Grid2>

        <Grid2
          size={{
            xs: 6,
            md: 3,
          }}
        >
          <MachineInfoLoaderLayout>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
            ></Typography>
          </MachineInfoLoaderLayout>
        </Grid2>

        <Grid2
          size={{
            xs: 6,
            md: 6,
          }}
        >
          <MachineInfoLoaderLayout>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
            ></Typography>
          </MachineInfoLoaderLayout>
        </Grid2>

        <Grid2
          size={{
            xs: 6,
            md: 6,
          }}
        >
          <MachineInfoLoaderLayout>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
            ></Typography>
          </MachineInfoLoaderLayout>
        </Grid2>
      </Grid2>
      <AnalyticContainerSkeleton sx={{ height: "30vh", flex: 1 }} />
    </Stack>
  );
};
