import { faker } from "@faker-js/faker";
import { Variable, DataVariable } from "../types/variable";

export const generatePreviewData = (variables: Variable[]): DataVariable[] => {
  return variables.map((variable) => transformVariable(variable));
};

const transformVariable = (variable: Variable): DataVariable => {
  switch (variable.series_type) {
    case "bar":
      return {
        ...variable,
        series_type: "bar",
        data: {
          name: faker.random.word(),
          categories: ["x bar", "y bar", "z bar"],
          series: Array.from({ length: 3 }, () => ({
            name: faker.random.word(),
            values: Array.from({ length: 10 }, () =>
              faker.datatype.number({
                min: 0,
                max: 2,
              }),
            ),
          })),
        },
      };

    case "line":
      return {
        ...variable,
        series_type: "line",
        data: {
          name: faker.random.word(),
          values: Array.from({ length: 10 }, () => [
            +faker.date.recent(),
            faker.datatype.number(),
          ]).sort((a, b) => a[0] - b[0]),
        },
      };

    case "scatter":
      return {
        ...variable,
        series_type: "scatter",
        data: {
          name: faker.random.word(),
          values: Array.from(
            {
              length: 100,
            },
            () => ({
              x: +faker.date.recent(),
              y: faker.datatype.number({
                min: 0,
                max: 2,
              }),
            }),
          ),
          categories: ["x scatter", "y scatter", "z scatter"],
        },
      };
    case "xrange":
      return {
        ...variable,
        series_type: "xrange",
        data: {
          name: faker.random.word(),
          values: Array.from(
            {
              length: 10,
            },
            () => {
              const x = +faker.date.recent();
              return {
                x,
                x2:
                  x + faker.datatype.number({ min: 3600000, max: 3600000 * 3 }),
                y: faker.datatype.number({
                  min: 0,
                  max: 2,
                }),
              };
            },
          ),
          categories: ["x xrange", "y xrange", "z xrange"],
        },
      };

    case "pie":
      return {
        ...variable,
        series_type: "pie",
        data: {
          name: faker.random.word(),
          values: [
            {
              name: "test 1",
              y: 1,
              z: 1,
            },
            {
              name: "test 2",
              y: 2,
              z: 2,
            },
            {
              name: "test 3",
              y: 3,
              z: 3,
            },
          ],
        },
      };
  }

  variable.series_type satisfies unknown;
};
