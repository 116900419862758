import { Navigate } from "react-router-dom";
import { useMachineContext } from "@/context/machine-context";

export const MachinePerformance = () => {
  const { machine } = useMachineContext();

  if (!machine) return null;

  return (
    <>
      {machine.machineType === "cyclic" ? (
        <Navigate to={"/machine-performance/cyclic"} replace />
      ) : (
        <Navigate to={"/machine-performance/process"} replace />
      )}
    </>
  );
};
