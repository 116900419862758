import { Card } from "@/components/Layout/Card";
import { ChevronRightOutlined } from "@mui/icons-material";
import { Grid2, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { CategoryCode } from "../api/CyclicLive/useGetSentinelLiveData";
import { CategoryIcon } from "./CategoryIcon";

export const CurrentMachineState = ({
  color,
  categoryCode,
  state,
  linkTo,
  dateTimeHandler,
  iconSize = 64,
}: {
  color: string;
  categoryCode: CategoryCode;
  state: string;
  linkTo?: string;
  dateTimeHandler?: () => void;
  iconSize?: number;
}) => {
  return (
    <Card sx={{ color }}>
      <Grid2
        columns={12}
        container
        spacing={1}
        sx={{
          alignItems: "center",
        }}
      >
        <Grid2
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <CategoryIcon
            width={iconSize}
            height={iconSize}
            categoryCode={categoryCode}
            fill={color}
          />
        </Grid2>
        <Grid2
          sx={{
            flex: 3,
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
            }}
          >
            {state}
          </Typography>
        </Grid2>
        {linkTo ? (
          <Grid2
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <NavLink
              to={linkTo}
              style={{ color, display: "flex" }}
              onClick={dateTimeHandler}
            >
              <ChevronRightOutlined color="inherit" />
            </NavLink>
          </Grid2>
        ) : (
          <Grid2
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          />
        )}
      </Grid2>
    </Card>
  );
};
