import { Line } from "@/features/OverallLineEfficiency/api/useGetUserProductionLines";
import { useDisclosure } from "@/hooks/useDisclosure";
import { Edit } from "@mui/icons-material";
import styles from "./ConfigurationModalButton.module.css";

import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { LineConfig } from "../LineConfig";
import { Configuration } from "../../api/useGetLineConfiguration";
import { EditAutomaticLineConfigurationButton } from "@/features/OverallLineEfficiency/components/EditAutomaticLineConfigurationButton/EditAutomaticLineConfigurationButton";
import { EditManualLineConfigurationButton } from "@/features/OverallLineEfficiency/components/EditManualLineConfigurationButton/EditManualLineConfigurationButton";
import { useTranslate } from "@/i18n/config";

export const ConfigurationModal = ({
  line,
  config_values,
  view,
}: {
  line: Line;
  config_values: Configuration;
  view: "automatic" | "manual";
}) => {
  const { open, close, isOpen } = useDisclosure();
  const translate = useTranslate();
  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="close"
        className={styles.pulse}
        onClick={open}
      >
        <Edit />
      </IconButton>
      <Dialog open={isOpen} onClose={close} fullWidth>
        <DialogActions sx={{ backgroundColor: "#0d1626" }}>
          {view === "automatic" ? (
            <Stack
              direction="row"
              sx={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  padding: 1,
                }}
              >
                {translate("line.new_configuration")}
              </Typography>
              <EditAutomaticLineConfigurationButton line={line} />
            </Stack>
          ) : (
            <Stack
              direction="row"
              sx={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  padding: 1,
                }}
              >
                {translate("line.new_configuration")}
              </Typography>
              <EditManualLineConfigurationButton line={line} />
            </Stack>
          )}
        </DialogActions>
        <DialogContent sx={{ padding: 0, margin: 0 }}>
          {<LineConfig config={config_values} />}
        </DialogContent>
      </Dialog>
    </>
  );
};
