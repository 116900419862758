import { useEffect } from "react";
import { Card } from "@/components/Layout/Card";
import { useLanguage, useTranslate } from "@/i18n/config";
import { DateFormat, dateTimeFormatStore } from "@/store/useDateTimeFormat";
import { timezoneStore, TimezoneType } from "@/store/useTimezoneStore";
import {
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

export const SettingsTimezone = () => {
  const { timezoneType, setTimezoneType, setTimezone, machineTimezone } =
    timezoneStore();
  const {
    setDateFormat,
    setTimeFormat,
    timeFormat,
    dateFormat,
    resetDateFormat,
  } = dateTimeFormatStore();

  const translate = useTranslate();
  const language = useLanguage();

  const handleTimezone = (selectedValue: TimezoneType) => {
    const newTimezone =
      selectedValue === "machine"
        ? machineTimezone
        : Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimezoneType(selectedValue);
    setTimezone(newTimezone);
  };

  useEffect(() => {
    if (language !== "en") {
      resetDateFormat();
    }
  }, [language, resetDateFormat]);

  const handleDateFormatChange = (
    event: React.MouseEvent<HTMLElement>,
    newFormat: DateFormat,
  ) => {
    if (newFormat !== null && language === "en") {
      setDateFormat(newFormat);
    }
  };

  const handleTimeFormatChange = (
    event: React.MouseEvent<HTMLElement>,
    newFormat: "24h" | "12h",
  ) => {
    if (newFormat !== null) {
      setTimeFormat(newFormat);
    }
  };

  return (
    <Card>
      <Stack spacing={1.5}>
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              padding: 1,
            }}
          >
            {translate("settings.timezone.section.timezone")}
          </Typography>
          <ToggleButtonGroup
            value={timezoneType}
            exclusive
            onChange={(event, newValue) => {
              if (newValue !== null) {
                handleTimezone(newValue);
              }
            }}
            aria-label="text alignment"
            size="small"
          >
            <ToggleButton value="user" aria-label="left aligned">
              <Typography
                sx={{
                  paddingX: 1,
                }}
              >
                {translate("user")}
              </Typography>
            </ToggleButton>
            <ToggleButton value="machine" aria-label="right aligned">
              <Typography
                sx={{
                  paddingX: 1,
                }}
              >
                {translate("machine")}
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              padding: 1,
            }}
          >
            {translate("settings.timezone.section.time_format")}
          </Typography>
          <ToggleButtonGroup
            value={timeFormat}
            exclusive
            onChange={handleTimeFormatChange}
            aria-label="text alignment"
            size="small"
          >
            <ToggleButton value={"24h"} aria-label="left aligned">
              <Typography
                sx={{
                  paddingX: 1,
                }}
              >
                24h
              </Typography>
            </ToggleButton>
            <ToggleButton value={"12h"} aria-label="right aligned">
              <Typography
                sx={{
                  paddingX: 1,
                }}
              >
                12h am/pm
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              padding: 1,
            }}
          >
            {translate("settings.timezone.section.date_format")}
          </Typography>
          <ToggleButtonGroup
            value={dateFormat}
            exclusive
            onChange={handleDateFormatChange}
            aria-label="text alignment"
            size="small"
          >
            <ToggleButton
              value={"en-US"}
              aria-label="left aligned"
              disabled={language !== "en"}
            >
              <Typography
                sx={{
                  paddingX: 1,
                }}
              >
                mm-dd-yyyy
              </Typography>
            </ToggleButton>
            <ToggleButton
              value={"en-GB"}
              aria-label="right aligned"
              disabled={language !== "en"}
            >
              <Typography
                sx={{
                  paddingX: 1,
                }}
              >
                dd-mm-yyyy
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Stack>
    </Card>
  );
};
